import React, { useEffect, useState } from "react";
import { getUser, getToken, tokenExpired, getUserName } from "../../utilities/common";
import { useLocation, useNavigate } from "react-router-dom";
import {Row, Col, Container, Carousel, Modal} from 'react-bootstrap'
import moment from "moment";

// components
import Navbar from "../Navbar/Navbar"
import LackingRequirements from "../Modals/LackingRequirements";

//icons
import CakeIcon from '@mui/icons-material/Cake';
import CelebrationIcon from '@mui/icons-material/Celebration';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import empty_profile from '../../images/empty_profile.png'
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DownloadIcon from '@mui/icons-material/Download';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PreviewIcon from '@mui/icons-material/Preview';

// css
import "./Dashboard.css";
import "../../utilities/common.css"
import '../Modals/modal.css';
import 'bootstrap/dist/css/bootstrap.css';

function Dashboard() {
    // Account Details id receiver
    const [announcements, setAnnouncements] = useState([]);
    const [memos, setMemos] = useState([]);
    const [companyFiles, setCompanyFiles] = useState([]);
    const [lackingRequirements, setLackingRequirements] = useState([]);
    const [birthdays, setBirthdays] = useState([]);
    const [anniversaries, setAnniversaries] = useState([]);
    const [employmentStatus, setEmploymentStatus] = useState([]);
    const [employeeStatus, setEmployeeStatus] = useState([]);
    const [recentActivities, setRecentActivities] = useState([]);
    const [showFileAttachments, setShowFileAttachments] = useState({});
    const [attachmentsShown, setAttachmentsShown] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [lackingEmployees, setLackingEmployees] = useState([]);

    const userToken = getToken();
    const userId = getUser();
    const userName = getUserName();

    // Clock and Date
    const [dateState, setDateState] = useState(new Date())
    useEffect(() => {
        setInterval(() => setDateState(new Date()), 30000)
    }, []);

    function getCurrentWeekRange() {
        const currentDate = new Date();
        const currentDay = currentDate.getDay();
        const diff = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1);
        const difference = diff - 1; // deducted 1 because advance one day ang returned dates
        const sunday = new Date(currentDate.setDate(difference));
        const saturday = new Date(currentDate.setDate(difference + 6));
        return { sunday, saturday };
    }

    function SentenceCaseName (name) {
        const sentenceCasedName = name.toLowerCase().replace(/(^|\s)[a-z]/g, (char) => char.toUpperCase());
        return sentenceCasedName
    }

    function dateDifference (start, end) {
        const startDate = new Date(start);
        const endDate = new Date(end);

        const diffInMilliseconds = endDate.getTime() - startDate.getTime();
        const diffInYears = diffInMilliseconds / 31536000000;
        const diff = Math.round(diffInYears);
        return diff;
    }

    const toggleRow = (id) => {
        getFileAttachmentsByFolderId(id);
        setShowFileAttachments({ ...showFileAttachments, [id]: !showFileAttachments[id] });
    };

    function viewFile (fileUrl) {
        var type_string = fileUrl.split(';')[0].split('/')[0].split(':')[1];
        var extension =  fileUrl.split(';')[0].split('/')[1];

        var newTab = window.open("");
        if (type_string === "image") {
            var image = new Image();
            image.src = fileUrl;
            newTab.document.write(image.outerHTML);
        } else {
            newTab.document.write("<object width='100%' height='100%' type='application/pdf' data='" + fileUrl + "'></object>")
        }
    };

    function renderAnnouncementSlide() {
        return (
            <Carousel className="memo-height">
                {
                   (announcements && announcements.length) ? announcements.map((data) => {
                        var rawHTML = data.content?data.content:'';
                        return (
                            <Carousel.Item>
                            <Container className="justify-content-center p-4 wrap-text">
                                <Row className="">
                                    <Col xs={6} className="welcome-msg">
                                        {data.subject ? data.subject : ''}
                                    </Col>
                                    <Col xs={6}>
                                        <Row className="d-flex justify-content-end">
                                            <Col xs={6} className="date-container me-1 text-center">{data.announcement_date?data.announcement_date:''}</Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {/* <Row className='p-3 justify-content-center'>{data.content?data.content:''}</Row> */}
                                <div dangerouslySetInnerHTML={{__html: rawHTML}} className='p-3'/>
                            </Container>
                        </Carousel.Item>
                        )
                    }) : <Row  className="d-flex justify-content-center profile-height">
                            <label className='d-flex justify-content-center dashboard-subheader'>No upcoming announcements</label>
                        </Row>
                }
            </Carousel>
        );
    }

    function renderMemoSlide() {
        return (
            <Carousel className="memo-height">
                {
                    (memos && memos.length) ? memos.map((data) => {
                        var rawHTML = data.content?data.content:'';
                        return (
                            <Carousel.Item>
                            <Container className="justify-content-center p-4 wrap-text">
                                <Row className="">
                                    <Col xs={6} className="welcome-msg">
                                        {data.subject ? data.subject : ''}
                                    </Col>
                                    <Col xs={6}>
                                        <Row className="d-flex justify-content-end">
                                            <Col xs={6} className="date-container me-1 text-center">{data.memo_date?data.memo_date:''}</Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div dangerouslySetInnerHTML={{__html: rawHTML}} className='p-3'/>
                                {/* <Row className='p-3 justify-content-center'>{data.content?data.content:''}</Row> */}
                            </Container>
                        </Carousel.Item>
                        )
                    }) : <Row  className="d-flex justify-content-center profile-height">
                            <label className='d-flex justify-content-center dashboard-subheader'>No upcoming memos</label>
                        </Row>
                }
            </Carousel>
        );
    }

    function renderLackingEmployees (lacking_employees) {
        setShowModal(true);
        setLackingEmployees(lacking_employees)
    }

    function getFileAttachmentsByFolderId (folder_id) {
        attachmentsShown.length = 0;
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'companies/show',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                company_id: folder_id
            }
        }).then(response => {
            const res = response.data.data.company;
            setAttachmentsShown(res)
        }).catch(function (error) {
            console.log(error);
        });
    }

    // Fetch Birthdays and Anniversaries
    React.useEffect(() => {
        anniversaries.length = 0;
        birthdays.length = 0;
        var axios = require('axios');
        var qs = require('qs');
        axios({
            method: 'post',
            url: window.$link + 'employees/get_all',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: '',
            })
        }).then(response => {
            const allEmployees = response.data.employees;
            const weekRange = getCurrentWeekRange();
            const sunday = moment(weekRange.sunday).format('MM-DD');
            const saturday = moment(weekRange.saturday).format('MM-DD');
            const currentDate = new Date();
            const start = new Date();
            const end = new Date(currentDate.setDate(currentDate.getDate() + 7));
            const startDate = moment(start).format('MM-DD');
            const endDate = moment(end).format('MM-DD');
            allEmployees.map((data) => {
                var bday = moment(data.birthdate).format('MM-DD');
                var date_hired = moment(data.date_hired).format('MM-DD');

                if (bday >= startDate && bday <= endDate) {
                    var info = [];
                    let mid_name = data.middle_name ? data.middle_name: ''
                    let full_name = data.first_name + ' ' + mid_name + ' ' + data.last_name
                    info.full_name = SentenceCaseName(full_name);
                    info.birthdate = moment(data.birthdate).format('MMM D, YYYY');
                    setBirthdays((prevArray) => [...prevArray, info]);
                };

                if (date_hired >= startDate && date_hired  <= endDate) {
                    var info = {};
                    let mid_name = data.middle_name ? data.middle_name: ''
                    let full_name = data.first_name + ' ' + mid_name + ' ' + data.last_name
                    info.full_name = SentenceCaseName(full_name);
                    info.date_hired = moment(data.date_hired).format('MMM D, YYYY');
                    info.year_count = dateDifference(data.date_hired, currentDate);
                    setAnniversaries((prevArray) => [...prevArray, info])
                };
            });
        }).catch(function (error) {
        });
    }, []);

    // Fetch Announcement Details
    React.useEffect(() => {
        announcements.length = 0;
        var axios = require('axios');
        var qs = require('qs');

        axios({
            method: 'get',
            url: window.$link + 'announcements/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                announcement_id: ""
            }
        }).then(response => {
            const res = response.data.data.announcements;
            const currentDate = new Date();
            const start = new Date();
            const end = new Date(currentDate.setDate(currentDate.getDate() + 7));
            const startDate = moment(start).format('MM-DD');
            const endDate = moment(end).format('MM-DD');
            res.map((data) => {
                var ann_date = moment(data.announcement_date).format('MM-DD');
                if (ann_date >= startDate && ann_date <= endDate) {
                    var info = [];
                    info.subject = data.subject ? SentenceCaseName(data.subject) : '';
                    info.announcement_date = data.announcement_date ? moment(data.announcement_date).format('MMM D, YYYY') : '';
                    info.content = data.content ? data.content : '';
                    setAnnouncements((prevArray) => [...prevArray, info]);
                }
            })
        }).catch(function (error) {
            console.log(error);
        });
    },[]);

    // Fetch memo details
    React.useEffect(() => {
        memos.length = 0;
        var axios = require('axios');
        var qs = require('qs');

        axios({
            method: 'get',
            url: window.$link + 'memos/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                memo_id: ""
            }
        }).then(response => {
            const res = response.data.data.memos;
            const currentDate = new Date();
            const start = new Date();
            const end = new Date(currentDate.setDate(currentDate.getDate() + 7));
            const startDate = moment(start).format('MM-DD');
            const endDate = moment(end).format('MM-DD');
            res.map((data) => {
                var mem_date = moment(data.memo_date).format('MM-DD');
                if (mem_date >= startDate && mem_date <= endDate) {
                    var info = [];
                    info.subject = data.subject ? SentenceCaseName(data.subject) : '';
                    info.memo_date = data.memo_date ? moment(data.memo_date).format('MMM D, YYYY') : '';
                    info.content = data.content ? data.content : '';
                    setMemos((prevArray) => [...prevArray, info]);
                }
            })
        }).catch(function (error) {
        });
    },[]);

    // Get Employment Status Report
    React.useEffect (() => {
        employmentStatus.length = 0;
        var axios = require('axios');
        var qs = require('qs');

        axios({
            method: 'get',
            url: window.$link + 'dashboard/get_employment_status_report',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
            }
        }).then(response => {
           setEmploymentStatus(response.data.reports);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

     // Get Employee Status Report
     React.useEffect (() => {
        employeeStatus.length = 0;
        var axios = require('axios');
        var qs = require('qs');

        axios({
            method: 'get',
            url: window.$link + 'dashboard/get_employee_status_report',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
            }
        }).then(response => {
           setEmployeeStatus(response.data.reports);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    // Get Recent Activities
    React.useEffect(() => {
        var axios = require('axios');
        var qs = require('qs');
        const currentDate = new Date();
        const end = new Date();
        const start = new Date(currentDate.setDate(currentDate.getDate() - 7));
        const endDate = moment(end).format('MM-DD-YYYY');
        const startDate = moment(start).format('MM-DD-YYYY');

        axios ({
            method: 'post',
            url: window.$link + 'users/get_recent_activities',
            headers : {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                date_from : startDate,
                date_to: endDate
            }
        }).then(response => {
            const res = response.data.data;
            res.map((data) => {
                var info = [];
                if (data.method === "add") {
                    var method = "Added";
                } else if (data.method === "update") {
                    var method = "Updated";
                } else if (data.method === "delete") {
                    var method = "Deleted";
                }
                var controller = SentenceCaseName(data.controller.slice(0, -1));
                info.activity = method + ' ' + controller;
                info.date = moment(data.responded_on).format('MMMM D, YYYY');
                setRecentActivities((prevArray) => [...prevArray, info]);
            });
            
        }).catch(function (error) {
            console.log(error)
        })
    }, []);

     // Get all company folders & files
     React.useEffect(() => {
        companyFiles.length = 0;

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'companies/get_all',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
            }
        }).then(response => {
            const res = response.data.data.company;
            setCompanyFiles(response.data.data.company);
        }).catch(function (error) {
            console.log(error);
        });
    },[]);

     // Get Requirements
     React.useEffect(() => {
        lackingRequirements.length = 0;

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'dashboard/get_requirement_lacking_count',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
            }
        }).then(response => {
            setLackingRequirements(response.data.reports);
        }).catch(function (error) {
            console.log(error);
        });
    },[]);

    
    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                {/* Headers */}
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Col xs={4} className="">
                        <Row className="mt-0">
                            <Col>
                                <Container className="dashboard-section-container">
                                    <Row className="justify-content-center">
                                        <div className='profile-pic-container align-middle me-3'>
                                            <img src={empty_profile} className='profile-photo'/>
                                        </div>
                                    </Row>
                                    
                                    <Row className='welcome-msg p-3 d-flex justify-content-center'>
                                        <h4 className="d-flex justify-content-center">Welcome, {userName?userName:''}!</h4>
                                    </Row>
                                    <Row className="">
                                        <Row className='modal-header adjusted-font p-0 py-0 ms-3'>Recent Activities</Row>
                                        <Container className="profile-height">
                                            {
                                            (recentActivities && recentActivities.length) ? recentActivities.map((data, index) => {
                                                return (
                                                <Row className="p-2">
                                                    <Col xs={2}>
                                                        <TaskAltIcon className="orange"/>
                                                    </Col>
                                                    <Col> 
                                                        <Row className='dashboard-subheader'>{data.activity}</Row>
                                                        <Row className='activity-date'>{data.date}</Row>
                                                    </Col>
                                                </Row>
                                                )
                                            }) : <Row  className="d-flex justify-content-center profile-height">
                                                    <label className='d-flex justify-content-center dashboard-subheader'>No recent activities</label>
                                                </Row>
                                        }
                                        </Container>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="">
                        <Row className="dashboard-section-container m-0 mb-1">
                            <span className='comm-header-employee ms-2'>
                            {dateState.toLocaleString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                            })} <span className='comm-header-employee primary-color'>
                                {dateState.toLocaleDateString('en-GB', {
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric',
                                })}
                            </span>
                            </span>
                        </Row>
                        <Row>
                            <Col>
                                <Container className="dashboard-section-container mt-1 birthday-height">
                                    <Row className='modal-header adjusted-font p-0 ms-2'>Birthdays</Row>
                                    {
                                       (birthdays && birthdays.length) ? birthdays.map((data, index) => {
                                            return (
                                            <Row className="p-2">
                                                <Col xs={2}>
                                                    <CakeIcon className="orange"/>
                                                </Col>
                                                <Col> 
                                                    <Row className='dashboard-subheader'>{data.full_name ? data.full_name : ''}</Row>
                                                    <Row className='activity-date'>Born on {data.birthdate?data.birthdate:''}</Row>
                                                </Col>
                                            </Row>
                                            )
                                        }) : <Row  className="d-flex justify-content-center profile-height">
                                                <label className='d-flex justify-content-center dashboard-subheader'>No upcoming birthdays</label>
                                            </Row>
                                    }
                                </Container>     
                            </Col>
                            <Col>
                                <Container className="dashboard-section-container mt-1 birthday-height">
                                    <Row className='modal-header adjusted-font p-0 ms-2'>Anniversaries</Row>
                                    {
                                        (anniversaries && anniversaries.length) ? anniversaries.map((data, index) => {
                                            return (
                                            <Row className="p-2">
                                                <Col xs={2}>
                                                    <CelebrationIcon className="orange"/>
                                                </Col>
                                                <Col> 
                                                    <Row className='dashboard-subheader'>{data.full_name?data.full_name:''}</Row>
                                                    <Row className='activity-date'>Hired on {data.date_hired?data.date_hired:''} | {data.year_count?data.year_count:''} yrs</Row>
                                                </Col>
                                            </Row>
                                            )
                                        }) : <Row  className="d-flex justify-content-center profile-height">
                                                <label className='d-flex justify-content-center dashboard-subheader'>No upcoming anniversaries</label>
                                            </Row>
                                    }
                                </Container>     
                            </Col>
                        </Row>       
                    </Col>
                </Row> 

                <Row className="p-1 mt-1" style={{    
                    width: "100%",
                    }}>
                    <Col xs={6}>
                        <Container className="dashboard-section-container mt-1">
                            <Row className='modal-header adjusted-font p-0 ms-2'>Announcements</Row>
                                {renderAnnouncementSlide()}
                        </Container>            
                    </Col>
                    <Col xs={6}>
                        <Container className="dashboard-section-container mt-1">
                            <Row className='modal-header adjusted-font p-0 ms-2'>Memorandum</Row>
                            {renderMemoSlide()}
                        </Container>            
                    </Col>
                </Row> 
                <Row className="p-1 mt-1" style={{    
                    width: "100%",
                    }}>
                    <Col xs={6}>
                        <Container className="dashboard-section-container mt-1">
                            <Row className='modal-header adjusted-font p-0 ms-2'>Requirements</Row>
                            <Row className="me-3 mt-3 mb-3 d-flex justify-content-center req-height">
                                {
                                (lackingRequirements && lackingRequirements.length) ? (
                                    lackingRequirements.map((data) => {
                                        return (
                                            <Col>
                                                <Container className="dashboard-section-container box p-3 pointer" onClick={() =>renderLackingEmployees(data.employees)}>
                                                        <Row  className="d-flex justify-content-center">
                                                            <label className='d-flex justify-content-center dashboard-subheader'>{data.count}</label>
                                                        </Row>
                                                        <Row  className="d-flex justify-content-center">
                                                            <label className='d-flex justify-content-center activity-date bold text-color'>{data.status}</label>
                                                        </Row>
                                                </Container>
                                            </Col>
                                        )
                                    })
                                    ) : (
                                        <Row  className="d-flex justify-content-center profile-height">
                                            <label className='d-flex justify-content-center dashboard-subheader'>NO AVAILABLE DATA</label>
                                        </Row>
                                    )
                                }
                            </Row>  
                        </Container>            
                    </Col>
                    <Col xs={6}>
                        <Container className="dashboard-section-container mt-1">
                            <Row className='modal-header adjusted-font p-0 ms-2'>Company Files</Row>
                            <Row className="memo-height mt-3">
                            {
                                (companyFiles && companyFiles.length) ? companyFiles.map((folder, index) => {
                                    return (
                                        <>
                                            <Row className="px-3 pointer" onClick={() => toggleRow(folder.id)}>
                                                <Col className="ms-3">
                                                    <span className="blueIconContainer me-3"><FolderOpenIcon/></span> <span className='folder-name py-0'>{folder?folder.name:''}</span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {
                                                    showFileAttachments[folder.id] && (
                                                        // folder.file_attachments ? (
                                                        //     folder.file_attachments.map((file) => {
                                                        attachmentsShown ? (
                                                            attachmentsShown.map((file) => {
                                                                return (
                                                                    <Row className="ms-5 py-2">
                                                                        <Col className='file-name'>
                                                                            <span className="me-3"><FileCopyIcon/></span><span className='file-name'>{file?file.file_name:''}</span>
                                                                        </Col>
                                                                        {/* <Col md={{ span: 2, offset: 5 }} className='d-flex justify-content-center'> */}
                                                                        <Col xs={3} className='d-flex justify-content-center'>
                                                                            <Row>
                                                                                <Col>
                                                                                    <a href={`${file.file_attachment}`} download={`${file.file_name}`}>
                                                                                        <DownloadIcon className="mx-1 pointer"/>
                                                                                    </a>
                                                                                </Col>
                                                                                <Col><PreviewIcon className="pointer" onClick={() => viewFile(file.file_attachment)}/></Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            })
                                                        ) : ('')
                                                    )
                                                }
                                            </Row>
                                        </>
                                    )
                                }) : <Row  className="d-flex justify-content-center profile-height">
                                        <label className='d-flex justify-content-center dashboard-subheader'>NO AVAILABLE DATA</label>
                                    </Row>
                            }
                            </Row>
                        </Container>            
                    </Col>
                </Row> 
                <Row className="p-1 mt-1 mb-3" style={{    
                    width: "100%",
                    }}>
                    <Col xs={6}>
                        <Container className="dashboard-section-container mt-1">
                            <Row className='modal-header adjusted-font p-0 ms-2'>Employment Status</Row>
                            <Row className="me-3 mt-3 mb-3 d-flex justify-content-center req-height">
                                {
                                (employmentStatus && employmentStatus.length) ? employmentStatus.map((data, index) => {
                                    return (
                                        <Col>
                                            <Container className="dashboard-section-container box p-3">
                                                    <Row  className="d-flex justify-content-center">
                                                        <label className='d-flex justify-content-center dashboard-subheader'>{data.count}</label>
                                                    </Row>
                                                    <Row  className="d-flex justify-content-center">
                                                        <label className='d-flex justify-content-center activity-date bold text-color'>{data.name}</label>
                                                    </Row>
                                            </Container>
                                        </Col>
                                    )
                                    }) : 
                                        <Row  className="d-flex justify-content-center profile-height">
                                            <label className='d-flex justify-content-center dashboard-subheader'>NO AVAILABLE DATA</label>
                                        </Row>
                                }
                            </Row>  
                        </Container>            
                    </Col>
                    <Col xs={6}>
                        <Container className="dashboard-section-container mt-1">
                            <Row className='modal-header adjusted-font p-0 ms-2'>Employee Status</Row>
                            <Row className="me-3 mt-3 mb-3 d-flex justify-content-center req-height">
                                {
                                (employeeStatus && employeeStatus.length) ? employeeStatus.map((data, index) => {
                                    return (
                                        <Col>
                                            <Container className="dashboard-section-container box p-3">
                                                <Row  className="d-flex justify-content-center">
                                                    <label className='d-flex justify-content-center dashboard-subheader'>{data.count}</label>
                                                </Row>
                                                <Row  className="d-flex justify-content-center">
                                                    <label className='d-flex justify-content-center activity-date bold text-color'>{data.name}</label>
                                                </Row>
                                            </Container>
                                        </Col>
                                    )
                                    }) : 
                                        <Row  className="d-flex justify-content-center profile-height">
                                            <label className='d-flex justify-content-center dashboard-subheader'>NO AVAILABLE DATA</label>
                                        </Row>
                                }
                            </Row>  
                        </Container>            
                    </Col>
                </Row> 

                <LackingRequirements
                    show = {showModal}
                    hide = {() => setShowModal(false)}
                    employees = {lackingEmployees}
                />

                {/* <Row className="me-3 mt-3 mb-3 d-flex justify-content-center">
                    {
                        employmentStatus.map((data) => {
                            return (
                                <Col>
                                    <Container className="dashboard-section-container p-3">
                                            <Row  className="d-flex justify-content-center">
                                                <label className='d-flex justify-content-center activity-date bold text-color'>{data.name}</label>
                                            </Row>
                                            <Row  className="d-flex justify-content-center">
                                                <label className='d-flex justify-content-center dashboard-subheader'>{data.count}</label>
                                            </Row>
                                    </Container>
                                </Col>
                            )
                        })
                    }
                </Row>  */}
                
            </div>
        </div>
    );
}

export default Dashboard;