import {useState, useEffect} from "react";

import { getUser, getToken } from "../utilities/common";

const userId = getUser()
const userToken = getToken()

// GET ALL EMPLOYEES
export const GetAllEmployees = (employee_id) => {
    const [allEmployees, setAllEmployees] = useState([])
    const employeeId = employee_id ? employee_id : null;
    useEffect(() => {
        allEmployees.length = 0;
        var axios = require('axios');
        var qs = require('qs');
        axios({
            method: 'post',
            url: window.$link + 'employees/get_all',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: employeeId,
            })
        }).then(response => {
            const infoSorted = response.data.employees.sort((a, b) => {return (a.last_name < b.last_name) ? -1 : (a.last_name > b.last_name) ? 1 : 0});
            infoSorted.map((employee) => {
                var info = {...employee};

                info.id = employee.id;
                info.name = employee.last_name + ", " + employee.first_name + " " + employee.middle_name;
                info.salary = employee.salary ? employee.salary[0].salary_to : 0.00;
                info.salary_type = employee.salary ? employee.salary[0].salary_type : '';

                setAllEmployees(oldArray => [...oldArray, info]);
            })
        }).catch(function (error) {
            // console.log(error);
        });
    },[]);

    return allEmployees;
}

// GET ALL BRANCHES
export const GetAllBranches = () => {
    const [allBranches, setAllBranches] = useState([])

    useEffect(() => {
        allBranches.length = 0;
        var axios = require('axios')
        var qs = require('qs')

        axios({
            method: 'get',
            url: window.$link + 'branches/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                branch_id: ""
            }
        }).then( response=> {
            setAllBranches(response.data.data)
        }).catch(function (error) {
            // console.log('Error Fetching Shifts')
        })
    }, [])

    return allBranches;
}

// GET ALL EMPLOYMENT STATUS
export const GetAllEmploymentStatuses = () => {
    const [allEmploymentStatus, setAllEmploymentStatus] = useState([])
   
    useEffect(() => {
        allEmploymentStatus.length = 0;
        var axios = require('axios')
        var qs = require('qs')

        axios({
            method: 'get',
            url: window.$link + 'employment_statuses/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                branch_id: ""
            }
        }).then( response=> {
            // // console.log(response.data.data)
            setAllEmploymentStatus(response.data.data)
        }).catch(function (error) {
            // console.log('Error Fetching Shifts')
        })
    }, [])

    return allEmploymentStatus;
}

// GET ALL SHIFTS
export const GetAllShifts = () => {
    const [allShifts, setAllShifts] = useState([])
    useEffect(() => {
        allShifts.length=0;
        var axios = require('axios')
        var qs = require('qs')

        axios({
            method: 'get',
            url: window.$link + 'shifts/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                branch_id: ""
            }
        }).then( response=> {
            // // console.log(response.data.data)
            setAllShifts(response.data.data)
        }).catch(function (error) {
            // console.log('Error Fetching Shifts')
        })
    }, [])

    return allShifts;
}

// GET ALL DEPARTMENTS
export const GetAllDepartments = () => {
    const [allDepartments, setAllDepartments] = useState([])
     useEffect(() => {
        var axios = require('axios');
        var qs = require('qs');
        allDepartments.length=0;
        axios({
            method: 'get',
            url: window.$link + 'departments/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                branch_id: ""
            }

        }).then(response => {
            setAllDepartments(response.data.data)
        }).catch(function (error) {
            // console.log('error')
        });
    }, [])

    return allDepartments;
}

// GET ALL PROJECTS
export const GetAllProjects = () => {
    const [allprojects, setAllProjects] = useState([])
    useEffect(() => {
        allprojects.length=0;
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'projects/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                branch_id: ""
            }
        }).then(response => {
            // // console.log(response.data.data);
            setAllProjects(response.data.data);
        }).catch(function (error) {
            // // console.log('Error getting all Branches');
            // // console.log(error);
        });
    },[]);

    return allprojects;
}

// GET ALL POSITIONS
export const GetAllPositions = () => {
    const [allPositions, setAllPositions] = useState([])
    useEffect(() => {
        allPositions.length=0;
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'positions/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                branch_id: ""
            }
        }).then(response => {
            // // console.log(response.data.data);
            setAllPositions(response.data.data);
        }).catch(function (error) {
            // console.log('Error getting all Branches');
            // console.log(error);
        });
    },[]);
    return allPositions;
}

// GET ALL BUSINESS UNITS 
export const GetAllBusinessUnits = () => {
    const [allBusinessUnits, setAllBusinessUnits] = useState([])
    useEffect(() => {
        allBusinessUnits.length=0;
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'business_units/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                branch_id: ""
            }
        }).then(response => {
            // // console.log(response.data.data);
            setAllBusinessUnits(response.data.data);
        }).catch(function (error) {
            // console.log('Error getting all Branches');
            // console.log(error);
        });
    },[]);
    return allBusinessUnits;
}

// GET ALL BUSINESS UNITS 
export const GetAllRequirements = () => {
    const [allRequirements, setAllRequirements] = useState([])
    useEffect(() => {
        allRequirements.length=0;
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'requirements/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                branch_id: ""
            }
        }).then(response => {
            // // console.log(response.data.data);
            setAllRequirements(response.data.data);
        }).catch(function (error) {
            // console.log('Error getting all Branches');
            // console.log(error);
        });
    },[]);
    return allRequirements;
}

// GET EMPLOYEE ID OF USER
export const GetUserEmployeeId = () => {
    const [employeeId, setEmployeeId] = useState();
    useEffect(() => {
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'users/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                user_id: userId
            }
        }).then(response => {
            setEmployeeId(response.data.data[0].employee_id);
        }).catch(function (error) {
            // console.log(error);
        });
    },[]);
    return employeeId;
}

// GET ALL EMPLOYEE STATUS
export const GetAllEmployeeStatus = () => {
    const [allLeaveTypes, setAllLeaveTypes] = useState([])
   
    useEffect(() => {
        allLeaveTypes.length = 0;
        var axios = require('axios')
        var qs = require('qs')

        axios({
            method: 'get',
            url: window.$link + 'employee_statuses/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                branch_id: ""
            }
        }).then( response=> {
            setAllLeaveTypes(response.data.data)
        }).catch(function (error) {
            // console.log('Error Fetching Shifts')
        })
    }, [])

    return allLeaveTypes;
}

export const GetAllMonths = () => {
    const allMonths = [
        {value: '1', name: 'January'},
        {value: '2', name: 'February'},
        {value: '3', name: 'March'},
        {value: '4', name: 'April'},
        {value: '5', name: 'May'},
        {value: '6', name: 'June'},
        {value: '7', name: 'July'},
        {value: '8', name: 'August'},
        {value: '9', name: 'September'},
        {value: '10', name: 'October'},
        {value: '11', name: 'November'},
        {value: '12', name: 'December'},
    ]

    return allMonths;
}

// GET ALL EMPLOYEE STATUS
export const GetAllLeaveTypes = () => {
    const [allLeaveTypes, setAllLeaveTypes] = useState([])
   
    useEffect(() => {
        allLeaveTypes.length = 0;
        var axios = require('axios')
        var qs = require('qs')

        axios({
            method: 'get',
            url: window.$link + 'leave_types/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                branch_id: ""
            }
        }).then( response=> {
            setAllLeaveTypes(response.data.data)
        }).catch(function (error) {
            // console.log('Error Fetching Shifts')
        })
    }, [])

    return allLeaveTypes;
}


// GET ALL SYSTEM ROLES
export const GetAllSystemRoles = () => {
    const [systemRoles, setAllSystemRoles] = useState([])
   
    useEffect(() => {
        systemRoles.length = 0;
        var axios = require('axios')
        var qs = require('qs')

        axios({
            method: 'get',
            url: window.$link + 'roles/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                branch_id: ""
            }
        }).then( response=> {
            setAllSystemRoles(response.data.data)
        }).catch(function (error) {
            // console.log('Error Fetching Shifts')
        })
    }, [])

    return systemRoles;
}

// GET REQUIREMENTS BY EMPLOYEE ID
export const GetAllRequirementsByEmployeeId = (employee_id) => {
     const [employeeRequirements, setEmployeeRequirements] = useState([]);
   
    useEffect(() => {
        employeeRequirements.length = 0;
        var axios = require('axios')
        var qs = require('qs')

        axios({
            method: 'post',
            url: window.$link + 'employees/get_employee_requirements',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: employee_id
            })
        }).then(response => {
            const res = response.data.employees;
            setEmployeeRequirements(res);
            
        }).catch(function (error) {
        });
    }, [])

    return employeeRequirements;
}