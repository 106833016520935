import React, { useState, useRef } from "react";
import { getToken, getUser, getRoleId, tokenExpired, refreshPage} from "../../utilities/common";
import { GetUserEmployeeId } from "../../helpers/CommonApi";
import { useNavigate } from "react-router-dom";
import {Row, Col} from 'react-bootstrap'
import ReactToPrint from "react-to-print";
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import PrintIcon from '@mui/icons-material/Print';
import UploadIcon from '@mui/icons-material/Upload';
import '../Modals/modal.css';
import './Pagibig.css';
import "../../utilities/common.css";
import 'react-datepicker/dist/react-datepicker.css';


export default function Pagibig() {
    const userToken = getToken();
    const userId = getUser();
    const userRoleId = getRoleId();
    const userEmployeeId = GetUserEmployeeId();
    const componentRef = useRef();
    const navigate = useNavigate();
    const currentDate = new Date();
    const dateToday = new Date().getDate();
    const [loading, setLoading] = useState(true);
    const [pagibigId, setPagibigId] = useState();
    const [showDelete, setShowDelete] = useState(false);
    const [tableData, setTableData] = useState([]);

    const [filterDate, setFilterDate] = useState({
        date_from: '',
        date_to: ''
    })

    function onClickDelete(pagibig_id) {
        setPagibigId(pagibig_id);
        setShowDelete(true);
    }

    function getAllContribution (employee_id) {
        var axios = require('axios');
        var qs = require('qs');
        axios({
            method: 'post',
            url: window.$link + 'contributions/get_pagibig_contribution',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                date_from: filterDate.date_from,
                date_to: filterDate.date_to,
                employee_id: employee_id
            })
        }).then(response => {
            setTableData(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            tokenExpired(error);
            setLoading(false);
            setTableData([]);
        });
    }

    // GET ALL PAGIBIG DATA
    React.useEffect(() => {
        tableData.length = 0;
        if (userRoleId === '1' || userRoleId === '2') {
            getAllContribution('');
        } else if (userEmployeeId) {
            getAllContribution(userEmployeeId);
        } else {
            setTableData([]);
        }
    },[filterDate]);



    // GET ALL PagibigS
    React.useEffect(() => {
        
        tableData.length = 0;
        
    },[filterDate]);
            
    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col xs={3} className='d-flex comm-header-employee'>
                            PAGIBIG  
                        </Col>
                        <Col className="d-flex justify-content-end me-4">
                            <Row className="col-9 d-flex justify-content-end me-5">
                                <Col xs={3} className="me-5 ms-3">
                                    <ReactToPrint
                                        trigger={() => (
                                            <button className="export-button py-2 px-0">
                                                <span className="export-text"><PrintIcon/> Print</span>
                                            </button>
                                        )}
                                        content={() => componentRef.current}
                                        documentTitle={"PAGIBIG Contribution"}
                                    />
                                </Col>
                                <Col xs={3} className="ms-3">
                                    <button className="export-button py-2 px-0" onClick={() => navigate('pagibig_batch_entry')}>
                                        <span className="export-text"><UploadIcon/>Upload</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>

                {/* Date Filter */}
                {/* <Row className="px-3 mt-4">
                    <Col xs={1}>Date From</Col>
                    <Col className="ms-0" xs={2}>
                            <input
                                type="date"
                                className={'form-control'}
                                placeholderText={'dd/mm/yyyy'}
                                id='date_from'
                                name='date_from'
                                ariaDescribedBy={'date_from'}
                                ariaLabelledBy={'date_from'}
                                onChange={(e) =>
                                    setFilterDate({ ...filterDate, date_from: e.target.value })
                                }
                            />
                    </Col>
                    <Col xs={1}>Date To</Col>
                    <Col className="ms-0" xs={2}>
                            <input
                                type="date"
                                className={'form-control'}
                                placeholderText={'dd/mm/yyyy'}
                                id='date_to'
                                name='date_to'
                                ariaDescribedBy={'date_to'}
                                ariaLabelledBy={'date_to'}
                                onChange={(e) =>
                                setFilterDate({ ...filterDate, date_to: e.target.value })
                                }
                            />
                    </Col>
                </Row> */}

                {/* TABLE HERE */}
                <div className="SSS-wrapper mt-2" ref={componentRef}>
                    <h5 className="comm-header-employee printOnly">Pag-IBIG</h5>
                    <TableTemplate
                        tableType='pagibig'
                        tableHeaders={["Employee Name", "Total Employee Share", "Total Employer Share", "Total Amount", ""]}
                        tableData={tableData}
                        deleteHandler={onClickDelete}
                    />
                </div>
            </div>
        </div>
    );
}
