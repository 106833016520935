import {handleValidationChange} from '../CommonValidation';

export const validateLeaveType = (data, setIsError) => {
    var isValid = true;

    if(data.description === "") {
        handleValidationChange("description", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("description", false, setIsError);
    }
    if(data.maximum_days === "") {
        handleValidationChange("maximum_days", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("maximum_days", false, setIsError);
    }
    return isValid
  
}