import React, { useState, useRef } from "react";
import { getToken, getUser, getRoleId, tokenExpired, refreshPage} from "../../utilities/common";
import { GetUserEmployeeId } from "../../helpers/CommonApi";
import {Row, Col} from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import PrintIcon from '@mui/icons-material/Print';
import UploadIcon from '@mui/icons-material/Upload';
import '../Modals/modal.css'
import './SSS.css';
import "../../utilities/common.css"
import 'react-datepicker/dist/react-datepicker.css'


export default function SSS() {
    const userToken = getToken();
    const userId = getUser();
    const componentRef = useRef();
    const userRoleId = getRoleId();
    const userEmployeeId = GetUserEmployeeId();
    const [allSSS, setAllSSS] = useState([]);
    const [loading, setLoading] = useState(true);
    const [SSSId, setSSSId] = useState();
    const [showDelete, setShowDelete] = useState(false);
    const currentDate = new Date();
    const dateToday = new Date().getDate();
    const navigate = useNavigate();
    const [filterDate, setFilterDate] = useState({
        date_from: '',
        date_to: ''
    });

    function onClickDelete(SSS_id) {
        setSSSId(SSS_id);
        setShowDelete(true);
    }

    // GET ALL SSSS
    function getAllContribution (employee_id) {
        var axios = require('axios');
        var qs = require('qs');
        axios({
            method: 'post',
            url: window.$link + 'contributions/get_sss_contribution',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                date_from: filterDate.date_from,
                date_to: filterDate.date_to,
                employee_id: employee_id
            })
        }).then(response => {
            setAllSSS(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            tokenExpired(error);
            setLoading(false);
            setAllSSS([]);
        });
    }

    React.useEffect(() => {
        allSSS.length = 0;
        if (userRoleId === '1' || userRoleId === '2') {
            getAllContribution('');
        } else if (userEmployeeId) {
            getAllContribution(userEmployeeId);
        } else {
            setAllSSS([]);
        }
    },[filterDate]);
            
    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    
                    <Row className="m-0">
                        <Col xs={6} className='d-flex comm-header-employee'>
                            Social Security System
                        </Col>
                        <Col className="d-flex justify-content-end me-4">
                            <Row className="col-9 d-flex justify-content-end me-3">
                                <Col xs={3} className="me-3 ms-3">
                                    <ReactToPrint
                                        trigger={() => (
                                            <button className="export-button py-2 px-0">
                                                <span className="export-text"><PrintIcon/> Print</span>
                                            </button>
                                        )}
                                        content={() => componentRef.current}
                                        documentTitle={"SSS Contribution"}
                                    />
                                </Col>
                                <Col xs={3} className="ms-3">
                                    <button className="export-button py-2 px-0" onClick={() => navigate('sss_batch_entry')}>
                                        <span className="export-text"><UploadIcon/>Upload</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                
                    </Row>
                </Row>

                {/* Date Filter */}
                {/* <Row className="px-3 mt-5">
                    <Col xs={1}>Date From</Col>
                    <Col className="ms-0" xs={2}>
                            <input
                                type="date"
                                className={'form-control'}
                                placeholderText={'dd/mm/yyyy'}
                                id='date_from'
                                name='date_from'
                                ariaDescribedBy={'date_from'}
                                ariaLabelledBy={'date_from'}
                                onChange={(e) =>
                                    setFilterDate({ ...filterDate, date_from: e.target.value })
                                }
                            />
                    </Col>
                    <Col xs={1}>Date To</Col>
                    <Col className="ms-0" xs={2}>
                            <input
                                type="date"
                                className={'form-control'}
                                placeholderText={'dd/mm/yyyy'}
                                id='date_to'
                                name='date_to'
                                ariaDescribedBy={'date_to'}
                                ariaLabelledBy={'date_to'}
                                onChange={(e) =>
                                setFilterDate({ ...filterDate, date_to: e.target.value })
                                }
                            />
                    </Col>
                </Row> */}

                {/* TABLE HERE */}
                <div className="SSS-wrapper mt-2" ref={componentRef}>
                    <h5 className="comm-header-employee printOnly">SSS</h5>
                    {
                        (function() {
                            return !loading ? (
                            <TableTemplate
                                tableType='sss'
                                tableHeaders={["Employee Name", "Total Employee Share", "Total Employer Share", "Total Amount", ""]}
                                tableData={allSSS}
                                deleteHandler={onClickDelete}
                            />) : (
                                <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                </div>
                            )
                        })()
                    }
                </div>
            </div>
        </div>
    );
}

