import React, { useState, useRef } from 'react';
import {Row, Col} from 'react-bootstrap';
import { getToken, getUser} from '../../utilities/common';
import { useLocation, useNavigate} from "react-router-dom";
import moment from "moment";
import ReactToPrint from 'react-to-print';
import Navbar from '../Navbar/Navbar'
import PrintIcon from '@mui/icons-material/Print';

export default function ViewWorkFromAnywhere () {    
    const userToken = getToken();
    const userId = getUser();
    const location = useLocation();
    const navigate = useNavigate();
    const componentRef = useRef();
    const wfaId = location.state;
    const [wfaDetails, setWfaDetails] = useState({});

    // Get memo detials by ID
    React.useEffect(() => {
        wfaDetails.length = 0;
        var axios = require('axios');
        var qs = require('qs');
        axios({
            method: 'get',
            url: window.$link + 'wfa_requests/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                wfa_request_id: wfaId
            }
        }).then(response => {
            const res = response.data.data[0].wfa_request;
            setWfaDetails(res);
        }).catch(function (error) {
            console.log(error);
        });
    },[]);

    return (
        <div className='comm-bg'>
            <Navbar />
            <div className='comm-page-container'>
                <div className='print-page-container me-3' ref={componentRef}>
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className='payslip-header d-flex justify-content-end'>WORK FROM ANYWHERE</Row>
                        <Row className='d-flex justify-content-end'>DATE: {(function() {
                                return moment(wfaDetails.date_from).format('MMMM DD, YYYY');
                            })()}
                    </Row>

                    <Row className='col-6 p-1 mt-3'>
                        <table className='table table-bordered'>
                            <thead>
                                <tr className='payslip-blue-bg'>
                                    <th colSpan={2}>WFA No. {wfaDetails.id ? wfaDetails.id : ''}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='bold'>Employee</td>
                                    <td>{wfaDetails.employee_name ? wfaDetails.employee_name : ''}</td>
                                </tr>
                                <tr>
                                    <td className='bold'>Number of Days</td>
                                    <td>{wfaDetails.no_of_days ? wfaDetails.no_of_days : ''}</td>
                                </tr>
                                <tr>
                                    <td className='bold'>Status</td>
                                    <td>{wfaDetails.status ? (wfaDetails.status).toUpperCase() : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Row>

                    <Row className='p-1 mt-3'>
                        <table className='table table-bordered'>
                            <tbody>
                                <tr className='p-3'>
                                    <div dangerouslySetInnerHTML={{__html: wfaDetails.remarks?wfaDetails.remarks:''}}/>
                                </tr>
                            </tbody>
                        </table>
                    </Row>

                    <Row className='d-flex justify-content-end me-3 mt-4'>
                        <Col className='d-flex justify-content-end'>
                            <ReactToPrint
                                trigger={() => (
                                    <button className="print-button me-0 noprint me-1">
                                        <span className="export-text"><PrintIcon/> Print</span>
                                    </button>
                                )}
                                content={() => componentRef.current}
                                documentTitle={"WFA"}
                            />
                            <button className="save-button blue noprint" onClick={()=> navigate(-1)}>
                                <span className="export-text">Back</span>
                            </button>
                        </Col>
                    </Row>
                    
                </Row>
                </div>
            </div>
        </div>
    );
}