import {handleValidationChange} from '../CommonValidation';

export const validateEmployee = (data, setIsError) => {
    
    function validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    var isValid = true;

    if(data.last_name === "" || data.last_name === null) {
        handleValidationChange("last_name", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("last_name", false, setIsError);
    }

    if (data.first_name === "" || data.first_name === null) {
        handleValidationChange("first_name", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("first_name", false, setIsError);
    }

    if(data.contact_no === "" || data.contact_no === null) {
        handleValidationChange("contact_no", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("contact_no", false, setIsError);
    }

    if (data.address === "" || data.address === null) {
        handleValidationChange("address", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("address", false, setIsError);
    }

    if(data.email === "" || data.email === null || !validateEmail(data.email) === true) {
        handleValidationChange("email", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("email", false, setIsError);
    }

    if(data.gender === "" || data.gender === null) {
        handleValidationChange("gender", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("gender", false, setIsError);
    }

    if(data.birthdate === "" || data.birthdate === null) {
        handleValidationChange("birthdate", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("birthdate", false, setIsError);
    }

    if(data.civil_status === "" || data.civil_status === null) {
        handleValidationChange("civil_status", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("civil_status", false, setIsError);
    }

    if(data.nationality === "" || data.nationality === null) {
        handleValidationChange("nationality", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("nationality", false, setIsError);
    }

    if(data.contact_person === "" || data.contact_person === null) {
        handleValidationChange("contact_person", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("contact_person", false, setIsError);
    }

    if(data.contact_person_relationship === "" || data.contact_person_relationship === null) {
        handleValidationChange("contact_person_relationship", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("contact_person_relationship", false, setIsError);
    }

    if(data.contact_number === "" || data.contact_number === null) {
        handleValidationChange("contact_number", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("contact_number", false, setIsError);
    }

    if(data.contact_address === "" || data.contact_address === null) {
        handleValidationChange("contact_address", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("contact_address", false, setIsError);
    }

    if(data.dtr_id === "" || data.dtr_id === null) {
        handleValidationChange("dtr_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("dtr_id", false, setIsError);
    }

    if(data.branch_id === "" || data.branch_id === null ) {
        handleValidationChange("branch_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("branch_id", false, setIsError);
    }

    if(data.business_unit_id === "" || data.business_unit_id === null) {
        handleValidationChange("business_unit_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("business_unit_id", false, setIsError);
    }

    if(data.employment_status_id === "" || data.employment_status_id === null) {
        handleValidationChange("employment_status_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("employment_status_id", false, setIsError);
    }

    if(data.employee_status_id === "" || data.employee_status_id === null) {
        handleValidationChange("employee_status_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("employee_status_id", false, setIsError);
    }

    if(data.salary_type === "" || data.salary_type === null) {
        handleValidationChange("salary_type", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("salary_type", false, setIsError);
    }

    if(data.salary_to === "" || data.salary_to === null) {
        handleValidationChange("salary_to", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("salary_to", false, setIsError);
    }

    if(data.department_id === "" || data.department_id === null) {
        handleValidationChange("department_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("department_id", false, setIsError);
    }

    if(data.position_id === "" || data.position_id === null) {
        handleValidationChange("position_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("position_id", false, setIsError);
    }

    if(data.date_hired === "" || data.date_hired === null) {
        handleValidationChange("date_hired", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("date_hired", false, setIsError);
    }

    if(data.current_shift_id === "" || data.current_shift_id === null) {
        handleValidationChange("current_shift_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("current_shift_id", false, setIsError);
    }

     if(data.role_id === "" || data.role_id === null) {
        handleValidationChange("role_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("role_id", false, setIsError);
    }

     if(data.password === "" || data.password === null) {
        handleValidationChange("password", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("password", false, setIsError);
    }

    return isValid;
  
}
