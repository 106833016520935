import React from 'react'
import { useRef, useState } from 'react';
import {Row, Col, Container} from 'react-bootstrap'
import ReactToPrint from 'react-to-print';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar'
import { getToken, getUser, tokenExpired } from '../../utilities/common';
import PrintIcon from '@mui/icons-material/Print';

export default function ViewCoop () {
    const componentRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const coopId = location.state;
    const [coopDetails, setCoopDetails] = useState({});
    const [coopContributions, setCoopContributions] = useState([]);

    function SentenceCaseName (name) {
        const sentenceCasedName = name.toLowerCase().replace(/(^|\s)[a-z]/g, (char) => char.toUpperCase());
        return sentenceCasedName
    }

    function monthNameFromInt(monthInt) {
        const date = new Date();
        date.setMonth(monthInt - 1); // months are zero-indexed in JS, so subtract 1 from monthInt
        return date.toLocaleString('default', { month: 'long' });
    }


    // GET Coop BY ID
    React.useEffect(() => {
        const userToken = getToken();
        const userId = getUser();

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'contributions/get_coop_contribution',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: coopId
            })
        }).then(response => {
            setCoopContributions(response.data.data[0].coop_contributions);
            setCoopDetails(response.data.data[0]);
        }).catch(function (error) {
            tokenExpired(error);
        });
    },[]);


    return (
        <div className='comm-page'>
            <Navbar/>
            <div className='comm-page-container no-margin-top'>
                <div className='print-page-container me-3 mt-0 payslip' ref={componentRef}>
                <Row className='d-flex comm-header-employee'>Coop Contribution</Row>

                <Row className="wrap-text mt-5">
                    <Col xs={3}>EMPLOYEE NAME</Col>
                    <Col xs={1}>:</Col>
                    <Col xs={7}>{coopDetails.full_name?SentenceCaseName(coopDetails.full_name):''}</Col>
                </Row>
                
                <Row className="wrap-text">
                    <Col xs={3}>TOTAL NO. OF MONTHS</Col>
                    <Col xs={1}>:</Col>
                    <Col xs={7}>{coopDetails.coop_contributions?coopDetails.coop_contributions.length:''}</Col>
                </Row>
                
                
                <Row className='mt-5'>
                <div className='title-philhealth bold py-3'>Employee Contribution Payment Summary</div>
                    <table className='table table-bordered'>
                        <thead>
                            <tr className='table-header-view'>
                                {/* <th>Total Employee Share</th>
                                <th>Total Employer Share</th> */}
                                <th>Total Amount of Contribution</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {/* <td className='content-philhealth'>Php {coopDetails.total_employee_contribution?coopDetails.total_employee_contribution:''}</td>
                                <td className='content-philhealth'>Php {coopDetails.total_employer_contribution?coopDetails.total_employer_contribution:''}</td> */}
                                <td className='content-philhealth'>Php {coopDetails.total_employee_contribution?coopDetails.total_employee_contribution:''}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>

                <Row className='mt-3'>
                <div className='title-philhealth py-3 bold'>Employee Contribution Payment History</div>
                    <table className='table table-bordered'>
                        <thead>
                            <tr className='table-header-view'>
                                <th>Year</th>
                                <th>Month</th>
                                {/* <th>Employer Share</th>
                                <th>Employee Share</th> */}
                                <th>Employee Contribution</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                (coopContributions && coopContributions.length) ? (
                                    coopContributions.map((data) => {
                                    return (
                                        <tr>
                                            <td className=''>{data.year}</td>
                                            <td className=''>{data.month}</td>
                                            {/* <td className='content-philhealth'>Php {data.employer_contribution}</td>
                                            <td className='content-philhealth'>Php {data.employee_contribution}</td> */}
                                            <td className='content-philhealth'>Php {data.employee_contribution}</td>
                                        </tr>
                                    )
                                    })
                                ) : ('')
                            }
                        </tbody>
                    </table>
                </Row>
                <Row className="d-flex justify-content-end me-3 mt-4 mt-5">
                    <Col className="print d-flex justify-content-end">
                        <ReactToPrint
                            trigger={() => (
                                <button className="save-button me-2 noprint">
                                    <span><PrintIcon/> Print</span>
                                </button>
                            )}
                            content={() => componentRef.current}
                            documentTitle={"COOP"}
                        />  
                    
                        <button
                            className="back-btn noprint exclude"
                            onClick={() => navigate(-1)}
                        >
                            <span className="export-text mx-auto">Back</span>
                        </button>
                    </Col>
                </Row>
            </div>
            </div>
        </div>
    )
}