import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import '../Modals/modal.css'
import { validateSystemAccess } from '../../helpers/Validation/SystemAccess';
import InputError from '../../helpers/InputError/InpuError';

export default function UpdateSystemAccess(props) {    

    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);
    const data = props.employeeData;

    //REQUIRED ERROR HANDLING
    const [isError, setIsError] = useState({
        role_id: false,
        password: false,
    });
   

/* SAVE EDITED EMPLOYEE INFORMATION. Note that the position & salary 
are updated separately for the employment history report. */
    function submitEditEmployee(e) {
        var axios = require('axios');
        var qs = require('qs');

        if (validateSystemAccess(data, setIsError) === true && click===false) {
            axios({
            url: window.$link + 'users/update',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: data.employee_id,
                name: data.name,
                email: data.email,
                password: data.password,
                role_id: data.role_id
            })
            })
            .then(function (response) {
                setClick(true);
                toast.success("System Access Updated Successfully!");
                setTimeout(() => refreshPage(), 1500);
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Failed to Update System Access");
                setClick(false);
            });
        }
    }

    function handle(e, element = null) {
        let newData = { ...data };
        newData[e.target.id] = e.target.value;
        props.setEditData(newData);
    }

    function renderInputError(var_name) {
        let value = data[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }


    return(
        <div className='account-details'>
            <Modal show={props.show} onHide={props.hide} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Update System Access
                    </div>
                    
                    <div className='body-head no-scroll mt-2 p-0'>
                    <Container>
                        <Row>
                            <Col>
                                <Form.Label className="h6" htmlFor="name">Role <span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Select 
                                        value={data.role_id}
                                        placeholder="" 
                                        aria-describedby="role_id" 
                                        aria-label="role_id" 
                                        name="role_id" 
                                        id='role_id' 
                                        onChange={(e) => handle(e)}>
                                            <option value=""> Select Role </option>
                                            <option value=''>Select Employment Status</option>
                                            {
                                                props.systemRoles.map((data) => (
                                                    <option value={data.id}>{data.name}</option>
                                                ))
                                            }
                                    </Form.Select>
                                {renderInputError('role_id')}
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label className="h6" htmlFor="name">Password <span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        value={data.password}
                                        type="text"
                                        aria-label="password" 
                                        aria-describedby="password" 
                                        placeholder=""
                                        name="password" 
                                        id='password' 
                                        onChange={(e) => handle(e)}
                                    />
                                    {renderInputError('password')}
                                </InputGroup>
                            </Col>  
                        </Row>
                    </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hide}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitEditEmployee}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}