import React, { useState } from "react";
import { getToken, getUser, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// components
import TableTemplate from "../../utilities/table_template";
import DeletePrompt from "../Modals/DeletePrompt";
import Navbar from "../Navbar/Navbar"
import AddShift from "./AddShifts";
import moment from "moment";

// icons
import StorageIcon from '@material-ui/icons/Storage';
import SearchIcon from '@material-ui/icons/Search'
import UploadIcon from '@mui/icons-material/Upload';

//css
import './Shifts.css'
import '../../utilities/common.css'

import { validateShift } from "../../helpers/Validation/Manage/ShiftValidation";
import InputError from "../../helpers/InputError/InpuError";

export default function Shifts() {
    const userToken = getToken();
    const userId = getUser();

    // delete modal handler
    const [showDelete, setShowDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = () => setShowDelete(true);
    const [shiftID, setShiftID] = useState();
    const [allShifts, setAllShifts] = useState([]);
    const [filteredShifts, setFilteredShifts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [click, setClick] = useState(false);
    const navigate = useNavigate();

    // Validation
    const [isError, setIsError] = useState({
        name: '',
        time_in: '',
        time_out: '',
        overtime_fee: '',
        break_duration: ''
    });

    function onClickDelete(id) {
        handleShowDelete();
        setShiftID(id);
    }

    function handleRemove() {
        var axios = require('axios');
        var qs = require('qs');
        axios({
        url: window.$link + 'shifts/delete',
        method: 'post',
        headers: {
            "api-key": window.$api_key,
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, ''),
            shift_id: shiftID
        })
        })
        .then(function (response) {
            console.log(response);
            toast.success("Shift Deleted Successfully!");
            setTimeout(() => refreshPage(), 1000);
        })
        .catch(function (error) {
            toast.error('Deleting Error!');
            console.log(error.response.data);
            tokenExpired(error);
        });
    }
    
    // Add Shift Handler
    const [showAddShift, setShowAddShift] = useState(false);

    // Edit Shift Handler
    const [showEdit, setShowEdit] = useState(false);
    const handleShowEdit = () => setShowEdit(true);
    const [shiftEdit, setShiftEdit] = useState('');

    // fetch shift with id=defaultData
    const [editData, setEditData] = useState({
        name: '',
        time_in: '',
        time_out: '',
        overtime_fee: '',
        break_duration: ''
    });

    // get shift details by id for editing
    function onClickEdit(id) {
        setShiftEdit(id);
        console.log("clicked: " + id);
        console.log("setting default values...");
        
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'shifts/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                shift_id: id,
            })
        }).then(response => {
            console.log(response.data.data[0]);
            let shift = response.data.data[0];
            let currentData = {
                name: shift.name,
                time_in: shift.time_in,
                time_out: shift.time_out,
                overtime_fee: shift.overtime_fee,
                break_duration: shift.break_duration
            }
            setEditData(currentData)
        }).catch(function (error) {
            console.log('Error getting shift with id: ' + shiftEdit);
            console.log(error.response.data);
            tokenExpired(error);
        });

        setTimeout(() => handleShowEdit(), 100);
    } 

    // edit shift
    function submitEditShifts(e) {
        e.preventDefault();
        var axios = require('axios');
        var qs = require('qs');
        if (validateShift(editData, setIsError)==true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'shifts/update',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                shift_id: shiftEdit,
                name: editData.name,
                time_in: editData.time_in,
                time_out: editData.time_out,
                overtime_fee: editData.overtime_fee,
                break_duration: editData.break_duration
            })
            })
            .then(function (response) {
                toast.success("Shift Edited Successfully!");
                setTimeout(refreshPage(), 1000);
            })
            .catch(function (error) {
                toast.error("Failed to Update Shift");
                console.log(error.response.data);
                tokenExpired(error);
            }); 
        }
    }

    function handle(e) {
        const newData = { ...editData };
        setEditData(newData);
        newData[e.target.id] = e.target.value;
    }

    function renderInputError(var_name) {
        let value = editData[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

     function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allShifts) {
            allShifts.forEach(function(shift) {
                if((shift.name).toLowerCase().includes(term)) {
                    filteredArr.push(shift);
                }
            })
        }

        if (term == "") {
            setFilteredShifts(allShifts);
        } else {
            setFilteredShifts(filteredArr);
        }
    }

    // GET ALL SHIFTS
    React.useEffect(() => {
        allShifts.length = 0;

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'shifts/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                shift_id: ''
            })
        }).then(response => {
            console.log(response.data);
            response.data.data.map((shift) => {
                var info = {};

                info.id = shift.id;
                info.name = shift.name;
                info.time_in = moment(shift.time_in, 'hh:mm:ss').format('h:mm A');
                info.time_out = moment(shift.time_out, 'hh:mm:ss').format('h:mm A');
                info.overtime_fee = parseFloat(shift.overtime_fee) + "%";
                info.break_duration = parseFloat(shift.break_duration) + " mins";

                setAllShifts(oldArray => [...oldArray, info]);
                setFilteredShifts(oldArray => [...oldArray, info]);
                setLoading(false)
            })
        }).catch(function (error) {
            console.log('Error getting all shifts');
            console.log(error);
            tokenExpired(error);
            setLoading(false);
        });
    },[]);

    // sort shifts by time_in
    React.useEffect(() => {
        allShifts.sort((a,b)=> (a.time_in > b.time_in) ? 1 : ((b.time_in > a.time_in) ? -1 : 0));
    },[allShifts]);

    return(
        <div className="comm-bg">
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-shift'>
                            Shift  
                        </Col>
                        <Col className='d-flex justify-content-end me-3'>
                            <Row>
                                {/* <Col className="me-5">
                                    <button className="export-button-shift" onClick={() => navigate('ShiftsBatchEntry')}>
                                        <span className="export-text"><UploadIcon/>Upload</span>
                                    </button>
                                </Col> */}
                                <Col className="me-5">
                                    <button className="export-button-shift" onClick={() => setShowAddShift(true)}>
                                        <span className="export-text"><StorageIcon/> Add Shift</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>     
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-shift">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Shift"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>
                
                <AddShift 
                    name="ADD" 
                    show={showAddShift} 
                    hide={() => {setShowAddShift(false); setTimeout(() => refreshPage(), 500);}}
                    purpose="add"
                />

                {/* CONTENT */}
                <div className="shift-wrapper mt-4">
                    {
                        (function() {
                            return !loading ? (
                                <TableTemplate
                                    tableType='shift'
                                    tableHeaders={["SHIFT NAME", "TIME IN", "TIME OUT", "OVERTIME RATE", "BREAK", ""]}
                                    tableData={filteredShifts}
                                    deleteHandler={onClickDelete}
                                    editHandler={onClickEdit}
                                />) : (
                                <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                </div>
                            )
                        })()
                    }
                    <DeletePrompt
                        name = "SHIFT"
                        show = {showDelete}
                        hide = {handleCloseDelete}
                        remover = {handleRemove}
                    />

                    {/* Edit Shift Modal */}
                    <Modal show={showEdit} onHide={() => setShowEdit(false)} size="m" centered>
                        <Form>
                            <Modal.Body>
                                <div className="modal-header p-0">
                                    Edit Shift
                                </div>
                                <button type="button" class="btn-close"  onClick={() => setShowEdit(false)}>
                                    <span class="icon-cross"></span>
                                    <span class="visually-hidden">Close</span>
                                </button>
                                <div className='body-head'>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <Form.Label className="h6" htmlFor="name">Name<span className='red'> *</span></Form.Label>
                                                <InputGroup className='mb-3'>
                                                    <Form.Control
                                                        type="text" 
                                                        name="name" 
                                                        id="name"
                                                        value={editData.name}
                                                        className="form-control mb-2"
                                                        onChange={(e) => handle(e)}
                                                    />
                                                    {renderInputError("name")}
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label className="h6" htmlFor="name">Time In<span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        type="time" 
                                                        name="time_in" 
                                                        id="time_in" 
                                                        value={editData.time_in}
                                                        className="form-control mb-2"
                                                        onChange={(e) => handle(e)}
                                                    />
                                                    {renderInputError("time_in")}
                                                </InputGroup>
                                            </Col>

                                            <Col>
                                                <Form.Label className="h6" htmlFor="name">Time Out<span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        type="time" 
                                                        name="time_out" 
                                                        id="time_out" 
                                                        value={editData.time_out}
                                                        className="form-control mb-2"
                                                        onChange={(e) => handle(e)}
                                                    />
                                                    {renderInputError("time_out")}
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        
                                        <Row>
                                            <Col>
                                                <Form.Label className="h6" htmlFor="name">Overtime Rate (%)<span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        type="number" 
                                                        name="overtime_fee"
                                                        id="overtime_fee" 
                                                        value={editData.overtime_fee}
                                                        className="form-control mb-2"
                                                        onChange={(e) => handle(e)}
                                                    />
                                                    {renderInputError("overtime_fee")}
                                                </InputGroup>
                                            </Col>
                                            <Col>
                                                <Form.Label className="h6" htmlFor="name">Break Duration (minutes)<span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        type="number" 
                                                        name="break_duration" 
                                                        id="break_duration" 
                                                        value={editData.break_duration}
                                                        className="form-control mb-2"
                                                        onChange={(e) => handle(e)}
                                                    />
                                                    {renderInputError("break_duration")}
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        
                                    </Container>
                                </div>
                                <div className='d-flex justify-content-end me-2 mt-4'>
                                    <button type="" className='cancel-button me-2' onClick={() => setShowEdit(false)}>Cancel</button>
                                    <button type="submit" className='save-button' onClick={(e) => submitEditShifts(e)}>Save</button>
                                </div>
                                
                            </Modal.Body>
                        </Form>
                    </Modal>
                </div>
            </div>
        </div>
    );
}