import React, {useState} from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getRoleId } from './common';

// icons
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import EditIcon from '@material-ui/icons/Edit';

// css
import './common.css';
import './Table.scss';

// Components
import TableFooter from './TableFooter'
import useTable from './Pagination';


function TableTemplate({tableType, tableHeaders, tableData, deleteHandler, editHandler, uploadRequirements, surveyHandler, approveHandler, updateContract, updateAllowance, updateEmployeeStatus, updateSystemAccess, givenClass, breakOn = "small"}) {
    let navigate = useNavigate();
    const userRoleId = getRoleId();
    const dateToday = new Date();

    // set all row fields in the table
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(tableData, page, rowsPerPage);

    let tableClass = "table-container__table";

    if (breakOn === "small") {
      tableClass += " table-container__table--break-sm";
    } else if (breakOn === "medium") {
      tableClass += " table-container_table--break-md";
    } else if (breakOn === "large") {
      tableClass += " table-container_table--break-lg";
    }

    const handleSelectChange = (e) => {
      const { id, value } = e.target;
      if (value === "view_employee") {
        navigate('/employee/view', {state:id})
      } else if (value === "update_contract"){
        updateContract(id);
      } else if(value === "update_allowance"){
         updateAllowance(id)
      }else if (value === "view_form") {
        navigate('/forms/view', {state:id});
      } else if (value === "edit") {
        editHandler(id);
      } else if (value === "delete") {
        deleteHandler(id);
      } else if (value === "approve") {
        approveHandler(id);
      } else if (value === "upload_requirements") {
        uploadRequirements(id);
      } else if (value === "update_status") {
        updateEmployeeStatus(id);
      } else if (value === "update_system_access") {
        updateSystemAccess(id);
      } else if (value === "view_memo") {
        navigate('/memo/view', {state:id});
      } else if (value === "view_leave") {
        navigate('/leave/view', {state:id});
      } else if (value === "view_wfa") {
        navigate('/work_from_anywhere/view', {state:id});
      } else if (value === "view_letter") {
        navigate('/communication_letters/view', {state: id});
      } else if (value === "view_announcement") {
        navigate('/announcement/view', {state:id});
      } else if (value === "view_philhealth") {
        navigate('/philhealth/view', {state:id});
      } else if (value === "view_sss") {
        navigate('/sss/view', {state:id});
      } else if (value === "view_pagibig") {
        navigate('/pagibig/view', {state:id});
      } else if (value === "view_pagibig") {
        navigate('/pagibig/view', {state:id});
      } else if (value === "view_coop") {
        navigate('/coop/view', {state:id});
      } else if (value === "view_loan") {
        navigate('/loan/view', {state:id});
      } else if (value === "leave_history") {
        navigate('/employee_leave_history', {state:id});
      }  else if (value === "wfa_history") {
        navigate('/employee_wfa_history', {state:id});
      } else if (value === "print_payslip") {
        window.open(`/employee_payslip?id=${id}`, '_blank');
      } else if (value === "survey", {state: id}) {
        surveyHandler(id);
      }
    };

    const loanTypes = [
        {value: 'sss_salary_loan', name: 'SSS Salary Loan'},
        {value: 'sss_calamity_loan', name: 'SSS Calamity Loan'},
        {value: 'cooperative_loan', name: 'Cooperative Loan'},
        {value: 'laptop_loan', name: 'Laptop Loan'},
        {value: 'phone_loan', name: 'Phone Loan'},
        {value: 'company_loan', name: 'Company Loan'},
        {value: 'pag_ibig_loan', name: 'PAGIBIG Loan'},
        {value: 'cash_advance', name: 'Cash Advance'},
    ];

    function formatTime (time) {
        const timeString = time;
        const [hours, minutes, seconds] = timeString.split(':').map(str => parseInt(str, 10));
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(seconds);
        const ampmTimeString = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

        return ampmTimeString; // "2:00 AM"
    }

    function SentenceCase(name) {
        const sentenceCasedName = name.toLowerCase().replace(/(^|\s)[a-z]/g, (char) => char.toUpperCase());
        return sentenceCasedName
    }

    function splitAndCapitalize (name) {
        if (name && name.includes('_')) {
            return name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        } else {
            return name;
        }
    }

    const rowFields = (row, index) => {
        if (tableType === 'employmenthistory') { // employment history per employee
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{moment(row.date).format('MMM D, YYYY')}</td>
                    <td>{row.details}</td>
                    <td>{row.salary}</td>
                </tr>
            )
        } else if (tableType === 'payroll') { // get all payroll table
            return (
                <div>
                <tr key={row.payroll_id} className="table-temp border-bottom">
                    <td>{moment(row.date_from).format('MMM D, yyyy')}</td>
                    <td>{row.date_to?moment(row.date_to).format('MMM D, yyyy'):''}</td>
                    <td>{row.employee_name}</td>
                    <td>{row.total_earnings ? Number(row.total_earnings).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</td>
                    <td>{row.total_deduction ? Number(row.total_deduction).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</td>
                    <td>{row.total_payout ? Number(row.total_payout).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</td>
                    <td>
                        <select className="dropdown-btn-employee" aria-label="" onChange={handleSelectChange} id={row.payroll_id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="print_payslip">Print</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
                </div>
            )
        } else if (tableType === 'print_payroll') { // print all payroll
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.id}</td>
                    <td>{row.employee_name}</td>
                    <td>{row.basic_hrs ? parseFloat(row.basic_hrs): ''}</td>
                    <td>{row.basic_pay ? parseFloat(row.basic_pay) : ''}</td>
                    <td>{row.overtime_hrs ? (row.overtime_hrs): ''}</td>
                    <td>{row.overtime_rate ? parseFloat(row.overtime_rate) : ''}</td>
                    <td>{row.undertime_hrs ? row.undertime_hrs: ''}</td>
                    <td>{row.undertime_rate ? parseFloat(row.undertime_rate): ''}</td>
                    <td>{row.absent_days ? parseFloat(row.absent_days): ''}</td>
                    <td>{row.absent_rate ? parseFloat(row.absent_rate) : ''}</td>
                    <td>{row.sss_contribution ? row.sss_contribution:''}</td>
                    <td>{row.philhealth_contribution ? row.philhealth_contribution: ''}</td>
                    <td>{row.pagibig_contribution ? row.pagibig_contribution: ''}</td>
                    <td>{row.sss_loans ? row.sss_loans : ''}</td>
                    <td>{row.philhealth_loans ? row.philhealth_loans : ''}</td>
                    <td>{row.cooperative_loans ? row.cooperative_loans : ''}</td>
                    <td>{row.cooperative_savings ? row.cooperative_savings : ''}</td>
                    <td>{row.cash_advance ? row.cash_advance : ''}</td>
                    <td>{Number(row.grand_total).toFixed(2)}</td>
                </tr>
            )
        } else if (tableType === 'attendance') { // get all timesheet table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.employee_name}</td>
                    <td>{row.dtr_id}</td>
                    <td>{moment(row.work_date).format('MMM D, YYYY')}</td>
                    <td>{moment(row.time_in, 'hh:mm:ss').format('h:mm A')}</td>
                    <td>{moment(row.time_out, 'hh:mm:ss').format('h:mm A')}</td>
                    <td>{row.late + ' ' + 'mins'}</td>
                    <td>
                        <select className="dropdown-btn-employee" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'schedule') { // get all schedule table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.full_name}</td>
                    <td>{row.shift_name}</td>
                    <td>{moment(row.date_from).format('MMM D, YYYY')}</td>
                    <td>{row.date_to?moment(row.date_to).format('MMM D, YYYY'):''}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-employee" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>                
            )
        } else if (tableType === 'employee') { // get all employee table
            return (
                    <tr key={row.id} className="table-temp border-bottom">
                        <td>{row.dtr_id ? row.dtr_id :''}</td>
                        <td>{row.name}</td>
                        <td>{moment(row.date_hired).format('MMM D, YYYY')}</td>
                        <td>{row.date_regularized ? moment(row.date_regularized).format('MMM D, YYYY') : ''}</td>
                        <td>{row.end_of_contract ? row.end_of_contract : ''}</td>
                        <td>{row.position ? row.position : ''}</td>
                        <td>
                            <select className="dropdown-btn-employee" aria-label="" onChange={handleSelectChange} id={row.id} status={row.employment_status_id} value=''>
                                <option selected hidden>Actions</option>
                                <option value="view_employee">View</option>
                                <option value="edit">Edit</option>
                                <option value="update_contract">Update Contract</option>
                                <option value="update_allowance">Update Allowance</option>
                                <option value="update_status">Update Employee Status</option>
                                <option value="update_system_access">Update System Acess</option>
                                <option value="upload_requirements">Upload Requirements</option>
                                <option value="leave_history">Leave History</option>
                                <option value="wfa_history">WFA History</option>
                                {
                                    userRoleId === '1' ? (
                                        <option value="delete">Delete</option>
                                    ) : ('')
                                }
                            </select>
                        </td>
                    </tr>
                )
        } else if (tableType === 'branch') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td data-heading={"BRANCH NAME"} style={{textAlign:"left"}}>{row.name}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-branch" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'thirteenth_month') { // get all 13th month table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.employee_name}</td>
                    <td>{parseFloat(row.january).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.february).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.march).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.april).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.may).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.june).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.july).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.august).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.september).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.october).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.november).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.december).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                </tr>
            )
        } else if (tableType === 'letter_type') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.name}</td>
                    <td>{row.description}</td>
                    <td>
                        <select className="dropdown-btn-branch" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'company_files') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td style={{textAlign:"left"}}>{row.name}</td>
                    <td style={{textAlign:"left"}}>{row.description}</td>
                    <td> {
                        (function() {
                            let updatedNums = row.file_attachments?row.file_attachments.map((file)=>{
                                return <li className='bullet-list'>{file.file_name}</li>;
                            }):[];
                            return <ul style={{ listStyleType: 'disc' }}>{updatedNums}</ul>
                        })()
                    }
                    </td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-branch" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'project') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td data-heading={"PROJECT NAME"} style={{textAlign:"left"}}>{row.name}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-project" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
                // </Link>
            )
        } else if (tableType === 'department') { // get all branch table
            return (
                // <Link to = "/department/view" state = {row.id} className='view-deets'>
                <tr key={row.id} className="table-temp border-bottom">
                    <td data-heading={"DEPARTMENT NAME"} style={{textAlign:"left"}}>{row.name}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-department" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
                // </Link>
            )
        } else if (tableType === 'position') { // get all branch table
            return (
                // <Link to = "/position/view" state = {row.id} className='view-deets'>
                <tr key={row.id} className="table-temp border-bottom">
                    <td data-heading={"POSITION NAME"} style={{textAlign:"left"}}>{row.name}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-position" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
                // </Link>
            )
        }  else if (tableType === 'businessunit') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td data-heading={"BUSINESS UNIT NAME"} style={{textAlign:"left"}}>{row.name}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-unit" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        }  else if (tableType === 'employmentstatus') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td data-heading={"EMPLOYMENT STATUS NAME"} style={{textAlign:"left"}}>{row.name}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-status" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'employee_status') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td style={{textAlign:"left"}}>{row.name}</td>
                    <td>
                        <select className="dropdown-btn-status" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'requirements') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td style={{textAlign:"left"}}>{row.name}</td>
                    <td>
                        <select className="dropdown-btn-status" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        }  else if (tableType === 'memo') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{moment(row.memo_date).format('MMM D, YYYY')}</td>
                    <td>{row.subject}</td>
                    <td>{row.memo_from}</td>
                    <td> {
                        (function() {
                            let items = row.details?row.details.map(t => t.memo_to):[];
                            var finalItems = [];
                            items.map((item) => {
                                var i = [];
                                if (item) {
                                    finalItems.push(item);
                                }
                            })
                            let to = row.details?row.details.map(t => t.memo_to):[];
                            return finalItems.join(', ');
                        })()
                    }
                    </td>
                    <td>{SentenceCase(row.status)}</td>
                    <td>
                        <select className="dropdown-btn-memo" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_memo">View</option>
                            {
                                (row.status==="pending" && (userRoleId === '1' || userRoleId === '2')) ? (
                                     <option value="approve">Approve</option>
                                ) : ('')
                            }
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'communication_letter') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.subject}</td>
                    <td>{row.letter_type_name}</td>
                    <td>{moment(row.letter_date).format('MMM D, YYYY')}</td>
                    <td>{row.letter_from}</td>
                    <td> {
                        (function() {
                            let items = row.details?row.details.map(t => t.letter_to):[];
                            var finalItems = [];
                            items.map((item) => {
                                var i = [];
                                if (item) {
                                    finalItems.push(item);
                                }
                            })
                            let to = row.details?row.details.map(t => t.letter_to):[];
                            return finalItems.join(', ');
                        })()
                    }
                    </td>
                    <td>{SentenceCase(row.status)}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-warning" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_letter">View</option>
                            {
                                (row.status==="pending" && (userRoleId === '1' || userRoleId === '2')) ? (
                                     <option value="approve">Approve</option>
                                ) : ('')
                            }
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>

                </tr>
                // </Link>
            )
        } else if (tableType === 'announcement') { // get all branch table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td> {moment(row.announcement_date).format('MMM D, YYYY')}</td>
                    <td>{row.subject}</td>
                    <td>{row.announcement_from}</td>
                    <td> {
                        (function() {
                            let items = row.details?row.details.map(t => t.announcement_to):[];
                            var finalItems = [];
                            items.map((item) => {
                                var i = [];
                                if (item) {
                                    finalItems.push(item);
                                }
                            })
                            let to = row.details?row.details.map(t => t.announcement_to):[];
                            return finalItems.join(', ');
                        })()
                    }
                    </td>
                    <td>{SentenceCase(row.status)}</td>
                    <td>
                        <select className="dropdown-btn-announcement" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_announcement">View</option>
                            {
                                (row.status==="pending" && (userRoleId === '1' || userRoleId === '2')) ? (
                                     <option value="approve">Approve</option>
                                ) : ('')
                            }
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'leave') { // get all leave table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.employee_name}</td>
                    <td>{moment(row.added_on).format('MMM D, YYYY')}</td>
                    <td>{row.date_from?moment(row.date_from).format('MMM D, YYYY'):''}</td>
                    <td>{row.date_to?moment(row.date_to).format('MMM D, YYYY'):''}</td>
                    <td>{row.no_of_days}</td>
                    <td>{row.leave_type}</td>
                    <td>{SentenceCase(row.status)}</td>
                    <td className='d-flex justify-content-end'>
                        <select className="dropdown-btn" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_leave">View</option>
                            {
                                (row.status==="pending" && (userRoleId === '1' || userRoleId === '2')) ? (
                                     <option value="approve">Approve</option>
                                ) : ('')
                            }
                            {
                                (userRoleId === '1' || userRoleId === '2') ? (
                                    <option value="edit">Edit</option>
                                ) : ('')
                            }
                            
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'leave_type') { // get all leave type table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.description}</td>
                    <td>{row.maximum_days}</td>
                    <td className='d-flex justify-content-end'>
                        <select className="dropdown-btn" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            <option value="delete">Delete</option>
                        </select>
                    </td>
                </tr>
            )
        }  else if (tableType === 'wfa') { // get all leave table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.employee_name}</td>
                    <td>{moment(row.added_on).format('MMM D, YYYY')}</td>
                    <td>{moment(row.date_from).format('MMM D, YYYY')}</td>
                    <td>{row.date_to ? moment(row.date_to).format('MMM D, YYYY') : ''}</td>
                    <td>{row.no_of_days}</td>
                    <td>{SentenceCase(row.status)}</td>
                    <td className='d-flex justify-content-end'>
                        <select className="dropdown-btn" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_wfa">View</option>
                            {
                                (row.status==="pending" && (userRoleId === '1' || userRoleId === '2')) ? (
                                     <option value="approve">Approve</option>
                                ) : ('')
                            }
                            {
                                (userRoleId === '1' || userRoleId === '2') ? (
                                    <option value="edit">Edit</option>
                                ) : ('')
                            }
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'holidays') { // get all holidays table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td><td>{moment(row.date).format('MMM D, YYYY')}</td></td>
                    <td>{row.name}</td>
                    <td>{row.bonus}</td>
                    <td >{row.type==="Special_nonworking"?"Special Nonworking":(row.type==="Special_working"?"Special Working":row.type)}</td>
                    <td>{row.with_pay}</td>
                    <td data-heading={""}>
                        <select className="dropdown-btn-holidays" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } else if (tableType === 'shift') { // get all shift table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.name}</td>
                    <td>{row.time_in}</td>
                    <td>{row.time_out}</td>
                    <td>{row.overtime_fee}</td>
                    <td>{row.break_duration}</td>
                    <td>
                        <select className="dropdown-btn-shift" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>                    
            )
        } else if (tableType === 'employee_deductions') { // get all deduction table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.employee_name}</td>
                    <td>{row.issue_date}</td>
                    <td>{parseFloat(row.sss_amount).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.phic_amount).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.hdmf_amount).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.intellicare_amount).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.axa_amount).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.phone_loan).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                     <td>{parseFloat(row.laptop_loan).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.cash_advance).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.charges).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td className='ms-0'>
                        <select className="dropdown-btn-deduction ms-0" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>                
            )
        } else if (tableType === 'philhealth') { // get all deduction table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.full_name}</td>
                    <td>{parseFloat(row.total_employee_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.total_employer_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.total_amount_contributed).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td className='ms-0'>
                        <select className="dropdown-btn-deduction ms-0" aria-label="" onChange={handleSelectChange} id={row.employee_id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_philhealth">View</option>
                        </select>
                    </td>
                </tr>                
            )
        }  else if (tableType === 'coop') { // get all deduction table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.full_name}</td>
                    <td>{row.total_employee_contribution}</td>
                    <td className='ms-0'>
                        <select className="dropdown-btn-deduction ms-0" aria-label="" onChange={handleSelectChange} id={row.employee_id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_coop">View</option>
                        </select>
                    </td>
                </tr>                
            )
        } else if (tableType === 'sss') { // get all deduction table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.full_name}</td>
                    <td>{parseFloat(row.total_employee_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.total_employer_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.total_amount_contributed).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td className='ms-0'>
                        <select className="dropdown-btn-deduction ms-0" aria-label="" onChange={handleSelectChange} id={row.employee_id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_sss">View</option>
                        </select>
                    </td>
                </tr>                
            )
        }  else if (tableType === 'pagibig') { // get all deduction table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.full_name}</td>
                    <td>{parseFloat(row.total_employee_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.total_employer_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{parseFloat(row.total_amount_contributed).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td className='ms-0'>
                        <select className="dropdown-btn-deduction ms-0" aria-label="" onChange={handleSelectChange} id={row.employee_id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_pagibig">View</option>
                        </select>
                    </td>
                </tr>                
            )
        } else if (tableType === 'employee_loan' && row) { // get all deduction table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.employee_name}</td>
                    <td>{row.loan_type?splitAndCapitalize(row.loan_type):''}</td>
                    <td>{parseFloat(row.loan_amount).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{row.paid_amount ? parseFloat(row.paid_amount).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}): 0}</td>
                    <td>{parseFloat(row.remaining_balance).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td className='ms-0'>
                        <select className="dropdown-btn-deduction ms-0" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_loan">View</option>
                            <option value="edit">Edit</option>
                            <option value="delete">Delete</option>
                        </select>
                    </td>
                </tr>                
            )
        }  else if (tableType === 'night_differential') { // get all deduction table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.date?moment(row.date).format('MMMM D, yyyy'):''}</td>
                    <td>{row.rate}</td>
                    <td>{formatTime(row.time_start)}</td>
                    <td>{formatTime(row.time_end)}</td>
                    <td className='ms-0'>
                        <select className="dropdown-btn-deduction ms-0" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="edit">Edit</option>
                            <option value="delete">Delete</option>
                        </select>
                    </td>
                </tr>                
            )
        } else if (tableType === "employee_leave_credit") {
           return (
                <tr key={row.id} className="table-temp border-bottom">
                    {
                        tableHeaders.map((header) => {
                            return (
                                <td key={index} className=''>{row[header]}</td>
                            )
                        })
                    }
                </tr>             
            )
        } else if (tableType === 'employee_wfa_credit') { // get all deduction table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.employee_name}</td>
                    <td>{moment(row.date_hired).format('MMM D, YYYY') +' - '+moment(row.date_end).format('MMM D, YYYY')}</td>
                    {/* <td>{
                        (function () {
                            const [startDate, endDate] = row.period_covered.split('-');
                            return moment(startDate).format('MMM D, YYYY') +'-'+moment(endDate).format('MMM D, YYYY')
                        })()
                    }</td> */}
                    <td>{row.credits_left}</td>
                </tr>                
            )
        } else if (tableType === 'forms') { // get all forms table
            return (
                <tr key={row.id} className="table-temp border-bottom">
                    <td>{row.name}</td>
                    <td>{row.added_on?moment(row.added_on).format('MMMM D, yyyy'):''}</td>
                    <td>{row.deadline?moment(row.deadline).format('MMMM D YYYY, h:mm:ss A'):''}</td>
                    <td>{SentenceCase(row.status)}</td>
                    <td className='d-flex justify-content-end'>
                        <select className="dropdown-btn" aria-label="" onChange={handleSelectChange} id={row.id} value=''>
                            <option selected hidden>Actions</option>
                            <option value="view_form">View</option>
                            {
                                (userRoleId === '1' || userRoleId === '2') ? (
                                    <option value="edit">Edit</option>
                                ) : ('')
                            }
                            {
                                (dateToday >= row.deadline) ? (
                                    <option value="survey">Take Survey</option>
                                ) : ('')
                            }
                            {
                                userRoleId === '1' ? (
                                    <option value="delete">Delete</option>
                                ) : ('')
                            }
                        </select>
                    </td>
                </tr>
            )
        } 

    }

    // table pagination
    const finalTable = () => {
        if (tableType !== null) {
            return (
                // tableData.map((rawRow, index) => {
                slice.map((rawRow, index) => {
                    return (
                        rowFields(rawRow, index)
                    )
                })
            )
        }
    }

    return (
        <>
			<div className="ebr-table-wrapper me-3">
                <Table borderless size="s" className={tableClass + " ebr-table"}>
                    <thead className='table-text'>
                        <tr>
                            {tableHeaders.map((header, index) => {
                                return (
                                    <th key={index} className=''>{splitAndCapitalize(header)}</th>
                                )
                            })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <div className='text-left table-text'>
                            {finalTable()}
                        </div>
                    </tbody>
                </Table>
                
                <TableFooter
                    range={range}
                    slice={slice}
                    setPage={setPage}
                    page={page}
                    footerClass={givenClass}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                />
			</div>
        </>
    )
}

export default TableTemplate;