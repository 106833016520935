import React, { useState } from "react";
import { Row, Col, Form, InputGroup} from "react-bootstrap";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";

// components
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt"
import AddEmployee from "./AddEmployee";
import UploadRequirements from "./UploadRequirements";
import ExportExcelButton from "../../utilities/export_excel_button.js"
import { CSVLink } from "react-csv";
import EditEmployee from "./EditEmployee";
import UpdateContract from "./UpdateContract";
import UpdateAllowance from "./UpdateAllowance";
import UpdateEmployeeStatus from "./UpdateEmployeeStatus";
import UpdateSystemAccess from "./UpdateSystemAccess";
import { GetAllBranches, GetAllDepartments, GetAllEmploymentStatuses, GetAllPositions, GetAllShifts, GetAllProjects, GetAllBusinessUnits, GetAllEmployeeStatus, GetAllSystemRoles } from "../../helpers/CommonApi";

// icons
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

// css
import './Employee.css';
import '../Modals/modal.css'
import "../../utilities/common.css"
import "../../utilities/loader_1.css"


export default function Employee() {
    const userToken = getToken();
    const userId = getUser();
    const navigateTo = useNavigate()
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({});
    const [allEmployees, setAllEmployees] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [showEditInfo, setShowEditInfo] = useState(false);
    const [showUpdateAllowance, setShowUpdateAllowance] = useState(false);
    const handleCloseEditInfo = () => {
        setShowEditInfo(false);
        setTimeout(() => refreshPage(), 500); 
    };
    const handleCloseUpdateContract = () => {setShowUpdateContract(false); };
    const handleCloseUpdateAllowance = () => {setShowUpdateAllowance(false); };
    const [showDelete, setShowDelete] = useState(false);
    const [showUpdateContract, setShowUpdateContract] = useState(false);
    const [showUploadRequirements, setShowUploadRequirements] = useState(false);
    const [allFilter, setAllFilter] = useState(true);
    const [showUpdateStatus, setShowUpdateStatus] = useState(false);
    const [showSystemAccess, setShowSystemAccess] = useState(false);
    const [editSystemAccessData, setEditSystemAccessData] = useState([]);

    const [employeeId, setEmployeeId] = useState();
    const [employmentStatusId, setEmploymentStatusId] = useState();
    const employmentStatus = GetAllEmploymentStatuses();
    const employeeStatus = GetAllEmployeeStatus();
    const branches = GetAllBranches();
    const shifts = GetAllShifts();
    const departments = GetAllDepartments();
    const projects = GetAllProjects();
    const positions = GetAllPositions();
    const businessUnits = GetAllBusinessUnits();
    const systemRoles = GetAllSystemRoles();

    const [editEmployeeData, setEditEmployeeData] = useState({
        requester: getUser(),
        token: getToken(),
        branch_id: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        nickname: '',
        contact_no: '',
        address: '',
        email: '',
        gender: '',
        birthdate: '',
        age: '',
        civil_status: '',
        nationality: '',
        religion: '',
        notes: '',
        file_name: '',

        sss: '',
        hdmf: '',
        phic: '',
        intellicare_number: '',
        axa_policy_number: '',

        tin: '',
        wtaxstatus: '',
        dependent: '',

        spouse_name: '',
        spouse_age: '',
        spouse_occupation: '',
        mothers_name: '',
        mothers_age: '',
        mothers_occupation: '',
        fathers_name: '',
        fathers_age: '',
        fathers_occupation: '',

        children: [],

        contact_person: '',
        contact_person_relationship: '',
        contact_number: '',
        contact_address: '',

        dtr_id: '',
        branch_id: '',
        salary_type: '',
        salary: '',
        employment_status_id: '',
        position_id: '',
        business_unit_id: '',
        immediate_head_id: '',
        project_id: '',
        department: '',
        date_hired: '',
        position_id: '',
        end_of_contract: '',
        date_regularized: '',
        no_of_days: '',
        previous_employer: '',
        current_shift_id: '',

        daily_allowance: '',
        commu_allowance: '',
        transpo_allowance: '',
        food_allowance: '',
        hmo_allowance: '',
        tech_allowance: '',
        ops_allowance: '',
        special_allowance: '',
        bank: '',
        bank_account_number: ''
    });

    const [filterData, setFilterData] = useState({});

    // add account handler
    const [showPersonalInfo, setShowPersonalInfo] = useState(false);
    const handleClosePersonalInfo = () => {
        setShowPersonalInfo(false);
        setTimeout(() => refreshPage(), 500);
    }
    const handleShowPersonalInfo = () => setShowPersonalInfo(true);

    function onClickPersonalInfo() {
        handleShowPersonalInfo();
    }

    // GET EMPLOYEE BY ID FOR EDITING OR UPDATING CONTRACT
    function getEmployeeDetailsById (employee_id) {
        var axios = require('axios');
        var qs = require('qs');

        axios({
            method: 'post',
            url: window.$link + 'employees/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: employee_id
            })
        }).then(response => {
            const res = response.data.employees[0];
            const salary_info = res.salary;
            setEmploymentStatusId(res.employment_status_id);
            var currentData = {
                requester: getUser(),
                token: getToken(),
                branch_id: res.branch_id,
                last_name: res.last_name,
                first_name: res.first_name,
                middle_name: res.middle_name,
                nickname: res.nickname,
                contact_no: res.contact_no,
                address: res.address,
                email: res.email,
                gender: res.gender,
                birthdate: res.birthdate,
                age: res.age,
                civil_status: res.civil_status,
                nationality: res.nationality,
                religion: res.religion,
                remarks: res.remarks,
                file_name: res.file_name,
                sss: res.sss,
                hdmf: res.hdmf,
                phic: res.phic,
                intellicare_number: res.intellicare_number,
                axa_policy_number: res.axa_policy_number,
                tin: res.tin,
                wtaxstatus: res.wtaxstatus,
                dependent: res.dependent,
                spouse_name: res.spouse_name,
                spouse_age: res.spouse_age,
                spouse_occupation: res.spouse_occupation,
                mothers_name: res.mothers_name,
                mothers_age: res.mothers_age,
                mothers_occupation: res.mothers_occupation,
                fathers_name: res.fathers_name,
                fathers_age: res.fathers_age,
                fathers_occupation: res.fathers_occupation,
                children: res.children? res.children:[],
                contact_person: res.contact_person,
                contact_number: res.contact_number,
                contact_address: res.contact_address,
                contact_person_relationship: res.contact_person_relationship,
                dtr_id: res.dtr_id,
                salary_type: salary_info ? salary_info[0].salary_type : '',
                employment_status_id: res.employment_status_id,
                employee_status_id: res.employee_status_id,
                employee_status_remarks: res.employee_status_remarks,
                position_id: res.position_id,
                business_unit_id: res.business_unit,
                immediate_head_id: res.immediate_head_id,
                project_id: res.project,
                department_id: res.department_id,
                date_hired: res.date_hired,
                date_regularized: res.date_regularized,
                no_of_days: res.no_of_days,
                end_of_contract: res.end_of_contract,
                previous_employer: res.previous_employer,
                current_shift_id: res.current_shift_id,
                file_name: res.file_name ? res.file_name : '',
                daily_allowance: res.allowance ? res.allowance[0].daily_allowance : '',
                commu_allowance: res.allowance ? res.allowance[0].commu_allowance: '',
                transpo_allowance: res.allowance ? res.allowance[0].transpo_allowance: '',
                food_allowance: res.allowance ? res.allowance[0].food_allowance: '',
                hmo_allowance: res.allowance ? res.allowance[0].hmo_allowance: '',
                tech_allowance: res.allowance ? res.allowance[0].tech_allowance: '',
                ops_allowance: res.allowance ? res.allowance[0].ops_allowance: '',
                special_allowance: res.allowance ? res.allowance[0].special_allowance: '',
                grocery_allowance: res.allowance ? res.allowance[0].grocery_allowance: '',
                medicine_allowance: res.allowance ? res.allowance[0].medicine_allowance: '',
                entertainment_allowance: res.allowance ? res.allowance[0].entertainment_allowance: '',
                uniform_allowance: res.allowance ? res.allowance[0].uniform_allowance: '',

                position_id: res.position_to,
                position_from: res.position_from,
                position_date: res.position_date,
                current_position: res.position_to,

                salary_from: salary_info ? salary_info[0].salary_from : '',
                salary_to: salary_info ? salary_info[0].salary_to : '',
                current_salary: salary_info ? salary_info[0].salary_to : '',
                salary_date: salary_info ? salary_info[0].salary_date : '',

                bank: res.bank,
                bank_account_number: res.bank_account_number,
                bank_account_name: res.bank_account_name,
                role_id: res.role_id,
                password: res.password
            }
            setEditEmployeeData(currentData);
        }).catch(function (error) {
        });
    }

    // GET EMPLOYEE ID FOR EDITING
    function onclickEditEmployee(employee_id) {
        setEmployeeId(employee_id);
        getEmployeeDetailsById(employee_id);
        setShowEditInfo(true);
    }

    // GET EMPLOYEE BY ID TO UPDATE CONTRACT
    function onClickUpdateContract(employee_id) {
        setEmployeeId(employee_id)
        getEmployeeDetailsById(employee_id);
        setShowUpdateContract(true)
    }

     // GET EMPLOYEE BY ID TO UPDATE ALLOWANCE
    function onClickUpdateAllowance(employee_id) {
        setEmployeeId(employee_id);
        getEmployeeDetailsById(employee_id);
        setShowUpdateAllowance(true);
    }

    // GET EMPLOYEE BY ID FOR DELETION
    function onClickDeleteEmployee(employee_id) {
        setEmployeeId(employee_id)
        setShowDelete(true)
    }

    function onClickUploadRequirement(employee_id) {
        setEmployeeId(employee_id);
        getEmployeeDetailsById(employee_id);
        setShowUploadRequirements(true);
    }

    function onClickUpdateStatus(employee_id) {
        setEmployeeId(employee_id);
        getEmployeeDetailsById(employee_id);
        setShowUpdateStatus(true);
    }

    function onClickUpdateSystemAccess (employee_id) {
        setEmployeeId(employee_id);
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'users/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: employee_id
            })
        }).then(response => {
            const res = response.data.data[0];
            setEditSystemAccessData(res);
            setShowSystemAccess(true);
        }).catch(function (error) {
        });
    }
    
    const handleCloseDelete = () => setShowDelete(false);

    // DELETING EMPLOYEE
    function handleRemove(e) {
        var axios = require('axios');
        var qs = require('qs');
        axios({
        url: window.$link + 'employees/delete',
        method: 'post',
        headers: {
            "api-key": window.$api_key,
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, ''),
            employee_id: employeeId
        })
        })
        .then(function (response) {
            // toast.success("Employee Deleted Successfully!");
            // setTimeout(() => refreshPage(), 1500);
        })
        .catch(function (error) {
            tokenExpired(error);
        });

        axios({
        url: window.$link + 'users/delete',
        method: 'post',
        headers: {
            "api-key": window.$api_key,
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, ''),
            employee_id: employeeId
        })
        })
        .then(function (response) {
            toast.success("Employee Deleted Successfully!");
            setTimeout(() => refreshPage(), 1500);
        })
        .catch(function (error) {
            tokenExpired(error);
        });
    }

    // GET ALL EMPLOYEES
    React.useEffect(() => {
        allEmployees.length = 0;
        filteredEmployees.length = 0;
        setAllEmployees([]);
        setFilteredEmployees([]);

        var axios = require('axios');
        var qs = require('qs');
        axios({
            method: 'post',
            url: window.$link + 'employees/get_all',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: '',
                branch_id: filterData.branch_id,
                employment_status_id: filterData.employment_status_id,
                position_id: filterData.position_id,
                business_unit_id: filterData.business_unit_id,
                project_id: filterData.project_id,
                department_id: filterData.department_id
            })
        }).then(response => {
            const infoSorted = response.data.employees.sort((a, b) => {return (a.last_name < b.last_name) ? -1 : (a.last_name > b.last_name) ? 1 : 0});
            if (infoSorted && infoSorted.length) {
                infoSorted.map((employee) => {
                    var info = {...employee};
                    info.id = employee.id;
                    info.date_hired = moment(employee.date_hired).format('MMM D, YYYY');
                    info.end_of_contract = employee.end_of_contract ? moment(employee.end_of_contract).format('MMM D, YYYY'): '';
                    info.name = employee.last_name + ", " + employee.first_name + " " + employee.middle_name;
                    info.position = employee.position_name;
                    var salary = info.salary_to ? Number(info.salary_to).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : '';
                    var salary_type = info.salary_type == "monthly5" || info.salary_type == "monthly6" ? "monthly" : info.salary_type;
                    info.salary = salary + " " + salary_type;
            
                    setAllEmployees(oldArray => [...oldArray, info]);
                    setFilteredEmployees(oldArray => [...oldArray, info]);
                });
            }
            setLoading(false);
        }).catch(function (error) {
            setAllEmployees([]);
            setLoading(false);
        });
    },[filterData]);

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allEmployees) {
            allEmployees.forEach(function(employee) {
                if((employee.name).toLowerCase().includes(term)) {
                    filteredArr.push(employee);
                }
            })
        }
        if(term == "") {
            setFilteredEmployees(allEmployees);
        } else {
            setFilteredEmployees(filteredArr);
        }
    }

    function handleFilters(e) {
        setAllFilter(false)
        const newFilterData = { ...filterData };
        newFilterData[e.target.id] = e.target.value;
        setFilterData(newFilterData);
    }

    function resetFilter(e) {
        const filter = [{
            branch_id: '',
            employment_status_id: '',
            position_id: '',
            business_unit_id: '',
            project_id: '',
            department_id: ''
        }]
        setFilterData(filter);
    }

    return(
        <div className='comm-bg'>
            <Navbar />
            
            <div className="comm-page-container">
                <Row className="p-1 m-0" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-employee'>
                            Employee    
                        </Col>
                        <Col className='d-flex justify-content-end '>
                            <Row>
                                <Col className="me-5">
                                    <CSVLink data={filteredEmployees} filename={"Employee Master List.csv"}><ExportExcelButton/></CSVLink>
                                </Col>
                                <Col className="me-5">
                                    <button className="export-button-employee" onClick={onClickPersonalInfo}>
                                        <span className="export-text"><PersonIcon/> Add Employee</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                        
                    </Row>
                    
                    
                    <AddEmployee 
                        showmodal1 = {showPersonalInfo}
                        hidemodal1 = {handleClosePersonalInfo}
                        allEmployees = {allEmployees}
                        branches = {branches}
                        employmentStatus = {employmentStatus}
                        employeeStatus = {employeeStatus}
                        businessUnits = {businessUnits}
                        positions = {positions}
                        projects = {projects}
                        departments = {departments}
                        shifts = {shifts}
                        systemRoles = {systemRoles}
                    />

                    <EditEmployee 
                        employeeId ={employeeId}
                        showmodal1 = {showEditInfo}
                        hidemodal1 = {handleCloseEditInfo}
                        employeeData = {editEmployeeData}
                        setEditData = {setEditEmployeeData}
                    />

                    <UploadRequirements
                        employeeId = {employeeId}
                        employmentStatusId = {employmentStatusId}
                        show = {showUploadRequirements}
                        hide = {() => setShowUploadRequirements(false)}
                    />

                    <DeletePrompt
                        name = "EMPLOYEE"
                        show = {showDelete}
                        hide = {handleCloseDelete}
                        remover = {handleRemove}
                    />

                    <UpdateContract
                        employeeId ={employeeId}
                        showmodal1 = {showUpdateContract}
                        hidemodal1 = {handleCloseUpdateContract}
                        employeeData = {editEmployeeData}
                        setEditData = {setEditEmployeeData}
                        allEmployees = {allEmployees}
                        branches = {branches}
                        employmentStatus = {employmentStatus}
                        employeeStatus={employeeStatus}
                        businessUnits = {businessUnits}
                        positions = {positions}
                        projects = {projects}
                        departments = {departments}
                        shifts = {shifts}
                    />

                    <UpdateAllowance 
                        employeeId ={employeeId}
                        showmodal1 = {showUpdateAllowance}
                        hidemodal1 = {handleCloseUpdateAllowance}
                        employeeData = {editEmployeeData}
                        setEditData = {setEditEmployeeData}
                    />

                    <UpdateEmployeeStatus 
                        employeeId ={employeeId}
                        show = {showUpdateStatus}
                        hide = {() => setShowUpdateStatus(false)}
                        employeeData = {editEmployeeData}
                        setEditData = {setEditEmployeeData}
                        employeeStatus = {employeeStatus}
                    />

                    <UpdateSystemAccess 
                        employeeId ={employeeId}
                        show = {showSystemAccess}
                        hide = {() => setShowSystemAccess(false)}
                        employeeData = {editSystemAccessData}
                        setEditData = {setEditSystemAccessData}
                        systemRoles = {systemRoles}
                    />

                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-employee">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Employee"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                {/* NEW FILTER DESIGN */}
                {/* <Row className="mt-3 ms-2"> */}
                    {/* <Col sm={12}> */}
                        <Row className="filter-container mt-3 ms-2 me-4">
                            {/* <Col xs={0} className={allFilter ? "all active-all" : "all"}> */}
                            <Col xs={1} className='my-auto'>
                                <button onClick={resetFilter} className='export-button-filter'>All</button>
                            </Col>
                            <Col s={1} className='margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select 
                                        value={filterData.branch_id}
                                        className="selected-filter"
                                        aria-label="branch_id"
                                        aria_describedby="branch_id"
                                        name="branch_id"
                                        id="branch_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Branch</option>
                                        {
                                            branches.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col s={1.} className='margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select 
                                        value={filterData.employment_status_id}
                                        className="selected-filter"
                                        aria-label="employment_status_id"
                                        aria_describedby="employment_status_id"
                                        name="employment_status_id"
                                        id="employment_status_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                        // onChange={(e) => setFilterData({...filterData, employment_status_id: e.target.value})}
                                    >
                                        <option value=''>Status</option>
                                        {
                                            employmentStatus.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col xs={2} className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select
                                        value={filterData.position_id}
                                        className="selected-filter"
                                        aria-label="position_id"
                                        aria_describedby="position_id"
                                        name="position_id"
                                        id="position_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                        // onChange={(e) => setFilterData({...filterData, position_id: e.target.value})}
                                    >
                                        <option value=''>Position</option>
                                        {
                                            positions.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col xs={2} className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select
                                        value={filterData.business_unit_id}
                                        className="selected-filter"
                                        aria-label="business_unit_id"
                                        aria_describedby="business_unit_id"
                                        name="business_unit_id"
                                        id="business_unit_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Business Unit</option>
                                        {
                                            businessUnits.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col xs={2} className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select 
                                        value={filterData.project_id}
                                        className="selected-filter"
                                        aria-label="project_id"
                                        aria_describedby="project_id"
                                        name="project_id"
                                        id="project_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Project</option>
                                        {
                                            projects.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col xs={2} className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select
                                        value={filterData.department_id} 
                                        className="selected-filter"
                                        aria-label="department_id"
                                        aria_describedby="department_id"
                                        name="department_id"
                                        id="department_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Department</option>
                                        {
                                            departments.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Row>
                    {/* </Col> */}
                {/* </Row> */}

                {/* table here */}
                <div className="employee-table-wrapper mt-4">
                {
                    (function() {
                        return !loading ? (<TableTemplate
                        tableType='employee'
                        tableHeaders={["DTR-ID", "Employee Name", "Date Hired", "Date Regularized", "End Contract", "Position", ""]}
                        tableData={filteredEmployees}
                        editHandler={onclickEditEmployee}
                        deleteHandler={onClickDeleteEmployee}
                        updateContract={onClickUpdateContract}
                        updateAllowance={onClickUpdateAllowance}
                        uploadRequirements={onClickUploadRequirement}
                        updateEmployeeStatus={onClickUpdateStatus}
                        updateSystemAccess={onClickUpdateSystemAccess}
                    />) : (
                        <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                            <div className="newtons-cradle__dot"></div>
                            <div className="newtons-cradle__dot"></div>
                            <div className="newtons-cradle__dot"></div>
                            <div className="newtons-cradle__dot"></div>
                        </div>
                        )
                    })()
                }
                </div>
            </div>
        </div>
    );
}