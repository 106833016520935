import { Modal, Row, Container, Col} from 'react-bootstrap'
import './modal.css'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function LackingRequirements(props) {    
   
    return (
        <div>
            <Modal show={props.show} onHide={props.hide} centered>
                <Modal.Body className="delete-modal">
            
                    <div className="modal-header p-0">
                        Employees
                    </div>

                    <div className='body-head no-scroll pt-3'>
                        <Container>

                            {
                                props.employees && props.employees.length ? (
                                    props.employees.map((data) => {
                                        return (
                                            <>
                                            <Row className="px-3">
                                                <Col className="ms-3 lacking-employee-names">
                                                    <span className="me-3 lacking-employee-icon py-2"><AccountCircleIcon/></span> <span className=''>{data?data:''}</span>
                                                </Col>
                                            </Row>
                                            </>
                                        )
                                    })
                                ) : ('')
                            }
                           

                            <div className='d-flex justify-content-end me-2 mt-4'>
                                <button type="" className='cancel-button me-2' onClick={props.hide}>Close</button>
                            </div>
    
                        </Container>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}