import React, { useState, useRef } from "react";
import { getToken, getUser, getRoleId, tokenExpired, refreshPage} from "../../utilities/common";
import { GetUserEmployeeId } from "../../helpers/CommonApi";
import {Row, Col, Container} from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar"
import '../Modals/modal.css'
import "../../utilities/common.css"
import 'react-datepicker/dist/react-datepicker.css'
import { current } from "immer";


export default function AUB() {
    const userToken = getToken();
    const userId = getUser();
    const componentRef = useRef();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const currentDate = new Date();
    const dateToday = new Date().getDate();
    const navigate = useNavigate();
    const [filterDate, setFilterDate] = useState({
        date_from: '',
        date_to: ''
    });

    // GET AUB FOR ONE SPECIFIC DATE RANGE ONLY
    React.useEffect(() => {
        var axios = require('axios');
        var qs = require('qs');
        axios({
            method: 'post',
            url: window.$link + 'aubs/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                date_from: filterDate.date_from,
                date_to: filterDate.date_to,
                aub_id: ''
            })
        }).then(response => {
            setData(response.data.data[0]);
            setLoading(false);
        }).catch(function (error) {
            setData({});
        });
    },[filterDate]);
            
    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    
                    <Row className="m-0">
                        <Col xs={6} className='d-flex comm-header-employee'>
                            AUB
                        </Col>
                        {/* <Col className="d-flex justify-content-end me-4">
                            <Row className="col-9 d-flex justify-content-end me-3">
                                <Col xs={3} className="me-3 ms-3">
                                    <ReactToPrint
                                        trigger={() => (
                                            <button className="export-button py-2 px-0">
                                                <span className="export-text"><PrintIcon/> Print</span>
                                            </button>
                                        )}
                                        content={() => componentRef.current}
                                        documentTitle={"AUB Contribution"}
                                    />
                                </Col>
                                <Col xs={3} className="ms-3">
                                    <button className="export-button py-2 px-0" onClick={() => navigate('sss_batch_entry')}>
                                        <span className="export-text"><UploadIcon/>Upload</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col> */}
                
                    </Row>
                </Row>

                {/* Date Filter */}
                <Row className="px-3 mt-5">
                    <Col xs={1}>Date From</Col>
                    <Col className="ms-0" xs={2}>
                            <input
                                type="date"
                                className={'form-control'}
                                placeholderText={'dd/mm/yyyy'}
                                id='date_from'
                                name='date_from'
                                ariaDescribedBy={'date_from'}
                                ariaLabelledBy={'date_from'}
                                onChange={(e) =>
                                    setFilterDate({ ...filterDate, date_from: e.target.value })
                                }
                            />
                    </Col>
                    <Col xs={1}>Date To</Col>
                    <Col className="ms-0" xs={2}>
                            <input
                                type="date"
                                className={'form-control'}
                                placeholderText={'dd/mm/yyyy'}
                                id='date_to'
                                name='date_to'
                                ariaDescribedBy={'date_to'}
                                ariaLabelledBy={'date_to'}
                                onChange={(e) =>
                                setFilterDate({ ...filterDate, date_to: e.target.value })
                                }
                            />
                    </Col>
                </Row>

                {/* TABLE HERE */}
                <div className="aub-wrapper mt-2 me-2" ref={componentRef}>
                    {
                        (function() {
                            return !loading ? (
                            // <TableTemplate
                            //     tableType='aub'
                            //     tableHeaders={["Date", ""]}
                            //     tableData={data}
                            //     deleteHandler={onClickDelete}
                            // />
                            // (filterDate.date_from || filterDate.date_to)? (
                            (data.file_attachment) ? (
                                <a href={`${data.file_attachment}`} download={`${data.file_name}`}>
                                     <button className="download-file-btn">
                                        DOWNLOAD FILE
                                    </button>
                                </a>
                                ) : (
                                    <button className="file-btn">
                                        DOWNLOAD FILE
                                    </button>
                                )
                                
                            ) : (
                                <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                </div>
                            )
                        })()
                    }
                </div>
            </div>
        </div>
    );
}

