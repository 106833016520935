import React, { useState } from "react";
import { Row, Col, Form, InputGroup} from "react-bootstrap";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";

// components
import Navbar from "../Navbar/Navbar"
import Dashboard from "../Dashboard/Dashboard"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt"
import AddBusinessUnit from "./AddBusinessUnit";
import EditBusinessUnit from "./EditBusinessUnit";
import "../../utilities/loader_1.css"

// icons
import BusinessIcon from '@mui/icons-material/Business';
import SearchIcon from "@mui/icons-material/Search";

// css
import './BusinessUnit.css';
import '../Modals/modal.css'
import "../../utilities/common.css"

export default function BusinessUnit() {
    const userToken = getToken();
    const userId = getUser();
    const [term, setTerm] = useState(false);
    const [loading, setLoading] = useState(true);

    // get all business units
    const [allBusinessUnits, setAllBusinessUnits] = useState([]);
    const [filteredBusinessUnits, setFilteredBusinessUnits] = useState([]);

    React.useEffect(() => {
        allBusinessUnits.length = 0;

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'business_units/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                business_unit_id: ""
            }
        }).then(response => {
            setAllBusinessUnits(response.data.data);
            setFilteredBusinessUnits(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            setLoading(false);
        });
    },[]);

    const [addBusinessUnit, setAddBusinessUnit] = useState(false);
    const handleAddBusinessUnit = () => setAddBusinessUnit(true);
    const handleCloseAddBusinessUnit = () => setAddBusinessUnit(false);

    const [editBusinessUnit, setEditBusinessUnit] = useState(false);
    const handleEditBusinessUnit = () => setEditBusinessUnit(true);
    const handleCloseEditBusinessUnit = () => setEditBusinessUnit(false);


    const [deleteBusinessUnit, setDeleteBusinessUnit] = useState(false);
    const handleDeleteBusinessUnit = () => setDeleteBusinessUnit(true);
    const handleCloseDeleteBusinessUnit = () => setDeleteBusinessUnit(false);


    function onClickaddBusinessUnit() {
        handleCloseAddBusinessUnit();
        handleAddBusinessUnit();
    }

    const [editBusinessUnitData, setEditBusinessUnitData] = useState({});
    function onClickEditBusinessUnit(business_unit_id) {
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'business_units/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                business_unit_id: business_unit_id
            }
        }).then(response => {
            setEditBusinessUnitData(response.data.data[0]);
            handleCloseEditBusinessUnit();
            handleEditBusinessUnit();
        }).catch(function (error) {
            console.log(error);
        });
    }

    // const [deleteBusinessUnitData, setDeleteBusinessUnitData] = useState({});
    const [businessUnitId, setBusinessUnitId] = useState()
    function onClickDeleteBusinessUnit(business_unit_id) {
        setBusinessUnitId(business_unit_id);
        handleDeleteBusinessUnit();
    }

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allBusinessUnits) {
            allBusinessUnits.forEach(function(business_unit) {
                if((business_unit.name).toLowerCase().includes(term)) {
                    filteredArr.push(business_unit);
                }
            })
        }

        if (term == "") {
            setFilteredBusinessUnits(allBusinessUnits);
        } else {
            setFilteredBusinessUnits(filteredArr);
        }
    }

    // Delete business unit API
    function handleRemove (e) {
        var axios = require('axios');
        var qs = require('qs');
            axios({
            url: window.$link + 'business_units/delete/'+businessUnitId,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, '')
            })
            })
            .then(function (response) {
                toast.success("Business Unit Deleted Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                console.log(error.response.data.messages);
                toast.error("Failed to Delete Business Unit")
                tokenExpired(error);
            });
    }

    return (
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-unit'>
                            Business Unit   
                        </Col>
                        <Col className='d-flex justify-content-end me-5'>
                            <Row>
                                <Col className="me-5">
                                    <button className="export-button-unit p-2" onClick={onClickaddBusinessUnit}>
                                        <span className="export-text"><BusinessIcon/> Add Business Unit</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>     
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-unit">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Business Unit"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                <AddBusinessUnit
                    showmodal1 = {addBusinessUnit}
                    hidemodal1 = {handleCloseAddBusinessUnit}
                    showmodal1handler = {onClickaddBusinessUnit}
                />

                <EditBusinessUnit
                    showmodal1 = {editBusinessUnit}
                    hidemodal1 = {handleCloseEditBusinessUnit}
                    showmodal1handler = {onClickEditBusinessUnit}
                    editBusinessUnitData = {editBusinessUnitData}
                />

                <DeletePrompt
                    name = "BUSINESS UNIT"
                    show = {deleteBusinessUnit}
                    hide = {handleCloseDeleteBusinessUnit}
                    remover = {handleRemove}
                />

                <div className="business-unit-wrapper mt-4">
                {
                    (function() {
                         return !loading ? (<TableTemplate
                            tableType='businessunit'
                            tableHeaders={["Business Unit Name", ""]}
                            tableData={filteredBusinessUnits}
                            deleteHandler={onClickDeleteBusinessUnit}
                            editHandler={onClickEditBusinessUnit}
                        />) : (
                            <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                            </div>
                        )
                    })()
                }
                </div>
            </div>
        </div>
    );
}