import {handleValidationChange} from '../Validation/CommonValidation';

export const validateLoan = (data, setIsError) => {
    var isValid = true;

    if(data.issue_date === "") {
        handleValidationChange("issue_date", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("issue_date", false, setIsError);
    }

    if(data.employee_id === "") {
        handleValidationChange("employee_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("employee_id", false, setIsError);
    }

    if(data.loan_type === "" || data.loan_type === null) {
        handleValidationChange("loan_type", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("loan_type", false, setIsError);
    }

    if(data.loan_amount === "") {
        handleValidationChange("loan_amount", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("loan_amount", false, setIsError);
    }
    
    if(data.term === "") {
        handleValidationChange("term", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("term", false, setIsError);
    }

    if(data.interest_rate === "") {
        handleValidationChange("interest_rate", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("interest_rate", false, setIsError);
    }

    if(data.reason === "") {
        handleValidationChange("reason", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("reason", false, setIsError);
    }

    return isValid
  
}