import React, { useState, useRef } from "react";
import { Row, Col, Form, InputGroup} from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { getUser, getToken} from "../../utilities/common";

// components
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import { GetAllBranches, GetAllDepartments, GetAllEmploymentStatuses, GetAllPositions, GetAllShifts, GetAllProjects, GetAllBusinessUnits, GetAllEmployeeStatus } from "../../helpers/CommonApi";


// icons
import PrintIcon from '@mui/icons-material/Print';
import SearchIcon from '@mui/icons-material/Search';

// css
import './ThirteenthMonth.css';
import '../Modals/modal.css'
import "../../utilities/common.css"
import "../../utilities/loader_1.css"


export default function ThirteenthMonth() {
    const userToken = getToken();
    const userId = getUser();
    const componentRef = useRef();
    const employmentStatus = GetAllEmploymentStatuses();
    const branches = GetAllBranches();
    const departments = GetAllDepartments();
    const projects = GetAllProjects();
    const positions = GetAllPositions();
    const businessUnits = GetAllBusinessUnits();
    const [filterYears, setFilterYears] = useState([]); 
    const [loading, setLoading] = useState(true)
    const [allThirteenthMonth, setAllThirteenthMonth] = useState([]);
    const [filteredThirteenthMonth, setFilteredThirteenthMonth] = useState([]);
    const [filterData, setFilterData] = useState({});

 
    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allThirteenthMonth) {
            allThirteenthMonth.forEach(function(thirteenth_month) {
                if((thirteenth_month.employee_name).toLowerCase().includes(term)) {
                    filteredArr.push(thirteenth_month);
                }
            })
        }

        if (term == "") {
            setFilteredThirteenthMonth(allThirteenthMonth);
        } else {
            setFilteredThirteenthMonth(filteredArr);
        }
    }

    function handleFilters(e) {
        const newFilterData = { ...filterData };
        newFilterData[e.target.id] = e.target.value;
        setFilterData(newFilterData);
    }

    // Years to be used for filter
    React.useEffect(() => {
        const currentYear = new Date().getFullYear();
        var years = [];
        filterYears.length = 0;
        for (let i = 2020; i <= currentYear; i++) {
            var year = {}
            year.value = i;
            year.name = i;
            years.push(year);
        }
        setFilterYears(years);
    }, []);
 
      // GET ALL THIRTEENTH MONTH
      React.useEffect(() => {
         allThirteenthMonth.length = 0;
         
         var axios = require('axios');
         var qs = require('qs');
         
         axios({
             method: 'post',
             url: window.$link + 'payrolls/thirteenth_month_report',
             headers: {
                 "api-key": window.$api_key,
                 "Content-Type": "application/x-www-form-urlencoded"
             },
             params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                branch_id: filterData.branch_id,
                employment_status_id: filterData.employment_status_id,
                position_id: filterData.position_id,
                business_unit_id: filterData.business_unit_id,
                project_id: filterData.project_id,
                department_id: filterData.department_id,
                year: filterData.year
            }
         }).then(response => {
             setAllThirteenthMonth(response.data.thirteenth_month);
             setFilteredThirteenthMonth(response.data.thirteenth_month);
             setLoading(false);
         }).catch(function (error) {
             console.log('Error getting all thirteenth month');
             setLoading(false);
         });
     },[]);


    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1 m-0" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-thirteenthMonth'>
                            13th Month Report
                        </Col>
                        <Col className='d-flex justify-content-end me-4'>
                            <Row>
                                <Col xs={8} className="me-5">
                                    <ReactToPrint
                                        trigger={() => (
                                            <button className="export-button">
                                                <span className="export-text"><PrintIcon/>Print Report</span>
                                            </button>
                                        )}
                                        content={() => componentRef.current}
                                        documentTitle={"13th Month Payslip"}
                                    />
                                </Col>
                            </Row>
                        </Col> 
                        
                    </Row>

                </Row>
                <Row className='mt-3'>
                    <InputGroup className="search-thirteenthMonth">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Employee"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                {/* NEW FILTER DESIGN */}
                <Row className="mt-3 ms-2 filterDesign">
                    <Col sm={10}>
                        <Row className="filter-container">
                            <Col className="col-button">
                                <button /*onClick={resetFilter}*/ className='export-button-filter'>All</button>
                            </Col>
                            <Col className='margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select 
                                        value={filterData.branch_id}
                                        className="selected-filter"
                                        aria-label="branch_id"
                                        aria_describedby="branch_id"
                                        name="branch_id"
                                        id="branch_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Branch</option>
                                        {
                                            branches.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col className='margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select 
                                        value={filterData.employment_status_id}
                                        className="selected-filter"
                                        aria-label="employment_status_id"
                                        aria_describedby="employment_status_id"
                                        name="employment_status_id"
                                        id="employment_status_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Status</option>
                                        {
                                            employmentStatus.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select
                                        value={filterData.position_id}
                                        className="selected-filter"
                                        aria-label="position_id"
                                        aria_describedby="position_id"
                                        name="position_id"
                                        id="position_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Position</option>
                                        {
                                            positions.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select
                                        value={filterData.business_unit_id}
                                        className="selected-filter"
                                        aria-label="business_unit_id"
                                        aria_describedby="business_unit_id"
                                        name="business_unit_id"
                                        id="business_unit_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Business Unit</option>
                                        {
                                            businessUnits.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select 
                                        value={filterData.project_id}
                                        className="selected-filter"
                                        aria-label="project_id"
                                        aria_describedby="project_id"
                                        name="project_id"
                                        id="project_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Project</option>
                                        {
                                            projects.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select
                                        value={filterData.department_id} 
                                        className="selected-filter"
                                        aria-label="department_id"
                                        aria_describedby="department_id"
                                        name="department_id"
                                        id="department_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Department</option>
                                        {
                                            departments.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select
                                        value={filterData.year} 
                                        className="selected-filter"
                                        aria-label="year"
                                        aria_describedby="year"
                                        name="year"
                                        id="year"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Year</option>
                                        {
                                            filterYears.map((data) => (
                                                <option value={data.value}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </InputGroup>
                            </Col>           
                        </Row>
                    </Col>
                </Row>

                {/* table here */}
                <div className="thirteenth-month-table-wrapper mt-4" ref={componentRef}>
                {
                    (function() {
                        return !loading ? (<TableTemplate
                        tableType='thirteenth_month'
                        tableHeaders={["Name", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
                        tableData={filteredThirteenthMonth}
                    />) : (
                        <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                            <div className="newtons-cradle__dot"></div>
                            <div className="newtons-cradle__dot"></div>
                            <div className="newtons-cradle__dot"></div>
                            <div className="newtons-cradle__dot"></div>
                        </div>
                        )
                    })()
                }
                </div>
                
            </div>
        </div>
    );
}