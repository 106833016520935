import React,{useState} from "react";
import { Row, Col, Form, InputGroup} from "react-bootstrap";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";

// components
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt"
import AddNightDifferential from "./AddNightDifferential";
import EditNightDifferential from "./EditNightDifferential";

// icons
import SearchIcon from "@mui/icons-material/Search";

// css
import './Nightdiff.css';
import '../Modals/modal.css'
import "../../utilities/common.css"
import "../../utilities/loader_1.css"


export default function NightDifferential() {
    const userToken = getToken();
    const userId = getUser();
    const [loading, setLoading] = useState(true);
    const [nightdiffId, setNightdiffId] = useState();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [allNightDifferential, setAllNightDifferential] = useState([]);
    const [filteredNightDifferential, setFilteredNightDifferential] = useState([]);
    const [editData, setEditData] = useState({});


    function onClickAddNightDifferential() {
        setShowAddModal(true);
        
    }

    // GET BRACH BY ID FOR EDITING
    function onClickEditNightDifferential(nightdiff_id) {
        var axios = require('axios');
        var qs = require('qs');
        axios({
            method: 'get',
            url: window.$link + 'night_differentials/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                night_differential_id: nightdiff_id
            }
        }).then(response => {
            setEditData(response.data.data.night_differential[0]);
            setShowEditModal(true);
        }).catch(function (error) {
            console.log(error);
        });
    }

    // GET Nightdiff BY Nightdiff ID FOR DELETION
    function onClickDeleteNightdiff(nightdiff_id) {
        setNightdiffId(nightdiff_id);
        setShowDeleteModal(true);
    }

    // DELETING Nightdiff
    function handleRemove(e) {
        var axios = require('axios');
        var qs = require('qs');
            axios({
            url: window.$link + 'night_differentials/delete',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                night_differential_id: nightdiffId
            })
            })
            .then(function (response) {
                toast.success("Night Differential Deleted Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                toast.error("Failed to Delete Night Differential");
                console.log(error.response.data.messages);
                tokenExpired(error);
            });
    }

    // function searchFilter(e) {
    //     let filteredArr = [];
    //     let term = e.target.value.toLowerCase();
        // if(allNightDifferential) {
        //     allNightDifferential.forEach(function(nightdiff) {
        //         if((nightdiff.name).toLowerCase().includes(term)) {
        //             filteredArr.push(nightdiff);
        //         }
        //     })
        // }

        // if (term == "") {
        //     setFilteredNightDifferential(allNightDifferential);
        // } else {
        //     setFilteredNightDifferential(filteredArr);
        // }
    // }
       
    // GET ALL Nightdiff
    React.useEffect(() => {
        allNightDifferential.length = 0;

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'night_differentials/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
            }
        }).then(response => {
            setAllNightDifferential(response.data.data.night_differential);
            setFilteredNightDifferential(response.data.data.night_differential);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            setLoading(false);
        });
    },[]);

    return (
        <div className='comm-bg'>
            <Navbar/>
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-nightdiff'>
                            Night Differential
                        </Col>
                        <Col className='d-flex justify-content-end me-5'>
                            <Row>
                                <Col className="me-5">
                                    <button className="export-button-nightdiff" onClick={onClickAddNightDifferential}>
                                        <span className="export-text"> Add Night Diff</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>     
                    </Row>
                </Row>

                {/* <Row className='mt-3'>
                    <InputGroup className="search-nightdiff">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Night Differential"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row> */}

                <AddNightDifferential
                    showmodal1 = {showAddModal}
                    hidemodal1 = {() => setShowAddModal(false)}
                    showmodal1handler = {onClickAddNightDifferential}
                />

                <EditNightDifferential 
                    showmodal1 = {showEditModal}
                    hidemodal1 = {() => setShowEditModal(false)}
                    editData = {editData}
                    setEditData = {setEditData}
                />

                <DeletePrompt
                    name = "NIGHT DIFFERENTIAL"
                    show = {showDeleteModal}
                    hide = {() => setShowDeleteModal(false)}
                    remover = {handleRemove}
                />

                <div className="nightdiff-wrapper mt-4">
                {
                    (function() {
                         return !loading ? (<TableTemplate
                            tableType='night_differential'
                            tableHeaders={["Date", "Rate (%)", "Time In", "Time Out", ""]}
                            tableData={filteredNightDifferential}
                            deleteHandler={onClickDeleteNightdiff}
                            editHandler={onClickEditNightDifferential}
                        />) : (
                            <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                            </div>
                        )
                    })()
                }
                </div>
            </div>
        </div>
    );
}