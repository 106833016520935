import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import {GetAllBusinessUnits, GetAllDepartments, GetAllProjects, GetAllPositions, GetAllBranches, GetAllEmploymentStatuses} from '../../helpers/CommonApi'
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import '../Modals/modal.css'

import { validateAnnouncement } from '../../helpers/Validation/AnnouncementValidation';
import InputError from '../../helpers/InputError/InpuError';

export default function AddAnnouncement(props) {    

    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);
    const {quill, quillRef} = useQuill();
    const [data, setData] = useState({
        announcement_date: '',
        announcement_from: '',
        subject: '',
        content: '',
        details: [{
            type: '',
            announcement_to: ''
        }],
        type_prime: '',
        announcement_to_prime: '',
    });

    const [isError, setIsError] = useState({
        announcement_date: false,
        announcement_from: false,
        subject: false,
        content: false,
        type_prime: false,
        announcement_to_prime: false
    });

    const allBusinessUnits = GetAllBusinessUnits();
    const allProjects = GetAllProjects();
    const allDepartments = GetAllDepartments();
    const allBranches = GetAllBranches();
    const allPositions = GetAllPositions();
    const allEmploymentStatus = GetAllEmploymentStatuses();

    function dateFormat(inputDate, format) {
        //parse the input date
        const date = new Date(inputDate);

        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();    

        //replace the month
        format = format.replace("MM", month.toString().padStart(2,"0"));        

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2,2));
        }

        //replace the day
        format = format.replace("dd", day.toString().padStart(2,"0"));

        return format;
    }

    function handle(e, element = null) {
        let newAnnouncementData = { ...data };
        if(e.target.id == "type" || e.target.id == "announcement_to" ) {
            if(element != null) {
                let index = data["details"].map(t => t.id).indexOf(element.id);
                newAnnouncementData["details"][index][e.target.id] = e.target.value;
            }
        } else if (e.target.id == "type_prime" || e.target.id == "announcement_to_prime") {
            if (element != null) {
                let index = data["details"].map(t => t.id).indexOf(element.id);
                newAnnouncementData[e.target.id] = e.target.value;
                if (e.target.id == "type_prime") {
                    newAnnouncementData["details"][index].type = e.target.value;
                } else {
                    newAnnouncementData["details"][index].announcement_to = e.target.value;
                }
            }
        } else {
            newAnnouncementData[e.target.id] = e.target.value;
        }
        setData(newAnnouncementData);
    }

    function makeRandomID(){
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

        for(var i = 0; i < 32; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    function addRecipient(e) {
        var recipients = data.details;

        recipients.push({
            key: makeRandomID(),  
            id: makeRandomID(), 
            type: '', 
            announcement_to:''
        });

        setData((prev) => {
            return {
                ...prev,
                details: recipients
            }
        })
    }

    function removeRecipient(e, element) {
        var recipients = data.details;
        for(let i=0;i<recipients.length;i++) {
            if(recipients[i].id == element.id) {
                recipients.splice(i, 1);
                break;
            }
        }

        if (!recipients.length || recipients[0].type == '') {
            data.type_prime = '';
            data.announcement_to_prime = '';
        }

        setData((prev) => {
            return {
                ...prev,
                details: recipients
            }
        })
    }

    // Add announcement API
    function submitAddAnnouncement(e) {
        var axios = require('axios');
        var qs = require('qs');
        if (validateAnnouncement(data, setIsError)==true && data.details.length>0 && click===false) {
            setClick(true);
            axios ({
            url: window.$link + 'announcements/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                announcement_date: dateFormat(data.announcement_date, 'MM/dd/yyyy'),
                subject: data.subject,
                content: data.content,
                no_announcement_to: data.details.length,
                announcement_to: data.details.map(t => t.announcement_to),
                announcement_from: data.announcement_from,
                type: data.details.map(t => t.type),
            })
            })
            .then(function (response) {
                toast.success("Announcement Added Successfully!");
                setTimeout(() => refreshPage(), 1000);
            })
            .catch(function (error) {
                console.log(error)
                toast.error("Failed to Add Announcement");
                tokenExpired(error);
                setClick(false);
            });
        }
    }

    function renderInputError(var_name) {
        var value = '';
        if (var_name=="type") {
            data.details.map((item) => {
                value = item[var_name];
            })
        } else if (var_name=="announcement_to") {
            data.details.map((item) => {
                value = item[var_name];
            })
        } else {
            value = data[var_name];
        }
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    // Fetch the quill content
    React.useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldDelta, source) => {
                var temp_content = quillRef.current.firstChild.innerHTML;
                setData((prev) => {
                    return {
                        ...prev,
                        content: temp_content
                    }
                });
          });
        }
    }, [quill]);

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
            <Modal.Body className=''>
                    <div className="modal-header p-0">
                        Add Announcement
                    </div>
                    <button type="button" class="btn-close"  onClick={props.hidemodal1}>
                        <span class="icon-cross"></span>
                        <span class="visually-hidden">Close</span>
                    </button>
                    <div className='body-head no-scroll p-0 mt-2'>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="posting_date">Date Posted<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="date"
                                            aria-label="announcement_date" 
                                            aria-describedby="announcement_date" 
                                            placeholder="Announcement Date" 
                                            name="announcement_date" 
                                            id='announcement_date' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError("announcement_date")}
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="h6" htmlFor="posting_date">Announcement From<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="announcement_from" 
                                            aria-describedby="announcement_from" 
                                            placeholder="" 
                                            name="announcement_from" 
                                            id='announcement_from' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError("announcement_from")}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="subject">Subject <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="subject" 
                                            aria-describedby="subject" 
                                            placeholder="Subject" 
                                            name="subject" 
                                            id='subject' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError("subject")}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label className="h6" htmlFor="content">Content <span className='red'> *</span></Form.Label>
                                <div ref={quillRef} />
                                <div style={{ width: 500, height: 20}}></div>
                                {/* <Col xs={12}>
                                    <Form.Label className="h6" htmlFor="content">Content <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            aria-label="content" 
                                            aria-describedby="content" 
                                            placeholder="Enter content" 
                                            name="content" 
                                            id='content' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError("content")}
                                    </InputGroup>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col xs={2}>
                                    <div className="h6">Add Recipient</div>
                                    <button id="addRow" className="add-button" onClick={(e) => addRecipient(e)}>+ Add</button>
                                </Col>
                            </Row>
                            {data["details"].map((element, i) => {
                                var recipient_index = i;
                                if (i===0) {
                                    return (
                                        <Row key={element.key}>
                                            <Col xs={5}>
                                                <Form.Label className="h6" htmlFor="type_prime">Type <span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Select placeholder="Type" aria-describedby="type" aria-label="type" name="type_prime" id='type_prime' onChange={(e) => handle(e, element)}>
                                                    <option value=""> --- </option>
                                                    <option value="branch">Branch</option>
                                                    <option value="business_unit">Business Unit</option>
                                                    <option value="department">Department</option>
                                                    <option value="employment_status">Employment Status</option>
                                                    <option value="position">Position</option>
                                                    <option value="project">Project</option>
                                                    </Form.Select>
                                                {renderInputError('type_prime')}
                                                </InputGroup>
                                            </Col>
                                            <Col xs={5}>
                                                <Form.Label className="h6" htmlFor="announcement_to_prime">Announcement To <span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        as="select"
                                                        aria-label="announcement_to_prime" 
                                                        aria-describedby="announcement_to_prime" 
                                                        placeholder="Announcement To" 
                                                        name="announcement_to_prime" 
                                                        id='announcement_to_prime' 
                                                        onChange={(e) => handle(e, element)}
                                                    >
                                                    <option value=""> --- </option>
                                                        {
                                                            (element.type == "business_unit") ? (allBusinessUnits.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "project")?(allProjects.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "position")?(allPositions.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "employment_status")?(allEmploymentStatus.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):((element.type == "branch")?(allBranches.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):((element.type == "department")?(allDepartments.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):""))
                                                        }
                                                    </Form.Control>
                                                {renderInputError('announcement_to_prime')}
                                                </InputGroup>
                                            </Col>
                                            <Col xs={2}>
                                                <Form.Label className="h6"></Form.Label>
                                                <InputGroup className="mb-3">
                                                <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, element)}>Remove</button>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    )
                                } else {
                                    return (
                                        <Row key={element.key}>
                                            <Col xs={5}>
                                                <Form.Label className="h6" htmlFor="type">Type</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Select placeholder="Type" aria-describedby="type" aria-label="type" name="type" id='type' onChange={(e) => handle(e, element)}>
                                                    <option value=""> --- </option>
                                                    <option value="branch">Branch</option>
                                                    <option value="business_unit">Business Unit</option>
                                                    <option value="department">Department</option>
                                                    <option value="employment_status">Employment Status</option>
                                                    <option value="position">Position</option>
                                                    <option value="project">Project</option>
                                                    </Form.Select>
                                                </InputGroup>
                                            </Col>
                                            <Col xs={5}>
                                                <Form.Label className="h6" htmlFor="announcement_to">Announcement To</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        as="select"
                                                        aria-label="announcement_to" 
                                                        aria-describedby="announcement_to" 
                                                        placeholder="Announcement To" 
                                                        name="announcement_to" 
                                                        id='announcement_to' 
                                                        onChange={(e) => handle(e, element)}
                                                    >
                                                    <option value=""> --- </option>
                                                        {
                                                            (element.type == "business_unit") ? (allBusinessUnits.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "project")?(allProjects.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "position")?(allPositions.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "employment_status")?(allEmploymentStatus.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):((element.type == "branch")?(allBranches.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):((element.type == "department")?(allDepartments.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):""))
                                                        }
                                                    </Form.Control>
                                                </InputGroup>
                                            </Col>
                                            <Col xs={2}>
                                                <Form.Label className="h6"></Form.Label>
                                                <InputGroup className="mb-3">
                                                <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, element)}>Remove</button>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    )
                                }
                            }
                            )}
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitAddAnnouncement}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}