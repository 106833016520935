import React, { useState } from "react";
import { Modal, Row, Col, Form, Container, InputGroup, Table} from "react-bootstrap";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SearchIcon from "@mui/icons-material/Search";
import { GetAllLeaveTypes } from "../../helpers/CommonApi";
import moment from "moment";

export default function EmployeeLeaveCreditReport() {
    const userToken = getToken();
    const userId = getUser();
    const leaveTypes = GetAllLeaveTypes();
    const [loading, setLoading] = useState(true);
    const [headers, setHeaders] = useState([]);
    const [leaveCreditsData, setleaveCreditsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if (leaveCreditsData) {
            leaveCreditsData.forEach(function(data) {
                if(data.Employee && (data.Employee).toLowerCase().includes(term)) {
                    filteredArr.push(data);
                }
            })
        }

        if (term === "") {
            setFilteredData(leaveCreditsData);
        } else {
            setFilteredData(filteredArr);
        }
    }

    React.useEffect(() => {
        var axios = require('axios');
        var qs = require('qs');
        headers.length = 0;
        leaveCreditsData.length = 0;
       
        axios({
            url: window.$link + 'leave_credits/get',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, '')
            })
            })
            .then(function (response) {
                const res = response.data.data;
                var temp_headers = [];
                temp_headers.push("Employee");
                temp_headers.push("period_covered");
                var table_data = [];
                table_data = res.map((data, index) => {
                    var header_name = data.description +' ('+data.maximum_days+')'; // to include maximum days in header
                    temp_headers.push(header_name); // header
                    var row = [];
                    row = data.employee_leaves.map((emp) => {
                        var col = {};
                        if (emp.employee_id && emp.employee_name) {
                            col.employee_id = emp.employee_id;
                            col.Employee = emp.employee_name;
                            col.period_covered = moment(emp.validity_from).format('MMM D, YYYY') + ' - ' + moment(emp.validity_to).format('MMM D, YYYY');
                            col[header_name] = emp.remaining;
                        }
                        return col;
                    })
                    return row;
                })

                const mergedArr = [];
                table_data.map((array) => {
                    array.map((object) => {
                        mergedArr.push(object)
                    })
                })

                const mergedObject = {};
                mergedArr.forEach(obj => {
                    const id = obj.employee_id;
                    if (id in mergedObject) {
                        mergedObject[id] = {
                            ...mergedObject[id],
                            ...obj
                        };
                    } else {
                        mergedObject[id] = obj;
                    }
                });

                // COMBINE OBJECTS IN ONE ARRAY THEN SORT BY EMPLOYEE NAME
                // const final_array = Object.values(mergedObject);
                const final_array = Object.values(mergedObject).filter(obj => obj != null && Object.keys(obj).length !== 0);
                console.log(final_array);
                final_array.sort(function(a, b) {
                    var nameA = a.Employee.toUpperCase();
                    var nameB = b.Employee.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });

                setHeaders(temp_headers);
                setleaveCreditsData(final_array);
                setFilteredData(final_array);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
        
    }, []);


    return (
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-branch'>
                            Employee Leave Credits  
                        </Col>    
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-branch">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Employee"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                <div className="employee_leave_credit-wrapper mt-4">
                {
                    (function() {
                        return !loading ? (
                         <TableTemplate
                            tableType='employee_leave_credit'
                            tableHeaders={headers}
                            tableData={filteredData}
                        />
                        ) : (
                            <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                            </div>
                        )
                    })()
                }
                </div>
            </div>
        </div>
    );
}