import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import '../Modals/modal.css'

import { validateLeaveType } from '../../helpers/Validation/Manage/LeaveTypeValidation';
import InputError from '../../helpers/InputError/InpuError';

export default function AddLeaveType(props) {    

    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);
    const [data, setData] = useState({
        description: '',
        maximum_days: ''
    });

    // Validation
    const [isError, setIsError] = useState({
        description: false,
        maximum_days: false
    });

    function handle(e, element = null) {
        let newLeaveData = { ...data };
        newLeaveData[e.target.id] = e.target.value;
        setData(newLeaveData);
    }

    // Add Leave API
    function submitAddLeaveType(e) {
        var axios = require('axios');
        var qs = require('qs');
        if(validateLeaveType(data, setIsError)==true && click===false) {
            axios({
            url: window.$link + 'leave_types/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                description: data.description,
                maximum_days: data.maximum_days
            })
            })
            .then(function (response) {
                setClick(true);
                toast.success("Leave Type Added Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                toast.error("Failed to Add Leave Type");
                tokenExpired(error);
                setClick(false);
            });
        }
    }

    function renderInputError(var_name) {
        let value = data[var_name];
        if (value === '') {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body className=''>
                    <div className="modal-header p-0">
                        Add Leave Type
                    </div>
                    
                    <div className='body-head no-scroll p-0 mt-2'>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Name<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            className='input-field'
                                            type="text"
                                            aria-label="description" 
                                            aria-describedby="description" 
                                            placeholder="" 
                                            name="description" 
                                            id='description' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('description')}
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Maximum No. of Days<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            className='input-field'
                                            type="number"
                                            aria-label="maximum_days" 
                                            aria-describedby="maximum_days" 
                                            placeholder="" 
                                            name="maximum_days" 
                                            id='maximum_days' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('maximum_days')}
                                    </InputGroup>
                                    
                                </Col>
                                
                            </Row>
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitAddLeaveType}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}