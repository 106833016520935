import React, { useState } from "react";
import { getUser, getToken, tokenExpired, getRoleId } from "../../utilities/common";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import {Row, Col, Container, Table} from 'react-bootstrap'
import { CSVLink } from "react-csv";
import moment from "moment";
import Navbar from "../Navbar/Navbar"
import { GetAllRequirementsByEmployeeId } from "../../helpers/CommonApi";
import ExportExcelButton from "../../utilities/export_excel_button";
import empty_profile from '../../images/empty_profile.png'
import "./ViewEmployee.css";
import "../../utilities/common.css"
import '../Modals/modal.css'
import 'bootstrap/dist/css/bootstrap.css';

export default function ViewEmployee(props) {
    const userToken = getToken();
    const userId = getUser();
    const location = useLocation();
    const navigate = useNavigate();
    const employeeId = location.state;
    const [employeeData, setEmployeeData] = useState({})
    const [children, setChildren] = useState([]);
    const [allowance, setAllowance] = useState({});
    const [salary, setSalary] = useState({});
    const [profileImg, setProfileImg] = useState();
    const [dataArray, setDataArray] = useState([]);
    const employeeRequirements = GetAllRequirementsByEmployeeId(employeeId);
    // const [employeeRequirements, setEmployeeRequirements] = useState([]);

    function capitalizeFirstLetter (data) {
        const capitalizedString = data.charAt(0).toUpperCase() + data.slice(1);
        return capitalizedString;
    }

    function SentenceCaseName (name) {
        const sentenceCasedName = name.toLowerCase().replace(/(^|\s)[a-z]/g, (char) => char.toUpperCase());
        return sentenceCasedName
    }

    const [employmentHistory, setEmploymentHistory] = useState([]);

    function employmentHistoryTable () {
        return (
            <Table className="primary-color text-color">
                <thead>
                    <tr>
                        <th>Period</th>
                        <th>Position</th>
                        <th>Salary</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        employmentHistory.map((data) => {
                            return (
                                <tr>
                                    <td>{ 
                                        data.period ? moment(data.period).format('MMMM D, yyyy') : '' }</td>
                                    <td>{data.position?data.position:''}</td>
                                    {/* <td>{data.salary?data.salary:''}</td> */}
                                    <td>Php {data.salary ? parseFloat(data.salary).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}): ''}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        )
    }

    function requirementsTable () {
        return (
            <Table className="primary-color text-color">
                <thead>
                    <tr>
                        <th>Requirement</th>
                        <th>Attachment</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        employeeRequirements.map((data) => {
                            return (
                                <tr>
                                    <td>{data.requirement_name?data.requirement_name:''}</td>
                                    <td>
                                        <a href={`${data.file_attachment}`} download={`${data.file_name}`}>
                                            {data.file_name?data.file_name:''}
                                        </a>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        )
    }

    // GET EMPLOYEE DETAILS BY ID
    React.useEffect(() => {
        var axios = require('axios');
        var qs = require('qs');

        axios({
            method: 'post',
            url: window.$link + 'employees/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: employeeId
            })
        }).then(response => {
            const res = response.data.employees[0];
            // console.log(res);
            const allowance_details = res.allowance[0];
            const salary_details = res.salary[0];
            const children_details = res.children;
            setEmployeeData(res);
            setSalary(salary_details);
            setAllowance(allowance_details);
            setChildren(children_details);
            setDataArray(response.data.employees);
            if (res.file_name) {
                setProfileImg(res.file_name)
            } 
        }).catch(function (error) {
            console.log(error)
            tokenExpired(error);
        });

        // Get employment history
        axios({
            method: 'post',
            url: window.$link + 'employees/employment_history/'+employeeId,
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
            })
        }).then(response => {
            const res = response.data.data;
            var result = res.map((data, index) => {
                var info = [];
                info.period = data.doc_date;
                info.salary = data.value_to;
                info.position = data.position_to;

                return info;
            })
            setEmploymentHistory(result);
        }).catch(function (error) {
            console.log(error)
            tokenExpired(error);
        });

    }, []);

    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                {/* Headers */}
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-branch'>
                            Employee Profile  
                        </Col>
                        <Col className='d-flex justify-content-end me-3'>
                            <Row>
                                <Col className="me-5">
                                    <CSVLink data={dataArray} filename={`${employeeData.last_name+'_'+employeeData.first_name}`}><ExportExcelButton/></CSVLink>
                                </Col>
                            </Row>
                        </Col>     
                    </Row>
                </Row>

                <Row className='ms-3 secondary-container mt-3 text-color'>
                    <Container className='p-3 details-container'>
                        <Row className="">
                            <Col xs={2} className='profile-pic-container align-middle me-3 ms-3'>
                                <img src={profileImg ? profileImg : empty_profile} className='profile-photo'/>
                                {/* <img src={profileImg ? profileImg : empty_profile} style={{width: '100%', height: 'auto', borderRadius: '2em'}} /> */}
                            </Col>
                            <Col className='p-3'>
                                <Row>
                                    <Col xs={2} className="bold">Name</Col>
                                    <Col xs={8} className=''>: {employeeData.first_name?capitalizeFirstLetter(employeeData.first_name):''} {employeeData.nickname?'"'+capitalizeFirstLetter(employeeData.nickname)+'"':''} {employeeData.middle_name?capitalizeFirstLetter(employeeData.middle_name):''} {employeeData.last_name?capitalizeFirstLetter(employeeData.last_name):''}</Col>
                                 </Row>
                                <Row>
                                    <Col xs={2} className="bold">Religion</Col>
                                    <Col xs={8} className=''>: {employeeData.religion ? capitalizeFirstLetter(employeeData.religion) : ' '}</Col>
                                 </Row>
                                <Row>
                                    <Col xs={2} className="bold">Civil Status</Col>
                                    <Col xs={8} className=''>: {employeeData.civil_status ? capitalizeFirstLetter(employeeData.civil_status): ' '}</Col>
                                 </Row>
                                <Row>
                                    <Col xs={2} className="bold">Gender</Col>
                                    <Col xs={8} className=''>: {employeeData.gender ? capitalizeFirstLetter(employeeData.gender): ' '}</Col>
                                 </Row>
                                <Row>
                                    <Col xs={2} className="bold">Birthdate</Col>
                                    <Col xs={8} className=''>
                                        : {employeeData.birthdate ? moment(employeeData.birthdate).format('MMM D, YYYY') : ''}
                                    </Col>
                                 </Row>
                                <Row>
                                    <Col xs={2} className="bold">Nationality</Col>
                                    <Col xs={8} className=''>: {employeeData.nationality ? capitalizeFirstLetter(employeeData.nationality) : ' '}</Col>
                                 </Row>
                            </Col>
                        </Row>
                    </Container>
                </Row>

                <Row className='ms-3 secondary-container mt-3 text-color'>
                    <Container className='p-3'>
                        <Row className=''>
                            <span className="bold">Contact Details</span>
                        </Row>
                        <hr/>
                        <Row className="">
                                <Row>
                                    <Col xs={2} className="bold">Address</Col>
                                    <Col xs={9} className=''>: {employeeData.address ? SentenceCaseName(employeeData.address) : ' ' }</Col>
                                 </Row>
                                <Row>
                                    <Col xs={2} className="bold">Contact Number</Col>
                                    <Col xs={9} className=''>: {employeeData.contact_no ? employeeData.contact_no : ' '}</Col>
                                 </Row>
                                <Row>
                                    <Col xs={2} className="bold">Email Address</Col>
                                    <Col xs={9} className=''>: {employeeData.email ? employeeData.email : ' '}</Col>
                                 </Row>
                        </Row>
                    </Container>
                </Row>

                <Row className='ms-3 secondary-container mt-3 text-color'>
                    <Container className='p-3'>
                        <Row className=''>
                            <span className="bold">Family Relationship Details</span>
                        </Row>
                        <hr/>
                        <Row className="">
                            <Col className=''>
                                <Row>
                                    <Col xs={2} className="bold">Mother's Name</Col>
                                    <Col xs={4} className=''>: {employeeData.mothers_name ? SentenceCaseName(employeeData.mothers_name) : ' '}</Col>
                                    <Col xs={1} className="bold">Age</Col>
                                    <Col xs={1} className=''>: {employeeData.mothers_age ? employeeData.mothers_age : ' '}</Col>
                                    <Col xs={1} className="bold">Occupation</Col>
                                    <Col xs={2} className=''>: {employeeData.mothers_occupation ? SentenceCaseName(employeeData.mothers_occupation) : ' '}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={2} className="bold">Father's Name</Col>
                                    <Col xs={4} className=''>: {employeeData.fathers_name ? SentenceCaseName(employeeData.fathers_name) : ' '}</Col>
                                    <Col xs={1} className="bold">Age</Col>
                                    <Col xs={1} className=''>: {employeeData.fathers_age ? employeeData.fathers_age : ' '}</Col>
                                    <Col xs={1} className="bold">Occupation</Col>
                                    <Col xs={2} className=''>: {employeeData.fathers_occupation ? SentenceCaseName(employeeData.fathers_occupation) : ' '}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={2} className="bold">Spouse's Name</Col>
                                    <Col xs={4} className=''>: {employeeData.spouse_name ? SentenceCaseName(employeeData.spouse_name) : ' '}</Col>
                                    <Col xs={1} className="bold">Age</Col>
                                    <Col xs={1} className=''>: {employeeData.spouse_age ? employeeData.spouse_age : ' '}</Col>
                                    <Col xs={1} className="bold">Occupation</Col>
                                    <Col xs={2} className=''>: {employeeData.spouse_occupation ? SentenceCaseName(employeeData.spouse_occupation) : ' '}</Col>
                                 </Row>
                                <Row className='pt-3'>
                                    <span className="bold">{children ? 'Children' : null}</span>
                                </Row>
                                    {
                                        children ? (
                                            children.map((data) => (
                                                <Row>
                                                    <Col xs={2} className="bold"><span className='ms-5'>Name</span></Col>
                                                    <Col xs={4} className=''>: {data.name ? SentenceCaseName(data.name) : ' '}</Col>
                                                    <Col xs={1} className="bold">Age</Col>
                                                    <Col xs={1} className=''>: {data.age ? data.age : ' '}</Col>
                                                    <Col xs={1} className="bold">Occupation</Col>
                                                    <Col xs={2} className=''>: {data.occupation ? SentenceCaseName(data.occupation) : ' '}</Col>
                                                </Row>
                                            ))
                                        ) : (null)
                                    }
                            </Col>
                        </Row>
                    </Container>
                </Row>

                <Row className="">
                    <Col xs={6}>
                        <Row className='ms-3 secondary-container mt-3 text-color'>
                            <Container className='p-3'>
                                <Row className=''>
                                    <span className="bold">Employee Benefit Contribution Information</span>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col xs={5} className="bold">SSS Number</Col>
                                    <Col xs={5} className=''>: {employeeData.sss ? employeeData.sss : ' ' }</Col>
                                </Row>
                                <Row>
                                    <Col xs={5} className="bold">HDMF Number</Col>
                                    <Col xs={5} className=''>: {employeeData.hdmf ? employeeData.hdmf : ' '}</Col>
                                </Row>
                                <Row>
                                    <Col xs={5} className="bold">PhilHealth Number</Col>
                                    <Col xs={5} className=''>: {employeeData.phic ? employeeData.phic : ' '}</Col>
                                </Row>
                                <Row>
                                    <Col xs={5} className="bold">Intellicare Number</Col>
                                    <Col xs={5} className=''>: {employeeData.intellicare_number ? employeeData.intellicare_number : ' '}</Col>
                                </Row>
                                <Row>
                                    <Col xs={5} className="bold">AXA Policy Number</Col>
                                    <Col xs={5} className=''>: {employeeData.axa_policy_number ? employeeData.axa_policy_number : ' '}</Col>
                                </Row>
                                
                            </Container>
                        </Row>
                    </Col>
                    <Col xs={6}>
                        <Row className='secondary-container mt-3 text-color'>
                            <Container className='p-3'>
                                <Row className=''>
                                    <span className="bold">Withholding Tax Information</span>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col xs={5} className="bold">TIN Number</Col>
                                    <Col xs={5} className=''>: {employeeData.tin ? employeeData.tin : ' ' }</Col>
                                </Row>
                                <Row>
                                    <Col xs={5} className="bold">Status</Col>
                                    <Col xs={5} className=''>: {employeeData.wtaxstatus ? employeeData.wtaxstatus : ' '}</Col>
                                </Row>
                                <Row>
                                    <Col xs={5} className="bold">Dependent</Col>
                                    <Col xs={5} className=''>: {employeeData.dependent ? employeeData.dependent : ' '}</Col>
                                </Row>
                            </Container>
                        </Row>
                    </Col>
                </Row>

                <Row className='ms-3 secondary-container mt-3 mb-3 text-color'>
                    <Container className='p-3'>
                        <Row className=''>
                            <span className="bold">Person to Contact in case of Emergency</span>
                        </Row>
                        <hr/>
                        <Row className="">
                            <Col xs={6} className=''>
                                <Row>
                                    <Col xs={5} className="bold">Name</Col>
                                    <Col xs={5} className=''>: {employeeData.contact_person ? SentenceCaseName(employeeData.contact_person) : ' '}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">Relationship</Col>
                                    <Col xs={5} className=''>: {employeeData.contact_person_relationship ? capitalizeFirstLetter(employeeData.contact_person_relationship) : ' '}</Col>
                                 </Row>
                            </Col>
                            <Col xs={6} className=''>
                                 <Row>
                                    <Col xs={5} className="bold">Contact Number</Col>
                                    <Col xs={5} className=''>: {employeeData.contact_number ? employeeData.contact_number : ' '}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">Contact Address</Col>
                                    <Col xs={5} className=''>: {employeeData.contact_address ? SentenceCaseName(employeeData.contact_address) : ' '}</Col>
                                 </Row>
                            </Col>
                            
                        </Row>
                    </Container>
                </Row>

                <Row className='ms-3 secondary-container mt-3 mb-3 text-color'>
                    <Container className='p-3'>
                        <Row className=''>
                            <span className="bold">Employment Information</span>
                        </Row>
                        <hr/>
                        <Row className="">
                            <Col xs={6} className=''>
                                <Row>
                                    <Col xs={5} className="bold">DTR ID</Col>
                                    <Col className=''>: {employeeData.dtr_id ? employeeData.dtr_id : ' '}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">Branch</Col>
                                    <Col className=''>: {employeeData.branch_name ? capitalizeFirstLetter(employeeData.branch_name) : ' '}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">Position</Col>
                                    <Col className=''>: {employeeData.position_name ? capitalizeFirstLetter(employeeData.position_name) : ' '}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">Salary</Col>
                                    <Col className=''>: Php {salary ? parseFloat(salary.salary_to).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}): ''}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">Salary Type</Col>
                                    <Col className=''>: {salary.salary_type ? capitalizeFirstLetter(salary.salary_type) : ' '}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold ">Bank</Col>
                                    <Col className=''>: {employeeData.bank ? capitalizeFirstLetter(employeeData.bank) : ''}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">Bank Account Number</Col>
                                    <Col className=''>: {employeeData.bank_account_number ? capitalizeFirstLetter(employeeData.bank_account_number) : ' '}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">Bank Account Name</Col>
                                    <Col className=''>: {employeeData.bank_account_name ? capitalizeFirstLetter(employeeData.bank_account_name) : ' '}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold ">Employee Status</Col>
                                    <Col className=''>: {employeeData.employee_status_name ? capitalizeFirstLetter(employeeData.employee_status_name) : ''}</Col>
                                 </Row>
                                <Row>
                                    <Col xs={5} className="bold ">Employment Status</Col>
                                    <Col className=''>: {employeeData.employment_status ? capitalizeFirstLetter(employeeData.employment_status) : ''}</Col>
                                 </Row>
                            </Col>
                            <Col xs={6} className=''>
                                 <Row>
                                    <Col xs={5} className="bold">Business Unit</Col>
                                    <Col className=''>: {employeeData.business_unit_name ? capitalizeFirstLetter(employeeData.business_unit_name) : ' '}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">Department</Col>
                                    <Col className=''>: {employeeData.department_name ? capitalizeFirstLetter(employeeData.department_name) : ' '}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">Immediate Head</Col>
                                    <Col className=''>: {employeeData.immediate_head_name ? SentenceCaseName(employeeData.immediate_head_name) : ''}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">Current Shift</Col>
                                    <Col className=''>: {employeeData.shift_name ? capitalizeFirstLetter(employeeData.shift_name) : ''}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold ">Project</Col>
                                    <Col className=''>: {employeeData.project_name ? capitalizeFirstLetter(employeeData.project_name) : ' '}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">Date Hired</Col>
                                    <Col className=''>: {employeeData.date_hired ? moment(employeeData.date_hired).format('MMM D, YYYY') : ' '}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">End of Contract</Col>
                                    <Col className=''>: {employeeData.end_of_contract ? moment(employeeData.end_of_contract).format('MMM D, YYYY') : ' '}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">Previous Employer</Col>
                                    <Col className=''>: {employeeData.previous_employer ? SentenceCaseName(employeeData.previous_employer) : ' '}</Col>
                                 </Row>
                            </Col>
                        </Row>
                    </Container>
                </Row>

                <Row className='ms-3 secondary-container mt-3 mb-3 text-color'>
                    <Container className='p-3'>
                        <Row className=''>
                            <span className="bold">Employment History</span>
                        </Row>
                        <hr/>
                        <Row className="p-3 pt-0 pb-0">
                            {employmentHistoryTable()}
                        </Row>
                    </Container>
                </Row>

                <Row className='ms-3 secondary-container mt-3 mb-3 text-color'>
                    <Container className='p-3'>
                        <Row className=''>
                            <span className="bold">Allowance</span>
                        </Row>
                        <hr/>
                        <Row className="">
                            <Col xs={6} className=''>
                                <Row>
                                    <Col xs={6} className="bold">Daily Allowance</Col>
                                    <Col className=''>: {allowance ? 'Php ' +  parseFloat(allowance.daily_allowance).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'Php 0.00'}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={6} className="bold">Communication Allowance</Col>
                                    <Col className=''>: {allowance ? 'Php ' +  parseFloat(allowance.commu_allowance).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'Php 0.00'}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={6} className="bold">Transportation Allowance</Col>
                                    <Col className=''>: {allowance ? 'Php ' +   parseFloat(allowance.transpo_allowance).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'Php 0.00'}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={6} className="bold">Food Allowance</Col>
                                    <Col className=''>: {allowance ? 'Php ' +   parseFloat(allowance.food_allowance).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'Php 0.00'}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={6} className="bold">Grocery Allowance</Col>
                                    <Col className=''>: {allowance ? 'Php ' +   parseFloat(allowance.grocery_allowance).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'Php 0.00'}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={6} className="bold">Entertainment Allowance</Col>
                                    <Col className=''>: {allowance ? 'Php ' +   parseFloat(allowance.entertainment_allowance).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'Php 0.00'}</Col>
                                 </Row>
                            </Col>
                            <Col xs={6} className=''>
                                 <Row>
                                    <Col xs={5} className="bold">HMO Allowance</Col>
                                    <Col className=''>: {allowance ? 'Php ' +   parseFloat(allowance.hmo_allowance).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'Php 0.00'}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">Tech Allowance</Col>
                                    <Col className=''>: {allowance ? 'Php ' +   parseFloat(allowance.tech_allowance).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'Php 0.00'}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">OPS Allowance</Col>
                                    <Col className=''>: {allowance ? 'Php ' +  parseFloat(allowance.ops_allowance).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold ">Special Allowance</Col>
                                    <Col className=''>: {allowance ? 'Php ' +   parseFloat(allowance.special_allowance).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'Php 0.00'}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">Medicine Allowance</Col>
                                    <Col className=''>: {allowance ? 'Php ' +  parseFloat(allowance.medicine_allowance).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'Php 0.00'}</Col>
                                 </Row>
                                 <Row>
                                    <Col xs={5} className="bold">Uniform Allowance</Col>
                                    <Col className=''>: {allowance ? 'Php ' +   parseFloat(allowance.uniform_allowance).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'Php 0.00'}</Col>
                                 </Row>
                            </Col>
                        </Row>
                    </Container>
                </Row>

                <Row className='ms-3 secondary-container mt-3 mb-3 text-color'>
                    <Container className='p-3'>
                        <Row className=''>
                            <span className="bold">Requirements</span>
                        </Row>
                        <hr/>
                        <Row className="p-3 pt-0 pb-0">
                            {requirementsTable()}
                        </Row>
                    </Container>
                </Row>

                <div className='d-flex justify-content-end me-2 mt-2 me-5 me-5 me-3 mt-4 mb-3'>
                    <button type="" className='cancel-button me-2' onClick={() => navigate(-1)}>Back</button>
                </div>
            </div>
        </div>
    );
}

// export default ViewEmployee;