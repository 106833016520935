import React, { useState, useRef } from "react";
import { getToken, getUser, tokenExpired, refreshPage} from "../../utilities/common";
import moment from "moment";
import {Row, Col} from 'react-bootstrap'
import ReactToPrint from "react-to-print";
import {toast} from 'react-toastify'

// components
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt";

// icons
import PrintIcon from '@mui/icons-material/Print';

// css
import '../Modals/modal.css'
import './Taxwithheld.css';
import "../../utilities/common.css"
import 'react-datepicker/dist/react-datepicker.css'


function Taxwithheld() {

    //get all employees
    const userToken = getToken();
    const userId = getUser();
    const componentRef = useRef()
    // const [allTaxwithhelds, setAllTaxwithhelds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [taxwithheldId, setTaxwithheldId] = useState();
    const [printDelete, setShowDelete] = useState(false);
    const currentDate = new Date();
    const dateToday = new Date().getDate();

    const [filterDate, setFilterDate] = useState({
        date_from: dateToday <= 15 ? getFirstDayOfMonth() : get16thDayOfMonth(),
        date_to: dateToday <= 15 ? get15thDayOfMonth() : getLastDayOfMonth()
    })

    const allTaxwithhelds = [
        {
            employee_name: 'John Doe',
            tax_total_employee_contribution: 'Php 200.00',
            tax_total_amount_of_contribution: 'Php 200.00',
            tax_year: '2023',
            tax_month: 'August',
            tax_employer_share: 'Php 200.00',
            tax_employee_share: 'Php 200.00',
            tax_total_contribution: 'Php 200.00'
        },
        {
            employee_name: 'Deson',
            tax_total_employee_contribution: 'Php 200.00',
            tax_total_amount_of_contribution: 'Php 200.00',
            tax_year: '2023',
            tax_month: 'August',
            tax_employer_share: 'Php 200.00',
            tax_employee_share: 'Php 200.00',
            tax_total_contribution: 'Php 200.00'
        },
        {
            employee_name: 'May',
            tax_total_employee_contribution: 'Php 200.00',
            tax_total_amount_of_contribution: 'Php 200.00',
            tax_year: '2023',
            tax_month: 'August',
            tax_employer_share: 'Php 200.00',
            tax_employee_share: 'Php 200.00',
            tax_total_contribution: 'Php 200.00'
        },
        {
            employee_name: 'Donna',
            tax_total_employee_contribution: 'Php 200.00',
            tax_total_amount_of_contribution: 'Php 200.00',
            tax_year: '2023',
            tax_month: 'August',
            tax_employer_share: 'Php 200.00',
            tax_employee_share: 'Php 200.00',
            tax_total_contribution: 'Php 200.00'
        },
        {
            employee_name: 'Peter',
            tax_total_employee_contribution: 'Php 200.00',
            tax_total_amount_of_contribution: 'Php 200.00',
            tax_year: '2023',
            tax_month: 'August',
            tax_employer_share: 'Php 200.00',
            tax_employee_share: 'Php 200.00',
            tax_total_contribution: 'Php 200.00'
        },
        {
            employee_name: 'Rochel',
            tax_total_employee_contribution: 'Php 200.00',
            tax_total_amount_of_contribution: 'Php 200.00',
            tax_year: '2023',
            tax_month: 'August',
            tax_employer_share: 'Php 200.00',
            tax_employee_share: 'Php 200.00',
            tax_total_contribution: 'Php 200.00'
        }
    ];


    function onClickDelete(taxwithheld_id) {
        setTaxwithheldId(taxwithheld_id);
        setShowDelete(true);
    }

    function handleRemove () {
        var axios = require('axios');
        var qs = require('qs');
        axios({
            url: window.$link + 'taxwithhelds/delete/' + taxwithheldId,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, ''),
        })
        })
        .then(function (response) {
            toast.success("Taxwithheld Deleted Successfully!");
            setTimeout(() => refreshPage(), 1500);
        })
        .catch(function (error) {
            toast.error('Failed to Delete Taxwithheld');
            tokenExpired(error);
        });
    }

    function getFirstDayOfMonth(year, month) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    }

    function get15thDayOfMonth(year, month) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 15);
    }

    function get16thDayOfMonth(year, month) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 16);
    }

    function getLastDayOfMonth(year, month) {
        return new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0);
    }


    // GET ALL TaxwithheldS
    React.useEffect(() => {
        const userToken = getToken();
        const userId = getUser();
        allTaxwithhelds.length = 0;
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'taxwithhelds/get_all',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                date_from: filterDate.date_from,
                date_to: filterDate.date_to,
                taxwithheld_id: ''
            })
        }).then(response => {
            // setAllTaxwithhelds(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            tokenExpired(error);
            setLoading(false);
            // setAllTaxwithhelds([]);
        });
    },[filterDate]);
            
    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col xs={5} className='d-flex comm-header-employee'>
                            Tax Withheld
                        </Col>
                        <Col className='d-flex justify-content-end me-4'>
                            <Row>
                                <Col className="me-5">
                                    <ReactToPrint
                                        trigger={() => (
                                            <button className="export-button">
                                                <span className="export-text"><PrintIcon/> Print Tax Withheld</span>
                                            </button>
                                        )}
                                        content={() => componentRef.current}
                                        documentTitle={"Tax Withheld Contribution"}
                                    />
                                </Col>
                            </Row>
                        </Col> 
                    </Row>
                </Row>

                {/* Date Filter */}
                <Row className="px-3 mt-4">
                    <Col xs={1}>Date From</Col>
                    <Col className="ms-0" xs={2}>
                            <input
                                type="date"
                                className={'form-control'}
                                placeholderText={'dd/mm/yyyy'}
                                id='date_from'
                                name='date_from'
                                ariaDescribedBy={'date_from'}
                                ariaLabelledBy={'date_from'}
                                onChange={(e) =>
                                    setFilterDate({ ...filterDate, date_from: e.target.value })
                                }
                            />
                    </Col>
                    <Col xs={1}>Date To</Col>
                    <Col className="ms-0" xs={2}>
                            <input
                                type="date"
                                className={'form-control'}
                                placeholderText={'dd/mm/yyyy'}
                                id='date_to'
                                name='date_to'
                                ariaDescribedBy={'date_to'}
                                ariaLabelledBy={'date_to'}
                                onChange={(e) =>
                                setFilterDate({ ...filterDate, date_to: e.target.value })
                                }
                            />
                    </Col>
                </Row>

                {/* TABLE HERE */}
                <div className="taxwithheld-wrapper mt-2" ref={componentRef}>
                    <h5 className="comm-header-employee printOnly">Tax Withheld</h5>
                    
                    <TableTemplate
                                tableType='taxwithheld'
                                tableHeaders={["Employee Name", "Total Employee Contribution", "Total Amount", ""]}
                                tableData={allTaxwithhelds}
                                deleteHandler={onClickDelete}
                            />

                </div>
                        
            </div>
        </div>
    );
}

export default Taxwithheld;