import React, { useState, useRef } from "react";
import { getToken, getUser, tokenExpired, refreshPage} from "../../utilities/common";
import { useNavigate } from "react-router-dom";
import {Row, Col, Form} from 'react-bootstrap'
import ReactToPrint from "react-to-print";
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import PrintIcon from '@mui/icons-material/Print';
import UploadIcon from '@mui/icons-material/Upload';
import { GetAllMonths } from "../../helpers/CommonApi";


export default function Coop() {
    //get all employees
    const userToken = getToken();
    const userId = getUser();
    const componentRef = useRef();
    const currentDate = new Date();
    const dateToday = new Date().getDate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [coopId, setCoopId] = useState();
    const [printDelete, setShowDelete] = useState(false);
    const [allCoop, setAllCoop] = useState([]);
    const allMonths = GetAllMonths();
    const [filterMonth, setFilterMonth] = useState({
        from: '',
        to: ''
    });


    function onClickDelete(coop_id) {
        setCoopId(coop_id);
        setShowDelete(true);
    }


    // GET ALL CoopS
    React.useEffect(() => {
        const userToken = getToken();
        const userId = getUser();
        allCoop.length = 0;
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'contributions/get_coop_contribution',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                month_from: '',
                month_to: ''
            })
        }).then(response => {
            setAllCoop(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            tokenExpired(error);
            setLoading(false);
            setAllCoop([]);
        });
    },[filterMonth]);
            
    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col xs={3} className='d-flex comm-header-employee'>
                            Coop  
                        </Col>
                        <Col className="d-flex justify-content-end me-4">
                            <Row className="col-9 d-flex justify-content-end me-5">
                                <Col xs={3} className="me-5 ms-3">
                                    <ReactToPrint
                                        trigger={() => (
                                            <button className="export-button py-2 px-0">
                                                <span className="export-text"><PrintIcon/> Print</span>
                                            </button>
                                        )}
                                        content={() => componentRef.current}
                                        documentTitle={"Coop Contribution"}
                                    />
                                </Col>
                                <Col xs={3} className="ms-3">
                                    <button className="export-button py-2 px-0" onClick={() => navigate('coop_batch_entry')}>
                                        <span className="export-text"><UploadIcon/>Upload</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>

                {/* Date Filter */}
                {/* <Row className="px-3 mt-4">
                    <Col xs={1}>Month From</Col>
                    <Col className="ms-0" xs={2}>
                        <Form.Select 
                            value={filterMonth.from}
                            className=""
                            aria-label="from"
                            aria_describedby="from"
                            name="from"
                            id="from"
                            placeholder=''
                            onChange={(e) =>
                                setFilterMonth({ ...filterMonth, from: e.target.value })
                            }
                        >
                            <option value=''>From</option>
                            {
                                allMonths.map((data) => (
                                    <option value={data.value}>{data.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col xs={1}>Month To</Col>
                    <Col className="ms-0" xs={2}>
                        <Form.Select 
                            value={filterMonth.to}
                            className=""
                            aria-label="to"
                            aria_describedby="to"
                            name="to"
                            id="to"
                            placeholder=''
                            onChange={(e) =>
                                setFilterMonth({ ...filterMonth, to: e.target.value })
                            }
                        >
                            <option value=''>To</option>
                            {
                                allMonths.map((data) => (
                                    <option value={data.value}>{data.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                </Row> */}

                {/* TABLE HERE */}
                <div className="coop-table-wrapper mt-2" ref={componentRef}>
                    <h5 className="comm-header-employee printOnly">Coop</h5>
                    {
                        (function() {
                            return !loading ? (
                            <TableTemplate
                                tableType='coop'
                                tableHeaders={["Employee Name", "Total Amount", ""]}
                                tableData={allCoop}
                                deleteHandler={onClickDelete}
                            />) : (
                                <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                </div>
                            )
                        })()
                    }

                </div>
            </div>
        </div>
    );
}
