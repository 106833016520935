import {handleValidationChange} from '../Validation/CommonValidation';

export const validateSystemAccess = (data, setIsError) => {
    var isValid = true;

    if(data.role_id === "") {
        handleValidationChange("role_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("role_id", false, setIsError);
    }

    if(data.password === "") {
        handleValidationChange("password", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("password", false, setIsError);
    }

    return isValid
  
}