import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import '../Modals/modal.css'

import { validateEmployee } from '../../helpers/Validation/Employee/EmployeeValidation';
import InputError from '../../helpers/InputError/InpuError';

export default function EditEmployee(props) {    

    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);
    const data = props.employeeData;
    const [selectedBirthdate, setSelectedBirthdate] = useState();
    const [fileName, setFileName] = useState(data.file_name);
    const children_count = data.children ? data.children.length : 0;

    //REQUIRED ERROR HANDLING
    const [isError, setIsError] = useState({
        last_name: false,
        first_name: false, 
        contact_no: false, 
        address: false, 
        email: false, 
        gender: false,
        birthdate: false, 
        civil_status: false, 
        nationality: false, 
        contact_person: false,
        contact_person_relationship: false,
        contact_number: false,
        contact_address: false,
        dtr_id: false,
        branch_id: false,
        business_unit_id: false,
        employment_status_id: false,
        current_shift_id: false,
        salary_type: false,
        salary_to: false,
        department_id: false,
        position_id: false,
        date_hired: false,
        employment_status_id: false
    });

    // SAVE EDITED EMPLOYEE INFORMATION
    function submitEditEmployee(e) {
        var axios = require('axios');
        var qs = require('qs');
        if (validateEmployee(data, setIsError)==true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'employees/update/' + props.employeeId,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                // employee_id: props.employeeId,
                last_name: data.last_name,
                first_name: data.first_name,
                middle_name: data.middle_name,
                nickname: data.nickname,
                gender: data.gender,
                address: data.address,                
                contact_no: data.contact_no,
                tin: data.tin,
                sss: data.sss,
                hdmf: data.hdmf,
                phic: data.phic,
                intellicare_number: data.intellicare_number,
                axa_policy_number: data.axa_policy_number,
                email: data.email,
                birthdate: dateFormat(data.birthdate, 'MM/dd/yyyy'),
                remarks: data.remarks,
                file_name: data.file_name,
                wtaxstatus: data.wtaxstatus,
                civil_status: data.civil_status,
                nationality: data.nationality,
                dependent: data.dependent,
                religion: data.religion,
                spouse_name: data.spouse_name,
                spouse_age: data.spouse_age,
                spouse_occupation: data.spouse_occupation,
                mothers_name: data.mothers_name,
                mothers_age: data.mothers_age,
                mothers_occupation: data.mothers_occupation,
                fathers_name: data.fathers_name,
                fathers_age: data.fathers_age,
                fathers_occupation: data.fathers_occupation,
                no_of_children: data.children.length,
                children: data.children.map(t => t.name),
                age: data.children.map(t => t.age),
                occupation: data.children.map(t => t.occupation),
                contact_person: data.contact_person,
                contact_person_relationship: data.contact_person_relationship,
                contact_number: data.contact_number,
                contact_address: data.contact_address,
                dtr_id: data.dtr_id,
                branch_id: data.branch_id,
                salary_type: data.salary_type,
                employment_status_id: data.employment_status_id,
                employment_status: data.employment_status_id,
                employee_status_id: data.employee_status_id,
                business_unit_id: data.business_unit_id,
                immediate_head_id: data.immediate_head_id,
                project_id: data.project_id,
                department_id: data.department_id,
                date_hired: dateFormat(data.date_hired, 'MM/dd/yyyy'),
                end_of_contract: data.end_of_contract? dateFormat(data.end_of_contract, 'MM/dd/yyyy'): '',
                date_regularized: data.date_regularized? dateFormat(data.date_regularized, 'MM/dd/yyyy'): '',
                no_of_days: data.no_of_days,
                previous_employer: data.previous_employer,
                current_shift_id: data.current_shift_id,
                daily_allowance: data.daily_allowance,
                commu_allowance: data.commu_allowance,
                transpo_allowance: data.transpo_allowance,
                food_allowance: data.food_allowance,
                hmo_allowance: data.hmo_allowance,
                tech_allowance: data.tech_allowance,
                ops_allowance: data.ops_allowance,
                special_allowance: data.special_allowance,
                file_name: data.file_name,
                num_of_days: data.num_of_days,
                grocery_allowance: data.grocery_allowance,
                entertainment_allowance: data.entertainment_allowance,
                medicine_allowance: data.medicine_allowance,
                uniform_allowance: data.uniform_allowance,

                position_to: data.position_id,
                position: data.position_id,
                position_date: data.position_date,
                position_from: data.position_from,
                salary_to: data.salary_to,
                salary: data.salary_to,
                salary_date: data.salary_date,
                salary_from: data.salary_from,
                bank: data.bank,
                bank_account_number: data.bank_account_number,
                bank_account_name: data.bank_account_name
            })
            }).then(function (response) {
                toast.success("Employee Edited Successfully!");
                setTimeout(() => refreshPage(), 1000);
            }).catch(function (error) {
                console.log(error);
                toast.error('Failed to Update Employee');
                setClick(false);
            });
        }
    }

    function makeRandomID(){
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

        for(var i = 0; i < 32; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    function addChild(e) {
        var oldChildren = data.children;

        if(oldChildren) {
            oldChildren.push({
                id: makeRandomID(), 
                name: '', 
                age:'',
                occupation: '',
            });            
        }

        props.setEditData((prev) => {
            return {
                ...prev,
                children: oldChildren
            }
        })
    }

    function removeChild(e, element) {
        var oldChildren = data.children;
        for(let i=0;i<oldChildren.length;i++) {
            if(oldChildren[i].id == element.id) {
                oldChildren.splice(i, 1);
                break;
            }
        }

        props.setEditData((prev) => {
            return {
                ...prev,
                children: oldChildren
            }
        })
    }

    function isOnlyWhiteSpace (str) {
        return !str.trim();
    }

    function renderInputError(var_name) {
        let value = data[var_name];
        if (var_name == "birthdate") {
            value = selectedBirthdate;
        }
        if (!value || isOnlyWhiteSpace(value)===true) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    function handle(e, element = null) {
        let newData = { ...data };
        if(e.target.id == "name" || e.target.id == "age" || e.target.id == "occupation" ) {
            if(element != null) {
                let index = data["children"].map(t => t.id).indexOf(element.id);
                data["children"][index][e.target.id] = e.target.value;
                newData[e.target.id] = e.target.value;
            }
        } else if (e.target.id == "file_name") {
            const file = document.querySelector('input[type="file"]').files[0];
            getBase64(file).then(base64 => {
                newData[e.target.id] = base64;
            });
        } else {
            newData[e.target.id] = e.target.value;
        }
        props.setEditData(newData);
    }

    function dateFormat(inputDate, format) {
        //parse the input date
        const date = new Date(inputDate);

        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();    

        //replace the month
        format = format.replace("MM", month.toString().padStart(2,"0"));        

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2,2));
        }

        //replace the day
        format = format.replace("dd", day.toString().padStart(2,"0"));

        return format;
    }


    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }


    return(
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Edit Employee
                    </div>
                  
                    <div className='body-head no-scroll mt-2 p-0'>
                    <Container>
                            <Row>
                                <Col xs={12}>
                                    <div>Personal Information</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Last Name <span className='red'> *</span></Form.Label>                                    
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.last_name}
                                            type="text"
                                            aria-label="last_name" 
                                            aria-describedby="last_name" 
                                            placeholder="Last Name"
                                            name="last_name" 
                                            id='last_name' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('last_name')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">First Name <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.first_name}
                                            type="text"
                                            aria-label="first_name" 
                                            aria-describedby="first_name" 
                                            placeholder="First Name"
                                            name="first_name" 
                                            id='first_name' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('first_name')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Middle Name</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.middle_name}
                                            type="text"
                                            aria-label="middle_name" 
                                            aria-describedby="middle_name" 
                                            placeholder="Middle Name"
                                            name="middle_name" 
                                            id='middle_name' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                {/* </Row>
                                <Row> */}
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Nickname</Form.Label>                                    
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.nickname}
                                            type="text"
                                            aria-label="nickname" 
                                            aria-describedby="nickname" 
                                            placeholder="Nickname"
                                            name="nickname" 
                                            id='nickname' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Contact Number <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.contact_no}
                                            type="text"
                                            aria-label="contact_no" 
                                            aria-describedby="contact_no" 
                                            placeholder="Contact Number"
                                            name="contact_no" 
                                            id='contact_no' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('contact_no')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Address <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.address}
                                            type="text"
                                            aria-label="address" 
                                            aria-describedby="address" 
                                            placeholder="Address"
                                            name="address" 
                                            id='address' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('address')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Email Address <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.email}
                                            type="text"
                                            aria-label="email" 
                                            aria-describedby="email" 
                                            placeholder="Email Address"
                                            name="email" 
                                            id='email' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('email')}
                                    </InputGroup>
                                </Col>
                                {/* </Row>
                                <Row> */}
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Gender <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Select value={data.gender?data.gender:""} placeholder="Gender" aria-describedby="gender" aria-label="gender" name="gender" id='gender' onChange={(e) => handle(e)}>
                                          <option value="">  Select Gender </option>
                                          <option value="male">Male</option>
                                          <option value="female">Female</option>
                                        </Form.Select>
                                    {renderInputError('gender')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Birthdate <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            defaultValue={data.birthdate}
                                            type="date" 
                                            id="birthdate" 
                                            name="birthdate" 
                                            className="mb-2" 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('birthdate')}
                                    </InputGroup>
                                </Col>
                                
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Civil Status <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Select value={data.civil_status?data.civil_status:""} placeholder="Civil Status" aria-describedby="civil_status" aria-label="civil_status" name="civil_status" id='civil_status' onChange={(e) => handle(e)}>
                                          <option value=""> Select Status </option>
                                          <option value="married">Married</option>
                                          <option value="single">Single</option>
                                          <option value="widowed">Widowed</option>
                                        </Form.Select>
                                    {renderInputError('civil_status')}
                                    </InputGroup>
                                </Col>
                                {/* </Row>
                                <Row> */}
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Nationality <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.nationality}
                                            type="text"
                                            aria-label="nationality" 
                                            aria-describedby="nationality" 
                                            placeholder=""
                                            name="nationality" 
                                            id='nationality' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('nationality')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Religion</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.religion}
                                            type="text"
                                            aria-label="religion" 
                                            aria-describedby="religion" 
                                            placeholder=""
                                            name="religion" 
                                            id='religion' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="notes">Remarks</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.notes}
                                            type="text"
                                            aria-label="notes" 
                                            aria-describedby="notes" 
                                            placeholder=""
                                            name="notes" 
                                            id='notes' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                {/* </Row>
                                <Row> */}
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="notes">Profile Picture</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="file"
                                            defaultValue={data.file_name?data.file_name:''}
                                            aria-label="file_name" 
                                            aria-describedby="file_name" 
                                            placeholder="file_name"
                                            name="file_name" 
                                            id='file_name' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <hr />
                                    <div>Benefit Contribution Information</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="sss">SSS No</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.sss}
                                            type="text"
                                            aria-label="sss" 
                                            aria-describedby="sss" 
                                            placeholder="SSS No. (12 Digits w/ dash)"
                                            name="sss" 
                                            id='sss' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="hdmf">HDMF No</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.hdmf}
                                            type="text"
                                            aria-label="hdmf" 
                                            aria-describedby="hdmf" 
                                            placeholder="HDMF No. (12 Digits w/ dash)"
                                            name="hdmf" 
                                            id='hdmf' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="phic">PhilHealth No</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.phic}
                                            type="text"
                                            aria-label="phic" 
                                            aria-describedby="phic" 
                                            placeholder="PhilHealth no. (12 Digits w/ dash)"
                                            name="phic" 
                                            id='phic' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Intellicare Number</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.intellicare_number}
                                            type="text"
                                            aria-label="intellicare_number" 
                                            aria-describedby="intellicare_number" 
                                            placeholder=""
                                            name="intellicare_number" 
                                            id='intellicare_number' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="hdmf">AXA Policy Number</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.axa_policy_number}
                                            type="text"
                                            aria-label="axa_policy_number" 
                                            aria-describedby="axa_policy_number" 
                                            placeholder=""
                                            name="axa_policy_number" 
                                            id='axa_policy_number' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col xs={12}>
                                    <hr />
                                    <div>Withholding Tax Information</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="tin">TIN No</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.tin}
                                            type="text"
                                            aria-label="tin" 
                                            aria-describedby="tin" 
                                            placeholder=""
                                            name="tin" 
                                            id='tin' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="wtaxstatus">Status</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Select value={data.wtaxstatus?data.wtaxstatus:""} placeholder="Status" aria-describedby="wtaxstatus" aria-label="wtaxstatus" name="wtaxstatus" id='wtaxstatus' onChange={(e) => handle(e)}>
                                          <option value=""> Status </option>
                                          <option value="Zero Exception">Zero Exception</option>
                                          <option value="Single">Single</option>
                                          <option value="Married Employee">Married</option>
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="dependent">Dependent</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.dependent?data.dependent:""}
                                            type="text"
                                            aria-label="dependent" 
                                            aria-describedby="dependent" 
                                            placeholder=""
                                            name="dependent" 
                                            id='dependent' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <hr />
                                    <div>Family Relationship Information</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="spouse_name">Spouse Name</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.spouse_name?data.spouse_name:""}
                                            type="text"
                                            aria-label="spouse_name" 
                                            aria-describedby="spouse_name" 
                                            placeholder=""
                                            name="spouse_name" 
                                            id='spouse_name' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="spouse_age">Age</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.spouse_age?data.spouse_age:""}
                                            type="text"
                                            aria-label="spouse_age" 
                                            aria-describedby="spouse_age" 
                                            placeholder=""
                                            name="spouse_age" 
                                            id='spouse_age' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="spouse_occupation">Occupation</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.spouse_occupation?data.spouse_occupation:""}
                                            type="text" 
                                            aria-label="spouse_occupation" 
                                            aria-describedby="spouse_occupation" 
                                            placeholder=""
                                            name="spouse_occupation" 
                                            id='spouse_occupation' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="mothers_name">Mother Name</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.mothers_name?data.mothers_name:""}
                                            type="text"
                                            aria-label="mothers_name" 
                                            aria-describedby="mothers_name" 
                                            placeholder=""
                                            name="mothers_name" 
                                            id='mothers_name' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="mothers_age">Age</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.mothers_age?data.mothers_age:""}
                                            type="text"
                                            aria-label="mothers_age" 
                                            aria-describedby="mothers_age" 
                                            placeholder=""
                                            name="mothers_age" 
                                            id='mothers_age' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="mothers_occupation">Occupation</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.mothers_occupation?data.mothers_occupation:""}
                                            type="text" 
                                            aria-label="mothers_occupation" 
                                            aria-describedby="mothers_occupation" 
                                            placeholder=""
                                            name="mothers_occupation" 
                                            id='mothers_occupation' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="fathers_name">Father Name</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.fathers_name?data.fathers_name:""}
                                            type="text"
                                            aria-label="fathers_name" 
                                            aria-describedby="fathers_name" 
                                            placeholder=""
                                            name="fathers_name" 
                                            id='fathers_name' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="fathers_age">Age</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.fathers_age?data.fathers_age:""}
                                            type="text"
                                            aria-label="fathers_age" 
                                            aria-describedby="fathers_age" 
                                            placeholder=""
                                            name="fathers_age" 
                                            id='fathers_age' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="fathers_occupation">Occupation</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.fathers_occupation?data.fathers_occupation:""}
                                            type="text" 
                                            aria-label="fathers_occupation" 
                                            aria-describedby="fathers_occupation" 
                                            placeholder=""
                                            name="fathers_occupation" 
                                            id='fathers_occupation' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>

                            {/* {(data.children && data["children"].length) ? data["children"].map((element, i) => { */}
                            {(data.children && data.children.length) ? data["children"].map((element, i) => {
                                return (<Row key={element.id?element.id:""}>
                                    <Col xs={4}>
                                        <Form.Label className="h6" htmlFor="name">Child Name</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="text"                                                 
                                                value={element.name?element.name:""}
                                                aria-label="name" 
                                                aria-describedby="name" 
                                                placeholder=""
                                                name="name" 
                                                id='name' 
                                                onChange={(e) => handle(e, element)}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Label className="h6" htmlFor="age">Age</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="number"
                                                value={element.age? element.age: ""}
                                                aria-label="age" 
                                                aria-describedby="age" 
                                                placeholder=""
                                                name="age" 
                                                id="age"
                                                onChange={(e) => handle(e, element)}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Label className="h6" htmlFor="occupation">Occupation</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="text"
                                                value={element.occupation?element.occupation:""}
                                                aria-label="occupation" 
                                                aria-describedby="occupation" 
                                                placeholder=""
                                                name="occupation" 
                                                id='occupation'
                                                onChange={(e) => handle(e, element)}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Label className="h6" htmlFor="age"></Form.Label>
                                        <InputGroup className="mb-3">
                                            <button id="removeRow" className="delete-button-employee" onClick={(e) => removeChild(e, element)}>Remove</button>
                                        </InputGroup>
                                    </Col>
                                </Row>)
                            }

                            ):<p></p>}

                            <Row>
                                <Col xs={3}>
                                    <div className="h6">Number of Children</div>
                                    <div>{children_count}</div>
                                </Col>
                                <Col xs={2}>
                                    <div className="h6">Add Child</div>
                                    <button id="addRow" className="add-button" onClick={(e) => addChild(e)}>+ Add</button>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <hr />
                                    <div>Person To Contact In Case Of Emergency</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Contact Person<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.contact_person?data.contact_person:""}
                                            type="text"
                                            aria-label="contact_person" 
                                            aria-describedby="contact_person" 
                                            placeholder=""
                                            name="contact_person" 
                                            id='contact_person' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Relationship to Contact Person<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.contact_person_relationship?data.contact_person_relationship:""}
                                            type="text"
                                            aria-label="contact_person_relationship" 
                                            aria-describedby="contact_person_relationship" 
                                            placeholder=""
                                            name="contact_person_relationship" 
                                            id='contact_person_relationship' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('contact_person_relationship')}
                                    </InputGroup>
                                </Col>
                                
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="contact_number">Contact Number<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.contact_number?data.contact_number:""}
                                            type="text"
                                            aria-label="contact_number" 
                                            aria-describedby="contact_number" 
                                            placeholder=""
                                            name="contact_number" 
                                            id='contact_number' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('contact_number')}
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="h6" htmlFor="contact_person_address">Contact Address<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.contact_address?data.contact_address:""}
                                            type="text"
                                            aria-label="contact_address" 
                                            aria-describedby="contact_address" 
                                            placeholder=""
                                            name="contact_address" 
                                            id='contact_address' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('contact_address')}
                                    </InputGroup>
                                </Col>
                            </Row>
                        {/* <Row>
                            <Col xs={12}>
                                <hr />
                                <div>System Access</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Role <span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Select 
                                        value={data.role_id}
                                        placeholder="" 
                                        aria-describedby="role_id" 
                                        aria-label="role_id" 
                                        name="role_id" 
                                        id='role_id' 
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=""> Select Role </option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </Form.Select>
                                {renderInputError('role_id')}
                                </InputGroup>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Password <span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        value={data.password}
                                        type="text"
                                        aria-label="password" 
                                        aria-describedby="password" 
                                        placeholder=""
                                        name="password" 
                                        id='password' 
                                        onChange={(e) => handle(e)}
                                    />
                                    {renderInputError('password')}
                                </InputGroup>
                            </Col>  
                        </Row> */}
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitEditEmployee}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}