import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import '../Modals/modal.css'

import { validateEmployee } from "../../helpers/Validation/Employee/EmployeeValidation";
import InputError from '../../helpers/InputError/InpuError';

export default function AddEmployee(props) {    

    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);
    const [selectedStartDate, setselectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [selectedBirthdate, setSelectedBirthdate] = useState(null);
    const [selectedDateRegularized, setSelectedDateRegularized] = useState(null);
    const [fileName, setFileName] = useState(null);
    const branches = props.branches;
    const employmentStatus = props.employmentStatus;
    const positions = props.positions;
    const businessUnits = props.businessUnits;
    const allEmployees = props.allEmployees;
    const projects = props.projects;
    const departments = props.departments;
    const shifts = props.shifts;
    const employeeStatus = props.employeeStatus;
    const systemRoles = props.systemRoles;
    
    //REQUIRED ERROR HANDLING
    const [isError, setIsError] = useState({
        last_name: false,
        first_name: false, 
        contact_no: false, 
        address: false, 
        email: false, 
        gender: false,
        birthdate: false, 
        civil_status: false, 
        nationality: false, 
        contact_person: false,
        contact_person_relationship: false,
        contact_number: false,
        contact_address: false,
        // dtr_id: false,
        branch_id: false,
        business_unit_id: false,
        employment_status_id: false,
        employee_status_id: false,
        current_shift_id: false,
        salary_type: false,
        salary_to: false,
        department_id: false,
        position_id: false,
        date_hired: false,
        role_id: false,
        password: false
    });

    const [data, setData] = useState({
        requester: getUser(),
        token: getToken(),
        last_name: '',
        first_name: '',
        middle_name: '',
        nickname: '',
        contact_no: '',
        address: '',
        email: '',
        gender: '',
        birthdate: '',
        age: '',
        civil_status: '',
        nationality: '',
        religion: '',
        notes: '',
        file_name: '',
        sss: '',
        hdmf: '',
        phic: '',
        intellicare_number: '',
        axa_policy_number: '',
        tin: '',
        wtaxstatus: '',
        dependent: '',
        spouse_name: '',
        spouse_age: '',
        spouse_occupation: '',
        mothers_name: '',
        mothers_age: '',
        mothers_occupation: '',
        fathers_name: '',
        fathers_age: '',
        fathers_occupation: '',
        children: [],
        contact_person: '',
        contact_person_relationship: '',
        contact_number: '',
        contact_address: '',
        // dtr_id: '',
        branch_id: '',
        salary_type: '',
        employment_status_id: '',
        employee_status_id: '',
        business_unit_id: '',
        immediate_head_id: '',
        project_id: '',
        department_id: '',
        date_hired: '',
        end_of_contract: '',
        date_regularized: '',
        no_of_days: 0,
        previous_employer: '',
        current_shift_id: '',
        daily_allowance: '',
        commu_allowance: '',
        transpo_allowance: '',
        food_allowance: '',
        hmo_allowance: '',
        tech_allowance: '',
        ops_allowance: '',
        special_allowance: '',
        grocery_allowance :'',
        entertainment_allowance:'',
        medicine_allowance:'',
        uniform_allowance:'',

        position_id: '',
        position_date: '',
        position_from: '',
        position_to: '',

        salary_from: 0,
        salary_to: '',
        salary_date: '',
        bank: '',
        bank_account_number: '',
        role_id: '',
        password: ''
    });


    // ADD EMPLOYEE
    function submitAddEmployee(e) {
        var axios = require('axios');
        var qs = require('qs');
        if (validateEmployee(data, setIsError) === true && click===false) {
            axios({
            url: window.$link + 'employees/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                last_name: data.last_name,
                first_name: data.first_name,
                middle_name: data.middle_name,
                nickname: data.nickname,
                gender: data.gender,
                address: data.address,                
                contact_no: data.contact_no,
                tin: data.tin,
                sss: data.sss,
                hdmf: data.hdmf,
                phic: data.phic,
                intellicare_number: data.intellicare_number,
                axa_policy_number: data.axa_policy_number,
                email: data.email,
                birthdate: data.birthdate,
                remarks: data.remarks,
                file_name: data.file_name,
                wtaxstatus: data.wtaxstatus,
                civil_status: data.civil_status,
                nationality: data.nationality,
                dependent: data.dependent,
                religion: data.religion,
                spouse_name: data.spouse_name,
                spouse_age: data.spouse_age,
                spouse_occupation: data.spouse_occupation,
                mothers_name: data.mothers_name,
                mothers_age: data.mothers_age,
                mothers_occupation: data.mothers_occupation,
                fathers_name: data.fathers_name,
                fathers_age: data.fathers_age,
                fathers_occupation: data.fathers_occupation,
                no_of_children: data.children.length,
                children: data.children.map(t => t.child_name),
                age: data.children.map(t => t.child_age),
                occupation: data.children.map(t => t.occupation),
                contact_person: data.contact_person,
                contact_person_relationship: data.contact_person_relationship,
                contact_number: data.contact_number,
                contact_address: data.contact_address,
                // dtr_id: data.dtr_id,
                branch_id: data.branch_id,
                salary_type: data.salary_type,
                employment_status_id: data.employment_status_id,
                employment_status: data.employment_status_id,
                business_unit_id: data.business_unit_id,
                immediate_head_id: data.immediate_head_id,
                project_id: data.project_id,
                department_id: data.department_id,
                date_hired: data.date_hired,
                end_of_contract: selectedEndDate? dateFormat(selectedEndDate, 'MM/dd/yyyy'): '',
                previous_employer: data.previous_employer,
                current_shift_id: data.current_shift_id,
                daily_allowance: data.daily_allowance,
                commu_allowance: data.commu_allowance,
                transpo_allowance: data.transpo_allowance,
                food_allowance: data.food_allowance,
                hmo_allowance: data.hmo_allowance,
                tech_allowance: data.tech_allowance,
                ops_allowance: data.ops_allowance,
                special_allowance: data.special_allowance,
                file_name: fileName,
                no_of_days: data.no_of_days?data.no_of_days:0,
                grocery_allowance: data.grocery_allowance,
                entertainment_allowance: data.entertainment_allowance,
                medicine_allowance: data.medicine_allowance,
                uniform_allowance: data.uniform_allowance,
                position_to: data.position_id,
                salary_to: data.salary_to,
                date_regularized: data.date_regularized,
                employee_status_id: data.employment_status_id,
                bank: data.bank,
                bank_account_number: data.bank_account_number,
                bank_account_name: data.bank_account_name,
                role_id: data.role_id,
                password: data.password
            })
            })
            .then(function (response) {
                // setClick(true);
                toast.success("Employee Added Successfully!");
                setTimeout(() => refreshPage(), 1000);
            })
            .catch(function (error) {
                toast.error("Failed to Add Employee.")
                tokenExpired(error);
                setClick(false);
            });
        };
    }

    function makeRandomID(){
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

        for(var i = 0; i < 32; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    function addChild(e) {
        var oldChildren = data.children;

        oldChildren.push({
            key: makeRandomID(),  
            id: makeRandomID(), 
            child_name: '',
            child_age: '',
            child_occupation: '',
        });

        setData((prev) => {
            return {
                ...prev,
                children: oldChildren
            }
        })
    }

    function removeChild(e, element) {
        var oldChildren = data.children;
        for(let i=0;i<oldChildren.length;i++) {
            if(oldChildren[i].id == element.id) {
                oldChildren.splice(i, 1);
                break;
            }
        }

        setData((prev) => {
            return {
                ...prev,
                children: oldChildren
            }
        })
    }

    function dateFormat(inputDate, format) {
        //parse the input date
        const date = new Date(inputDate);

        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();    

        //replace the month
        format = format.replace("MM", month.toString().padStart(2,"0"));        

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2,2));
        }

        //replace the day
        format = format.replace("dd", day.toString().padStart(2,"0"));

        return format;
    }

    function handle(e, element = null) {
        let newData = { ...data };
        if(e.target.id == "child_name" || e.target.id == "child_age" || e.target.id == "child_occupation") {
            if(element != null) {
                let index = data["children"].map(t => t.id).indexOf(element.id);
                data["children"][index][e.target.id] = e.target.value;
                newData[e.target.id] = e.target.value;
            }
        } else if (e.target.id == "file_name") {
            const file = document.querySelector('input[type="file"]').files[0];
            getBase64(file).then(base64 => {
                const imgSrc = base64;
                setFileName(imgSrc);
            });
        } else {
            newData[e.target.id] = e.target.value;
        }
        setData(newData);
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    function isOnlyWhiteSpace (str) {
        return !str.trim();
    }

    function validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    function renderInputError(var_name) {
        let value = data[var_name];
        if (!value || isOnlyWhiteSpace(value)===true) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
        if(var_name === 'email'){
            if (!value || !validateEmail(value) === true) {
                return (
                    <InputError
                        isValid={isError[var_name]}
                        message={"invalid email format."} 
                    />
                    
                )
            }
        }
    }

    const handleBirthDate = (e) => {
        let newData = { ...data };
        var date = dateFormat(e.target.value, 'MM/dd/yyyy');
        newData.birthdate = date;
        setData(newData);
    };

    const handleDateHired = (e) => {
        var start_date = new Date(e.target.value);
        setselectedStartDate(start_date);
        let newData = { ...data };
        var date = dateFormat(start_date, 'MM/dd/yyyy');
        newData["date_hired"] = date;
        setData(newData);
    };

    const handleEndDate = (e) => {
        var end_date = new Date(e.target.value);
        setSelectedEndDate(end_date);
        let newData = { ...data };
        var date = dateFormat(end_date, 'MM/dd/yyyy');
        newData.end_of_contract = date;
        setData(newData);
    };

    const handleDateRegularized = (e) => {
        // setSelectedDateRegularized(e);
        let newData = { ...data };
        var date = dateFormat(e.target.value, 'MM/dd/yyyy');
        newData.date_regularized = date;
        setData(newData);
    };

    

    React.useEffect(() => {
        let newData = { ...data };
        const date1 = selectedStartDate;
        const date2 = selectedEndDate;
        if (date1 && date2) {
            var diffTime = date2.getTime() - date1.getTime();
            var diffDays = diffTime / (1000 * 3600 * 24);
            newData.no_of_days = diffDays;
        } else {
            newData.no_of_days = 0;
        }
        setData(newData);
    }, [selectedStartDate, selectedEndDate]);
                                    
    return(
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
            <Modal.Body className=''>
                    <div className="modal-header p-0">
                        Add Employee
                    </div>
                    <button type="button" class="btn-close-employee"  onClick={props.hidemodal1}>
                        <span class="icon-cross"></span>
                        <span class="visually-hidden">Close</span>
                    </button>
                    <div className='body-head no-scroll p-0 mt-2'>
                    <Container>
                            <Row>
                                <Col xs={12}>
                                    <div>Personal Information</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Last Name <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="last_name" 
                                            aria-describedby="last_name" 
                                            placeholder=""
                                            name="last_name" 
                                            id='last_name' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('last_name')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">First Name <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="first_name" 
                                            aria-describedby="first_name" 
                                            placeholder=""
                                            name="first_name" 
                                            id='first_name' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('first_name')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Middle Name</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="middle_name" 
                                            aria-describedby="middle_name" 
                                            placeholder=""
                                            name="middle_name" 
                                            id='middle_name' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                {/* </Row>
                                <Row> */}
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Nickname</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="nickname" 
                                            aria-describedby="nickname" 
                                            placeholder=""
                                            name="nickname" 
                                            id='nickname' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Contact Number <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="number"
                                            aria-label="contact_no" 
                                            aria-describedby="contact_no" 
                                            placeholder=""
                                            name="contact_no" 
                                            id='contact_no' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('contact_no')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Address <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="address" 
                                            aria-describedby="address" 
                                            placeholder=""
                                            name="address" 
                                            id='address' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('address')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Email Address <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="email" 
                                            aria-describedby="email" 
                                            placeholder=""
                                            name="email" 
                                            id='email' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('email')}
                                    </InputGroup>
                                </Col>
                                {/* </Row>
                                <Row> */}
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Gender <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Select placeholder="Gender" aria-describedby="gender" aria-label="gender" name="gender" id='gender' onChange={(e) => handle(e)}>
                                          <option value=""> Select Gender </option>
                                          <option value="male">Male</option>
                                          <option value="female">Female</option>
                                        </Form.Select>
                                    {renderInputError('gender')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Birthdate <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        
                                        <Form.Control
                                            type="date"
                                            onChange = {(e) => handleBirthDate(e)}
                                            className={'form-control birthdate'}
                                            placeholderText={'dd/mm/yyyy'}
                                            id='birthdate'
                                            name='birthdate'
                                            ariaDescribedBy={'birthdate'}
                                            ariaLabelledBy={'birthdate'}
                                        />
                                    {renderInputError('birthdate')}
                                    </InputGroup>
                                </Col>
                                
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Civil Status <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Select placeholder="" aria-describedby="civil_status" aria-label="civil_status" name="civil_status" id='civil_status' onChange={(e) => handle(e)}>
                                          <option value=""> Select Status </option>
                                          <option value="single">Single</option>
                                          <option value="married">Married</option>
                                          <option value="widowed">Widowed</option>
                                        </Form.Select>
                                        {/* <InputError
                                            isValid={isError.civil_status}
                                            message={"This field is required."}
                                        /> */}
                                    {renderInputError('civil_status')}
                                    </InputGroup>
                                </Col>
                                {/* </Row>
                                <Row> */}
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Nationality <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="nationality" 
                                            aria-describedby="nationality" 
                                            placeholder=""
                                            name="nationality" 
                                            id='nationality' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {/* <InputError
                                            isValid={isError.nationality}
                                            message={"This field is required."}
                                        /> */}
                                    {renderInputError('nationality')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Religion </Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="religion" 
                                            aria-describedby="religion" 
                                            placeholder=""
                                            name="religion" 
                                            id='religion' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="remarks">Remarks</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="remarks" 
                                            aria-describedby="remarks" 
                                            placeholder=""
                                            name="remarks" 
                                            id='remarks' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                {/* </Row>
                                <Row> */}
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="notes">Profile Picture</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="file"
                                            aria-label="file_name" 
                                            aria-describedby="file_name" 
                                            placeholder=""
                                            name="file_name" 
                                            id='file_name' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <hr />
                                    <div>Benefit Contribution Information</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">SSS No</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="sss" 
                                            aria-describedby="sss" 
                                            placeholder=""
                                            name="sss" 
                                            id='sss' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="hdmf">HDMF No</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="hdmf" 
                                            aria-describedby="hdmf" 
                                            placeholder=""
                                            name="hdmf" 
                                            id='hdmf' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="phic">PhilHealth No</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="phic" 
                                            aria-describedby="phic" 
                                            placeholder=""
                                            name="phic" 
                                            id='phic' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="name">Intellicare Number</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="intellicare_number" 
                                            aria-describedby="intellicare_number" 
                                            placeholder=""
                                            name="intellicare_number" 
                                            id='intellicare_number' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="hdmf">AXA Policy Number</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="axa_policy_number" 
                                            aria-describedby="axa_policy_number" 
                                            placeholder=""
                                            name="axa_policy_number" 
                                            id='axa_policy_number' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col xs={12}>
                                    <hr />
                                    <div>Withholding Tax Information</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="tin">TIN No</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="tin" 
                                            aria-describedby="tin" 
                                            placeholder=""
                                            name="tin" 
                                            id='tin' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="wtaxstatus">Status</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Select placeholder="" aria-describedby="wtaxstatus" aria-label="wtaxstatus" name="wtaxstatus" id='wtaxstatus' onChange={(e) => handle(e)}>
                                          <option value=""> Select Status </option>
                                          <option value="Zero Exception">Zero Exception</option>
                                          <option value="Single">Single</option>
                                          <option value="Married">Married</option>
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="dependent">Dependent</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="dependent" 
                                            aria-describedby="dependent" 
                                            placeholder=""
                                            name="dependent" 
                                            id='dependent' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <hr />
                                    <div>Family Relationship Information</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="spouse_name">Spouse Name</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="spouse_name" 
                                            aria-describedby="spouse_name" 
                                            placeholder=""
                                            name="spouse_name" 
                                            id='spouse_name' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="spouse_age">Age</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="number"
                                            aria-label="spouse_age" 
                                            aria-describedby="spouse_age" 
                                            placeholder=""
                                            name="spouse_age" 
                                            id='spouse_age' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="spouse_occupation">Occupation</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text" 
                                            aria-label="spouse_occupation" 
                                            aria-describedby="spouse_occupation" 
                                            placeholder=""
                                            name="spouse_occupation" 
                                            id='spouse_occupation' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="mothers_name">Mother Name</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="mothers_name" 
                                            aria-describedby="mothers_name" 
                                            placeholder=""
                                            name="mothers_name" 
                                            id='mothers_name' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="mothers_age">Age</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="number"
                                            aria-label="mothers_age" 
                                            aria-describedby="mothers_age" 
                                            placeholder=""
                                            name="mothers_age" 
                                            id='mothers_age' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="mothers_occupation">Occupation</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text" 
                                            aria-label="mothers_occupation" 
                                            aria-describedby="mothers_occupation" 
                                            placeholder=""
                                            name="mothers_occupation" 
                                            id='mothers_occupation' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="fathers_name">Father Name</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="fathers_name" 
                                            aria-describedby="fathers_name" 
                                            placeholder=""
                                            name="fathers_name" 
                                            id='fathers_name' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="fathers_age">Age</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="number"
                                            aria-label="fathers_age" 
                                            aria-describedby="fathers_age" 
                                            placeholder=""
                                            name="fathers_age" 
                                            id='fathers_age' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className="h6" htmlFor="fathers_occupation">Occupation</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text" 
                                            aria-label="fathers_occupation" 
                                            aria-describedby="fathers_occupation" 
                                            placeholder=""
                                            name="fathers_occupation" 
                                            id='fathers_occupation' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>

                            {data.children.map((element, i) => {
                                return (<Row key={element.key}>
                                    <Col xs={4}>
                                        <Form.Label className="h6" htmlFor="child_name">Child Name</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="text" 
                                                value={element.child_name}
                                                aria-label="child_name" 
                                                aria-describedby="child_name" 
                                                placeholder=""
                                                name="child_name" 
                                                id='child_name' 
                                                onChange={(e) => handle(e, element)}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Label className="h6" htmlFor="child_age">Age</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="number"
                                                value={element.child_age}
                                                aria-label="child_age" 
                                                aria-describedby="child_age" 
                                                placeholder=""
                                                name="child_age" 
                                                id='child_age'
                                                onChange={(e) => handle(e, element)}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Label className="h6" htmlFor="occupation">Occupation</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="text"
                                                value={element.occupation}
                                                aria-label="occupation" 
                                                aria-describedby="occupation" 
                                                placeholder=""
                                                name="occupation" 
                                                id='occupation'
                                                onChange={(e) => handle(e, element)}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Label className="h6" htmlFor="child_age"></Form.Label>
                                        <InputGroup className="mb-3">
                                            {/* <button id="removeRow" className="bg-danger text-white border-0" onClick={(e) => removeChild(e, element)}>Remove</button> */}
                                            <button id="removeRow" className="delete-button" onClick={(e) => removeChild(e, element)}>Remove</button>
                                        </InputGroup>
                                    </Col>
                                </Row>)
                            }

                            )}

                            <Row>
                                <Col xs={4}>
                                    <div className="h6">Number of Children</div>
                                    <div>{data["children"].length}</div>
                                </Col>
                                <Col xs={2}>
                                    <div className="h6">Add Child</div>
                                    {/* <button id="addRow" className="bg-primary text-white border-0" onClick={(e) => addChild(e)}>+ Add</button> */}
                                    <button id="addRow" className="add-button" onClick={(e) => addChild(e)}>+ Add</button>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <hr />
                                    <div>Person To Contact In Case Of Emergency</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Contact Person <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="contact_person" 
                                            aria-describedby="contact_person" 
                                            placeholder=""
                                            name="contact_person" 
                                            id='contact_person' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('contact_person')}
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Relationship to Contact Person<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="contact_person_relationship" 
                                            aria-describedby="contact_person_relationship" 
                                            placeholder=""
                                            name="contact_person_relationship" 
                                            id='contact_person_relationship' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('contact_person_relationship')}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Contact Number <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="number"
                                            aria-label="contact_number" 
                                            aria-describedby="contact_number" 
                                            placeholder=""
                                            name="contact_number" 
                                            id='contact_number' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('contact_number')}
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Contact Address <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="contact_address" 
                                            aria-describedby="contact_address" 
                                            placeholder=""
                                            name="contact_address" 
                                            id='contact_address' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('contact_address')}
                                    </InputGroup>
                                </Col>
                            </Row>
                             <Row>
                                <Col xs={12}>
                                    <hr />
                                    <div>Employment Information</div>
                                </Col>
                            </Row>
                            <Row>
                                {/* <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">DTR ID<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Control
                                        type="text"
                                        aria-label="dtr_id"
                                        aria_describedby="dtr_id"
                                        name="dtr_id"
                                        id="dtr_id"
                                        onChange={(e) => handle(e)}
                                    />
                                    {renderInputError('dtr_id')}
                                    </InputGroup>
                                </Col> */}
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Branch<span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        aria-label="branch_id"
                                        aria_describedby="branch_id"
                                        name="branch_id"
                                        id="branch_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Branch</option>
                                        {
                                            branches.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    {renderInputError('branch_id')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Business Unit<span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        aria-label="business_unit_id"
                                        aria_describedby="business_unit_id"
                                        name="business_unit_id"
                                        id="business_unit_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Business Unit</option>
                                        {
                                            businessUnits.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    {renderInputError('business_unit_id')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Project</Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        aria-label="project_id"
                                        aria_describedby="project_id"
                                        name="project_id"
                                        id="project_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Project</option>
                                        {
                                            projects.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    </InputGroup>
                                </Col>
                            {/* </Row>
                            <Row> */}
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Employment Status<span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        aria-label="employment_status_id"
                                        aria_describedby="employment_status_id"
                                        name="employment_status_id"
                                        id="employment_status_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Employment Status</option>
                                        {
                                            employmentStatus.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    {renderInputError('employment_status_id')}
                                    </InputGroup>
                                </Col>
                                
                                 <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Salary Type<span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        aria-label="salary_type"
                                        aria_describedby="salary_type"
                                        name="salary_type"
                                        id="salary_type"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Type</option>
                                        <option value='hourly'>Hourly</option>
                                        <option value='daily'>Daily</option>
                                        <option value='weekly'>Weekly</option>
                                        <option value='monthly5'>Monthly (5)</option>
                                        <option value='monthly6'>Monthly (6)</option>
                                    </Form.Select>
                                    {renderInputError('salary_type')}
                                    </InputGroup>
                                </Col>

                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Basic Salary<span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        aria-label="salary_to"
                                        aria_describedby="salary_to"
                                        name="salary_to"
                                        id="salary_to"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    />
                                    {renderInputError('salary_to')}
                                    </InputGroup>
                                </Col>
                            {/* </Row>
                            <Row> */}
                                
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Bank</Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Control
                                        type="text"
                                        aria-label="bank"
                                        aria_describedby="bank"
                                        name="bank"
                                        id="bank"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Bank Account Name</Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Control
                                        type="text"
                                        aria-label="bank_account_name"
                                        aria_describedby="bank_account_name"
                                        name="bank_account_name"
                                        id="bank_account_name"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Bank Account Number</Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Control
                                        type="text"
                                        aria-label="bank_account_number"
                                        aria_describedby="bank_account_number"
                                        name="bank_account_number"
                                        id="bank_account_number"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Employee Status<span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        aria-label="employee_status_id"
                                        aria_describedby="employee_status_id"
                                        name="employee_status_id"
                                        id="employee_status_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Employee Status</option>
                                        {
                                            employeeStatus.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    {renderInputError('employee_status_id')}
                                    </InputGroup>
                                </Col>
                            {/* </Row>
                            <Row> */}
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Immediate Head</Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        aria-label="immediate_head_id"
                                        aria_describedby="immediate_head_id"
                                        name="immediate_head_id"
                                        id="immediate_head_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Immediate Head</option>
                                        {
                                            allEmployees.map((data) => (
                                                <option value={data.id}>{data.first_name + ' '+ data.middle_name + ' ' + data.last_name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    {/* {renderInputError('immediate_head_id')} */}
                                    </InputGroup>
                                </Col>
                                
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Department<span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        aria-label="department_id"
                                        aria_describedby="department_id"
                                        name="department_id"
                                        id="department_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Department</option>
                                        {
                                            departments.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    {renderInputError('department_id')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Position<span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        aria-label="position_id"
                                        aria_describedby="position_id"
                                        name="position_id"
                                        id="position_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Position</option>
                                        {
                                            positions.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                        {renderInputError('position_id')}
                                    </InputGroup>
                                </Col>
                            {/* </Row>
                            <Row> */}
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Date Hired<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="date"
                                            onChange = {(e) => handleDateHired(e)}
                                            className={'form-control date_hired'}
                                            placeholderText={'dd/mm/yyyy'}
                                            id={'date_hired'}
                                            name={'date_hired'}
                                            ariaDescribedBy={'date_hired'}
                                            ariaLabelledBy={'date_hired'}
                                        />
                                    {renderInputError('date_hired')}
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">End of Contract</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="date"
                                            onChange = {(e) => {handleEndDate(e)}}
                                            className={'form-control'}
                                            placeholderText={'dd/mm/yyyy'}
                                            id={'end_of_contract'}
                                            name={'end_of_contract'}
                                            ariaDescribedBy={'end_of_contract'}
                                            ariaLabelledBy={'end_of_contract'}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">No. of Days</Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        value={data.no_of_days}
                                        aria-label="no_of_days"
                                        aria_describedby="no_of_days"
                                        name="no_of_days"
                                        id="no_of_days"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                        disabled
                                    />
                                    </InputGroup>
                                </Col>
                            {/* </Row>
                            <Row> */}
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Date Regularized</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="date"
                                            onChange = {(e) => handleDateRegularized(e)}
                                            className={'form-control date_hired'}
                                            placeholderText={'dd/mm/yyyy'}
                                            id={'date_regularized'}
                                            name={'date_regularized'}
                                            ariaDescribedBy={'date_regularized'}
                                            ariaLabelledBy={'date_regularized'}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Previous Employer</Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Control
                                        type="text"
                                        aria-label="previous_employer"
                                        aria_describedby="previous_employer"
                                        name="previous_employer"
                                        id="previous_employer"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    />
                                    </InputGroup>
                                </Col>
                                <Col xs={4}>
                                    <Form.Label className='h6' htmlFor="name">Current Shift <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        aria-label="current_shift_id"
                                        aria_describedby="current_shift_id"
                                        name="current_shift_id"
                                        id="current_shift_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Shift</option>
                                        {
                                            shifts.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    {renderInputError('current_shift_id')}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <hr />
                                    <div>Allowance</div>
                                </Col>
                            </Row>
                            <Row>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Daily Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        aria-label="daily_allowance" 
                                        aria-describedby="daily_allowance" 
                                        placeholder=""
                                        name="daily_allowance" 
                                        id='daily_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Communication Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        aria-label="commu_allowance" 
                                        aria-describedby="commu_allowance" 
                                        placeholder=""
                                        name="commu_allowance" 
                                        id='commu_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Transportation Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        aria-label="transpo_allowance" 
                                        aria-describedby="transpo_allowance" 
                                        placeholder=""
                                        name="transpo_allowance" 
                                        id='transpo_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row> 
                        <Row>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Food Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        aria-label="food_allowance"
                                        aria-describedby="food_allowance" 
                                        placeholder=""
                                        name="food_allowance" 
                                        id='food_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">HMO Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        aria-label="hmo_allowance" 
                                        aria-describedby="hmo_allowance" 
                                        placeholder=""
                                        name="hmo_allowance" 
                                        id='hmo_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Tech Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        aria-label="tech_allowance" 
                                        aria-describedby="tech_allowance" 
                                        placeholder=""
                                        name="tech_allowance" 
                                        id='tech_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">OPS Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        aria-label="ops_allowance" 
                                        aria-describedby="ops_allowance" 
                                        placeholder=""
                                        name="ops_allowance" 
                                        id='ops_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Special Allowance</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        aria-label="special_allowance" 
                                        aria-describedby="special_allowance" 
                                        placeholder=""
                                        name="special_allowance" 
                                        id='special_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>   
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Grocery</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        aria-label="grocery_allowance" 
                                        aria-describedby="grocery_allowance" 
                                        placeholder=""
                                        name="grocery_allowance" 
                                        id='grocery_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>                 
                        </Row>  
                        <Row>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Entertainment</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        aria-label="entertainment_allowance" 
                                        aria-describedby="entertainment_allowance" 
                                        placeholder=""
                                        name="entertainment_allowance" 
                                        id='entertainment_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Medicine</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        aria-label="medicine_allowance" 
                                        aria-describedby="medicine_allowance" 
                                        placeholder=""
                                        name="medicine_allowance" 
                                        id='medicine_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>   
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Uniform</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="number"
                                        aria-label="uniform_allowance" 
                                        aria-describedby="uniform_allowance" 
                                        placeholder=""
                                        name="uniform_allowance" 
                                        id='uniform_allowance' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                            </Col>                 
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <hr />
                                <div>System Access</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Role <span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Select placeholder="" aria-describedby="role_id" aria-label="role_id" name="role_id" id='role_id' onChange={(e) => handle(e)}>
                                        <option value=""> Select Role </option>
                                        {
                                            systemRoles.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                {renderInputError('role_id')}
                                </InputGroup>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="h6" htmlFor="name">Password <span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="text"
                                        aria-label="password" 
                                        aria-describedby="password" 
                                        placeholder=""
                                        name="password" 
                                        id='password' 
                                        onChange={(e) => handle(e)}
                                    />
                                    {renderInputError('password')}
                                </InputGroup>
                            </Col>  
                        </Row>
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitAddEmployee}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}