import React, { useState } from "react";
import { Modal, Row, Col, Form, Container, InputGroup} from "react-bootstrap";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";

// components
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt"
import AddEmploymentStatus from "./AddEmploymentStatus";
import EditEmploymentStatus from "./EditEmploymentStatus";
import "../../utilities/loader_1.css"

// icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from "@mui/icons-material/Search";

// css
import './EmploymentStatus.css';
import '../Modals/modal.css'
import "../../utilities/common.css"

export default function EmploymentStatus() {
    const userToken = getToken();
    const userId = getUser();
    const [loading, setLoading] = useState(true);

    // get all EmploymentStatus
    const [allEmploymentStatuses, setAllEmploymentStatuses] = useState([]);
    const [filteredEmploymentStatuses, setFilteredEmploymentStatuses] = useState([]);

    React.useEffect(() => {
        allEmploymentStatuses.length = 0;

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'employment_statuses/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employment_statuses_id: ""
            }
        }).then(response => {
            setAllEmploymentStatuses(response.data.data);
            setFilteredEmploymentStatuses(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            setLoading(false);
        });
    },[]);

    const [addEmploymentStatus, setAddEmploymentStatus] = useState(false);
    const handleAddEmploymentStatus = () => setAddEmploymentStatus(true);
    const handleCloseAddEmploymentStatus = () => setAddEmploymentStatus(false);

    const [editEmploymentStatus, setEditEmploymentStatus] = useState(false);
    const handleEditEmploymentStatus = () => setEditEmploymentStatus(true);
    const handleCloseEditEmploymentStatus = () => setEditEmploymentStatus(false);


    const [deleteEmploymentStatus, setDeleteEmploymentStatus] = useState(false);
    const handleDeleteEmploymentStatus = () => setDeleteEmploymentStatus(true);
    const handleCloseDeleteEmploymentStatus = () => setDeleteEmploymentStatus(false);


    function onClickAddEmploymentStatus() {
        handleCloseAddEmploymentStatus();
        handleAddEmploymentStatus();
    }

    const [editEmploymentStatusData, setEditEmploymentStatusData] = useState({});

    function onClickEditEmploymentStatus(employment_status_id) {
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'employment_statuses/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employment_status_id: employment_status_id
            }
        }).then(response => {
            const res = response.data.data[0];
            var info = {};
            info.name = res.name;
            info.id = res.id;
            info.requirements = res.employment_requirements?res.employment_requirements:[];
            setEditEmploymentStatusData(info);
            handleCloseEditEmploymentStatus();
            handleEditEmploymentStatus();
        }).catch(function (error) {
            console.log(error);
        });
    }

    const [employmentStatusId, setEmploymentStatusId] = useState()
    function onClickDeleteEmploymentStatus(employment_status_id) {
        setEmploymentStatusId(employment_status_id);
        handleDeleteEmploymentStatus();
    }

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allEmploymentStatuses) {
            allEmploymentStatuses.forEach(function(employment_statuses) {
                if((employment_statuses.name).toLowerCase().includes(term)) {
                    filteredArr.push(employment_statuses);
                }
            })
        }

        if (term == "") {
            setFilteredEmploymentStatuses(allEmploymentStatuses);
        } else {
            setFilteredEmploymentStatuses(filteredArr);
        }
    }

    // Delete Employment Status API
    function handleRemove(e) {
        var axios = require('axios');
        var qs = require('qs');
            axios({
            url: window.$link + 'employment_statuses/delete',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employment_status_id: employmentStatusId
            })
            })
            .then(function (response) {
                toast.success("Employment Status Deleted Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                toast.error("Failed to Delete Employment Status")
                tokenExpired(error);
            });
    }

    return (
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-status'>
                            Employment Status   
                        </Col>
                        <Col className='d-flex justify-content-end me-5'>
                            <Row>
                                <Col className="me-5">
                                    <button className="export-button-status p-2" onClick={onClickAddEmploymentStatus}>
                                        <span className="export-text"><AccountCircleIcon/> Add Employment Status</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>     
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-status">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Employment Status"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                <AddEmploymentStatus
                    showmodal1 = {addEmploymentStatus}
                    hidemodal1 = {handleCloseAddEmploymentStatus}
                    showmodal1handler = {onClickAddEmploymentStatus}
                />

                <EditEmploymentStatus
                    showmodal1 = {editEmploymentStatus}
                    hidemodal1 = {handleCloseEditEmploymentStatus}
                    showmodal1handler = {onClickEditEmploymentStatus}
                    editEmploymentStatusData = {editEmploymentStatusData}
                    setEditEmploymentStatusData = {setEditEmploymentStatusData}
                />

                <DeletePrompt
                    name = "EMPLOYMENT STATUS"
                    show = {deleteEmploymentStatus}
                    hide = {handleCloseDeleteEmploymentStatus}
                    remover = {handleRemove}
                />

                <div className="employment-status-wrapper mt-4">
                {
                    (function() {
                         return !loading ? (<TableTemplate
                            tableType='employmentstatus'
                            tableHeaders={["Employment Status Name", ""]}
                            tableData={filteredEmploymentStatuses}
                            deleteHandler={onClickDeleteEmploymentStatus}
                            editHandler={onClickEditEmploymentStatus}
                        />) : (
                            <div style={{marginTop:"15%"}} className="newtons-cradle EmploymentStatus-relative start-50">
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                            </div>
                        )
                    })()
                }
                </div>
            </div>
        </div>
    );
}