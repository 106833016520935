import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import '../Modals/modal.css'

import { GetAllEmployees} from '../../helpers/CommonApi';
import { validateLoan } from '../../helpers/Validation/LoanValidation';
import InputError from '../../helpers/InputError/InpuError'
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

export default function ApplyLoan(props) {    
    
    // REACT QUILL
    const { quill, quillRef } = useQuill();

    const userToken = getToken();
    const userId = getUser();

    // Get all employees
    const allEmployees = GetAllEmployees();

    const [click, setClick] = useState(false);
    const [data, setData] = useState({
        employee_id: '',
        issue_date: '',
        loan_amount: '',
        term: '',
        interest_rate: '',
        loan_type: '',
        reason: '',
    });

    const [isError, setIsError] = useState({
        employee_id: false,
        issue_date: false,
        loan_amount: false,
        term: false,
        interest_rate: false,
        loan_type: false,
        reason: false,
    });

    const loanTypes = [
        {value: 'sss_salary_loan', name: 'SSS Salary Loan'},
        {value: 'sss_calamity_loan', name: 'SSS Calamity Loan'},
        {value: 'cooperative_loan', name: 'Cooperative Loan'},
        {value: 'laptop_loan', name: 'Laptop Loan'},
        {value: 'phone_loan', name: 'Phone Loan'},
        {value: 'company_loan', name: 'Company Loan'},
        {value: 'pag_ibig_loan', name: 'PAGIBIG Loan'},
        {value: 'cash_advance', name: 'Cash Advance'},
    ];
    

    function handle(e, element = null) {
        let newLoanData = { ...data };
        newLoanData[e.target.id] = e.target.value;
        setData(newLoanData);
    }

    // APPLY LOAN API
    function submitApplyLoan(e) {
        var axios = require('axios');
        var qs = require('qs');
        if(validateLoan(data, setIsError)===true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'employee_loans/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: data.employee_id,
                issue_date: data.issue_date,
                loan_amount: data.loan_amount,
                term: data.term,
                interest_rate: data.interest_rate,
                loan_type: data.loan_type,
                reason: data.reason,
            })
            })
            .then(function (response) {
                toast.success("Loan Added Successfully!");
                setTimeout(() => refreshPage(), 1000);
            })
            .catch(function (error) {
                console.log(error)
                toast.error("Failed to Apply Loan");
                tokenExpired(error);
                setClick(false);
            });
        }
    }   
    
    function renderInputError(var_name) {
        let value = data[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    // FETCH THE CONTENT OF QUILL
    React.useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldDelta, source) => {
            var temp_content = quillRef.current.firstChild.innerHTML;
            data.content = temp_content;
          });
          }
      }, [quill]);

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Apply Loan
                    </div>
                    <button type="button" class="btn-close"  onClick={props.hidemodal1}>
                        <span class="icon-cross"></span>
                        <span class="visually-hidden">Close</span>
                    </button>
                    <div className='body-head'>
                        <Container>
                            <Row>
                                <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="employee_name">Employee<span className='red'> *</span></Form.Label>
                                    <InputGroup>
                                        <Form.Select name="employee_id" id='employee_id' className="mb-2" onChange={(e) => handle(e)}>
                                                <option>Select Employee</option>
                                                {allEmployees.map((data) => {
                                                    return (
                                                        <option value={data.id}>{data.name}</option>
                                                    )
                                                })}
                                        </Form.Select>
                                    {renderInputError('employee_id')}
                                    </InputGroup>
                                </Col>
                                <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="type">Issue Date<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="date"
                                            aria-label="issue_date" 
                                            aria-describedby="issue_date" 
                                            placeholder="dd/mm/yyyy" 
                                            name="issue_date" 
                                            id='issue_date' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('issue_date')}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="type">Loan Type<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">

                                         <Form.Select name="loan_type" id='loan_type' className="mb-2" onChange={(e) => handle(e)}>
                                            <option>Select Type</option>
                                            {loanTypes.map((data) => {
                                                return (
                                                    <option value={data.value}>{data.name}</option>
                                                )
                                            })}
                                    </Form.Select>
                                        {renderInputError('type')}
                                    </InputGroup>
                                </Col>
                                <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="loan_amount">Amount<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="number"
                                            aria-label="loan_amount" 
                                            aria-describedby="loan_amount" 
                                            placeholder="" 
                                            name="loan_amount" 
                                            id='loan_amount' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('loan_amount')}
                                    </InputGroup>
                                </Col>
                            </Row>
                             <Row>
                                <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="type">Term (Days)<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="number"
                                            aria-label="term" 
                                            aria-describedby="term" 
                                            placeholder="" 
                                            name="term" 
                                            id='term' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('term')}
                                    </InputGroup>
                                </Col>
                                <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="type">Interest Rate (%)<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="number"
                                            aria-label="interest_rate" 
                                            aria-describedby="interest_rate" 
                                            placeholder="" 
                                            name="interest_rate" 
                                            id='interest_rate' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('interest_rate')}
                                    </InputGroup>
                                </Col>
                                
                            </Row>
                            <Row>
                                <Form.Label className="h6" htmlFor="type">Reason<span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        as="textarea"
                                        aria-label="reason" 
                                        aria-describedby="reason" 
                                        placeholder="" 
                                        name="reason" 
                                        id='reason' 
                                        onChange={(e) => handle(e)}
                                    />
                                    {renderInputError('reason')}
                                </InputGroup>
                            </Row>
                            
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitApplyLoan}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}