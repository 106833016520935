import React, { useState } from "react";
import { getToken, getUser, tokenExpired, refreshPage } from "../../utilities/common";
import {GetAllEmployees} from "../../helpers/CommonApi"
import { Modal, Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import '../Modals/modal.css'

// components
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt";
import ApprovePrompt from "../Modals/ApprovePrompt";
import AddLeave from "./AddLeave";
import { validateLeave } from "../../helpers/Validation/LeaveValidation";
import InputError from "../../helpers/InputError/InpuError";

// icons
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined';
import SearchIcon from '@mui/icons-material/Search';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


// css
import './Leave.css';

function Leave() {
    const userToken = getToken();
    const userId = getUser();
    const allEmployees = GetAllEmployees();
    const [showApprove, setShowApprove]=useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = () => setShowDelete(true);
    const [leaveID, setLeaveID] = useState();
    const [loading, setLoading] = useState(true);
    const [showAddLeave, setShowAddLeave] = useState(false);
    const handleCloseAddLeave = () => {setShowAddLeave(false); refreshPage()};
    const handleShowAddLeave = () => setShowAddLeave(true);
    const [showEdit, setShowEdit] = useState(false);
    const handleShowEdit = () => setShowEdit(true);
    const [leaveEdit, setLeaveEdit] = useState('');
    const [leaves, setLeaves] = useState([]);
    const [filteredLeaves, setFilteredLeaves] = useState([]);
    const [leavetype, setLeaveType] = useState([]);
    const [files, setFiles] = useState([]);
    const [click, setClick] = useState(false);
    const {quill, quillRef} = useQuill();

    function onClickDelete(id) {
        handleShowDelete();
        setLeaveID(id);
    }

    // API for delete leave
    function handleRemove() {
        var axios = require('axios');
        var qs = require('qs');
        axios({
        url: window.$link + 'employee_leaves/delete',
        method: 'post',
        headers: {
            "api-key": window.$api_key,
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, ''),
            employee_leave_id: leaveID
        })
        })
        .then(function (response) {
            toast.success("Leave Deleted Successfully!");
            setTimeout(refreshPage(), 1000);
        })
        .catch(function (error) {
            console.log(error)
            toast.error('Error Deleting Leave');
            tokenExpired(error);
        });
    }
    
    function onClickAddLeave() {
        handleShowAddLeave();
    }

    const [editedData, setEditedData] = useState({
        id: '',
        status: '',
        date_from: '',
        date_to: '',
        no_of_days: '',
        employee_id: '',
        leave_type: '',
        remarks: '',
        files: []
        });

    // GET LEAVE DETAILS BY EMPLOYEE LEAVE ID
    function onClickEdit(id) {
        setLeaveEdit(id);        
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'employee_leaves/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_leave_id: id
            })
        }).then(response => {
            const leave = response.data.employees[0];
            var info = {}
            info.files = leave.leave_files?leave.leave_files:[];
            info.id = leave.id;
            info.leave_credit_id = leave.leave_credit_id;
            info.status = leave.status;
            info.admin_remarks = leave.admin_remarks;
            info.date_from = leave.date_from;
            info.date_to = leave.date_to;
            info.no_of_days = leave.no_of_days;
            info.employee_id = leave.employee_id;
            info.leave_type = leave.leave_id;
            info.remarks = leave.remarks;
            setFiles(leave.files);
            setEditedData(info);
            handleShowEdit();
        }).catch(function (error) {
            console.log(error);
        });
    }

    const [isError, setIsError] = useState({
        date_from: false,
        date_to: false,
        // no_of_days: false,
        leave_type: false,
        remarks: false,
    });


    function isOnlyWhiteSpace (str) {
        return !str.trim();
    }

    function renderInputError(var_name) {
        let value = editedData[var_name];
        if (!value || isOnlyWhiteSpace(value)===true) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    // API CALL TO SUBMIT EDITED LEAVE
    function submitEditLeave(e) {
        e.preventDefault();
        var axios = require('axios');
        var qs = require('qs');
        if (validateLeave(editedData, setIsError)===true && click === false) {
            axios({
            url: window.$link + 'employee_leaves/update',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_leave_id: leaveEdit,
                employee_id: editedData.employee_id,
                leave_id: editedData.leave_type,
                leave_type: editedData.leave_type,
                date_from: editedData.date_from,
                date_to: editedData.date_to,
                no_of_days: editedData.no_of_days,
                remarks: editedData.remarks,
                admin_remarks: editedData.admin_remarks,
                status: editedData.status,
                leave_credit_id: editedData.leave_credit_id,
                file_names: editedData.files.map((file) => {return(file?file:null)})
                // file_names: editedData.file_names
            })
            })
            .then(function (response) {
                setClick(true);
                toast.success("Leave Updated Successfully!");
                setTimeout(() => refreshPage(), 1000);
            })
            .catch(function (error) {
                toast.error('Updating Error!');
                tokenExpired(error);
                setClick(false);
            });
        }
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    function removeFile (e, index) {
        var currentConvertedFiles = editedData.files;
        // var currentFileNames = data.fileNames;
        for (let i=0; i<currentConvertedFiles.length; i++) {
            if (i === index) {
                currentConvertedFiles.splice(i, 1);
                break;
            }
        }

        setEditedData((prev) => {
            return {
                ...prev,
             files: currentConvertedFiles,
            }
        });
    }

    function handle(e) {
        let newLeave = { ...editedData };
        if (e.target.id === "file_name") {
            var files_temp = e.target.files;
            Array.from(files_temp).forEach(file => {

                var newFile = {}
                newFile.file_name = file.name;
                getBase64(file).then(base64 => {
                    newFile.file_attachment = base64;
                })
                newLeave["files"].push(newFile);
            });
        } else {
            newLeave[e.target.id] = e.target.value;
        }
        setEditedData(newLeave);
    }

    // Search for employee
    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(leaves) {
            leaves.forEach(function(employee) {
                if((employee.employee_name).toLowerCase().includes(term)) {
                    filteredArr.push(employee);
                }
            })
        }
        if(term == "") {
            setFilteredLeaves(leaves);
        } else {
            setFilteredLeaves(filteredArr);
        }
    }

    function onClickApprove (leave_id) {
        setLeaveID(leave_id);
        setShowApprove(true);
    }

    function handleApprove () {
        var axios = require('axios');
        var qs = require('qs');
        axios({
        url: window.$link + 'employee_leaves/approve',
        method: 'post',
        headers: {
            "api-key": window.$api_key,
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, ''),
            employee_leave_id: leaveID,
           status: "approved"
        })
        })
        .then(function (response) {
            toast.success("Leave Approved Successfully!");
            setTimeout(refreshPage(), 1000);
        })
        .catch(function (error) {
            toast.error(error.response.data.messages.error);
            tokenExpired(error);
        });
    }

    // Get all leaves and leave types
    React.useEffect(() => {
        var axios = require('axios');
        var qs = require('qs');
        setLeaves([]);
        setFilteredLeaves([]);
        
        // Get All Leaves
        leaves.length = 0;
        filteredLeaves.length = 0;
        axios({
            method: 'post',
            url: window.$link + 'employee_leaves/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_leave_id: '',
                offset: 0,
                limit: 5
            })
        }).then(response => {
            const res = response.data.employees;
            setLeaves(res);
            setFilteredLeaves(res);
            setLoading(false);
        }).catch(function (error) {
            console.log(error)
            tokenExpired(error);
            setLoading(false);
        });

        // Get all leave_types
        leavetype.length = 0;
        axios({
            method: 'post',
            url: window.$link + 'leave_types/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                leave_type_id: ''
            })
        }).then(response => {
            const res = response.data.data;
            var types = res.map((data) => {
                var info = [];
                info.name = data.description;
                info.id = data.id;
                return info;
            })
            setLeaveType(types);
            
        }).catch(function (error) {
            console.log(error);
            tokenExpired(error);
            setLoading(false);
        });
    },[]);


    React.useEffect(() => {
        if (quill) {
            quill.clipboard.dangerouslyPasteHTML(editedData.remarks?editedData.remarks:'');
            quill.on('text-change', () => {
                var temp_content = quillRef.current.firstChild.innerHTML;
                editedData.remarks = temp_content;
            })
        }
    }, [quill]);


    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    height: "3em",
                    }}>
                        <Row className="m-0">
                    <Col className='d-flex comm-header-employee'>
                        Leave   
                    </Col>
                    <Col className='d-flex justify-content-end me-3'>
                        <Row>
                            
                            <Col className="me-5">
                                <button className="export-button" onClick={onClickAddLeave}>
                                    <span className="export-text"><MeetingRoomOutlinedIcon/> Add Leave</span>
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <AddLeave show={showAddLeave} hide={handleCloseAddLeave} user='admin' leavetypes={leavetype} />
            </Row>

            <Row className='mt-3'>
                    <InputGroup className="search-employee">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Employee"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                {/* TABLE HERE */}
                <div className="leave-wrapper mt-4">
                    {
                        (function() {
                            return !loading ? (
                            <TableTemplate
                                tableType='leave'
                                tableHeaders={["Employee Name","Date Applied", "Date From", "Date To", "Days", "Leave Type", "Status", ""]}
                                tableData={filteredLeaves}
                                editHandler={onClickEdit}
                                deleteHandler={onClickDelete}
                                approveHandler={onClickApprove}
                            />) : (
                                <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                </div>
                            )
                        })()
                    }

                </div>
                    <DeletePrompt
                        name = "LEAVE"
                        show = {showDelete}
                        hide = {handleCloseDelete}
                        remover = {handleRemove}
                    />

                    <ApprovePrompt
                        name = "LEAVE"
                        show = {showApprove}
                        hide = {() => setShowApprove(false)}
                        approve = {handleApprove}
                    />

                    {/* Edit Leave */}
                    <Modal show={showEdit} onHide={() => {setShowEdit(false); refreshPage()}} centered>
                        <Form>
                            <Modal.Body>
                                <div className="modal-header p-0">
                                    Edit Leave Application Form
                                </div>
                                <button type="button" class="btn-close"  onClick={() => {setShowEdit(false); refreshPage()}}>
                                    <span class="icon-cross"></span>
                                    <span class="visually-hidden">Close</span>
                                </button>
                                <div className='body-head'>
                                    <Container>
                                        <Row>
                                            <Col xs={6}>
                                                <Form.Label className="h6" htmlFor="name">Employee Name<span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Select name="employee_id" id="employee_id" className="mb-2" value={editedData.employee_id} disabled>
                                                        <option>Select Employee</option>
                                                        {allEmployees.map((data) => {
                                                            return (
                                                                <option value={data.id}>{data.name}</option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                    {renderInputError("employee_id")}
                                                </InputGroup>
                                            </Col>
                                            
                                            <Col xs={6}>
                                                <Form.Label className="h6" htmlFor="name">Leave Type<span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Select name="leave_type" id="leave_type" className="mb-2" defaultValue={editedData.leave_type} onChange={(e) => handle(e)}>
                                                        <option value="">Select Leave Type</option>
                                                        {leavetype.map((data) => {
                                                            return (
                                                                <option value={data.id}>{data.name}</option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                    {renderInputError("leave_type")}
                                                </InputGroup>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Label className="h6" htmlFor="name">Date From<span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control type="date" id="date_from" name="date_from" className="mb-2" defaultValue={editedData.date_from} onChange={(e) => handle(e)}/>
                                                    {renderInputError("date_from")}
                                                </InputGroup>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Label className="h6" htmlFor="name">Date To<span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control type="date" id="date_to" name="date_to" className="mb-2" defaultValue={editedData.date_to} onChange={(e) => handle(e)}/>
                                                    {renderInputError("date_to")}
                                                </InputGroup>
                                            </Col>
                                            {/* <Col>
                                                <Form.Label className="h6" htmlFor="name">Number of Days<span className='red'> *</span></Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control type="text" id="no_of_days" name="no_of_days" className="mb-2" defaultValue={editedData.no_of_days} onChange={(e) => handle(e)}/>
                                                        {renderInputError("no_of_days")}
                                                    </InputGroup>
                                            </Col> */}
                                        </Row>

                                        <Row>
                                            <Form.Label className="h6" htmlFor="name">Reason<span className='red'> *</span></Form.Label>
                                            <div ref={quillRef} />
                                            <div style={{ width: 500, height: 20}}></div>
                                        </Row>

                                        <Row>
                                            <Col xs={6}>
                                                <Form.Label className="h6" htmlFor="notes">Attach File</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        type="file"
                                                        multiple
                                                        aria-label="file_name" 
                                                        aria-describedby="file_name" 
                                                        placeholder="file_name"
                                                        name="file_name" 
                                                        id='file_name' 
                                                        onChange={(e) => handle(e)}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        {
                                            (editedData["files"]) ? (
                                                editedData["files"].map((fileName, index) => {
                                                        return (
                                                            <Row>
                                                                <Col xs={10} class="input-group p-0">
                                                                    <Row className="question-bg text-color mt-2 ms-0 p-0">
                                                                        <Col xs={1} class="">
                                                                            <FolderOpenIcon/>
                                                                        </Col>
                                                                        <Col xs={9} className=''>
                                                                            <span className="mt-1 fileName">{fileName.file_name?fileName.file_name:''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <button id="removeRow" className="trash-icon mt-1" onClick={(e) => removeFile(e, index)}><DeleteOutlineIcon/></button>
                                                                </Col>
                                                            </Row>
                                                        )
                                                })
                                            ) : (
                                                <p></p>
                                            )
                                        }
                                    </Container>
                                </div>
                                <div className='d-flex justify-content-end me-2 mt-4'>
                                    <button type="" className='cancel-button me-2' onClick={() => setShowEdit(false)}>Cancel</button>
                                    <button type="submit" className='save-button' onClick={submitEditLeave}>Save</button>
                                </div>
                            </Modal.Body>
                        </Form>
                    </Modal>
            </div>
        </div>
    );
}

export default Leave;