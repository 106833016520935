import React from 'react'
import { useRef, useState } from 'react';
import {Row, Col, Container} from 'react-bootstrap'
import ReactToPrint from 'react-to-print';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar'
import { getToken, getUser, tokenExpired } from '../../utilities/common';
import PrintIcon from '@mui/icons-material/Print';
import NewLogo from "../../images/NewLogo.png";
import './Philhealth.css';

export default function ViewPhilhealth () {
    const componentRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const date_today = new Date();
    const philhealthId = location.state;
    const [philhealthDetails, setPhilhealthDetails] = useState({});
    const [philhealthContributions, setPhilhealthContributions] = useState([]);

    function SentenceCaseName (name) {
        const sentenceCasedName = name.toLowerCase().replace(/(^|\s)[a-z]/g, (char) => char.toUpperCase());
        return sentenceCasedName
    }

    function monthNameFromInt(monthInt) {
        const date = new Date();
        date.setMonth(monthInt - 1); // months are zero-indexed in JS, so subtract 1 from monthInt
        return date.toLocaleString('default', { month: 'long' });
    }


    // GET Philhealth BY ID
    React.useEffect(() => {
        const userToken = getToken();
        const userId = getUser();

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'contributions/get_philhealth_contribution',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: philhealthId
            })
        }).then(response => {
            setPhilhealthContributions(response.data.data[0].philhealth_contributions);
            setPhilhealthDetails(response.data.data[0]);
        }).catch(function (error) {
            tokenExpired(error);
        });
    },[]);


    return (
        <div className='comm-page'>
            <Navbar/>
            <div className='comm-page-container no-margin-top'>
                <div className='print-page-container me-3 mt-0 payslip' ref={componentRef}>

                <Row className='payslip-header d-flex justify-content-end'>PHILHEALTH CONTRIBUTION</Row>
                <Row className='d-flex justify-content-end'>Date Printed: {date_today.toLocaleDateString('en-GB', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                })}
                </Row>
                <Row className='p-1 ms-2'><img src = {NewLogo} alt={"logo"} className="payslip-logo ms-0"></img></Row>
                <Row className='bold p-1'>{window.$company_name}</Row>
                <Row className='p-1'>{window.$company_address}</Row>
                <Row className='p-1 mt-3'>
                    <table className='table table-bordered'>
                        <thead>
                            <tr className='payslip-blue-bg'>
                                <th colSpan={2}>Contribution Information</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='bold w-50'>Employee Name</td>
                                <td>{philhealthDetails.full_name ? SentenceCaseName(philhealthDetails.full_name) : ''}</td>
                            </tr>
                            <tr>
                                <td className='bold w-50'>Philhealth No.</td>
                                <td>{philhealthDetails.philhealth_no ? SentenceCaseName(philhealthDetails.philhealth_no) : ''}</td>
                            </tr>
                            <tr>
                                <td className='bold w-50'>Total No. of Months</td>
                                <td>{philhealthDetails.philhealth_contributions?philhealthDetails.philhealth_contributions.length:''}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                
                <Row className='mt-5'>
                <Row className='title-philhealth bold py-2'>CONTRIBUTION SUMMARY</Row>
                    <table className='table table-bordered'>
                        <thead>
                            <tr className='payslip-blue-bg'>
                                <th>Total Employee Share</th>
                                <th>Total Employer Share</th>
                                <th>Total Amount of Contribution</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='content-philhealth'>Php {philhealthDetails.total_employee_contribution?parseFloat(philhealthDetails.total_employee_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}):''}</td>
                                <td className='content-philhealth'>Php {philhealthDetails.total_employer_contribution?parseFloat(philhealthDetails.total_employer_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}):''}</td>
                                <td className='content-philhealth'>Php {philhealthDetails.total_amount_contributed?parseFloat(philhealthDetails.total_amount_contributed).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}):''}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>

                <Row className='mt-3'>
                <Row className='title-philhealth py-2 bold'>CONTRIBUTION HISTORY</Row>
                    <table className='table table-bordered cont-history-wrapper'>
                        <thead>
                            <tr className='payslip-blue-bg align-right'>
                                <th>Year</th>
                                <th>Month</th>
                                <th>Employee Share</th>
                                <th>Employer Share</th>
                                <th>Total Contribution</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (philhealthContributions && philhealthContributions.length) ? (
                                    philhealthContributions.map((data) => {
                                    return (
                                        <tr>
                                            <td className=''>{data.year}</td>
                                            <td className=''>{data.month}</td>
                                            <td>Php {parseFloat(data.employee_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                            <td>Php {parseFloat(data.employer_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                            <td>Php {parseFloat(data.total_contribution).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                                        </tr>
                                    )
                                    })
                                ) : ('')
                            }
                        </tbody>
                    </table>
                </Row>

                <Row className="d-flex justify-content-end me-3 mt-4 mt-5">
                    <Col className="print d-flex justify-content-end">
                        <ReactToPrint
                            trigger={() => (
                                <button className="save-button me-2 noprint">
                                    <span><PrintIcon/> Print</span>
                                </button>
                            )}
                            content={() => componentRef.current}
                            documentTitle={"PhilHealth"}
                        />  
                    
                        <button
                            className="save-button blue noprint exclude"
                            onClick={() => navigate(-1)}
                        >
                            <span className="export-text mx-auto">Back</span>
                        </button>
                    </Col>
                </Row>
            </div>
            </div>
        </div>
    )
}