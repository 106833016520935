import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import {getToken, getUser} from './utilities/common.js';

//GLOBAL VARIABLES
window.$company_name = "WAD AND COMPANIES";
window.$company_address = "103 del Pilar St., Tacloban City";
window.$link = "https://hr-api.wadandco.com/";
window.$api_key = "Y5QubbhTOb";
window.$userToken = getToken();
window.$userId = getUser();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
