import React, { useState } from "react";
import { Modal, Row, Col, Form, Container, InputGroup, Table} from "react-bootstrap";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import SearchIcon from "@mui/icons-material/Search";
import '../Modals/modal.css';
import "../../utilities/common.css";
import "../../utilities/loader_1.css";
import "./EmployeeCreditsReport.css";

export default function EmployeeWfaCreditReport() {
    const userToken = getToken();
    const userId = getUser();
    const [loading, setLoading] = useState(true);
    const [headers, setHeaders] = useState([]);
    const [wfaCreditsData, setWfaCreditsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if (wfaCreditsData) {
            wfaCreditsData.forEach(function(data) {
                if(data.employee_name!==null && (data.employee_name).toLowerCase().includes(term)) {
                    filteredArr.push(data);
                }
            })
        }

        if (term === "") {
            setFilteredData(wfaCreditsData);
        } else {
            setFilteredData(filteredArr);
        }
    }

    React.useEffect(() => {
        var axios = require('axios');
        var qs = require('qs');
        headers.length = 0;
        wfaCreditsData.length = 0;
       
        axios({
            url: window.$link + 'wfa_requests/get_wfa_credits',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, '')
            })
            })
            .then(function (response) {
                const res = response.data.data;
                setWfaCreditsData(res);
                setFilteredData(res);
                setLoading(false);
            })
            .catch(function (error) {
                tokenExpired(error);
                setLoading(false);
            });
        
    }, []);


    return (
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-branch'>
                            Employee WFA Credits  
                        </Col>    
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-branch">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Employee"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                <div className="wfa-report-wrapper mt-4">
                {
                    (function() {
                        return !loading ? (
                         <TableTemplate
                            tableType='employee_wfa_credit'
                            tableHeaders={["Employee Name", "Period Covered", "WFA Credits"]}
                            tableData={filteredData}
                        />
                        ) : (
                            <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                            </div>
                        )
                    })()
                }
                </div>
            </div>
        </div>
    );
}