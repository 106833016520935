import React, { useState } from "react";
import { Row, Col, Form, Container, InputGroup} from "react-bootstrap";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";
import moment from "moment";

// components
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt"
import ApprovePrompt from "../Modals/ApprovePrompt";
import AddMemo from "./AddMemo";
import EditMemo from "./EditMemo";
import { GetAllBranches, GetAllDepartments, GetAllEmploymentStatuses, GetAllPositions, GetAllShifts, GetAllProjects, GetAllBusinessUnits } from "../../helpers/CommonApi";
import InputError from '../../helpers/InputError/InpuError';

// icons
import SearchIcon from "@mui/icons-material/Search";
import NoteAltIcon from '@mui/icons-material/NoteAlt';

// css
import './Memo.css';
import '../Modals/modal.css'
import "../../utilities/common.css"
import "../../utilities/loader_1.css"

import { validateMemo } from "../../helpers/Validation/MemoValidation";


export default function Memo() {
    const userToken = getToken();
    const userId = getUser();
    const [loading, setLoading] = useState(true);
    const [allFilter, setAllFilter] = useState(true);
    const [memoId, setMemoId] = useState();

    // get data
    const [allMemos, setAllMemos] = useState([]);
    const [filteredMemos, setFilteredMemos] = useState([]);
    const [click, setClick] = useState(false);

    const employmentStatus = GetAllEmploymentStatuses();
    const branches = GetAllBranches();
    const departments = GetAllDepartments();
    const projects = GetAllProjects();
    const positions = GetAllPositions();
    const businessUnits = GetAllBusinessUnits();

    const [addMemo, setAddMemo] = useState(false);
    const handleAddMemo = () => setAddMemo(true);
    const handleCloseAddMemo = () => {setAddMemo(false); refreshPage()};
    const [editMemo, setEditMemo] = useState(false);
    const handleEditMemo = () => setEditMemo(true);
    const handleCloseEditMemo = () => {setEditMemo(false); refreshPage()};
    const [showApprove, setShowApprove] = useState(false);
    const [deleteMemo, setDeleteMemo] = useState(false);
    const handleDeleteMemo = () => setDeleteMemo(true);

    const [filters, setFilters] = useState({});
    const [filterData, setFilterData] = useState({});

    function handleFilters(e) {
        setAllFilter(false)
        const newFilterData = { ...filterData };
        newFilterData[e.target.id] = e.target.value;
        setFilterData(newFilterData);
    }

    function onClickAddMemo() {
        handleAddMemo();
    }

    const [editMemoData, setEditMemoData] = useState({
        memo_date: '',
        memo_from: '',
        subject: '',
        content: '',
        type_prime: '',   
        memo_to_id_prime: ''
    });

    const [isError, setIsError] = useState({
        memo_date: false,
        memo_from: false,
        subject: false,
        content: false,
        type_prime: false,
        memo_to_id_prime: false
    });

    function onClickEditMemo(memo_id) {
        var axios = require('axios');
        var qs = require('qs');

        editMemoData.length = 0;
        axios({
            method: 'get',
            url: window.$link + 'memos/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                memo_id: memo_id
            }
        }).then(response => {
            setEditMemoData(response.data.data.memos[0]);
            handleEditMemo();
        }).catch(function (error) {
            console.log(error);
        });
    }


    // Update Memo API
    function submitEditMemo(e) {
        var axios = require('axios');
        var qs = require('qs');

        if (validateMemo(editMemoData, setIsError)==true && editMemoData.details.length>0 && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'memos/update/'+editMemoData.id,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                memo_date: editMemoData.memo_date,
                subject: editMemoData.subject,
                content: editMemoData.content,
                no_memo_to: editMemoData.details? editMemoData.details.length:0,
                memo_to: editMemoData.details?editMemoData.details.map(t => t.memo_to_id):[],
                memo_from: editMemoData.memo_from,
                type: editMemoData.details?editMemoData.details.map(t => t.type):[]
            })
            })
            .then(function (response) {
                toast.success("Memo Edited Successfully!");
                setTimeout(() => refreshPage(), 1500);
            })
            .catch(function (error) {
                toast.error("Failed to Update Memo");
                tokenExpired(error);
                setClick(false);
            });
        }
    }

    function onClickApprove (memo_id) {
        setMemoId(memo_id);
        setShowApprove(true);
    }

    function handleApprove () {
        var axios = require('axios');
        var qs = require('qs');
        axios({
        url: window.$link + 'memos/approve',
        method: 'post',
        headers: {
            "api-key": window.$api_key,
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, ''),
            status: "approved",
            memo_id: memoId
        })
        })
        .then(function (response) {
            toast.success("Memo Approved Successfully!");
            setTimeout(() => refreshPage(), 500);
        })
        .catch(function (error) {
            console.log(error.response.data.messages);
            tokenExpired(error);
        });
    }

    function onClickDeleteMemo(memo_id) {
        setMemoId(memo_id);
        handleDeleteMemo();
    }

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allMemos) {
            allMemos.forEach(function(memo) {
                if((memo.subject).toLowerCase().includes(term)) {
                    filteredArr.push(memo);
                }
            })
        }

        if (term == "") {
            setFilteredMemos(allMemos);
        } else {
            setFilteredMemos(filteredArr);
        }
    }

    function handleRemove() {
        var axios = require('axios');
        var qs = require('qs');
        axios({
        url: window.$link + 'memos/delete',
        method: 'post',
        headers: {
            "api-key": window.$api_key,
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, ''),
            memo_id: memoId
        })
        })
        .then(function (response) {
            toast.success("Memo Deleted Successfully!");
            setTimeout(() => refreshPage(), 500);
        })
        .catch(function (error) {
            console.log(error.response.data.messages);
            tokenExpired(error);
        });
    }

    function renderInputError(var_name) {
        var value = '';
        if (var_name=="type") {
            editMemoData.details.map((item) => {
                value = item[var_name];
            })
        } else if (var_name=="memo_to") {
            editMemoData.details.map((item) => {
                value = item[var_name];
            })
        } else {
            value = editMemoData[var_name];
        }
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    function resetFilter(e) {
        const filter = [{
            branch_id: '',
            employment_status_id: '',
            position_id: '',
            business_unit_id: '',
            project_id: '',
            department_id: ''
        }]
        setFilterData(filter);
    }

     // Get all memos
    React.useEffect(() => {
        allMemos.length = 0;

        var axios = require('axios');
        var qs = require('qs');

        axios({
            method: 'get',
            url: window.$link + 'memos/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                memo_id: "",
                branch_id: filterData.branch_id,
                employment_status_id: filterData.employment_status_id,
                position_id: filterData.position_id,
                business_unit_id: filterData.business_unit_id,
                project_id: filterData.project_id,
                department_id: filterData.department_id
            }
        }).then(response => {
            const res = response.data.data.memos;
            setAllMemos(response.data.data.memos);
            setFilteredMemos(response.data.data.memos);
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
        });
    }, [filterData]);

    return (
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-memo'>
                            Memorandum   
                        </Col>
                        <Col className='d-flex justify-content-end me-5'>
                            <Row>
                                <Col className="me-5">
                                    <button className="export-button-memo" onClick={onClickAddMemo}>
                                        <span className="export-text"><NoteAltIcon/> Create Memo</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-memo">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Memo"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                {/* NEW FILTER DESIGN */}
                <Row className="mt-3 ms-2">
                    <Col sm={10}>
                        <Row className="filter-container">
                            {/* <Col xs={0} className={allFilter ? "all active-all" : "all"}> */}
                            <Col xs={0}>
                                <button onClick={resetFilter} className='export-button-filter'>All</button>
                            </Col>
                            <Col xs={2} className='margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select 
                                        value={filterData.branch_id}
                                        className="selected-filter"
                                        aria-label="branch_id"
                                        aria_describedby="branch_id"
                                        name="branch_id"
                                        id="branch_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Branch</option>
                                        {
                                            branches.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col xs={2} className='margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select 
                                        value={filterData.employment_status_id}
                                        className="selected-filter"
                                        aria-label="employment_status_id"
                                        aria_describedby="employment_status_id"
                                        name="employment_status_id"
                                        id="employment_status_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Status</option>
                                        {
                                            employmentStatus.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col xs={2} className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select
                                        value={filterData.position_id}
                                        className="selected-filter"
                                        aria-label="position_id"
                                        aria_describedby="position_id"
                                        name="position_id"
                                        id="position_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Position</option>
                                        {
                                            positions.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col xs={2} className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select
                                        value={filterData.business_unit_id}
                                        className="selected-filter"
                                        aria-label="business_unit_id"
                                        aria_describedby="business_unit_id"
                                        name="business_unit_id"
                                        id="business_unit_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Business Unit</option>
                                        {
                                            businessUnits.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col xs={2} className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select 
                                        value={filterData.project_id}
                                        className="selected-filter"
                                        aria-label="project_id"
                                        aria_describedby="project_id"
                                        name="project_id"
                                        id="project_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Project</option>
                                        {
                                            projects.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>|
                                </InputGroup>
                            </Col>
                            <Col xs={2} className='me-0 margin-less'>
                                <InputGroup className="filter-dropdown ">
                                    <Form.Select
                                        defaultValue={filterData.department_id} 
                                        className="selected-filter"
                                        aria-label="department_id"
                                        aria_describedby="department_id"
                                        name="department_id"
                                        id="department_id"
                                        placeholder=''
                                        onChange={(e) => handleFilters(e)}
                                    >
                                        <option value=''>Department</option>
                                        {
                                            departments.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col xs={1}>
                        <button className="export-btn-filter" onClick={submitFilter}><FilterAltIcon/> Filter</button>
                    </Col> */}
                </Row>

                {/* HERE IS WHAT USED TO BE THE HIGHLIGTED MEMO */}
                {/* <Container className="message-container justify-content-center p-4">
                    <Row className="">
                        <Col xs={6} className="message-header"><EventNoteIcon/>{highlight ? highlight.subject.toUpperCase() : '---' }</Col>
                        <Col xs={6}>
                            <Row className="d-flex justify-content-end">
                                <Col xs={6} className="date-container me-1 text-center">
                                    {highlight ? moment(highlight.memo_date).format('MMM D, YYYY') : '---' }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='p-3 justify-content-center'>
                        {highlight ? (highlight.content).toUpperCase() : 'No upcoming memorandum.'}
                    </Row>
                </Container> */}

                <AddMemo 
                    showmodal1 = {addMemo}
                    hidemodal1 = {handleCloseAddMemo}
                    showmodal1handler = {onClickAddMemo}
                />

                <EditMemo 
                    showmodal1 = {editMemo}
                    hidemodal1 = {handleCloseEditMemo}
                    showmodal1handler = {onClickEditMemo}
                    editMemoData = {editMemoData}
                    setEditMemoData = {setEditMemoData}
                    submitEditMemo = {submitEditMemo}
                    allBusinessUnits = {businessUnits}
                    allProjects = {projects}
                    allDepartments = {departments}
                    allPositions = {positions}
                    allEmploymentStatus = {employmentStatus}
                    allBranches = {branches}
                    isError = {isError}
                    renderInputError = {renderInputError}
                />

                <DeletePrompt
                    name = "MEMO"
                    show = {deleteMemo}
                    hide = {() => setDeleteMemo(false)}
                    remover = {handleRemove}
                />

                <ApprovePrompt
                    name = "MEMO"
                    show = {showApprove}
                    hide = {() => setShowApprove(false)}
                    approve = {handleApprove}
                />
               
                <div className="memo-wrapper mt-3">
                {
                    (function() {
                         return !loading ? (
                         <TableTemplate
                            tableType='memo'
                            tableHeaders={["DATE POSTED", "SUBJECT", "MEMO FROM", "MEMO TO", "STATUS", ""]}
                            tableData={filteredMemos}
                            deleteHandler={onClickDeleteMemo}
                            editHandler={onClickEditMemo}
                            approveHandler={onClickApprove}
                        />) : (
                            <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                            </div>
                        )
                    })()
                }
                </div>
            </div>
        </div>
    );
}