import React, { useState } from "react";
import { getToken, getUser, getRoleId, tokenExpired, refreshPage } from "../../utilities/common";
import {GetAllEmployees} from "../../helpers/CommonApi"
import { Modal, Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";

// components
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt";
import ApprovePrompt from "../Modals/ApprovePrompt";
import AddWorkFromAnywhere from "./AddWorkFromAnywhere";
import { validateWorkFromAnywhere } from "../../helpers/Validation/WorkFromAnywhereValidation";
import InputError from "../../helpers/InputError/InpuError";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import '../Modals/modal.css'
// icons
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined';
import SearchIcon from '@mui/icons-material/Search';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// css
import './WorkFromAnywhere.css'

export default function WorkFromAnywhere () {
    const userToken = getToken();
    const userId = getUser();
    const userRoleId = getRoleId();
    const allEmployees = GetAllEmployees()
    const [showDelete, setShowDelete] = useState(false);
    const [workFromAnywhereId, setWorkFromAnywhereId] = useState();
    const [loading, setLoading] = useState(true);
    const [showAddLeave, setShowAddLeave] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showApprove, setShowApprove] = useState(false);
    const [workFromAnywhere, setWorkFromAnywhere] = useState([]);
    const [filteredWfa, setFilteredWfa] = useState([]);
    const [files, setFiles] = useState([]);
    const [click, setClick] = useState(false);
    const {quill, quillRef} = useQuill();

    function onClickDelete(id) {
        setShowDelete(true);
        setWorkFromAnywhereId(id);
    }

    // API for delete leave
    function handleRemove() {
        var axios = require('axios');
        var qs = require('qs');
        axios({
        url: window.$link + 'wfa_requests/delete/'+workFromAnywhereId,
        method: 'post',
        headers: {
            "api-key": window.$api_key,
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, ''),
        })
        })
        .then(function (response) {
            toast.success("WFA Deleted Successfully!");
            setTimeout(refreshPage(), 1000);
        })
        .catch(function (error) {
            toast.error('Error Deleting WFA');
            tokenExpired(error);
        });
    }
    
    function onClickAddLeave() {
        setShowAddLeave(true);
    }

    const [editedData, setEditedData] = useState({
        id: '',
        date_from: '',
        date_to: '',
        employee_id: '',
        // no_of_days: '',
        files: [],
        remarks: '',
        status: ''
    });
    const [isError, setIsError] = useState({
        employee_id: false,
        date_from: false,
        date_to: false,
        // no_of_days: false,
        remarks: false,
    });

    function renderInputError(var_name) {
        let value = editedData[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    // GET LEAVE DETAILS BY EMPLOYEE LEAVE ID
    function onClickEdit(wfa_id) {
        setWorkFromAnywhereId(wfa_id);        
        var axios = require('axios');
        var qs = require('qs');
        axios({
            method: 'post',
            url: window.$link + 'wfa_requests/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                wfa_request_id: wfa_id
            })
        }).then(response => {
            const wfa_details = response.data.data[0];
            var info = {}
            info.id = wfa_details.id;
            info.date_from = wfa_details.date_from;
            info.date_to = wfa_details.date_to;
            info.employee_id = wfa_details.employee_id;
            info.remarks = wfa_details.remarks;
            info.admin_remarks = wfa_details.admin_remarks;
            info.files = wfa_details.files?wfa_details.files:[];
            setEditedData(info);
            setShowEdit(true);
        }).catch(function (error) {
            console.log(error);
        });

    }

    // API CALL TO SUBMIT EDITED LEAVE
    function submitEditWorkFromAnywhere(e) {
        e.preventDefault();
        var axios = require('axios');
        var qs = require('qs');
        if (validateWorkFromAnywhere(editedData, setIsError)===true && click === false) {
            setClick(true);
            axios({
            url: window.$link + 'wfa_requests/update/'+editedData.id,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: editedData.employee_id,
                date_from: editedData.date_from,
                date_to: editedData.date_to,
                // no_of_days: editedData.no_of_days,
                remarks: editedData.remarks,
                admin_remarks: editedData.admin_remarks,
                file_names: editedData.files.map((file) => {return(file?file.file_name:'')})
            })
            })
            .then(function (response) {
                toast.success("WFA Updated Successfully!");
                setTimeout(() => refreshPage(), 1000);
            })
            .catch(function (error) {
                console.log(error)
                toast.error('Updating Error!');
                tokenExpired(error);
                setClick(false);
            });
        }
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    function handle(e) {
        let newLeave = { ...editedData };
        if (e.target.id === "file_name") {
            var files_temp = e.target.files;
            Array.from(files_temp).forEach(file => {
                getBase64(file).then(base64 => {
                    var info = {}
                    info.file_name = base64;
                    newLeave["files"].push(info);
                    // setFiles((prevFileArray) => [...prevFileArray, base64]);
                });
            });
        }  else {
            newLeave[e.target.id] = e.target.value;
        }

        setEditedData(newLeave);
    }

    function removeFile (e, index) {
        var currentConvertedFiles = editedData.files;
        // var currentFileNames = data.fileNames;
        for (let i=0; i<currentConvertedFiles.length; i++) {
            if (i === index) {
                currentConvertedFiles.splice(i, 1);
                break;
            }
        }

        setEditedData((prev) => {
            return {
                ...prev,
             files: currentConvertedFiles,
            }
        });
    }

    // Search for employee
    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(workFromAnywhere) {
            workFromAnywhere.forEach(function(employee) {
                if((employee.employee_name).toLowerCase().includes(term)) {
                    filteredArr.push(employee);
                }
            })
        }
        if(term == "") {
            setFilteredWfa(workFromAnywhere);
        } else {
            setFilteredWfa(filteredArr);
        }
    }

    function onClickApprove (wfa_id) {
        setWorkFromAnywhereId(wfa_id);
        setShowApprove(true);
    }

    function handleApprove () {
        var axios = require('axios');
        var qs = require('qs');
        axios({
        url: window.$link + 'wfa_requests/approve/'+workFromAnywhereId,
        method: 'post',
        headers: {
            "api-key": window.$api_key,
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, ''),
            status: "approved"
        })
        })
        .then(function (response) {
            toast.success("WFA Approved Successfully!");
            setTimeout(refreshPage(), 500);
        })
        .catch(function (error) {
            toast.error(error.response.data.messages.error);
            tokenExpired(error);
        });
    }

    // Get all workFromAnywhere
    React.useEffect(() => {
        var axios = require('axios');
        var qs = require('qs');
        setFilteredWfa([]);
        
        workFromAnywhere.length = 0;
        filteredWfa.length = 0;
        axios({
            method: 'post',
            url: window.$link + 'wfa_requests/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_leave_id: ''
            })
        }).then(response => {
            const res = response.data.data;
            setWorkFromAnywhere(res);
            setFilteredWfa(res);
            setLoading(false);
        }).catch(function (error) {
            console.log(error)
            // tokenExpired(error);
            setLoading(false);
        });
    },[]);

     React.useEffect(() => {
        if (quill) {
            quill.clipboard.dangerouslyPasteHTML(editedData.remarks?editedData.remarks:'');
            quill.on('text-change', () => {
                var temp_content = quillRef.current.firstChild.innerHTML;
                editedData.remarks = temp_content;
            })
        }
    }, [quill]);

    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    height: "3em",
                    }}>
                        <Row className="m-0">
                    <Col xs={8} className='d-flex comm-header-employee'>
                        WORK FROM ANYWHERE   
                    </Col>
                    <Col className='d-flex justify-content-end me-3'>
                        <Row>    
                            <Col className="me-5">
                                <button className="export-button" onClick={onClickAddLeave}>
                                    <span className="export-text"><MeetingRoomOutlinedIcon/> Add WFA</span>
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <AddWorkFromAnywhere 
                    show={showAddLeave} 
                    hide={() => {setShowAddLeave(false); refreshPage()}} 
                    user='admin'
                />
            </Row>

            <Row className='mt-3'>
                    <InputGroup className="search-employee">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Employee"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                {/* TABLE HERE */}
                <div className="wfa-wrapper mt-4">
                    {
                        (function() {
                            return !loading ? (
                            <TableTemplate
                                tableType='wfa'
                                tableHeaders={["Employee Name", "Date Applied", "Date From", "Date To", "Days", "Status", ""]}
                                tableData={filteredWfa}
                                editHandler={onClickEdit}
                                deleteHandler={onClickDelete}
                                approveHandler={onClickApprove}
                            />) : (
                                <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                </div>
                            )
                        })()
                    }

                </div>
                    <DeletePrompt
                        name = "WFA"
                        show = {showDelete}
                        hide = {() => setShowDelete(false)}
                        remover = {handleRemove}
                    />

                    <ApprovePrompt
                        name = "WORK FROM ANYWHERE"
                        show = {showApprove}
                        hide = {() => setShowApprove(false)}
                        approve = {handleApprove}
                    />

                    {/* Edit WFA */}
                    <Modal show={showEdit} onHide={() => {setShowEdit(false); refreshPage(); }} centered>
                        <Form>
                            <Modal.Body>
                                <div className="modal-header p-0">
                                    Edit WFA Application Form
                                </div>
                                <button type="button" class="btn-close"  onClick={() => {setShowEdit(false); refreshPage();}}>
                                    <span class="icon-cross"></span>
                                    <span class="visually-hidden">Close</span>
                                </button>
                                <div className='body-head'>
                                    <Container>
                                        <Row>
                                            <Col xs={12}>
                                                <Form.Label className="h6" htmlFor="name">Employee Name<span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Select name="employee_id" id="employee_id" className="mb-2" value={editedData.employee_id} disabled>
                                                        <option>Select Employee</option>
                                                        {allEmployees.map((data) => {
                                                            return (
                                                                <option value={data.id}>{data.name}</option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                </InputGroup>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Label className="h6" htmlFor="name">Date From<span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control type="date" id="date_from" name="date_from" className="mb-2" defaultValue={editedData.date_from} onChange={(e) => handle(e)}/>
                                                     {renderInputError("date_from")}
                                                </InputGroup>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Label className="h6" htmlFor="name">Date To<span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control type="date" id="date_to" name="date_to" className="mb-2" defaultValue={editedData.date_to} onChange={(e) => handle(e)}/>
                                                     {renderInputError("date_to")}
                                                </InputGroup>
                                            </Col>
        
                                            {/* <Col xs={6}>
                                                <Form.Label className="h6" htmlFor="name">Number of Days<span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control type="number" id="no_of_days" name="no_of_days" className="mb-2" defaultValue={editedData.no_of_days} onChange={(e) => handle(e)}/>
                                                        {renderInputError("no_of_days")}
                                                </InputGroup>
                                            </Col> */}
                                        </Row>

                                        <Row>
                                            <Form.Label className="h6" htmlFor="name">Reason<span className='red'> *</span></Form.Label>
                                            <div ref={quillRef} />
                                            <div style={{ width: 500, height: 20}}></div>
                                        </Row>

                                        <Row>
                                            <Col xs={6}>
                                                <Form.Label className="h6" htmlFor="notes">Attach File</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        type="file"
                                                        multiple
                                                        aria-label="file_name" 
                                                        aria-describedby="file_name" 
                                                        placeholder="file_name"
                                                        name="file_name" 
                                                        id='file_name' 
                                                        onChange={(e) => handle(e)}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        {
                                            (editedData["files"]) ? (
                                                editedData["files"].map((fileName, index) => {
                                                        return (
                                                            <Row>
                                                                <Col xs={10} class="input-group p-0">
                                                                    <Row className="question-bg text-color mt-2 ms-0 p-0">
                                                                        <Col xs={1} class="">
                                                                            <FolderOpenIcon/>
                                                                        </Col>
                                                                        <Col xs={9} className=''>
                                                                            <span className="mt-1 fileName">{fileName.file_name?fileName.file_name:''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <button id="removeRow" className="trash-icon mt-1" onClick={(e) => removeFile(e, index)}><DeleteOutlineIcon/></button>
                                                                </Col>
                                                            </Row>
                                                        )
                                                })
                                            ) : (
                                                <p></p>
                                            )
                                        }
                                    </Container>
                                </div>
                                <div className='d-flex justify-content-end me-2 mt-4'>
                                    <button type="" className='cancel-button me-2' onClick={() => setShowEdit(false)}>Cancel</button>
                                    <button type="submit" className='save-button' onClick={submitEditWorkFromAnywhere}>Save</button>
                                </div>
                            </Modal.Body>
                        </Form>
                    </Modal>
            </div>
        </div>
    );
}
