import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import '../Modals/modal.css'
import { validateLeaveType } from '../../helpers/Validation/Manage/LeaveTypeValidation';
import InputError from '../../helpers/InputError/InpuError';

export default function EditLeaveType(props) {
    const userToken = getToken();
    const userId = getUser();

    // Validation
    const [isError, setIsError] = useState({
        description: false,
        maximum_days: false,
    });
    const [click, setClick] = useState(false);
    // const [editLeaveTypeData, setEditLeaveData] = useState(props.editLeaveTypeData);
    const editLeaveTypeData = props.editLeaveTypeData;
    
    // SUBMIT EDITED LEAVE
    function submitEditLeave(e) {
        var axios = require('axios');
        var qs = require('qs');
        if (validateLeaveType(editLeaveTypeData, setIsError)==true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'leave_types/update',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                leave_type_id: props.editLeaveTypeData.id,
                description: editLeaveTypeData.description,
                maximum_days: editLeaveTypeData.maximum_days
            })
            })
           
            .then(function (response) {
                toast.success("Leave Type Edited Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                toast.error("Failed to Edit Leave Type");
                tokenExpired(error);
                setClick(false);
            });
            
        }
    }

    function handle(e) {
        let editedLeaveData = { ...editLeaveTypeData };
        editedLeaveData[e.target.id] = e.target.value;
        props.setEditLeaveTypeData(editedLeaveData);
    }

    function renderInputError(var_name) {
        let value = editLeaveTypeData[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Edit Leave
                    </div>
                    
                    <div className='body-head no-scroll mt-2 p-0'>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Name<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={props.editLeaveTypeData?props.editLeaveTypeData.description:""}
                                            type="text"
                                            aria-label="name" 
                                            aria-describedby="name" 
                                            placeholder="" 
                                            name="description" 
                                            id='description' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('description')}
                                    </InputGroup>
                                </Col>
                            
                                <Col>
                                <Form.Label className="h6" htmlFor="name">Maximum No. of Days<span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        value={props.editLeaveTypeData?props.editLeaveTypeData.maximum_days:""}
                                        type="text"
                                        aria-label="maximum_days" 
                                        aria-describedby="maximum_days" 
                                        placeholder="" 
                                        name="maximum_days" 
                                        id='maximum_days' 
                                        onChange={(e) => handle(e)}
                                    />
                                </InputGroup>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitEditLeave}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}