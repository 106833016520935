import React, { useState, useRef } from 'react';
import {Row, Col} from 'react-bootstrap'
import { getToken, getUser, refreshPage} from '../../utilities/common';
import { useLocation, useNavigate} from "react-router-dom";
import ReactToPrint from 'react-to-print';
import moment from "moment";
import Navbar from '../Navbar/Navbar'
import PrintIcon from '@mui/icons-material/Print';
import NewLogo from '../../images/NewLogo.png'
import SendEmailPrompt from "../Modals/SendEmailPrompt";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {toast} from 'react-toastify';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

export default function ViewWarningLetters(props) {    
    const userToken = getToken();
    const userId = getUser();
    const location = useLocation();
    const componentRef = useRef();
    const navigate = useNavigate();
    const letterId = location.state;
    const [letterDetails, setLetterDetails] = useState({});
    const [showEmailPromt, setShowEmailPrompt] = useState(false);
    const [sendClicked, setSendClicked] = useState(false);

    function handleClickSend () {
      setShowEmailPrompt(true);
    }

    // FUNCTION TO SEND LETTER TO EMAIL
    function handleSendEmail () {
        const pdf = new jsPDF();
        html2canvas(componentRef.current, {
          ignoreElements: function(element) {
            return element.classList.contains("exclude");
          }
        }).then(function(canvas) {
            const imgData = canvas.toDataURL('image/jpeg', 0.4);
            pdf.addImage(imgData, 'PNG', 10, 10, 180, 170, '', 'FAST');
            const pdfData = pdf.output('arraybuffer');
            const pdfArray = new Uint8Array(pdfData);
            const pdfBlob = new Blob([pdfArray], { type: 'application/pdf' });
            sendEmail(pdfBlob);
        });
    }

    function sendEmail (pdfBlob) {
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();

        data.append('token', userToken.replace(/['"]+/g, ''));
        data.append('requester', userId);
        data.append('letter_id', letterDetails.id);
        data.append('letter_file', pdfBlob, 'letter.pdf');
        data.append('file_name', 'Letter');

        axios({
            url: window.$link + 'letters/send',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "multipart/form-data",
            },
            data: data
        })
        .then(function (response) {
            toast.success("Letter Sent Successfully!");
            setTimeout(() => refreshPage(), 500);
        })
        .catch(function (error) {
            toast.error(error.response.data.messages.message);
        });      
    }

    // Get letter details by ID
    React.useEffect(() => {
        letterDetails.length = 0;
        var axios = require('axios');
        var qs = require('qs');
        axios({
            method: 'get',
            url: window.$link + 'letters/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                letter_id: letterId
            }
        }).then(response => {
            setLetterDetails(response.data.data.letters[0]);
        }).catch(function (error) {
            console.log(error);
        });
    },[]);

    return (
        <div className='comm-bg'>
            <Navbar />
            <div className='comm-page-container'>
              <div className='print-page-container me-3' ref={componentRef}>

                <Row className="p-1"
                  style={{
                    width: "100%",
                    fontSize: "100%"
                  }}
                >
                <div className="topCol me-2">
                  <div>
                    <img
                      src={NewLogo}
                      alt={"logo"}
                      className="payslip-logo ms-0"
                    ></img>
                  </div>
                </div>

                <div className="toprightCol">
                  <div>
                    <ul className="payslip-header d-flex justify-content">
                      {window.$company_name}
                    </ul>
                    <ul className="headeraddress">
                      {window.$company_address}
                    </ul>
                  </div>
                </div>
                <div className="headerline"></div>
                <div className="warningheader bold d-flex justify-content-center mb-5">
                  LETTER NO. {letterDetails.code ? letterDetails.code : ""}
                </div>

                <Row className="bold on-print">
                  <Col xs={2}>DATE</Col>
                  <Col xs={1}>:</Col>
                  <Col >{(function () {
                        return moment(letterDetails.letter_date).format(
                          "MMMM DD, YYYY"
                        );
                      })()}
                    </Col>
                </Row>
                <Row className="bold wrap-text">
                  <Col xs={2}>SUBJECT</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={9}>{letterDetails.subject ? letterDetails.subject : ""}</Col>
                </Row>
                <Row className="bold">
                  <Col xs={2}>TYPE</Col>
                  <Col xs={1}>:</Col>
                  <Col >{letterDetails.letter_type_name ? letterDetails.letter_type_name : ""}</Col>
                </Row>
                <Row className="bold wrap-text">
                  <Col xs={2}>FROM</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={9}>{letterDetails.letter_from? letterDetails.letter_from: ""}</Col>
                </Row>
                <Row className="bold">
                  <Col xs={2}>TO</Col>
                  <Col xs={1}>:</Col>
                  <Col className=''>{(function () {
                        let to = letterDetails.details
                          ? letterDetails.details.map((t) => t.letter_to)
                          : [];
                        return to.join(", ");
                      })()}
                    </Col>
                </Row>

                  <div className="mt-4 headerline"></div>

                  <Row className="updatedon">
                      <div dangerouslySetInnerHTML={{__html: letterDetails.content?letterDetails.content:''}}/>
                  </Row>

                  <Row className="d-flex justify-content-end me-3 mt-4">
                    <Col className=" print d-flex justify-content-end">
                      <ReactToPrint
                        trigger={() => (
                          <button className="print-button me-0 noprint me-1 exclude">
                            <span className="export-text">
                              <PrintIcon /> Print
                            </span>
                          </button>
                        )}
                        content={() => componentRef.current}
                        documentTitle={"Letter"}
                      />
                      <button className="print-button me-0 noprint me-1 exclude" onClick={handleClickSend}>
                        <span className="export-text">
                          <ForwardToInboxIcon /> Send Email
                        </span>
                      </button>
                      <button
                        className="back-btn noprint exclude"
                        onClick={() => navigate(-1)}
                      >
                        <span className="export-text">Back</span>
                      </button>
                    </Col>
                  </Row>
                </Row>
              </div>
          </div>

          <SendEmailPrompt
            show = {showEmailPromt}
            hide = {() => setShowEmailPrompt(false)}
            send = {handleSendEmail}
          />
      </div>
    );
}