import React, { useState, useRef } from "react";
import { getToken, getUser, getRoleId, tokenExpired, refreshPage} from "../../utilities/common";
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import ReactToPrint from "react-to-print";
import {toast} from 'react-toastify'
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt";
import ApplyLoan from "./ApplyLoan";
import InputError from "../../helpers/InputError/InpuError";
import PrintIcon from '@mui/icons-material/Print';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SearchIcon from '@mui/icons-material/Search';
import '../Modals/modal.css'
import './Loan.css';
import "../../utilities/common.css"
import 'react-datepicker/dist/react-datepicker.css'
import { GetAllEmployees, GetUserEmployeeId} from '../../helpers/CommonApi';
import { validateLoan } from "../../helpers/Validation/LoanValidation";

export default function Loan() {
    const userToken = getToken();
    const userId = getUser();
    const userRoleId = getRoleId();
    const userEmployeeId = GetUserEmployeeId();
    const componentRef = useRef();
    const allEmployees = GetAllEmployees();
    const [allLoans, setAllLoans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loanId, setLoanId] = useState();
    const [showAdd, setShowAdd] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [click, setClick] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [editData, setEditData] = useState({
        employee_id: '',
        issue_date: '',
        loan_amount: '',
        term: '',
        interest_rate: '',
        loan_type: '',
        reason: '',
    });

    const [filterDate, setFilterDate] = useState({
        date_from: '',
        date_to: ''
    })

    const [isError, setIsError] = useState({
        employee_id: false,
        issue_date: false,
        loan_amount: false,
        term: false,
        interest_rate: false,
        loan_type: false,
        reason: false,
    });

    const loanTypes = [
        {value: 'sss_salary_loan', name: 'SSS Salary Loan'},
        {value: 'sss_calamity_loan', name: 'SSS Calamity Loan'},
        {value: 'cooperative_loan', name: 'Cooperative Loan'},
        {value: 'laptop_loan', name: 'Laptop Loan'},
        {value: 'phone_loan', name: 'Phone Loan'},
        {value: 'company_loan', name: 'Company Loan'},
        {value: 'pag_ibig_loan', name: 'PAGIBIG Loan'},
        {value: 'cash_advance', name: 'Cash Advance'},
    ];

    function onClickEdit(loan_id) {
        var axios = require('axios');
        var qs = require('qs');
        axios({
            method: 'post',
            url: window.$link + 'employee_loans/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_loan_id: loan_id,
            })
        }).then(response => {
            setEditData(response.data.employees[0]);
            setShowEdit(true);
        }).catch(function (error) {
            console.log(error);
            tokenExpired(error);
        });
    }

    function handle(e) {
        var newData = {...editData}
        newData[e.target.id] = e.target.value;
        setEditData(newData);
    }

    function onClickDelete(loan_id) {
        setLoanId(loan_id);
        setShowDelete(true);
    }

    // APPLY LOAN HANDLER
    function onClickApplyLoan() {
        setShowAdd(true);
    }

    function renderInputError(var_name) {
        let value = editData[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allLoans) {
            allLoans.forEach(function(employee) {
                if(employee.employee_name!==null&&(employee.employee_name).toLowerCase().includes(term)) {
                    filteredArr.push(employee);
                }
            })
        }
        if(term == "") {
            setFilteredData(allLoans);
        } else {
            setFilteredData(filteredArr);
        }
    }

    // API CALL TO SUBMIT THE EDITED DATA
    function submitEditLoan () {
        var axios = require('axios');
        var qs = require('qs');
        if (validateLoan(editData, setIsError)===true && click===false) {
            axios({
            url: window.$link + 'employee_loans/update',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                loan_id: editData.id,
                employee_id: editData.employee_id,
                issue_date: editData.issue_date,
                loan_amount: editData.loan_amount,
                term: editData.term,
                interest_rate: editData.interest_rate,
                loan_type: editData.loan_type,
                reason: editData.reason,
            })
            })
            .then(function (response) {
                setClick(true);
                toast.success("Loan Updated Successfully!");
                setTimeout(() => refreshPage(), 1000);
            })
            .catch(function (error) {
                console.log(error)
                toast.error("Failed to Update Loan");
                tokenExpired(error);
                setClick(false);
            });
        }
    }

    // API CALL TO DELETE LOAN BY ID
    function handleRemove () {
        var axios = require('axios');
        var qs = require('qs');
        axios({
            url: window.$link + 'employee_loans/delete',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, ''),
            loan_id: loanId
        })
        })
        .then(function (response) {
            toast.success("Loan Deleted Successfully!");
            setTimeout(() => refreshPage(), 1500);
        })
        .catch(function (error) {
            toast.error('Failed to Delete Loan');
            tokenExpired(error);
        });
    }

    function getAllLoans (employee_id) {
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'employee_loans/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                date_from: filterDate.date_from,
                date_to: filterDate.date_to,
                employee_id: employee_id
            })
        }).then(response => {
            const finalData = response.data.employees;
            console.log(finalData);
            setAllLoans(finalData);
            setFilteredData(finalData);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            tokenExpired(error);
            setLoading(false);
        });
    }


    // GET ALL LOANS
    React.useEffect(() => {
        allLoans.length = 0;
        if (userRoleId === '1' || userRoleId === '2') {
            getAllLoans('');
        } else if (userEmployeeId) {
            getAllLoans(userEmployeeId);
        } else {
            setAllLoans([]);
            setFilteredData([]);
        }
    },[userEmployeeId]);
            
    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col xs={1} className='d-flex comm-header-employee'>
                            Loan
                        </Col>
                        <Col className='d-flex justify-content-end me-4'>
                            <Row>
                                <Col xs={4} className="me-5">
                                    <ReactToPrint
                                        trigger={() => (
                                            <button className="export-button-apply py-2">
                                                <span className="export-text"><PrintIcon/> Print</span>
                                            </button>
                                        )}
                                        content={() => componentRef.current}
                                        documentTitle={"Loan"}
                                    />
                                </Col>
                                <Col xs={4} className="">
                                    <button className="export-button-apply py-2" onClick={onClickApplyLoan}>
                                        <span className="export-text"><AddBoxIcon/> Apply Loan</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col> 
                    </Row>
                </Row>

                <ApplyLoan
                    showmodal1 = {showAdd}
                    hidemodal1 = {() => setShowAdd(false)}
                />

                <DeletePrompt
                    name = "LOAN"
                    show = {showDelete}
                    hide = {() => setShowDelete(false)}
                    remover = {handleRemove}
                />

                <Row className='mt-3'>
                    <InputGroup className="search-employee">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Employee"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>
                

                {/* TABLE HERE */}
                <div className="loan-wrapper mt-4" ref={componentRef}>
                    <h5 className="comm-header-employee printOnly">Loan</h5>
                    {
                        (function() {
                            return !loading ? (<TableTemplate
                                tableType='employee_loan'
                                tableHeaders={["Employee Name", "Loan Type", "Loan Amount","Paid Amount", "Remaining Balance", ""]}
                                tableData={filteredData}
                                deleteHandler={onClickDelete}
                                editHandler={onClickEdit}
                            />) : (
                                <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                </div>
                            )
                        })()
                    }
                </div>

{/* MODAL FOR EDIT LOAN HERE */}
                <Modal show={showEdit} onHide={() => setShowEdit(false)} centered>
                    <Modal.Body>
                        <div className="modal-header p-0">
                            Edit Loan
                        </div>
        
                        <div className='body-head'>
                            <Container>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Label className="h6" htmlFor="employee_name">Employee<span className='red'> *</span></Form.Label>
                                        <InputGroup>
                                            <Form.Select value={editData.employee_id} name="employee_id" id='employee_id' className="mb-2" onChange={(e) => handle(e)} disabled>
                                                    <option>Select Employee</option>
                                                    {allEmployees.map((data) => {
                                                        return (
                                                            <option value={data.id}>{data.name}</option>
                                                        )
                                                    })}
                                            </Form.Select>
                                        {renderInputError('employee_id')}
                                        </InputGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Label className="h6" htmlFor="type">Issue Date<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                value={editData.issue_date}
                                                type="date"
                                                aria-label="issue_date" 
                                                aria-describedby="issue_date" 
                                                placeholder="dd/mm/yyyy" 
                                                name="issue_date" 
                                                id='issue_date' 
                                                onChange={(e) => handle(e)}
                                            />
                                            {renderInputError('issue_date')}
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Label className="h6" htmlFor="type">Loan Type<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Select value={editData.loan_type} name="loan_type" id='loan_type' className="mb-2" onChange={(e) => handle(e)}>
                                                <option>Select Type</option>
                                                {loanTypes.map((data) => {
                                                    return (
                                                        <option value={data.value}>{data.name}</option>
                                                    )
                                                })}
                                        </Form.Select>
                                            {renderInputError('loan_type')}
                                        </InputGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Label className="h6" htmlFor="loan_amount">Amount<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                value={editData.loan_amount}
                                                type="number"
                                                aria-label="loan_amount" 
                                                aria-describedby="loan_amount" 
                                                placeholder="" 
                                                name="loan_amount" 
                                                id='loan_amount' 
                                                onChange={(e) => handle(e)}
                                            />
                                            {renderInputError('loan_amount')}
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Label className="h6" htmlFor="type">Term (Days)<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                value={editData.term}
                                                type="number"
                                                aria-label="term" 
                                                aria-describedby="term" 
                                                placeholder="" 
                                                name="term" 
                                                id='term' 
                                                onChange={(e) => handle(e)}
                                            />
                                            {renderInputError('term')}
                                        </InputGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Label className="h6" htmlFor="type">Interest Rate (%)<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                value={editData.interest_rate}
                                                type="number"
                                                aria-label="interest_rate" 
                                                aria-describedby="interest_rate" 
                                                placeholder="" 
                                                name="interest_rate" 
                                                id='interest_rate' 
                                                onChange={(e) => handle(e)}
                                            />
                                            {renderInputError('interest_rate')}
                                        </InputGroup>
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <Form.Label className="h6" htmlFor="type">Reason<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={editData.reason}
                                            as="textarea"
                                            aria-label="reason" 
                                            aria-describedby="reason" 
                                            placeholder="" 
                                            name="reason" 
                                            id='reason' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError('reason')}
                                    </InputGroup>
                                </Row>
                                
                            </Container>
                        </div>
                        <div className='d-flex justify-content-end me-2 mt-4'>
                            <button type="" className='cancel-button me-2' onClick={() => setShowEdit(false)}>Cancel</button>
                            <button type="submit" className='save-button' onClick={submitEditLoan}>Save</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}

