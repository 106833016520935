import React, { useState } from "react";
import { Modal, Row, Col, Form, Container, InputGroup} from "react-bootstrap";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";

// components
import Navbar from "../Navbar/Navbar"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt"
import AddDepartment from "./AddDepartment";
import EditDepartment from "./EditDepartment";

// icons
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import SearchIcon from "@mui/icons-material/Search";

// css
import './Department.css';
import '../Modals/modal.css'
import "../../utilities/common.css"
import "../../utilities/loader_1.css"

export default function Department() {
    const userToken = getToken();
    const userId = getUser();
    const [term, setTerm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [departmentId, setDepartmentId] = useState(null);

    // get all Departments
    const [allDepartments, setAllDepartments] = useState([]);
    const [filteredDepartments, setFilteredDepartments] = useState([]);

    React.useEffect(() => {
        allDepartments.length = 0;

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'departments/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                department_id: ""
            }
        }).then(response => {
            setAllDepartments(response.data.data);
            setFilteredDepartments(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            setLoading(false);
        });
    },[]);

    const [showAddDepartment, setShowAddDepartment] = useState(false);
    const [showEditDepartment, setShowEditDepartment] = useState(false);
    const [showDeleteDepartment, setShowDeleteDepartment] = useState(false);

    function onClickAddDepartment() {
        setShowAddDepartment(true);
    }

    // Fetch department data to edit
    const [editDepartmentData, setEditDepartmentData] = useState({});
    function onClickEditDepartment(department_id) {
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'departments/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                department_id: department_id
            }
        }).then(response => {
            setEditDepartmentData(response.data.data[0]);
            setShowEditDepartment(true);
        }).catch(function (error) {
            console.log(error);
        });
    }

    // Delete department API
    const [deleteDepartmentData, setDeleteDepartmentData] = useState({});
    function onClickDeleteDepartment(department_id) {
        setDepartmentId(department_id);
        setShowDeleteDepartment(true);
    }

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allDepartments) {
            allDepartments.forEach(function(department) {
                if((department.name).toLowerCase().includes(term)) {
                    filteredArr.push(department);
                }
            })
        }

        if (term == "") {
            setFilteredDepartments(allDepartments);
        } else {
            setFilteredDepartments(filteredArr);
        }
    }

    function handleRemove(e) {
        var axios = require('axios');
        var qs = require('qs');
        axios({
        url: window.$link + 'departments/delete/' + departmentId,
        method: 'post',
        headers: {
            "api-key": window.$api_key,
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, '')
        })
        })
        .then(function (response) {
            toast.success("Department Deleted Successfully!");
            setTimeout(() => refreshPage(), 500);
        })
        .catch(function (error) {
            console.log(error.response.data.messages);
            tokenExpired(error);
        });
    }

    return (
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-department'>
                            Department   
                        </Col>
                        <Col className='d-flex justify-content-end me-3'>
                            <Row>
                                <Col className="me-5">
                                    <button className="export-button-department" onClick={onClickAddDepartment}>
                                        <span className="export-text"><OtherHousesIcon/> Add Department</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>     
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-department">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Department"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                <AddDepartment
                    showmodal1 = {showAddDepartment}
                    hidemodal1 = {() => setShowAddDepartment(false)}
                />

                <EditDepartment
                    showmodal1 = {showEditDepartment}
                    hidemodal1 = {() => setShowEditDepartment(false)}
                    showmodal1handler = {onClickEditDepartment}
                    editDepartmentData = {editDepartmentData}
                />

                <DeletePrompt
                    name = "DEPARTMENT"
                    show = {showDeleteDepartment}
                    hide = {() => setShowDeleteDepartment(false)}
                    remover = {handleRemove}
                />

                <div className="department-wrapper mt-4">
                {
                    (function() {
                         return !loading ? (<TableTemplate
                            tableType='department'
                            tableHeaders={["Department Name", ""]}
                            tableData={filteredDepartments}
                            deleteHandler={onClickDeleteDepartment}
                            editHandler={onClickEditDepartment}
                        />) : (
                            <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                            </div>
                        )
                    })()
                }
                </div>
            </div>
        </div>
    );
}