import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

import { GetAllBranches, GetAllDepartments, GetAllEmploymentStatuses, GetAllPositions, GetAllProjects, GetAllBusinessUnits } from "../../helpers/CommonApi";
import { validateMemo } from '../../helpers/Validation/MemoValidation';
import InputError from '../../helpers/InputError/InpuError'


import '../Modals/modal.css'

export default function AddMemo(props) {    

    const userToken = getToken();
    const userId = getUser();
    const {quill, quillRef} = useQuill();
    const [click, setClick] = useState(false);
    const [data, setData] = useState({
        memo_date: '',
        memo_from: '',
        subject: '',
        content: '',
        details: [{
            type: '',
            memo_to: ''
        }],
        memo_to_prime: '',
        type_prime: '',
        status: ''
    });

     const [isError, setIsError] = useState({
        memo_date: false,
        memo_from: false,
        subject: false,
        content: false,
        type_prime: false,
        memo_to_prime: false
    });

    function dateFormat(inputDate, format) {
        //parse the input date
        const date = new Date(inputDate);

        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();    

        //replace the month
        format = format.replace("MM", month.toString().padStart(2,"0"));        

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString().substr(2,2));
        }

        //replace the day
        format = format.replace("dd", day.toString().padStart(2,"0"));

        return format;
    }

    const allBusinessUnits = GetAllBusinessUnits();
    const allProjects = GetAllProjects();
    const allDepartments = GetAllDepartments();
    const allBranches = GetAllBranches();
    const allPositions = GetAllPositions();
    const allEmploymentStatus = GetAllEmploymentStatuses();

    function handle(e, element = null) {
        let newMemoData = { ...data };
        if (e.target.id == "type" || e.target.id == "memo_to" ) {
            if (element != null) {
                let index = data["details"].map(t => t.id).indexOf(element.id);
                newMemoData["details"][index][e.target.id] = e.target.value;
            }
        } else if (e.target.id == "type_prime" || e.target.id == "memo_to_prime") {
            if (element != null) {
                let index = data["details"].map(t => t.id).indexOf(element.id);
                newMemoData[e.target.id] = e.target.value;
                if (e.target.id == "type_prime") {
                    newMemoData["details"][index].type = e.target.value;
                } else {
                    newMemoData["details"][index].memo_to = e.target.value;
                }
            }
        } else {
            newMemoData[e.target.id] = e.target.value;
        }
        setData(newMemoData);
    }

    function makeRandomID(){
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

        for(var i = 0; i < 32; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    function addRecipient(e) {
        var recipients = data.details;

        recipients.push({
            key: makeRandomID(),  
            id: makeRandomID(), 
            type: '', 
            memo_to:''
        });

        setData((prev) => {
            return {
                ...prev,
                details: recipients
            }
        })
    }

    function removeRecipient(e, element) {
        var recipients = data.details;
        for(let i=0;i<recipients.length;i++) {
            if(recipients[i].id == element.id) {
                recipients.splice(i, 1);
                break;
            }
        }

        if (!recipients.length || recipients[0].type == '') {
            data.type_prime = '';
            data.memo_to_prime = '';
        }

        setData((prev) => {
            return {
                ...prev,
                details: recipients
            }
        })
    }

    // Add Memo API
    function submitAddMemo(e) {
        var axios = require('axios');
        var qs = require('qs');
        if (validateMemo(data, setIsError)==true && data.details.length>0 && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'memos/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                memo_date: dateFormat(data.memo_date, 'MM/dd/yyyy'),
                subject: data.subject,
                content: data.content,
                approved_by: "",
                no_memo_to: data.details.length,
                memo_to: data.details.map(t => t.memo_to),
                memo_from: data.memo_from,
                type: data.details.map(t => t.type),
                status: "pending"
            })
            })
            .then(function (response) {
                toast.success("Memo Added Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                setClick(false);
                toast.error("Fail to Add Memo!");
                tokenExpired(error);
                setClick(false);
            });
        }
    }

    function renderInputError(var_name) {
        var value;
        if (var_name=="type_prime") {
            value =  data[var_name];
        } else if (var_name=="memo_to_prime") {
            value =  data[var_name];
        } else {
            value = data[var_name];
        }
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    // Fetch the quill content
    React.useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldDelta, source) => {
                var temp_content = quillRef.current.firstChild.innerHTML;
                // data.content = temp_content;
                setData((prev) => {
                    return {
                        ...prev,
                        content: temp_content
                    }
                });
          });
        }
    }, [quill]);

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body className=''>
                    <div className="modal-header p-0">
                        Add Memo
                    </div>
                    <button type="button" class="btn-close"  onClick={props.hidemodal1}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <span class="cross-stand-alone"></span>
                        <span class="cross-1px"></span>
                    <div className='body-head no-scroll p-0 mt-2'>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Date Posted<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-0">
                                        <Form.Control
                                            className="dateFilter"
                                            type="date"
                                            aria-label="memo_date" 
                                            aria-describedby="memo_date" 
                                            name="memo_date" 
                                            id='memo_date' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError("memo_date")}
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Memo From<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="memo_from" 
                                            aria-describedby="memo_from" 
                                            placeholder="" 
                                            name="memo_from" 
                                            id='memo_from' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError("memo_from")}
                                    </InputGroup>
                                </Col>
                            </Row>  
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Subject<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            aria-label="subject" 
                                            aria-describedby="subject" 
                                            placeholder="Subject" 
                                            name="subject" 
                                            id='subject' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError("subject")}
                                    </InputGroup>
                                </Col>
                            </Row> 
                            <Row>
                                <Form.Label className="h6" htmlFor="content">Content <span className='red'> *</span></Form.Label>
                                <div ref={quillRef}/>
                                <div style={{ width: 500, height: 20}}></div>
                                {/* <Col>
                                    <Form.Label className="h6" htmlFor="content">Content <span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            aria-label="content" 
                                            aria-describedby="content" 
                                            placeholder="Enter memo body" 
                                            name="content" 
                                            id='content' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError("content")}
                                    </InputGroup>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col xs={2}>
                                    <div className="h6">Add Recipient</div>
                                    <button id="addRow" className="add-button" onClick={(e) => addRecipient(e)}>+ Add</button>
                                </Col>
                            </Row>
                            {data["details"].map((element, i) => {
                                if (i===0) {
                                return (
                                <Row key={element.key}>
                                    <Col xs={5}>
                                        <Form.Label className="h6" htmlFor="type">Type <span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Select placeholder="Type" aria-describedby="type" aria-label="type" name="type_prime" id='type_prime' onChange={(e) => handle(e, element)}>
                                              <option value=""> --- </option>
                                              <option value="branch">Branch</option>
                                              <option value="business_unit">Business Unit</option>
                                              <option value="department">Department</option>
                                              <option value="employment_status">Employment Status</option>
                                              <option value="position">Position</option>
                                              <option value="project">Project</option>
                                            </Form.Select>
                                            {renderInputError("type_prime")}
                                        </InputGroup>
                                    </Col>
                                    <Col xs={5}>
                                        <Form.Label className="h6" htmlFor="memo_to_prime">Memo To <span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Select 
                                                aria-label="memo_to" 
                                                aria-describedby="memo_to" 
                                                placeholder="Memo To" 
                                                name="memo_to_prime" 
                                                id='memo_to_prime' 
                                                onChange={(e) => handle(e, element)}
                                            >
                                              <option value=""> --- </option>
                                                {
                                                    (element.type == "business_unit") ? (
                                                        allBusinessUnits.map( (x) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    element.type == "project") ? (
                                                        allProjects.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    (element.type == "position") ? (
                                                        allPositions.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    element.type == "employment_status") ? (
                                                        allEmploymentStatus.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    element.type == "branch") ? (
                                                        allBranches.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    (element.type == "department") ? (
                                                        allDepartments.map( (x) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):""))
                                                }
                                            </Form.Select>
                                            {renderInputError("memo_to_prime")}
                                        </InputGroup>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Label className="h6"></Form.Label>
                                        <InputGroup className="mb-3">
                                            <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, element)}>Remove</button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                ) } else {
                                    return (
                                <Row key={element.key}>
                                    <Col xs={5}>
                                        <Form.Label className="h6" htmlFor="type">Type</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Select placeholder="Type" aria-describedby="type" aria-label="type" name="type" id='type' onChange={(e) => handle(e, element)}>
                                              <option value=""> --- </option>
                                              <option value="branch">Branch</option>
                                              <option value="business_unit">Business Unit</option>
                                              <option value="department">Department</option>
                                              <option value="employment_status">Employment Status</option>
                                              <option value="position">Position</option>
                                              <option value="project">Project</option>
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col xs={5}>
                                        <Form.Label className="h6" htmlFor="memo_to">Memo To</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Select 
                                                aria-label="memo_to" 
                                                aria-describedby="memo_to" 
                                                placeholder="Memo To" 
                                                name="memo_to" 
                                                id='memo_to' 
                                                onChange={(e) => handle(e, element)}
                                            >
                                              <option value=""> --- </option>
                                                {
                                                    (element.type == "business_unit") ? (
                                                        allBusinessUnits.map( (x) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    element.type == "project") ? (
                                                        allProjects.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    (element.type == "position") ? (
                                                        allPositions.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    element.type == "employment_status") ? (
                                                        allEmploymentStatus.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    element.type == "branch") ? (
                                                        allBranches.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(
                                                    (element.type == "department") ? (
                                                        allDepartments.map( (x) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):""))
                                                }
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Label className="h6"></Form.Label>
                                        <InputGroup className="mb-3">
                                            <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, element)}>Remove</button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                )
                                }
                            }
                            )}
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitAddMemo}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}