import React, { useState, useRef } from "react";
import { getToken, getUser, refreshPage, tokenExpired } from "../../utilities/common";
import {Row, Col, Container, Form} from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import {toast} from 'react-toastify';
import Navbar from "../Navbar/Navbar"
import UploadImg from '../../images/UploadIcon.png'
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

export default function CoopBatchEntry () {
    const userToken = getToken();
    const userId = getUser();
    const inputRef = useRef();
    const navigate = useNavigate();
    const fileReader = new FileReader();
    const [file, setFile] = useState(null);
    const [click, setClick] = useState(false);

    function handleClick (e) {
    // 👇️ open file input box on click of other element
        inputRef.current.click();
    };
    
    const handleSubmit = (e) => {
        if (file) {
            fileReader.onload = function (event) {
                const text = event.target.result;
                csvFileToArray(text);
            };
            fileReader.readAsText(file);
        }
    };

    const csvFileToArray = (string) => {
        const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

        const array = csvRows.map((i) => {
            const values = i.split(",");
            const obj = csvHeader.reduce((object, header, index) => {
                if (header != undefined && values[index] != undefined) {
                    header = header.replaceAll('"', "");
                    header = header.trim();
                    object[header] = values[index].trim();
                } else {
                    object[header] = values[index];
                }
                return object;
            }, {});
            return obj;
        });

        array.pop();

        var axios = require('axios');
        var qs = require('qs');
        if (click===false) {
            axios({
            url: window.$link + 'contributions/upload_contributions',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                type: 'coop',
                data: array
            })
            })
            .then(function (response) {
                if (response.data.has_errors === true) {
                    // toast.error('Some fields are missing');
                    toast.error(response.data.errors[0]);
                } else {
                    setClick(true);
                    toast.success("Contribution Uploaded Successfully!");
                    setTimeout(() => {navigate(-1);
                    setTimeout(() => refreshPage(), 500)}, 500);
                }
            })
            .catch(function (error) {
                toast.error('Failed to Upload Report');
            });
        }
    };

    const handleOnChange = (e) => {
        e.preventDefault();
        setFile(e.target.files[0]);
    }

    const handleDrop = (e) => {
        e.preventDefault();
        const fileReceived = e.dataTransfer.files[0];
        setFile(fileReceived);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };    

    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">

                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-employee'>
                            Upload Contribution
                        </Col>
                    </Row>

                    <Container className='ms-3 secondary-container mt-3 p-4 m-10'>
                       <Row className="mx-5 mx-4 my-1">
                            <Container className="browse-file-container pointer p-4" onClick={(e) => handleClick(e)} onDrop={(e) => handleDrop(e)} onDragOver={(e) => handleDragOver(e)}>
                                <Row className="justify-content-center">
                                    <img src={UploadImg} className="upload-icon"/>
                                </Row>
                                <Row className="mt-3 justify-content-center text-color bold">
                                    Browse Files
                                </Row>
                                <Row className="justify-content-center text-color mt-2">
                                    Select a file or drag here
                                </Row>
                                <Row className="input-file-btn-container mt-3">
                                    <input
                                        style={{ display: "none" }}
                                        type={"file"}
                                        id={"csvFileInput"}
                                        accept={".csv"}
                                        onChange={(e) => handleOnChange(e)}
                                        ref={inputRef}
                                        className="input-file-btn"
                                    />
                                </Row>
                            </Container>
                            <Row className="question-bg text-color mt-2 ms-0 p-0">
                                <div class="input-group p-0">
                                <div class="input-group-prepend me-3 p-0">
                                    <span class="input-group-text"><FolderOpenIcon/></span>
                                </div>
                                    <span className="mt-1">{file?file.name:''}</span>
                                </div>
                            </Row>
                            <Row className="d-flex justify-content-center p-0 mt-2">
                                <Col xs={3} className="save-button mt-3">
                                    <Row className=" d-flex justify-content-center p-1">
                                        <a href="https://hr-api.wadandco.com/myt_coop_contribution.csv" className="center-text">
                                            Download Template
                                        </a>
                                    </Row>
                                </Col>
                            </Row>
                       </Row>
                       
                        <div className='d-flex justify-content-end me-2 mt-2 me-5 me-5 me-3 mt-4'>
                            <button type="" className='cancel-button me-2' onClick={() => navigate(-1)}>Back</button>
                            <button type="submit" className='save-button' onClick={(e) => handleSubmit(e)}>Save</button>
                        </div>
                    </Container>
                </Row>
            </div>
        </div>
    );
}