import { Modal, Row, Container} from 'react-bootstrap'
import './modal.css'

import EmailIcon from '@mui/icons-material/Email';

export default function SendEmailPrompt(props) {    

    return (
        <div>
            <Modal show={props.show} onHide={props.hide} centered>
                <Modal.Body className="delete-modal">
                
                    <div className='body-head no-scroll pt-4'>
                        <Container>
                            <Row>
                                <p className='text-center'><EmailIcon className='warning warning-icon primary-color'/></p>
                            </Row>
                            
                            <Row>
                                <p className='text-center mt-3'>ARE YOU SURE YOU WANT TO SEND THIS EMAIL?</p>
                            </Row>

                            <div className='d-flex justify-content-end me-2 mt-4'>
                                <button type="" className='cancel-button me-2' onClick={props.hide}>Cancel</button>
                                <button type="submit" className='save-button' onClick={props.send}>Send</button>
                            </div>
    
                        </Container>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}