import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { GetAllBusinessUnits, GetAllDepartments, GetAllProjects, GetAllBranches, GetAllPositions, GetAllEmploymentStatuses } from '../../helpers/CommonApi';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import '../Modals/modal.css'


export default function EditAnnouncement(props) {   
     
    const [click, setClick] = useState(false);
    const {quill, quillRef} = useQuill();
    const allProjects = GetAllProjects();
    const allBusinessUnits = GetAllBusinessUnits();
    const allDepartments = GetAllDepartments();
    const allBranches = GetAllBranches();
    const allPositions = GetAllPositions();
    const allEmploymentStatus = GetAllEmploymentStatuses();

    function makeRandomID(){
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

        for(var i = 0; i < 32; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    // Function to Add Row
    function addRecipient(e) {
        var recipients = props.editAnnouncementData.details;
        recipients.push({
            key: makeRandomID(),  
            id: makeRandomID(), 
            type: '', 
            announcement_to_id:''
        });

        props.setEditAnnouncementData((prev) => {
            return {
                ...prev,
                details: recipients
            }
        })
    }

    function removeRecipient(e, element) {
        var recipients = []
        props.editAnnouncementData.details.map(data=> {
            if(data.type === element.type && data.announcement_to_id === element.announcement_to_id){
                // console.log(data)
            } else{
                recipients.push({...data})
            }
        })
      
        if (!recipients.length || recipients[0].type == '') {
            props.editAnnouncementData.type_prime = '';
            props.editAnnouncementData.announcement_to_prime = '';
        }

        props.setEditAnnouncementData((prev) => {
            return {
                ...prev,
                details: recipients
            }
        })
    }

    function handle(e, element = null) {
        let newAnnouncement = { ... props.editAnnouncementData };
        if(e.target.id == "type" || e.target.id == "announcement_to_id" ) {
            if(element != null) {
                let index = props.editAnnouncementData["details"].map(t => t.id).indexOf(element.id);
                newAnnouncement["details"][index][e.target.id] = e.target.value;
            }
        } else if (e.target.id == "type_prime" || e.target.id == "announcement_to_prime") {
            if (element != null) {
                let index = props.editAnnouncementData["details"].map(t => t.id).indexOf(element.id);
                newAnnouncement[e.target.id] = e.target.value;
                if (e.target.id == "type_prime") {
                    newAnnouncement["details"][index].type = e.target.value;
                } else {
                    newAnnouncement["details"][index].announcement_to = e.target.value;
                }
            }
        } else {
            newAnnouncement[e.target.id] = e.target.value;
        }
        props.setEditAnnouncementData(newAnnouncement);
    }

    // Get the default value of the content for display & editing
    React.useEffect(() => {
        if (quill) {
            quill.clipboard.dangerouslyPasteHTML(props.editAnnouncementData?props.editAnnouncementData.content:"");
            quill.on('text-change', () => {
                var temp_content = quillRef.current.firstChild.innerHTML;
                props.editAnnouncementData.content = temp_content;
            })
        }
    }, [quill]);

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
            <Modal.Body className=''>
                    <div className="modal-header p-0">
                        Edit Announcement
                    </div>
                    <button type="button" class="btn-close"  onClick={props.hidemodal1}>
                        <span class="icon-cross"></span>
                        <span class="visually-hidden">Close</span>
                    </button>
                    <div className='body-head no-scroll p-0 mt-2'>
                        <Container>
                        <Row>
                            <Col>
                                <Form.Label className="h6" htmlFor="posting_date">Date Posted <span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="date"
                                        defaultValue={props.editAnnouncementData?props.editAnnouncementData.announcement_date:""}
                                        aria-label="announcement_date" 
                                        aria-describedby="announcement_date" 
                                        placeholder="Announcement Date" 
                                        name="announcement_date" 
                                        id='announcement_date' 
                                        onChange={(e) => handle(e)}
                                    />
                                    {props.renderInputError("announcement_date")}
                                </InputGroup>
                            </Col>
                                <Col>
                                    <Form.Label className="h6" htmlFor="posting_date">Announcement From<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            defaultValue={props.editAnnouncementData?props.editAnnouncementData.announcement_from:""}
                                            aria-label="announcement_from" 
                                            aria-describedby="announcement_from" 
                                            placeholder="" 
                                            name="announcement_from" 
                                            id='announcement_from' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {props.renderInputError("announcement_from")}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="subject">Subject<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            defaultValue={props.editAnnouncementData?props.editAnnouncementData.subject:""}
                                            type="text"
                                            aria-label="subject" 
                                            aria-describedby="subject" 
                                            placeholder="Subject" 
                                            name="subject" 
                                            id='subject' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {props.renderInputError("subject")}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label className="h6" htmlFor="content">Content <span className='red'> *</span></Form.Label>
                                <div ref={quillRef} />
                                <div style={{ width: 500, height: 20}}></div>
                            </Row>
                            <Row>
                                <Col xs={2}>
                                    <div className="h6">Add Recipient</div>
                                    <button id="addRow" className="add-button" onClick={(e) => addRecipient(e)}>+ Add</button>
                                </Col>
                            </Row>
                           
                            {(props.editAnnouncementData["details"] && props.editAnnouncementData["details"].length) ? props.editAnnouncementData["details"].map((element, i) => {
                                // if (i===0) {
                                    return (
                                // <Row key={i}>
                                //     <Col xs={5}>
                                //         <Form.Label className="h6" htmlFor="type">Type <span className='red'> *</span></Form.Label>
                                //         <InputGroup className="mb-3">
                                //             <Form.Select defaultValue={element.type?element.type:""} placeholder="Type" aria-describedby="type" aria-label="type" name="type_prime" id='type_prime' onChange={(e) => handle(e, element)}    >
                                //               <option value=""> --- </option>
                                //               <option value="branch">Branch</option>
                                //               <option value="business_unit">Business Unit</option>
                                //               <option value="department">Department</option>
                                //               <option value="employment_status">Employment Status</option>
                                //               <option value="position">Position</option>
                                //               <option value="project">Project</option>
                                //             </Form.Select>
                                //             {props.renderInputError("type_prime")}
                                //         </InputGroup>
                                //     </Col>
                                //     <Col xs={5}>
                                //         <Form.Label className="h6" htmlFor="announcement_to_id_prime">Announcement To <span className='red'> *</span></Form.Label>
                                //         <InputGroup className="mb-3">
                                //             <Form.Select defaultValue={element.announcement_to_id?element.announcement_to_id:""} 
                                //                 placeholder="Announcement to" aria-describedby="announcement_to" 
                                //                 aria-label="announcement_to" 
                                //                 name="announcement_to_id_prime" 
                                //                 id='announcement_to_id_prime' 
                                //                 onChange={(e) => handle(e, element)}
                                //             >
                                //               <option value=""> --- </option>
                                //                 {
                                //                     (element.type == "business_unit") ? (allBusinessUnits.map( (x,y) => 
                                //                         <option value={x.id} key={x.id}>{x.name}</option>
                                //                     )):(element.type == "project")?(allProjects.map( (x,y) => 
                                //                         <option value={x.id} key={x.id}>{x.name}</option>
                                //                     )):(element.type == "position")?(allPositions.map( (x,y) => 
                                //                         <option value={x.id} key={x.id}>{x.name}</option>
                                //                     )):(element.type == "employment_status")?(allEmploymentStatus.map( (x,y) => 
                                //                         <option value={x.id} key={x.id}>{x.name}</option>
                                //                     )):((element.type == "branch")?(allBranches.map( (x,y) => 
                                //                         <option value={x.id} key={x.id}>{x.name}</option>
                                //                     )):((element.type == "department")?(allDepartments.map( (x,y) => 
                                //                         <option value={x.id} key={x.id}>{x.name}</option>
                                //                     )):""))
                                //                 }
                                //             </Form.Select>
                                //             {props.renderInputError("announcement_to_prime")}
                                //         </InputGroup>
                                //     </Col>
                                //     <Col xs={2}>
                                //         <Form.Label className="h6"></Form.Label>
                                //         <InputGroup className="mb-3">   
                                //             <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, element)}>Remove</button>
                                //         </InputGroup>
                                //     </Col>
                                // </Row>)
                                // } else {
                                //     return (
                                <Row key={i}>
                                    <Col xs={5}>
                                        <Form.Label className="h6" htmlFor="type">Type</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Select value={element.type?element.type:""} placeholder="Type" aria-describedby="type" aria-label="type" name="type" id='type' onChange={(e) => handle(e, element)}    >
                                              <option value=""> --- </option>
                                              <option value="branch">Branch</option>
                                              <option value="business_unit">Business Unit</option>
                                              <option value="department">Department</option>
                                              <option value="employment_status">Employment Status</option>
                                              <option value="position">Position</option>
                                              <option value="project">Project</option>
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col xs={5}>
                                        <Form.Label className="h6" htmlFor="announcement_to">Announcement To</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Select defaultValue={element.announcement_to_id?element.announcement_to_id:""} 
                                                placeholder="Announcement to" aria-describedby="announcement_to_id" 
                                                aria-label="announcement_to_id" 
                                                name="announcement_to_id"
                                                id='announcement_to_id' 
                                                value={element.announcement_to_id}
                                                onChange={(e) => handle(e, element)}
                                            >
                                              <option value=""> --- </option>
                                                {
                                                    (element.type === "business_unit") ? (allBusinessUnits.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(element.type === "project")?(allProjects.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(element.type === "position")?(allPositions.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(element.type === "employment_status")?(allEmploymentStatus.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):((element.type === "branch")?(allBranches.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):((element.type === "department")?(allDepartments.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):""))
                                                }
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Label className="h6"></Form.Label>
                                        <InputGroup className="mb-3">
                                            <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, element)}>Remove</button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                )
                                // }
                                }):<p></p>}
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={props.submitEditAnnouncement}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}