import {handleValidationChange} from './CommonValidation';

export const validateNightDifferential = (data, setIsError) => {
    var isValid = true;

    if(data.date === "") {
        handleValidationChange("date", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("date", false, setIsError);
    }

    if(data.time_start === "") {
        handleValidationChange("time_start", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("time_start", false, setIsError);
    }

    if(data.time_end === "") {
        handleValidationChange("time_end", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("time_end", false, setIsError);
    }

    if(data.rate === "") {
        handleValidationChange("rate", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("rate", false, setIsError);
    }

    return isValid;
    
}