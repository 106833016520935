import React, { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { getToken, getUser, refreshPage } from "../../utilities/common";
import "../Modals/modal.css";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Navbar from "../Navbar/Navbar";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import NewLogo from '../../images/NewLogo.png'
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {toast} from 'react-toastify';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import SendEmailPrompt from "../Modals/SendEmailPrompt";

export default function ViewMemo(props) {
  const userToken = getToken();
  const userId = getUser();
  const location = useLocation();
  const navigate = useNavigate();
  const componentRef = useRef();
  const [announcementId, setAnnouncementId] = useState(location.state);
  const [announcement, setAnnouncement] = useState({});
  const [loading, setLoading] = useState(true);
  const currentYear = new Date().getFullYear();
  const [showEmailPromt, setShowEmailPrompt] = useState(false);
  const [click, setClick] = useState(false);

  function handleClickSend () {
    setShowEmailPrompt(true);
  }

    // FUNCTION TO SEND LETTER TO EMAIL
    function handleSendEmail () {
      const pdf = new jsPDF();
      html2canvas(componentRef.current, {
        ignoreElements: function(element) {
          return element.classList.contains("exclude");
        }
      }).then(function(canvas) {
          const imgData = canvas.toDataURL('image/jpeg', 0.4);
          pdf.addImage(imgData, 'PNG', 10, 10, 180, 170, '', 'FAST');
          const pdfData = pdf.output('arraybuffer');
          const pdfArray = new Uint8Array(pdfData);
          const pdfBlob = new Blob([pdfArray], { type: 'application/pdf' });
          sendEmail(pdfBlob);
      });
    }

  function sendEmail (pdfBlob) {
      var axios = require('axios');
      var FormData = require('form-data');
      var data = new FormData();

      if (click === false) {
        data.append('token', userToken.replace(/['"]+/g, ''));
        data.append('requester', userId);
        data.append('announcement_id', announcement.id);
        data.append('announcement_file', pdfBlob, 'announcement.pdf');
        data.append('file_name', 'Announcement');

        axios({
            url: window.$link + 'announcements/send',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "multipart/form-data",
            },
          data: data
        })
        .then(function (response) {
          setClick(true);
            toast.success("Announcement Sent Successfully!");
            setTimeout(() => refreshPage(), 500);
        })
        .catch(function (error) {
            toast.error(error.response.data.messages.message);
        });      
      }
    }

  // Get announcement details by ID
  React.useEffect(() => {
    announcement.length = 0;

    var axios = require("axios");
    var qs = require("qs");

    axios({
      method: "get",
      url: window.$link + "announcements/get",
      headers: {
        "api-key": window.$api_key,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: {
        requester: userId,
        token: userToken.replace(/['"]+/g, ""),
        announcement_id: announcementId,
      },
    })
      .then((response) => {
        setAnnouncement(response.data.data.announcements[0]);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div className="comm-bg">
      <Navbar />
      <div className="comm-page-container">
        <div className="print-page-container me-3" ref={componentRef}>

          <Row>
            <div className="topCol me-2">
              <div>
                <img
                  src={NewLogo}
                  alt={"logo"}
                  className="payslip-logo ms-0"
                ></img>
              </div>
            </div>
            <div className="toprightCol">
              <div>
                <ul className="payslip-header d-flex justify-content">
                  {window.$company_name}
                </ul>
                <ul className="headeraddress">
                   {window.$company_address}
                </ul>
              </div>
            </div>
            <div className="headerline"></div>
            <div className="announcementheader bold d-flex justify-content-center mb-5">
              {/* ANNOUNCEMENT NO. {currentYear}-00{announcement.id ? announcement.id : ""} */}
              ANNOUNCEMENT NO. {announcement.code?announcement.code:''}
            </div>

        {/* DATE */}
            <Row className="bold">
              <Col xs={2}>DATE</Col>
              <Col xs={1}>:</Col>
              <Col >{(function () {
                    return moment(announcement.announcement_date).format(
                      "MMMM DD, YYYY"
                    );
                  })()}</Col>
            </Row>
            <Row className="bold">
              <Col xs={2}>TO</Col>
              <Col xs={1}>:</Col>
              <Col >{(function () {
                    let to = announcement.details
                      ? announcement.details.map((t) => t.announcement_to)
                      : [];
                    return to.join(", ");
                  })()}
                </Col>
            </Row>
            <Row className="bold">
              <Col xs={2}>FROM</Col>
              <Col xs={1}>:</Col>
              <Col >{announcement.announcement_from? announcement.announcement_from: ""}</Col>
            </Row>
            <Row className="bold mb-3">
              <Col xs={2}>SUBJECT</Col>
              <Col xs={1}>:</Col>
              <Col >{announcement.subject ? announcement.subject : ""}</Col>
            </Row>

            <div className="headerline"></div>
            <div className="updatedon">
              {/* {announcement.content ? announcement.content : ""} */}
                <div dangerouslySetInnerHTML={{__html: announcement.content?announcement.content:''}}/>
            </div>
            <Row className="mt-5">
              <Col className="mt-5">
                <Row className="assigned d-flex justify-content-left margin-bottom">
                  Prepared by:
                </Row>
                <Row className="d-flex justify-content-left">
                  ____________________________________
                </Row>
                <Row className="role d-flex justify-content-left">
                  Executive Assistant
                </Row>

                <Row className="assigned d-flex justify-content-left margin-bottom">
                  Approved by:
                </Row>
                <Row className="d-flex justify-content-left">
                  ____________________________________
                </Row>
                <Row className="role d-flex justify-content-left">
                  Operations Head
                </Row>
              </Col>
            </Row>

            <Row className="d-flex justify-content-end me-3 mt-4">
              <Col className=" print d-flex justify-content-end">
                <ReactToPrint
                  trigger={() => (
                    <button className="print-button me-0 noprint me-1 exclude">
                      <span className="export-text">
                        <PrintIcon /> Print
                      </span>
                    </button>
                  )}
                  content={() => componentRef.current}
                  documentTitle={"Announcements"}
                />
                <button className="print-button me-0 noprint me-1 exclude" onClick={handleClickSend}>
                  <span className="export-text">
                    <ForwardToInboxIcon /> Send Email
                  </span>
                </button>
                <button
                  className="back-btn noprint exclude"
                  onClick={() => navigate(-1)}
                >
                  <span className="export-text">Back</span>
                </button>
              </Col>
            </Row>

            <SendEmailPrompt
              show = {showEmailPromt}
              hide = {() => setShowEmailPrompt(false)}
              send = {handleSendEmail}
            />
          </Row>
        </div>
      </div>
    </div>
  );
}
