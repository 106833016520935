import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser} from '../../utilities/common';
import {toast} from 'react-toastify';
import { refreshPage } from '../../utilities/common';
import { GetAllRequirementsByEmployeeId } from '../../helpers/CommonApi';
import '../Modals/modal.css'

export default function UploadRequirements (props) {
    const employeeId = props.employeeId;
    const employmentStatusId = props.employmentStatusId;
    const userId = getUser();
    const userToken = getToken();
    // const currentRequirements = GetAllRequirementsByEmployeeId(employeeId);
    const [employeeRequirements, setEmployeeRequirements] = useState([]);
    const [currentRequirements, setCurrentRequirements] = useState([]);

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    function handle (e, file_index) {
        var file = e.target.files[0];
        var newData = [...employeeRequirements];
        newData[file_index].file_name = file.name;
        getBase64(file).then(base64 => {
            newData[file_index].file_attachment = base64
        });
        setEmployeeRequirements(newData);
    }

    function submitRequirements (e) {
        var axios = require('axios');
        var qs = require('qs');
        axios({
            url: window.$link + 'employees/upload_requirements',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: employeeId,
                employee_requirements: employeeRequirements
            })
            })
            .then(function (response) {
                toast.success("Requirements Added Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                toast.error("Failed to Add Requirements");
                // console.log(error.response.data.messages);
            });
    }

    // Get all requirements
    React.useEffect(() => {
        employeeRequirements.length = 0;
        var axios = require('axios');
        var qs = require('qs');
        if (employmentStatusId) {
            axios({
                method: 'get',
                url: window.$link + 'requirements/get',
                headers: {
                    "api-key": window.$api_key,
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                params: {
                    requester: userId,
                    token: userToken.replace(/['"]+/g, ''),
                    employment_status_id: employmentStatusId
                }
            }).then(response => {
                var finalRequirements = [];
                finalRequirements = response.data.data.map((data) => {
                    var info = {};
                    info.employment_requirement_id = data.id;
                    info.requirement_name = data.name;
                    info.file_name = '';
                    info.file_attachment = '';
                    return info;
                })
                setEmployeeRequirements(finalRequirements);
            }).catch(function (error) {
                console.log(error);
            });
        }

        axios({
            method: 'post',
            url: window.$link + 'employees/get_employee_requirements',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: employeeId
            })
        }).then(response => {
            const res = response.data.employees;
            setCurrentRequirements(res);
        }).catch(function (error) {
        });
        
    },[employmentStatusId]);

    return(
        <div className='account-details'>
            <Modal show={props.show} onHide={props.hide} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Upload Requirements
                    </div>
                   
                    <div className='body-head no-scroll mt-2 p-0'>
                    <Container>
                        {
                            (employeeRequirements.length) ? (
                                employeeRequirements.map((data, index) => {
                                    return (
                                        <Row>
                                            <Col xs={6}>
                                            <Form.Label className="h5 mt-1" htmlFor="notes">{data.requirement_name}</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    type="file"
                                                    multiple
                                                    aria-label="file_name" 
                                                    aria-describedby="file_name" 
                                                    placeholder="file_name"
                                                    name="file_name" 
                                                    id='file_name' 
                                                    onChange={(e) => handle(e, index)}
                                                />
                                            </InputGroup>
                                            </Col>
                                        </Row>
                                    )
                                })
                            ) : (<p></p>)
                        }
                    </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hide}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitRequirements}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}