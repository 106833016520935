import React, { useState } from "react"
import { getToken, getUser, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";
import { Modal, Container, Col, Row, Form } from "react-bootstrap";
import moment from "moment";
import { validateHoliday } from "../../helpers/Validation/Manage/HolidayValidation";
import InputError from "../../helpers/InputError/InpuError";

// components
import Navbar from "../Navbar/Navbar"
import DeletePrompt from "../Modals/DeletePrompt"
import TableTemplate from '../../utilities/table_template';
import AddHoliday from "./AddHolidays";
import { InputGroup } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import EventIcon from '@mui/icons-material/Event';
import './Holidays.css';

function Holidays() {
    const userToken = getToken();
    const userId = getUser();
    var axios = require('axios');
    var qs = require('qs');

    // delete modal handler
    const [showDelete, setShowDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = () => setShowDelete(true);
    const [holidayID, setHolidayID] = useState();
    const [allHolidays, setAllHolidays] = useState([])
    const [filteredHolidays, setFilteredHolidays] = useState([])
    const [loading, setLoading] = useState(true);
    const [click, setClick] = useState(false);
    const [isError, setIsError] = useState({
        name: false,
        date: false,
        type: false,
    });
    
    function onClickDelete(id) {
        handleShowDelete();
        setHolidayID(id);
    }

    // Delete holiday API
    function handleRemove() {
        axios({
        url: window.$link + 'holidays/delete',
        method: 'post',
        headers: {
            "api-key": window.$api_key,
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, ''),
            holiday_id: holidayID
        })
        })
        .then(function (response) {
            toast.success("Holiday Deleted Successfully!");
            setTimeout(refreshPage(), 1000);
        })
        .catch(function (error) {
            toast.error('Failed to Delete Holiday');
            console.log(error.response.data);
        });
    }

    // Add Holiday Handler
    const [showAddHoliday, setShowAddHoliday] = useState(false);

    // Edit Holiday Handler
    const [showEdit, setShowEdit] = useState(false);
    const handleShowEdit = () => setShowEdit(true);
    const [holidayEdit, setHolidayEdit] = useState('');

    // fetch holiday with specific id
    const [editData, setEditData] = useState({
        name: '',
        date: '',
        bonus: '',
        type: '',
        with_pay: ''
    });

    // get holiday details by Id for editing
    function onClickEdit(id) {
        setHolidayEdit(id);
        
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'holidays/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                holiday_id: id,
                date_to: ''
            })
        }).then(response => {
            let holiday = response.data.data[0];
            let currentData = {
                name: holiday.name,
                date: holiday.date,
                bonus: holiday.bonus,
                type: holiday.remarks,
                with_pay: holiday.with_pay
            }
            setEditData(currentData);
            handleShowEdit();
        }).catch(function (error) {
            console.log(error.response.data);
        });
    }       

    // SUBMIT EDITED HOLIDAY DETAILS
    function submitEditHolidays(e) {
        var axios = require('axios');
        var qs = require('qs');
        if (validateHoliday(editData, setIsError)==true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'holidays/update',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                holiday_id: holidayEdit,
                name: editData.name,
                date: editData.date,
                bonus: editData.bonus,
                remarks: editData.type,
                with_pay: editData.with_pay
            })
            })
            .then(function (response) {
                toast.success("Holiday Edited Successfully!");
                setTimeout(refreshPage(), 1000);
            })
            .catch(function (error) {
                toast.error("Failed to Update Holiday");
                setClick(false);
            });
        }
    }

    function handle(e) {
        const newData = { ...editData };
        newData[e.target.id] = e.target.value;
        newData["with_pay"] = e.target.checked?1:0;
        setEditData(newData);
    }

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allHolidays) {
            allHolidays.forEach(function(holiday) {
                if((holiday.name).toLowerCase().includes(term)) {
                    filteredArr.push(holiday);
                }
            })
        }

        if (term == "") {
            setFilteredHolidays(allHolidays);
        } else {
            setFilteredHolidays(filteredArr);
        }
    }

    function renderInputError(var_name) {
        let value = editData[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    function SentenceCaseName (name) {
        const sentenceCasedName = name.toLowerCase().replace(/(^|\s)[a-z]/g, (char) => char.toUpperCase());
        return sentenceCasedName
    }

    // FETCH ALL HOLIDAYS
    React.useEffect(() => {
        filteredHolidays.length=0;
        allHolidays.length = 0;
        axios({
            method: 'post',
            url: window.$link + 'holidays/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                holiday_id: ''
            })
        }).then(response => {
            const infoSorted = response.data.data.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse()));
            infoSorted.map((holiday) => {
                var info = {};

                info.id = holiday.id;
                info.name = SentenceCaseName(holiday.name);
                info.date = moment(holiday.date).format('MMM D, YYYY');
                info.bonus = holiday.bonus;
                info.type = SentenceCaseName(holiday.remarks);
                info.with_pay = holiday.with_pay==1 ? 'Yes' : 'No';

                setAllHolidays(oldArray => [...oldArray, info]);
                setFilteredHolidays(oldArray => [...oldArray, info]);
                setLoading(false)
            })  
        }).catch(function (error) {
            tokenExpired(error);
            setLoading(false);
        });
    },[]);

    return(
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-holidays'>
                            Holiday   
                        </Col>
                        <Col className='d-flex justify-content-end me-3'>
                            <Row>
                                <Col className="me-5">
                                    <button className="export-button-holidays" onClick={() => setShowAddHoliday(true)}>
                                        <span className="export-text"><EventIcon/> Add Holiday</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>     
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-holidays">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Holiday"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                <AddHoliday 
                    name="ADD" 
                    show={showAddHoliday} 
                    hide={() => setShowAddHoliday(false)}
                    purpose="add"
                />

                <div className="holidays-wrapper mt-4">
                    {
                        (function() {
                            return !loading ? (
                            <TableTemplate
                                tableType='holidays'
                                tableHeaders={["Date", "Holiday Name", "Add-on Rate (%)", "Type", "With Pay", "   "]}
                                tableData={filteredHolidays}
                                deleteHandler={onClickDelete}
                                editHandler={onClickEdit}
                            />) : (
                                <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                </div>
                            )
                        })()
                    }
                </div>
                    <DeletePrompt
                        name = "HOLIDAY"
                        show = {showDelete}
                        hide = {handleCloseDelete}
                        remover = {handleRemove}
                    />

                    {/* Edit Holiday Modal */}
                    <Modal show={showEdit} onHide={() => setShowEdit(false)} size="m" centered>
                        <Modal.Body>
                            <div className="modal-header p-0">
                                Edit Holiday
                            </div>
                            <button type="button" class="btn-close" onClick={() => setShowEdit(false)}>
                                <span class="icon-cross"></span>
                                <span class="visually-hidden">Close</span>
                            </button>
                            <div className='Container body-head'>
                            <Container>
                                <Row>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Name<span className='red'> *</span></Form.Label>
                                        <InputGroup className='mb-3'>
                                            <Form.Control
                                                type="text" 
                                                name="name"
                                                id="name"
                                                value={editData.name}
                                                className="form-control mb-2"
                                                onChange={(e) => handle(e)}
                                            />
                                        {renderInputError("name")}
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Date<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="date"
                                                name="date" 
                                                id="date" 
                                                value={editData.date}
                                                className="form-control mb-2"
                                                onChange={(e) => handle(e)}
                                            />
                                        {renderInputError("date")}
                                        </InputGroup>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Add-on Rate (%)</Form.Label>
                                        <InputGroup>
                                            <Form.Control 
                                                type="number" 
                                                name="bonus" 
                                                id="bonus" 
                                                value={editData.bonus}
                                                className="form-control mb-2"
                                                onChange={(e) => handle(e)}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Type<span className='red'> *</span></Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                as="select"
                                                aria-label="type" 
                                                aria-describedby="type" 
                                                placeholder="Type" 
                                                name="type" 
                                                id='type' 
                                                onChange={(e) => handle(e)}
                                                value={editData.type}
                                            >
                                            <option value=""> --- </option>
                                            <option value="regular"> Regular </option>
                                            <option value="special_nonworking"> Special Non-Working </option>
                                            <option value="special_working"> Special Working</option>
                                            </Form.Control>
                                            {renderInputError("type")}
                                        </InputGroup>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col>
                                        <Row><span className='white'>---</span></Row>
                                        <Row>
                                            <Col xs={1}>
                                                {
                                                    (function() {
                                                        return (editData.with_pay == 0 || editData.with_pay == null) ? 
                                                            (<Form.Check type="checkbox" name="with_pay" className="float-left" id='with_pay' onChange={(e) => handle(e)}/>):
                                                            (<Form.Check checked = {true} type="checkbox" name="with_pay" className="float-left" id='with_pay' onChange={(e) => handle(e)}/>)
                                                    })()
                                                }
                                            </Col>
                                            <Col>
                                            <Form.Label className="h6" htmlFor="name">With Pay</Form.Label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row> */}
                            </Container>
                            </div>

                            <div className='d-flex justify-content-end me-2 mt-4'>
                                <button type="" className='cancel-button me-2' onClick={() => setShowEdit(false)}>Cancel</button>
                                <button type="submit" className='save-button' onClick={submitEditHolidays}>Save</button>
                            </div>                 
                        </Modal.Body>
                    </Modal>
            </div>
        </div>
    );
}

export default Holidays;