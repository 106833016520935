import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, tokenExpired, refreshPage } from '../../utilities/common';
import { toast } from 'react-toastify';
import { GetAllEmployees } from '../../helpers/CommonApi';
import InputError from '../../helpers/InputError/InpuError';
import { validateWorkFromAnywhere } from '../../helpers/Validation/WorkFromAnywhereValidation';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import '../Modals/modal.css'

export default function AddWorkFromAnywhere(props) {
    const userToken = getToken();
    const userId = getUser();

    // Get All Employees
    const allEmployees = GetAllEmployees();
    const [click, setClick] = useState(false);
    const [files, setFiles] = useState([]);
    const {quill, quillRef} = useQuill();

    const [data, setData] = useState({
        employee_id: "",
        date_from: "",
        date_to: "",
        // no_of_days: "",
        remarks: "",
        status: "pending",
        admin_remarks: "temp",
        fileNames: [],
        convertedFiles: []
    });

    const [isError, setIsError] = useState({
        employee_id: false,
        date_from: false,
        date_to: false,
        // no_of_days: false,
        remarks: false,
    });

    function renderInputError(var_name) {
        let value = data[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    // Add Leave API
    function submitAddLeave(e) {
        e.preventDefault();
        if(validateWorkFromAnywhere(data, setIsError)===true && click === false) {
            setClick(true);
            var axios = require('axios');
            var qs = require('qs');
            axios({
            url: window.$link + 'wfa_requests/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: data.employee_id,
                date_from: data.date_from,
                date_to: data.date_to,
                // no_of_days: data.no_of_days,
                remarks: data.remarks,
                admin_remarks: data.admin_remarks,
                status: "pending",
                file_names: data.convertedFiles.map((file) => {return(file?file:null)})
            })
            })
            .then(function (response) {
                toast.success("WFA Added Successfully!");
                setTimeout(() => refreshPage(), 1000);
            })
            .catch(function (error) {
                setClick(false);
                toast.error('Error Adding WFA');
                tokenExpired(error);
            });
        }
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    function handle(e) {
        const newData = { ...data };
        if (e.target.id === "file_name") {
            var files_temp = e.target.files;
            Array.from(files_temp).forEach(file => {
                newData["fileNames"].push(file);
                getBase64(file).then(base64 => {
                    newData["convertedFiles"].push(base64);
                    setFiles((prevFileArray) => [...prevFileArray, base64]);
                });
            });
        } else {
            newData[e.target.id] = e.target.value;
        }
        setData(newData);
    }

    function removeFile (e, index) {
        var currentConvertedFiles = data.convertedFiles;
        var currentFileNames = data.fileNames;
        for (let i=0; i<currentFileNames.length; i++) {
            if (i === index) {
                currentFileNames.splice(i, 1);
                currentConvertedFiles.splice(i, 1);
                break;
            }
        }

        setData((prev) => {
            return {
                ...prev,
             fileNames: currentFileNames,
             convertedFiles: currentConvertedFiles
            }
        });
    }

    // Fetch the quill content
    React.useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldDelta, source) => {
                var temp_content = quillRef.current.firstChild.innerHTML;
                // data.content = temp_content;
                setData((prev) => {
                    return {
                        ...prev,
                        remarks: temp_content
                    }
                });
          });
        }
    }, [quill]);

    return(
        <div>
            <Modal show={props.show} onHide={props.hide} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        WFA Application Form
                    </div>
                    <button type="button" class="btn-close"  onClick={props.hide}>
                        <span class="icon-cross"></span>
                        <span class="visually-hidden">Close</span>
                    </button>
                    <div className='body-head'>
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <Form.Label className="h6" htmlFor="name">Employee Name<span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Select id="employee_id" name="employee_id" className="mb-2" onChange={(e) => handle(e)}>
                                            <option>Select Employee</option>
                                            {allEmployees.map((data) => {
                                                return (
                                                    <option value={data.id}>{data.name}</option>
                                                )
                                            })}
                                        </Form.Select>
                                         {renderInputError("employee_id")}
                                    </InputGroup>
                                </Col>
                                <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="name">Date of WFA<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="date" id="date_from" className="mb-2" onChange={(e) => handle(e)}/>
                                         {renderInputError("date_from")}
                                    </InputGroup>
                                </Col>
                                {/* <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="name">Number of Days<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="number" id="no_of_days" name="no_of_days" className="mb-2" onChange={(e) => handle(e)}/>
                                            {renderInputError("no_of_days")}
                                    </InputGroup>
                                </Col> */}
                                <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="name">Date To<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="date" id="date_to" className="mb-2" onChange={(e) => handle(e)}/>
                                         {renderInputError("date_to")}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label className="h6" htmlFor="name">Reason<span className='red'> *</span></Form.Label>
                                <div ref={quillRef} />
                                <div style={{ width: 500, height: 20}}></div>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <Form.Label className="h6" htmlFor="notes">Attach File</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="file"
                                            multiple
                                            aria-label="file_name" 
                                            aria-describedby="file_name" 
                                            placeholder="file_name"
                                            name="file_name" 
                                            id='file_name' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            {
                                // (fileNames && fileNames.length) ? (
                                    data["fileNames"].map((fileName, index) => {
                                        if (fileName) {
                                            return (
                                                <Row>
                                                    <Col xs={10} class="input-group p-0">
                                                        <Row className="question-bg text-color mt-2 ms-0 p-0">
                                                            <Col xs={1} class="">
                                                                <FolderOpenIcon/>
                                                            </Col>
                                                            <Col xs={9} className=''>
                                                                <span className="mt-1 fileName">{fileName.name?fileName.name:''}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={2}>
                                                        <button id="removeRow" className="trash-icon mt-1" onClick={(e) => removeFile(e, index)}><DeleteOutlineIcon/></button>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    })
                                // ) : (
                                //     <p></p>
                                // )
                            }
                        </Container>
                    </div>
                    {/* <div className='btn modal-btn'>
                        <button type="button" onClick={submitAddLeave}>Submit</button>
                    </div> */}
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hide}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitAddLeave}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}