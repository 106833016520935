import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import '../Modals/modal.css'

export default function EditMemo(props) {    

    const allBusinessUnits = props.allBusinessUnits;
    const allProjects = props.allProjects;
    const allDepartments = props.allDepartments;
    const allBranches = props.allBranches;
    const allEmploymentStatus = props.allEmploymentStatus;
    const allPositions = props.allPositions;
    const [click, setClick] = useState(false);
    const {quill, quillRef} = useQuill();

    function handle(e, element = null) {
        let newMemoData = { ...props.editMemoData };
        if(e.target.id == "type" || e.target.id == "memo_to_id" ) {
            if(element != null) {
                let index = props.editMemoData["details"].map(t => t.id).indexOf(element.id);
                newMemoData["details"][index][e.target.id] = e.target.value;
            }
        } else if (e.target.id == "type_prime" || e.target.id == "memo_to_id_prime") {
            if (element != null) {
                let index = props.editMemoData["details"].map(t => t.id).indexOf(element.id);
                newMemoData[e.target.id] = e.target.value;
                if (e.target.id == "type_prime") {
                    newMemoData["details"][index].type = e.target.value;
                } else {
                    newMemoData["details"][index].memo_to_id = e.target.value;
                }
            }
        } else {
            newMemoData[e.target.id] = e.target.value;
        }
        props.setEditMemoData(newMemoData);
    }

    function makeRandomID(){
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

        for(var i = 0; i < 32; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    function addRecipient(e) {
        var recipients = props.editMemoData.details;

        recipients.push({
            key: makeRandomID(),  
            id: makeRandomID(), 
            type: '', 
            memo_to:''
        });

        props.setEditMemoData((prev) => {
            return {
                ...prev,
                details: recipients
            }
        })
    }

    function removeRecipient(e, element) {
        var recipients = props.editMemoData.details;
        for(let i=0;i<recipients.length;i++) {
            if(recipients[i].id == element.id) {
                recipients.splice(i, 1);
                break;
            }
        }

        if (!recipients.length || recipients[0].type == '') {
            props.editMemoData.type_prime = '';
            props.editMemoData.memo_to_id_prime = '';
        }

        props.setEditMemoData((prev) => {
            return {
                ...prev,
                details: recipients
            }
        })
    }

    React.useEffect(() => {
        if (quill) {
            quill.clipboard.dangerouslyPasteHTML(props.editMemoData.content?props.editMemoData.content:'');
            quill.on('text-change', () => {
                var temp_content = quillRef.current.firstChild.innerHTML;
                props.editMemoData.content = temp_content;
            })
        }
    }, [quill]);

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body className=''>
                    <div className="modal-header p-0">
                        Edit Memo
                    </div>
                    <button type="button" class="btn-close"  onClick={props.hidemodal1}>
                            <span class="icon-cross"></span>
                            <span class="visually-hidden">Close</span>
                        </button>
                        <span class="cross-stand-alone"></span>
                        <span class="cross-1px"></span>
                    <div className='body-head no-scroll p-0 mt-2'>
                        <Container>
                        <Row>
                            <Col>
                                <Form.Label className="h6" htmlFor="subject">Date Posted <span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-0">
                                    <Form.Control
                                        defaultValue={props.editMemoData? props.editMemoData.memo_date:""}
                                        className="dateFilter"
                                        type="date"
                                        aria-label="memo_date" 
                                        aria-describedby="memo_date" 
                                        name="memo_date" 
                                        id='memo_date' 
                                        onChange={(e) => handle(e)}
                                    />
                                    {props.renderInputError("memo_date")}
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label className="h6" htmlFor="subject">Memo From <span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        defaultValue={props.editMemoData? props.editMemoData.memo_from: ""}
                                        type="text"
                                        aria-label="memo_from" 
                                        aria-describedby="memo_from" 
                                        placeholder="memo_from" 
                                        name="memo_from" 
                                        id='memo_from' 
                                        onChange={(e) => handle(e)}
                                    />
                                    {props.renderInputError("memo_from")}
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label className="h6" htmlFor="subject">Subject <span className='red'> *</span></Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    defaultValue={props.editMemoData? props.editMemoData.subject: ""}
                                    type="text"
                                    aria-label="subject" 
                                    aria-describedby="subject" 
                                    placeholder="Subject" 
                                    name="subject" 
                                    id='subject' 
                                    onChange={(e) => handle(e)}
                                />
                                {props.renderInputError("subject")}
                            </InputGroup>
                        </Row>
                            <Row>
                                <Form.Label className="h6" htmlFor="content">Content <span className='red'> *</span></Form.Label>
                                <div ref={quillRef} />
                                <div style={{ width: 500, height: 20}}></div>
                            </Row>
                            <Row>
                                <Col xs={2}>
                                    <div className="h6">Add Recipient</div>
                                    <button id="addRow" className="add-button" onClick={(e) => addRecipient(e)}>+ Add</button>
                                </Col>
                            </Row>
                        
                            {(props.editMemoData["details"] && props.editMemoData["details"].length) ? props.editMemoData["details"].map((element, i) => {
                                if (i===0) {
                                    return (
                                        <Row key={element.id}>
                                            <Col xs={5}>
                                                <Form.Label className="h6" htmlFor="type">Type <span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Select defaultValue={element.type?element.type:""} placeholder="Type" aria-describedby="type" aria-label="type" name="type_prime" id='type_prime' onChange={(e) => handle(e, element)}>
                                                    <option value=""> --- </option>
                                                    <option value="branch">Branch</option>
                                                    <option value="business_unit">Business Unit</option>
                                                    <option value="department">Department</option>
                                                    <option value="employment_status">Employment Status</option>
                                                    <option value="position">Position</option>
                                                    <option value="project">Project</option>
                                                    </Form.Select>
                                                    {props.renderInputError("type_prime")}
                                                </InputGroup>
                                            </Col>
                                            <Col xs={5}>
                                                <Form.Label className="h6" htmlFor="memo_to">Memo To <span className='red'> *</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Select defaultValue={element.memo_to_id?element.memo_to_id:""} placeholder="Memo To" aria-describedby="memo_to" aria-label="memo_to" name="memo_to_id_prime" id='memo_to_id_prime' onChange={(e) => handle(e, element)}>
                                                    <option value=""> --- </option>
                                                        {
                                                            (element.type == "business_unit") ? (allBusinessUnits.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "project")?(allProjects.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "position")?(allPositions.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "employment_status")?(allEmploymentStatus.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):((element.type == "branch")?(allBranches.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):((element.type == "department")?(allDepartments.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):""))
                                                        }
                                                    </Form.Select>
                                                    {props.renderInputError("memo_to_id_prime")}
                                                </InputGroup>
                                            </Col>
                                            <Col xs={2}>
                                                <Form.Label className="h6"></Form.Label>
                                                <InputGroup className="mb-3">
                                                <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, element)}>Remove</button>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    )
                                } else {
                                    return (
                                        <Row key={element.id}>
                                            <Col xs={5}>
                                                <Form.Label className="h6" htmlFor="type">Type </Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Select defaultValue={element.type?element.type:""} placeholder="Type" aria-describedby="type" aria-label="type" name="type" id='type' onChange={(e) => handle(e, element)}>
                                                    <option value=""> --- </option>
                                                    <option value="branch">Branch</option>
                                                    <option value="business_unit">Business Unit</option>
                                                    <option value="department">Department</option>
                                                    <option value="employment_status">Employment Status</option>
                                                    <option value="position">Position</option>
                                                    <option value="project">Project</option>
                                                    </Form.Select>
                                                </InputGroup>
                                            </Col>
                                            <Col xs={5}>
                                                <Form.Label className="h6" htmlFor="memo_to">Memo To</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Select defaultValue={element.memo_to_id?element.memo_to_id:""} placeholder="Memo To" aria-describedby="memo_to" aria-label="memo_to" name="memo_to_id" id='memo_to_id' onChange={(e) => handle(e, element)}>
                                                    <option value=""> --- </option>
                                                        {
                                                            (element.type == "business_unit") ? (allBusinessUnits.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "project")?(allProjects.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "position")?(allPositions.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):(element.type == "employment_status")?(allEmploymentStatus.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):((element.type == "branch")?(allBranches.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):((element.type == "department")?(allDepartments.map( (x,y) => 
                                                                <option value={x.id} key={x.id}>{x.name}</option>
                                                            )):""))
                                                        }
                                                    </Form.Select>
                                                </InputGroup>
                                            </Col>
                                            <Col xs={2}>
                                                <Form.Label className="h6"></Form.Label>
                                                <InputGroup className="mb-3">
                                                <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, element)}>Remove</button>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    )
                                }
                                }):<p></p>}
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={props.submitEditMemo}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}