import React, { useState } from "react";
import { Modal, Row, Col, Form, Container, InputGroup} from "react-bootstrap";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import { toast } from "react-toastify";
import moment from "moment";
import * as Icon from 'react-bootstrap-icons';

// components
import Navbar from "../Navbar/Navbar"
import Dashboard from "../Dashboard/Dashboard"
import TableTemplate from '../../utilities/table_template';
import DeletePrompt from "../Modals/DeletePrompt"
import AddPosition from "./AddPosition";
import EditPosition from "./EditPosition";
import DeletePosition from "./DeletePosition";
import "../../utilities/loader_1.css"

// icons
import BadgeIcon from '@mui/icons-material/Badge';
import SearchIcon from "@mui/icons-material/Search";

// css
import './Position.css';
import '../Modals/modal.css'
import "../../utilities/common.css"

export default function Position() {
    const userToken = getToken();
    const userId = getUser();
    const [term, setTerm] = useState(false);
    const [loading, setLoading] = useState(true);

    // get all Positions
    const [allPositions, setAllPositions] = useState([]);
    const [filteredPositions, setFilteredPositions] = useState([]);

    React.useEffect(() => {
        allPositions.length = 0;

        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'positions/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                position_id: ""
            }
        }).then(response => {
            setAllPositions(response.data.data);
            setFilteredPositions(response.data.data);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            setLoading(false);
        });
    },[]);

    const [addPosition, setAddPosition] = useState(false);
    const handleAddPosition = () => setAddPosition(true);
    const handleCloseAddPosition = () => setAddPosition(false);

    const [editPosition, setEditPosition] = useState(false);
    const handleEditPosition = () => setEditPosition(true);
    const handleCloseEditPosition = () => setEditPosition(false);


    const [deletePosition, setDeletePosition] = useState(false);
    const handleDeletePosition = () => setDeletePosition(true);
    const handleCloseDeletePosition = () => setDeletePosition(false);


    function onClickAddPosition() {
        handleCloseAddPosition();
        handleAddPosition();
    }


    const [editPositionData, setEditPositionData] = useState({});

    function onClickEditPosition(position_id) {
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'positions/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                position_id: position_id
            }
        }).then(response => {
            setEditPositionData(response.data.data[0]);
            handleCloseEditPosition();
            handleEditPosition();
        }).catch(function (error) {
            console.log(error);
        });
    }

    const [deletePositionData, setDeletePositionData] = useState({});

    function onClickDeletePosition(position_id) {
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'get',
            url: window.$link + 'positions/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            params: {
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                position_id: position_id
            }
        }).then(response => {
            setDeletePositionData(response.data.data[0]);
            handleCloseDeletePosition();
            handleDeletePosition();
        }).catch(function (error) {
            console.log(error);
        });
    }

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allPositions) {
            allPositions.forEach(function(position) {
                if((position.name).toLowerCase().includes(term)) {
                    filteredArr.push(position);
                }
            })
        }

        if (term == "") {
            setFilteredPositions(allPositions);
        } else {
            setFilteredPositions(filteredArr);
        }
    }

    return (
        <div className='comm-bg'>
            <Navbar />
            <div className="comm-page-container">
                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col className='d-flex comm-header-position'>
                            Position   
                        </Col>
                        <Col className='d-flex justify-content-end me-3'>
                            <Row>
                                <Col className="me-5">
                                    <button className="export-button" onClick={onClickAddPosition}>
                                        <span className="export-text"><BadgeIcon/> Add Position</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>     
                    </Row>
                </Row>

                <Row className='mt-3'>
                    <InputGroup className="search-position">
                        <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                        <Form.Control
                            className = "search-part me-3"
                            type="text"
                            autoComplete="off"
                            aria-label="term" 
                            aria-describedby="term" 
                            placeholder="Search Position"
                            name="term" 
                            id='term'
                            onChange={(e) => searchFilter(e)}
                        />
                    </InputGroup>
                </Row>

                <AddPosition
                    showmodal1 = {addPosition}
                    hidemodal1 = {handleCloseAddPosition}
                    showmodal1handler = {onClickAddPosition}
                />

                <EditPosition
                    showmodal1 = {editPosition}
                    hidemodal1 = {handleCloseEditPosition}
                    showmodal1handler = {onClickEditPosition}
                    editPositionData = {editPositionData}
                />

                <DeletePosition  
                    showmodal1 = {deletePosition}
                    hidemodal1 = {handleCloseDeletePosition}
                    showmodal1handler = {onClickDeletePosition}
                    deletePositionData = {deletePositionData}
                />

                <div className="position-wrapper mt-4">
                {
                    (function() {
                         return !loading ? (<TableTemplate
                            tableType='position'
                            tableHeaders={["Position Name", ""]}
                            tableData={filteredPositions}
                            deleteHandler={onClickDeletePosition}
                            editHandler={onClickEditPosition}
                        />) : (
                            <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                                <div className="newtons-cradle__dot"></div>
                            </div>
                        )
                    })()
                }
                </div>
            </div>
        </div>
    );
}