import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import { validateNightDifferential } from '../../helpers/Validation/NightDifferentialValidation';
import InputError from '../../helpers/InputError/InpuError';

export default function EditNightdiff(props) {
    const userToken = getToken();
    const userId = getUser();
    const editData = props.editData;
    const [click, setClick] = useState(false);
    const [isError, setIsError] = useState({
        date: false,
        time_start: false,
        time_end: false,
        rate: false
    });
    
    
    // SUBMIT EDITED Nightdiff
    function submitEditNightdiff(e) {
        var axios = require('axios');
        var qs = require('qs');

        if (validateNightDifferential(editData, setIsError)==true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'night_differentials/update',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                night_differential_id: editData.id,
                date: editData.date,
                time_start: editData.time_start,
                time_end: editData.time_end,
                rate: editData.rate
            })
            })
           
            .then(function (response) {
                toast.success("Night Differential Updated Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                toast.error("Failed to Update Night Differential");
                tokenExpired(error);
                setClick(false);
            });
            
        }
    }

    function handle(e) {
        let editedNightdiffData = { ...editData };
        editedNightdiffData[e.target.id] = e.target.value;
        props.setEditData(editedNightdiffData);
    }

    function renderInputError(var_name) {
        let value = editData[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Edit Night Differential
                    </div>
                    
                    <div className='body-head no-scroll mt-2 p-0'>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Date<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={editData.date}
                                            className='input-field'
                                            type="date"
                                            aria-label="date" 
                                            aria-describedby="date" 
                                            placeholder="" 
                                            name="date" 
                                            id='date' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('date')}
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Rate (%)<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={editData.rate}
                                            className='input-field'
                                            type="number"
                                            aria-label="rate" 
                                            aria-describedby="rate" 
                                            placeholder="" 
                                            name="rate" 
                                            id='rate' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('rate')}
                                    </InputGroup>
                                    
                                </Col>
                                
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Time Start<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={editData.time_start}
                                            className='input-field'
                                            type="time"
                                            aria-label="time_start" 
                                            aria-describedby="time_start" 
                                            placeholder="" 
                                            name="time_start" 
                                            id='time_start' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('time_start')}
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Time End<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={editData.time_end}
                                            className='input-field'
                                            type="time"
                                            aria-label="time_end" 
                                            aria-describedby="time_end" 
                                            placeholder="" 
                                            name="time_end" 
                                            id='time_end' 
                                            onChange={(e) => handle(e)}
                                        />
                                    {renderInputError('time_end')}
                                    </InputGroup>
                                    
                                </Col>
                                
                            </Row>
                        </Container>
                    </div>
                    
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitEditNightdiff}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}