import {handleValidationChange} from '../CommonValidation';

export const validateCompanyFiles = (data, setIsError) => {
    var isValid = true;

    if(data.name === "") {
        handleValidationChange("name", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("name", false, setIsError);
    }

    if(data.description === "") {
        handleValidationChange("description", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("description", false, setIsError);
    }
    return isValid
  
}