import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import '../Modals/modal.css'
import {validateEmployee} from '../../helpers/Validation/Employee/EmployeeValidation'
import InputError from '../../helpers/InputError/InpuError';

export default function UpdateEmployeeStatus(props) {    

    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);
    const data = props.employeeData;
    const employeeStatus = props.employeeStatus;

    const [isError, setIsError] = useState({
        employee_status_id: false,
        employee_status_remarks: false
    })


    function submitEditEmployee(e) {
        var axios = require('axios');
        var qs = require('qs');
        
        if (validateEmployee(data, setIsError) === true && click===false) {
            axios({
            url: window.$link + 'employees/update_employee_status',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                employee_id: props.employeeId,
                employee_status_id: data.employee_status_id,
                employee_status_remarks: data.employee_status_remarks
            })
            })
            .then(function (response) {
                setClick(true);
                toast.success("Status Updated Successfully!");
                setTimeout(() => refreshPage(), 1500);
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Failed to Update Status");
                setClick(false);
            });
        }
    }

    function handle(e, element = null) {
        let newData = { ...data };
        newData[e.target.id] = e.target.value;
        props.setEditData(newData);
    }

    function renderInputError(var_name) {
        let value = data[var_name];
        if (!value) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    React.useEffect(() => {
        let newData = { ...data };
        const date1 = new Date(data.date_hired);
        const date2 = new Date(data.end_of_contract);
        if (date1 && date2) {
            var diffTime = date2.getTime() - date1.getTime();
            var diffDays = diffTime / (1000 * 3600 * 24);
            newData.no_of_days = Math.ceil(diffDays);
        } else {
             newData.no_of_days = null;
        }
    props.setEditData(newData);
    }, [data.date_hired, data.end_of_contract]);


    return(
        <div className='account-details'>
            <Modal show={props.show} onHide={props.hide} centered>
                <Modal.Body>
                    <div className="modal-header p-0">
                        Update Employment Status
                    </div>
                    
                    <div className='body-head no-scroll mt-2 p-0'>
                    <Container>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Employee</Form.Label>                                    
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.last_name +', '+ data.first_name}
                                            type="text"
                                            aria-label="last_name" 
                                            aria-describedby="last_name" 
                                            placeholder="Last Name"
                                            name="last_name" 
                                            id='last_name' 
                                            disabled
                                        />
                                    {renderInputError('last_name')}
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Form.Label className='h6' htmlFor="name">Employee Status <span className='red'>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                    <Form.Select 
                                        value={data.employee_status_id?data.employee_status_id:""}
                                        aria-label="employee_status_id"
                                        aria_describedby="employee_status_id"
                                        name="employee_status_id"
                                        id="employee_status_id"
                                        placeholder=''
                                        onChange={(e) => handle(e)}
                                    >
                                        <option value=''>Select Employee Status</option>
                                        {
                                            employeeStatus.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    {renderInputError('employee_status_id')}
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Remarks</Form.Label>                                    
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            value={data.employee_status_remarks}
                                            as = "textarea"
                                            aria-label="employee_status_remarks" 
                                            aria-describedby="employee_status_remarks" 
                                            placeholder=""
                                            name="employee_status_remarks" 
                                            id='employee_status_remarks' 
                                            onChange={(e) => handle(e)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hide}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitEditEmployee}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}