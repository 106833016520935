import React, { useState } from "react";
import { getUser, getToken, refreshPage, tokenExpired } from "../../utilities/common";
import {Modal, Row, Col, Form, Container, InputGroup } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

// components
import TableTemplate from "../../utilities/table_template";
import DeletePrompt from "../Modals/DeletePrompt";
import Navbar from "../Navbar/Navbar"
import AddSchedule from "./AddSchedule"
import { GetAllShifts, GetAllEmployees } from "../../helpers/CommonApi";
import { validateSchedule } from '../../helpers/Validation/ScheduleValidation';
import InputError from '../../helpers/InputError/InpuError'


// icons
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import SearchIcon from '@mui/icons-material/Search';
import UploadIcon from '@mui/icons-material/Upload';

//css
import './Schedule.css'
import '../../utilities/common.css'

export default function Schedule() {
    const userToken = getToken();
    const userId = getUser();
    const navigate = useNavigate();

    // Add Schedule Handler
    const [showAddSchedule, setShowAddSchedule] = useState(false); 
    const [click, setClick] = useState(false);
    // Edit Schedule Handler
    const [showEdit, setShowEdit] = useState(false);
    const [scheduleEdit, setScheduleEdit] = useState([]);
    // delete modal handler
    const [showDelete, setShowDelete] = useState(false);
    const [scheduleID, setScheduleID] = useState();
    const [loading, setLoading] = useState(true);
    // Get All Shifts & Employees
    const allShifts = GetAllShifts();
    const allEmployees = GetAllEmployees();
    const [allSchedules, setAllSchedules] = useState([]);
    const [filteredSchedules, setFilteredSchedules] = useState([]);
    const [editData, setEditData] = useState({
        shift_id: '',
        date_from: '',
        date_to: ''
    });
    

    function onClickDelete(id) {
        setScheduleID(id);
        setShowDelete(true);
    }

    // API Call for delete schedule/shift
    function handleRemove() {
        var axios = require('axios');
        var qs = require('qs');
        axios({
        url: window.$link + 'schedules/delete',
        method: 'post',
        headers: {
            "api-key": window.$api_key,
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
            requester: userId,
            token: userToken.replace(/['"]+/g, ''),
            schedule_id: scheduleID
        })
        })
        .then(function (response) {
            toast.success("Schedule Deleted Successfully!");
            setTimeout(refreshPage(), 1000);
        })
        .catch(function (error) {
            toast.error('Failed to Delete Schedule');
        });
    }

    // Validation
    const [isError, setIsError] = useState({
        shift_id: false,
        date_from: false,
        date_to: false
    });

    function isOnlyWhiteSpace (str) {
        return !str.trim();
    }

    function renderInputError(var_name) {
        let value = editData[var_name];
        if (!value || isOnlyWhiteSpace(value)===true) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    // Fetch schedule by ID for editing
    function onClickEdit(schedule_id) {
        setScheduleEdit(schedule_id);
        var axios = require('axios');
        var qs = require('qs');
        
        axios({
            method: 'post',
            url: window.$link + 'schedules/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                schedule_id: schedule_id,
                date_to: ''
            })
        }).then(response => {
            let schedule = response.data.data[0];
            setEditData(schedule);
            setShowEdit(true);
        }).catch(function (error) {
            tokenExpired(error);
            console.log(error);
        });
    }  

    // API call for edit schedule
    function submitEditSchedules(e) {
        e.preventDefault();
        var axios = require('axios');
        var qs = require('qs');
        if (validateSchedule(editData, setIsError)===true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'schedules/update',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                schedule_id: scheduleEdit,
                employee_id: editData.employee_id,
                shift_id: editData.shift_id,
                date_from: editData.date_from,
                date_to: editData.date_to
            })
            })
            .then(function (response) {
                toast.success("Schedule Edited Successfully!");
                setTimeout(() => refreshPage(), 1000);
            })
            .catch(function (error) {
                toast.error('Updating Error');
                tokenExpired(error);
                setClick(false);
            });
        }
    }

    // Update edited data in every change
    function handle(e) {
        const newData = { ...editData };
        newData[e.target.id] = e.target.value;
        setEditData(newData);
    }

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(allSchedules) {
            allSchedules.forEach(function(employee) {
                if((employee.full_name).toLowerCase().includes(term)) {
                    filteredArr.push(employee);
                }
            })
        }
        if(term == "") {
            setFilteredSchedules(allSchedules);
        } else {
            setFilteredSchedules(filteredArr);
        }
    }
    
    // Get all schedules
    React.useEffect(() => {
        var axios = require('axios');
        var qs = require('qs');

        allSchedules.length = 0; 
        filteredSchedules.length = 0;       
        axios({
            method: 'post',
            url: window.$link + 'schedules/get',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                schedule_id: '',
                date_to: ''
            })
        }).then(response => {
            const infoSorted = response.data.data.sort((a, b) => new Date(...a.date_from.split('/').reverse()) - new Date(...b.date_from.split('/').reverse()));
            infoSorted.map((schedule) => {
                var info = {};

                info.id = schedule.id;
                info.full_name = schedule.full_name;
                info.shift_name = schedule.shift_name;
                info.date_from = moment(schedule.date_from).format('MMM D, YYYY');
                info.date_to = moment(schedule.date_to).format('MMM D, YYYY');

                setAllSchedules(oldArray => [...oldArray, info]);
                setFilteredSchedules(oldArray => [...oldArray, info]);
                setLoading(false)
            })
        }).catch(function (error) {
            setLoading(false);
        });
    },[]);


    return(
        <div className="comm-bg">
            <Navbar />
            <div className="comm-page-container">

                <Row className="p-1" style={{    
                    width: "100%",
                    }}>
                    <Row className="m-0">
                        <Col xs={3} className='d-flex comm-header-employee'>
                            Schedules  
                        </Col>
                        <Col className="d-flex justify-content-end me-5">
                            <Row className="col-9 d-flex justify-content-end me-4">
                                <Col xs={3} className="me-5 ms-3">
                                    <button className="export-button py-2 px-0" onClick={() => setShowAddSchedule(true)}>
                                        <span className="export-text"><FormatListBulletedIcon/> Add Schedule</span>
                                    </button>
                                </Col>
                                <Col xs={3} className="ms-3">
                                    <button className="export-button py-2 px-0" onClick={() => navigate('schedule_batch_entry')}>
                                        <span className="export-text"><UploadIcon/>Upload</span>
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <InputGroup className="search-employee">
                            <InputGroup.Text className='icon-part'><SearchIcon/></InputGroup.Text>
                            <Form.Control
                                className = "search-part me-3"
                                type="text"
                                autoComplete="off"
                                aria-label="term" 
                                aria-describedby="term" 
                                placeholder="Search Employee"
                                name="term" 
                                id='term'
                                onChange={(e) => searchFilter(e)}
                            />
                        </InputGroup>
                    </Row>

                    <AddSchedule 
                        name="ADD" 
                        show={showAddSchedule} 
                        hide={() => setShowAddSchedule(false)}
                        allEmployees = {allEmployees}
                        allShifts = {allShifts}
                    />

                </Row>

                {/* CONTENT */}
                <div className="schedule-wrapper mt-4">
                    {
                        (function() {
                            return !loading ? (
                            <TableTemplate
                                tableType='schedule'
                                tableHeaders={["Employee Name", "Shift", "From", "To", ""]}
                                tableData={filteredSchedules}
                                deleteHandler={onClickDelete}
                                editHandler={onClickEdit}
                            />) : (
                                <div style={{marginTop:"15%"}} className="newtons-cradle position-relative start-50">
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                    <div className="newtons-cradle__dot"></div>
                                </div>
                            )
                        })()
                    }
                </div>

                    <DeletePrompt
                        name = "SCHEDULE"
                        show = {showDelete}
                        hide = {() => setShowDelete(false)}
                        remover = {handleRemove}
                    />

                    {/* Edit/Edit Schedule Modal */}
                    <Modal show={showEdit} onHide={() => setShowEdit(false)} size="m" centered>
                        <Form>
                            <Modal.Body>
                            <div className="modal-header p-0">
                                Edit Schedule
                            </div>
                            <button type="button" class="btn-close" onClick={() => setShowEdit(false)}>
                                <span class="icon-cross"></span>
                                <span class="visually-hidden">Close</span>
                            </button>
                                <div className='body-head'>
                                <Container>
                                <Row>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Employee</Form.Label>
                                        <InputGroup>
                                            <Form.Control 
                                                value={editData.full_name}
                                                type="text" 
                                                id="employee_name" 
                                                name="employee_name"
                                                className="mb-2"
                                                disabled
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Shift <span className='red'> *</span></Form.Label>
                                        <InputGroup>
                                            <Form.Select 
                                                defaultValue={editData.shift_id}
                                                name="shift_id" 
                                                id='shift_id' 
                                                className="mb-2" 
                                                onChange={(e) => handle(e)}
                                            >
                                                <option value={''}>---</option>
                                                {allShifts.map((data) => {
                                                    return (
                                                        <option value={data.id}>{data.name}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                            {renderInputError("shift_id")}
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Date From <span className='red'> *</span></Form.Label>
                                        <Form.Control
                                            defaultValue={editData.date_from}
                                            type="date" 
                                            id="date_from" 
                                            name="date_from" 
                                            className="mb-2" 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError("date_from")}
                                    </Col>
                                    <Col>
                                        <Form.Label className="h6" htmlFor="name">Date To <span className='red'> *</span></Form.Label>
                                        <Form.Control 
                                            value={editData.date_to}
                                            type="date" 
                                            id="date_to" 
                                            name="date_to" 
                                            className="mb-2" 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError("date_to")}
                                    </Col>
                                </Row>                               
                            </Container>
                                </div>
                                <div className='d-flex justify-content-end me-2 mt-4'>
                                    <button type="" className='cancel-button me-2' onClick={() => setShowEdit(false)}>Cancel</button>
                                    <button type="submit" className='save-button' onClick={submitEditSchedules}>Save</button>
                                </div>
                            </Modal.Body>
                        </Form>
                    </Modal>
            </div>
        </div>
    );
}