import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, Row, Col, Form, Container, Table, InputGroup } from 'react-bootstrap';
import {GetAllBusinessUnits, GetAllDepartments, GetAllProjects, GetAllPositions, GetAllBranches, GetAllEmploymentStatuses} from '../../helpers/CommonApi'
import { getUser, getToken, refreshPage } from '../../utilities/common';
import {toast} from 'react-toastify'
import { validateForm } from '../../helpers/Validation/FormValidation';
import InputError from '../../helpers/InputError/InpuError';

import Navbar from "../Navbar/Navbar"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

// css
import './Forms.css'
import '../../utilities/common.css'

export default function EditForms() {
    const location = useLocation();
    const navigate = useNavigate();
    const [click, setClick] = useState(false);
    const data = location.state;
    const form_details = data.form_details;
    const recipients = data.recipients;
    const question_set = data.question_set;

    const allBusinessUnits = GetAllBusinessUnits();
    const allProjects = GetAllProjects();
    const allDepartments = GetAllDepartments();
    const allBranches = GetAllBranches();
    const allPositions = GetAllPositions();
    const allEmploymentStatus = GetAllEmploymentStatuses();

    const [editedData, setEditedData] = useState({
        name: form_details.name,
        deadline: form_details.deadline,
        description: form_details.description,
        recipients: recipients,
        question_set: question_set,
        recipient_type_prime: recipients.length>0?recipients[0].type:'',
        form_to_prime: recipients.length>0?recipients[0].recipient:'',
        type_prime: question_set.length>0?question_set[0].type:'',
        question_prime:question_set.length>0?question_set[0].question:'',
        type: '',
        form_to: ''
    });

    const [isError, setIsError] = useState({
        name: false,
        description: false,
        deadline: false,
        recipient_type_prime: false,
        form_to_prime: false,
        type_prime: false,
        question_prime: false,
    });
    

    function handle (e, element=null, choice_index=null, question_index=null) {
        let newFormData = {...editedData};
        let questionIndex = question_index;
        if (e.target.id == "type") {
            let index = editedData["recipients"].map(t => t.id).indexOf(element.id);
            newFormData["recipients"][index][e.target.id] = e.target.value;
        } else if (e.target.id == "form_to") {
            let index = editedData["recipients"].map(t => t.id).indexOf(element.id);
            newFormData["recipients"][index].recipient = e.target.value;
        } else if (e.target.id == "type_prime") {
            let index = editedData["recipients"].map(t => t.id).indexOf(element.id);
            newFormData.recipient_type_prime = e.target.value;
            newFormData["recipients"][index].type = e.target.value;
        } else if (e.target.id == "form_to_prime") {
            let index = editedData["recipients"].map(t => t.id).indexOf(element.id);  
            newFormData.form_to_prime = e.target.value;
            newFormData["recipients"][index].recipient = e.target.value;
        } else if (e.target.id == "question_type" && element!=null) {
            newFormData.type_prime = e.target.value;
            newFormData["question_set"][questionIndex][e.target.id] = e.target.value;
        } else if (e.target.id == "question" && element!=null) {
            newFormData.question_prime = e.target.value;
            newFormData["question_set"][questionIndex][e.target.id] = e.target.value;
        }  else if (e.target.id == "choices") {
            newFormData["question_set"][questionIndex]["choices"][choice_index].option = e.target.value;
        } else {
            newFormData[e.target.id] = e.target.value;
        }
        setEditedData(newFormData);
    }


    function makeRandomID(){
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

        for(var i = 0; i < 32; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    function addQuestion(e) {
        var questions = editedData.question_set;
        questions.push({
            key: makeRandomID(),  
            id: makeRandomID(), 
            type: '', 
            question:'',
            choices: []
        });

        setEditedData((prev) => {
            return {
                ...prev,
                question_set: questions
            }
        })
    }

    function removeQuestion(e, element) {
        var recipients = editedData.question_set;
        for(let i=0;i<recipients.length;i++) {
            if(recipients[i].id == element.id) {
                recipients.splice(i, 1);
                break;
            }
        }

        if (!recipients.length || recipients[0].type==='') {
            editedData.type_prime = '';
            editedData.question_prime='';
        }

        setEditedData((prev) => {
            return {
                ...prev,
                question_set: recipients
            }
        })
    }

    function removeOption(e, element, index) {
        var recipients = editedData["question_set"][index]["choices"];
        for(let i=0;i<recipients.length;i++) {
            if (recipients[i].id == element.id) {
                recipients.splice(i, 1);
                break;
            }
        }

        var rec = data.question_set;

        setEditedData ((prev) => {
            return {
                ...prev, question_set: rec
            }
        })
    }

    function addOption(e, passed_data, index) {
        var recipients = editedData["question_set"][index]["choices"];
        recipients.push({
            key: makeRandomID(),  
            id: makeRandomID(),
            option: ''
        });
        var rec = data.question_set;

        setEditedData((prev) => {
            return {
                ...prev, question_set: rec
            }
        })
    }

    function addRecipient(e) {
        var recipients = editedData.recipients;

        recipients.push({
            key: makeRandomID(),  
            id: makeRandomID(), 
            type: '', 
            form_to:''
        });

        setEditedData((prev) => {
            return {
                ...prev,
                recipients: recipients
            }
        })
    }

    function removeRecipient(e, element) {
        var recipients = editedData.recipients;
        for(let i=0;i<recipients.length;i++) {
            if(recipients[i].id == element.id) {
                recipients.splice(i, 1);
                break;
            }
        }

        if (recipients.length===0 || recipients[0].type==='') {
            editedData.recipient_type_prime = '';
            editedData.form_to_prime='';
        }

        setEditedData((prev) => {
            return {
                ...prev,
                recipients: recipients
            }
        })
    }

    function isOnlyWhiteSpace (str) {
        return !str.trim();
    }
    
    function renderInputError(var_name) {
        var value = '';
        if (var_name=="recipient_type") {
            editedData.recipients.map((item) => {
                value = item[var_name];
            })
        } else if (var_name=="form_to") {
            editedData.recipients.map((item) => {
                value = item[var_name];
            })
        } else if (var_name=="type") {
            editedData.question_set.map((item) => {
                value = item[var_name];
            })
        } else if (var_name=="question") {
            editedData.question_set.map((item) => {
                value = item[var_name];
            })
        } else {
            value = editedData[var_name];
        }
        if (!value || isOnlyWhiteSpace(value)) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

     function submitEditedForm () {
        var axios = require('axios');
        var qs = require('qs');

        if (validateForm(editedData, setIsError)===true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'forms/update/' + form_details.id,
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: getUser(),
                token: getToken().replace(/['"]+/g, ''),
                name: editedData.name,
                deadline: editedData.deadline,
                description: editedData.description,
                types: editedData.recipients.map((t) => {return(t.type)}),
                recipients: editedData.recipients.map((t) => {return(t.recipient)}),
                question_types: editedData.question_set.map((t) => {return(t.question_type)}),
                questions: editedData.question_set.map((t) => {return(t.question)}),
                choices: editedData.question_set.map((t) => {return(t.choices&&t.choices.length ? t.choices : null)})
            })
            })
            .then(function (response) {
                setClick(true);
                toast.success("Form Updated Successfully!");
                setTimeout(() => {navigate(-1);
                setTimeout(() => refreshPage(), 1000)}, 1000);
            })
            .catch(function (error) {
                setClick(false);
                console.log(error);
                toast.error("Failed to Update Form");
                setClick(false);
            });
        }
    }


    return (
        <div className="comm-bg">
            <Navbar />
            <div className="comm-page-container">
                <Row className="" style={{    
                    width: "100%",
                    height: "3em",
                    }}>
                    <Row className="m-0">
                        <Col xs={8} className='d-flex comm-header-branch'>
                            Edit Evaluation Form   
                        </Col>
                    </Row>
                </Row>

                <div className="ebr-container-wrapper me-3 mt-3">
                    <Container className='px-5 pt-2'>
                        <Row>
                            <Col xs={6}>
                                <Form.Label className="h6" htmlFor="name">Form Name<span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        value={editedData.name}
                                        className='input-field'
                                        type="text"
                                        aria-label="name" 
                                        aria-describedby="name" 
                                        placeholder="" 
                                        name="name" 
                                        id='name' 
                                        onChange={(e) => handle(e,'','')}
                                    />
                                    {renderInputError('name')}
                                </InputGroup>
                            </Col>
                            <Col xs={6}>
                                <Form.Label className="h6" htmlFor="name">Deadline<span className='red'> *</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        value={editedData.deadline}
                                        className='input-field'
                                        type="datetime-local"
                                        aria-label="deadline" 
                                        aria-describedby="deadline" 
                                        placeholder="" 
                                        name="deadline" 
                                        id='deadline' 
                                        onChange={(e) => handle(e,'','')}
                                    />
                                    {renderInputError('deadline')}
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label className="h6" htmlFor="description">Description<span className='red'> *</span></Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    value={editedData.description}
                                    as="textarea"
                                    className='input-field'
                                    type="text"
                                    aria-label="description" 
                                    aria-describedby="description" 
                                    placeholder="" 
                                    name="description" 
                                    id='description' 
                                    onChange={(e) => handle(e,'','')}
                                />
                                {renderInputError('description')}
                            </InputGroup>
                        </Row>
                    </Container>
                </div>

                <div className="ebr-container-wrapper me-3 mt-3 p-3">
                    <Container>
                        {(editedData['recipients'] && editedData["recipients"].length) ? editedData["recipients"].map((element, i) => {
                            if (i===0) {
                                return (
                                <Row key={element.id}>
                                    <Col xs={5}>
                                        <Form.Label className="h6" htmlFor="type">Type <span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Select value={element.type?element.type:''} placeholder="Type" aria-describedby="type" aria-label="type" name="type_prime" id='type_prime' onChange={(e) => handle(e, element)}>
                                              <option value=""> --- </option>
                                              <option value="branch">Branch</option>
                                              <option value="business_unit">Business Unit</option>
                                              <option value="department">Department</option>
                                              <option value="employment_status">Employment Status</option>
                                              <option value="position">Position</option>
                                              <option value="project">Project</option>
                                            </Form.Select>
                                            {renderInputError('recipient_type_prime')}
                                        </InputGroup>
                                    </Col>
                                    <Col xs={5}>
                                        <Form.Label className="h6" htmlFor="form_to_prime">Form To <span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                value={element.recipient?element.recipient:''}
                                                as="select"
                                                aria-label="form_to_prime" 
                                                aria-describedby="form_to_prime" 
                                                placeholder="" 
                                                name="form_to_prime" 
                                                id='form_to_prime' 
                                                onChange={(e) => handle(e, element,'','')}
                                            >
                                              <option value=""> --- </option>
                                                {
                                                    (element.type == "business_unit") ? (allBusinessUnits.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(element.type == "project")?(allProjects.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(element.type == "position")?(allPositions.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(element.type == "employment_status")?(allEmploymentStatus.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):((element.type == "branch")?(allBranches.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):((element.type == "department")?(allDepartments.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):""))
                                                }
                                            </Form.Control>
                                            {renderInputError('form_to_prime')}
                                        </InputGroup>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Label className="h6"></Form.Label>
                                        <InputGroup className="mb-3">
                                        <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, element)}>Remove</button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                )
                            } else {
                                return (
                                <Row key={element.id}>
                                    <Col xs={5}>
                                        <Form.Label className="h6" htmlFor="type">Type</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Select value={element.type?element.type:''} placeholder="Type" aria-describedby="type" aria-label="type" name="type" id='type' onChange={(e) => handle(e, element)}>
                                              <option value=""> --- </option>
                                              <option value="branch">Branch</option>
                                              <option value="business_unit">Business Unit</option>
                                              <option value="department">Department</option>
                                              <option value="employment_status">Employment Status</option>
                                              <option value="position">Position</option>
                                              <option value="project">Project</option>
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col xs={5}>
                                        <Form.Label className="h6" htmlFor="form_to">Form To</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                value={element.recipient?element.recipient:''}
                                                as="select"
                                                aria-label="form_to" 
                                                aria-describedby="form_to" 
                                                placeholder="" 
                                                name="form_to" 
                                                id='form_to' 
                                                onChange={(e) => handle(e, element,'','')}
                                            >
                                              <option value=""> --- </option>
                                                {
                                                    (element.type == "business_unit") ? (allBusinessUnits.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(element.type == "project")?(allProjects.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(element.type == "position")?(allPositions.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):(element.type == "employment_status")?(allEmploymentStatus.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):((element.type == "branch")?(allBranches.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):((element.type == "department")?(allDepartments.map( (x,y) => 
                                                        <option value={x.id} key={x.id}>{x.name}</option>
                                                    )):""))
                                                }
                                            </Form.Control>
                                        </InputGroup>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Label className="h6"></Form.Label>
                                        <InputGroup className="mb-3">
                                        <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, element)}>Remove</button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            )
                            }
                            }
                            ):('')
                            }
                    <Row className='justify-content-end'>
                        <Col xs={2} className='me-4'>
                            <button id="addRow" className="add-button me-3" onClick={(e) => addRecipient(e)}>+ Add Recipient</button>
                        </Col>
                    </Row>
                    </Container>
                </div>

                <div className="create-form-container me-3 mt-3 p-3">
                    <Container>
                        {(editedData["question_set"] && editedData["question_set"].length) ? editedData["question_set"].map((element, i) => {
                            var question_index = i;
                            if (i===0) {
                                return (
                                <Row key={element.key}>
                                    <Col xs={3}>
                                        <Form.Label className="h6" htmlFor="type">Type <span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                defaultValue={element.question_type? element.question_type:''}
                                                as="select"
                                                aria-label="question_type" 
                                                aria-describedby="question_type" 
                                                placeholder="" 
                                                name="question_type" 
                                                id='question_type' 
                                                onChange={(e) => handle(e, element,'',question_index)}
                                            >
                                             <option value="">---</option>
                                              <option value="short">Short Answer</option>
                                              <option value="long">Paragraph</option>
                                              <option value="multiple_choice">Multiple Choice</option>
                                            </Form.Control>
                                            {renderInputError('type_prime')}
                                        </InputGroup>
                                    </Col>
                                    <Col xs={7}>
                                        <Form.Label className="h6" htmlFor="question">Question <span className='red'> *</span></Form.Label>
                                        <InputGroup className="mb-3">
                                            {
                                                (element.question_type == "short") ? (
                                                    <>
                                                    <Form.Control
                                                        defaultValue={element.question?element.question:''}
                                                        className='input-field'
                                                        type="text"
                                                        aria-label="question" 
                                                        aria-describedby="question" 
                                                        placeholder="Short answer question" 
                                                        name="question" 
                                                        id='question' 
                                                        onChange={(e) => handle(e, element, '',question_index)}
                                                    />
                                                    {renderInputError('question_prime')}
                                                    </>
                                                ):((element.question_type == "long") ? (
                                                    <>
                                                    <Form.Control
                                                        defaultValue={element.question?element.question:''}
                                                        className='input-field'
                                                        type="text"
                                                        aria-label="question" 
                                                        aria-describedby="question" 
                                                        placeholder="Unlimited answer question" 
                                                        name="question" 
                                                        id='question' 
                                                        onChange={(e) => handle(e, element, '', question_index)}
                                                    />
                                                    {renderInputError('question_prime')}
                                                    </>
                                                ): ((element.question_type == "multiple_choice") ? (
                                                <InputGroup>
                                                    <InputGroup>                                                        
                                                        <Form.Control
                                                            defaultValue={element.question?element.question:''}
                                                            className='input-field'
                                                            type="text"
                                                            aria-label="question" 
                                                            aria-describedby="question" 
                                                            placeholder="Multiple choice question" 
                                                            name="question" 
                                                            id='question' 
                                                            onChange={(e) => handle(e, element, '', question_index)}
                                                        />
                                                        {renderInputError('question_prime')}
                            
                                                        {
                                                            element['choices'].map((data, choice_index) => {
                                                                return (
                                                                    <InputGroup className='mt-2' key={data.key}>
                                                                        <Col xs={1} className='primary-color m-0 justify-content-center'><RadioButtonCheckedIcon/></Col>
                                                                        <Col xs={6} className='me-3'>
                                                                            <Form.Control
                                                                                defaultValue={data.choice?data.choice:''}
                                                                                className='input-field'
                                                                                type="text"
                                                                                aria-label="choices" 
                                                                                aria-describedby="choices" 
                                                                                placeholder="" 
                                                                                name="choices" 
                                                                                id='choices' 
                                                                                onChange={(e) => handle(e, data, choice_index, question_index)}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <Row className="mb-3">
                                                                                <Col xs={2}><button id="removeOption" className="trash-icon" onClick={(e) => removeOption(e, data, i)}><DeleteIcon/></button></Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </InputGroup>
                                                                    
                                                                )
                                                            })
                                                        }
                                                    </InputGroup>
                                                    <Row className="mb-3 mt-2">
                                                        <Col><button id="addOption" className="add-button" onClick={(e) => addOption(e, data, question_index)}>Add Option</button></Col>
                                                    </Row>
                                                </InputGroup>
                                                ):""))
                                            }
                                        </InputGroup>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Label className="h6"></Form.Label>
                                        <InputGroup className="mb-3">
                                            <button id="removeRow" className="delete-button" onClick={(e) => removeQuestion(e, element)}>Remove</button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                )
                            } else {
                                return (
                                <Row key={element.key}>
                                    <Col xs={3}>
                                        <Form.Label className="h6" htmlFor="type">Type</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                defaultValue={element.question_type? element.question_type:''}
                                                as="select"
                                                aria-label="question_type" 
                                                aria-describedby="question_type" 
                                                placeholder="" 
                                                name="question_type" 
                                                id='question_type' 
                                                onChange={(e) => handle(e, element,'',question_index)}
                                            >
                                             <option value="">---</option>
                                              <option value="short">Short Answer</option>
                                              <option value="long">Paragraph</option>
                                              <option value="multiple_choice">Multiple Choice</option>
                                            </Form.Control>
                                        </InputGroup>
                                    </Col>
                                    <Col xs={7}>
                                        <Form.Label className="h6" htmlFor="question">Question</Form.Label>
                                        <InputGroup className="mb-3">
                                            {
                                                (element.question_type == "short") ? (
                                                    <Form.Control
                                                        defaultValue={element.question?element.question:''}
                                                        className='input-field'
                                                        type="text"
                                                        aria-label="question" 
                                                        aria-describedby="question" 
                                                        placeholder="Short answer question" 
                                                        name="question" 
                                                        id='question' 
                                                        onChange={(e) => handle(e, element, '',question_index)}
                                                    />
                                                ):((element.question_type == "long") ? (
                                                    <Form.Control
                                                        defaultValue={element.question?element.question:''}
                                                        className='input-field'
                                                        type="text"
                                                        aria-label="question" 
                                                        aria-describedby="question" 
                                                        placeholder="Unlimited answer question" 
                                                        name="question" 
                                                        id='question' 
                                                        onChange={(e) => handle(e, element, '', question_index)}
                                                    />
                                                ): ((element.question_type == "multiple_choice") ? (
                                                <InputGroup>
                                                    <InputGroup>                                                        
                                                        <Form.Control
                                                            defaultValue={element.question?element.question:''}
                                                            className='input-field'
                                                            type="text"
                                                            aria-label="question" 
                                                            aria-describedby="question" 
                                                            placeholder="Multiple choice question" 
                                                            name="question" 
                                                            id='question' 
                                                            onChange={(e) => handle(e, element, '', question_index)}
                                                        />
                            
                                                        {
                                                            element['choices'].map((data, choice_index) => {
                                                                return (
                                                                    <InputGroup className='mt-2' key={data.key}>
                                                                        <Col xs={1} className='primary-color m-0 justify-content-center'><RadioButtonCheckedIcon/></Col>
                                                                        <Col xs={6} className='me-3'>
                                                                            <Form.Control
                                                                                defaultValue={data.choice?data.choice:''}
                                                                                className='input-field'
                                                                                type="text"
                                                                                aria-label="choices" 
                                                                                aria-describedby="choices" 
                                                                                placeholder="" 
                                                                                name="choices" 
                                                                                id='choices' 
                                                                                onChange={(e) => handle(e, data, choice_index, question_index)}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <Row className="mb-3">
                                                                                <Col xs={2}><button id="removeOption" className="trash-icon" onClick={(e) => removeOption(e, data, i)}><DeleteIcon/></button></Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </InputGroup>
                                                                    
                                                                )
                                                            })
                                                        }
                                                    </InputGroup>
                                                    <Row className="mb-3 mt-2">
                                                        <Col><button id="addOption" className="add-button" onClick={(e) => addOption(e, data, question_index)}>Add Option</button></Col>
                                                    </Row>
                                                </InputGroup>
                                                ):""))
                                            }
                                        </InputGroup>
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Label className="h6"></Form.Label>
                                        <InputGroup className="mb-3">
                                            <button id="removeRow" className="delete-button" onClick={(e) => removeQuestion(e, element)}>Remove</button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                )
                            }
                            }): ('')}
                    </Container>
                    <Row className='mt-2 justify-content-end '>
                    <Col xs={2} className='me-4'>
                        <button id="addRow" className="add-button me-4" onClick={(e) => addQuestion(e)}>+ Add Question</button>
                    </Col>
                </Row>
                </div>
                 <div className='d-flex justify-content-end me-3 mt-4'>
                    <button type="" className='cancel-button me-2' onClick={() => navigate(-1)}>Back</button>
                    <button type="submit" className='save-button' onClick={submitEditedForm}>Save</button>
                </div>
            </div>
        </div>
    );
}