import React, { useState } from 'react';
import {NavLink} from 'react-router-dom';
import { getRoleId, getUser } from '../../utilities/common';
import { removeUserSession, refreshPage } from '../../utilities/common';
import { Row} from 'react-bootstrap';
import { GetUserEmployeeId } from '../../helpers/CommonApi';


// icons
import Logo from "../../images/NewLogo.png";
import HomeIcon from '@mui/icons-material/Home'; // dashboard
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'; // payroll
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'; // attendance
import WatchLaterIcon from '@mui/icons-material/WatchLater'; // schedule
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'; // employee
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'; // leave
import WidgetsIcon from '@mui/icons-material/Widgets'; // manage
import ExitToAppIcon from '@material-ui/icons/ExitToApp'; // logout
import ExpandLessIcon from '@material-ui/icons/ExpandLess'; // caret up
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'; // caret down
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Transition } from 'react-transition-group';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EmailIcon from '@mui/icons-material/Email';
import CampaignIcon from '@mui/icons-material/Campaign';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CreditScoreIcon from '@mui/icons-material/CreditScore'; //loan
import AddCardIcon from '@mui/icons-material/AddCard'; // contribution
import Requirements from '../Requirements/Requirements';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import "../../utilities/common.css"

// css
import './Navbar.css'


function EmployeeNavbar() {
    const [showManage, setShowManage] = useState(false);
    const [inactive, setInactive] = useState(true);
    const userId = getUser();
    const userEmployeeId = GetUserEmployeeId();
    const [showContribution, setShowContribution] = useState(false);

    return (  
        <div onClick={() => setInactive(!inactive)} class="hamburger-menu">
        <input id="menu__toggle" type="checkbox" />
        <label class="menu__btn" for="menu__toggle">
        </label>
        <div className = "sidenav d-print-none">
            {/* logo */}
            <Row className="logo pt-3 pb-3 p-0 m-0" style={{width:"100%"}}>
                <img src={Logo} className ='logoSize' alt='Logo' />
            </Row>

            {/* tabs */}
            <NavLink style={{transition: "all .3s"}} to = "/dashboard" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <HomeIcon fontSize='medium' className='content-icon'/>
                    <span>DASHBOARD</span>
                </div>
            </NavLink>

             <NavLink style={{transition: "all .3s"}} to ="/employee/view" state={userEmployeeId} className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <PersonIcon fontSize='medium' className='content-icon'/>
                    <span>VIEW PROFILE</span>
                </div>
            </NavLink>

            <NavLink style={{transition: "all .3s"}} to = "/payroll" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <PointOfSaleIcon fontSize='medium' className='content-icon'/>
                    <span>PAYROLL</span>
                </div>
            </NavLink>
      
            <NavLink style={{transition: "all .3s"}} to = "/leave" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <MeetingRoomIcon fontSize='medium' className='content-icon'/>
                    <span>LEAVE</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/work_from_anywhere" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <MeetingRoomIcon fontSize='medium' className='content-icon'/>
                    <span>WFA</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/forms" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <QuestionAnswerIcon fontSize='medium' className='content-icon'/>
                    <span>FORMS</span>
                </div>
            </NavLink>

            <NavLink style={{transition: "all .3s"}} to = "/loan" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <CreditScoreIcon fontSize='medium' className='content-icon'/>
                    <span>LOAN</span>
                </div>
            </NavLink>

            <div className='content' onClick={(e) => setShowContribution(!showContribution)}>
                <AddCardIcon fontSize='medium' className='content-icon'/>
                <span>CONTRIBUTION</span>
                {!showContribution ? 
                    <ExpandMoreIcon fontSize='medium' className='caret'/> :
                    <ExpandLessIcon fontSize='medium' className='caret'/>
                }
            </div>

            {showContribution &&
            <div>
                 <NavLink style={{transition: "all .3s"}} to = "/philhealth" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> PHILHEALTH</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/sss" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> SSS</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/pagibig" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> PAG-IBIG</span>
                    </p>
                </NavLink>
                {/* <NavLink style={{transition: "all .3s"}} to = "/coop" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> COOP</span>
                    </p>
                </NavLink> */}
                <NavLink style={{transition: "all .3s"}} to = "/taxwithheld" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> TAX WITHHELD</span>
                    </p>
                </NavLink>
            </div>    
            }
            
            <NavLink style={{transition: "all .3s"}} to = "/">
                <div className='content' onClick={() => {removeUserSession(); refreshPage();}}>
                    <ExitToAppIcon fontSize='medium' className='logout-icon'/>
                    <span className="">logout</span>
                </div>
            </NavLink>
        </div>

    <ul class={`menu__box ${inactive ? "inactive" : ""}`}>
    <div className = "logo pt-0 pb-3 p-0 m-0" style={{width:"100%"}}>
                <img src = {Logo} className = 'logoSize' alt = 'Logo' />
            </div>
            <NavLink style={{transition: "all .3s"}} to = "/dashboard" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <HomeIcon fontSize='medium' className='content-icon'/>
                    <span>DASHBOARD</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to ="/employee/view" state={userEmployeeId} className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <PersonIcon fontSize='medium' className='content-icon'/>
                    <span>VIEW PROFILE</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/payroll" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <PointOfSaleIcon fontSize='medium' className='content-icon'/>
                    <span>PAYROLL</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/leave" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <MeetingRoomIcon fontSize='medium' className='content-icon'/>
                    <span>LEAVE</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/forms" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <QuestionAnswerIcon fontSize='medium' className='content-icon'/>
                    <span>FORMS</span>
                </div>
            </NavLink>

            <NavLink style={{transition: "all .3s"}} to = "/loan" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <CreditScoreIcon fontSize='medium' className='content-icon'/>
                    <span>LOAN</span>
                </div>
            </NavLink>

            <div className='content' onClick={(e) => setShowContribution(!showContribution)}>
                <AddCardIcon fontSize='medium' className='content-icon'/>
                <span>CONTRIBUTION</span>
                {!showContribution ? 
                    <ExpandMoreIcon fontSize='medium' className='caret'/> :
                    <ExpandLessIcon fontSize='medium' className='caret'/>
                }
            </div>

            {showContribution &&
            <div>
                 <NavLink style={{transition: "all .3s"}} to = "/philhealth" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> PHILHEALTH</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/sss" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> SSS</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/pagibig" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> PAG-IBIG</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/coop" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> COOP</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/taxwithheld" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> TAX WITHHELD</span>
                    </p>
                </NavLink>
            </div>    
            }
            
            <NavLink style={{transition: "all .3s"}} to = "/">
                <div className='content' onClick={() => {removeUserSession(); refreshPage();}}>
                    <ExitToAppIcon fontSize='medium' className='logout-icon'/>
                    <span className="">logout</span>
                </div>
            </NavLink>
            </ul>
        </div>
    );
};

function AdminNavbar() {
    const [showManage, setShowManage] = useState(false);
    const [inactive, setInactive] = useState(true);
    const [showManageEmployee, setShowManageEmployee] = useState(false);
    const [showManageSystem, setShowManageSystem] = useState(false);
    const [showContribution, setShowContribution] = useState(false);

    return (  
        <div onClick={() => setInactive(!inactive)} class="hamburger-menu">
        <input id="menu__toggle" type="checkbox" />
        <label class="menu__btn" for="menu__toggle">
        </label>
        <div className = "sidenav d-print-none">
            {/* logo */}
            <Row className="logo pt-3 pb-3 p-0 m-0" style={{width:"100%"}}>
                <img src={Logo} className ='' alt='Logo' style={{width:"75%"}} />
            </Row>

            {/* tabs */}
            <NavLink style={{transition: "all .3s"}} to = "/dashboard" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <HomeIcon fontSize='medium' className='content-icon'/>
                    <span>DASHBOARD</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/payroll" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <PointOfSaleIcon fontSize='medium' className='content-icon'/>
                    <span>PAYROLL</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/thirteenth_month_report" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <PointOfSaleIcon fontSize='medium' className='content-icon'/>
                    <span>13TH MONTH REPORT</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/aub" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <AccountBalanceIcon fontSize='medium' className='content-icon'/>
                    <span>AUB</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/attendance" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <CalendarMonthIcon fontSize='medium' className='content-icon'/>
                    <span>TIMESHEET</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/schedule" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <WatchLaterIcon fontSize="medium" className='content-icon'/>
                    <span>SCHEDULE</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/memo" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <EventNoteIcon fontSize='medium' className='content-icon'/>
                    <span>Memo</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/announcement" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <CampaignIcon fontSize='medium' className='content-icon'/>
                    <span>Announcement</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/communication_letters" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <EmailIcon fontSize='medium' className='content-icon'/>
                    <span>Communication</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/leave" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <MeetingRoomIcon fontSize='medium' className='content-icon'/>
                    <span>LEAVE</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/work_from_anywhere" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <MeetingRoomIcon fontSize='medium' className='content-icon'/>
                    <span>WFA</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/forms" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <QuestionAnswerIcon fontSize='medium' className='content-icon'/>
                    <span>FORMS</span>
                </div>
            </NavLink>

            <NavLink style={{transition: "all .3s"}} to = "/loan" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <CreditScoreIcon fontSize='medium' className='content-icon'/>
                    <span>LOAN</span>
                </div>
            </NavLink>

            <div className='content' onClick={(e) => setShowContribution(!showContribution)}>
                <AddCardIcon fontSize='medium' className='content-icon'/>
                <span>CONTRIBUTION</span>
                {!showContribution ? 
                    <ExpandMoreIcon fontSize='medium' className='caret'/> :
                    <ExpandLessIcon fontSize='medium' className='caret'/>
                }
            </div>

            {showContribution &&
            <div>
                 <NavLink style={{transition: "all .3s"}} to = "/philhealth" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> PHILHEALTH</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/sss" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> SSS</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/pagibig" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> PAG-IBIG</span>
                    </p>
                </NavLink>
                {/* <NavLink style={{transition: "all .3s"}} to = "/coop" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> COOP</span>
                    </p>
                </NavLink> */}
                <NavLink style={{transition: "all .3s"}} to = "/taxwithheld" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> TAX WITHHELD</span>
                    </p>
                </NavLink>
            </div>    
            }

            <div className='content' onClick={(e) => setShowManageEmployee(!showManageEmployee)}>
                <GroupsIcon fontSize='medium' className='content-icon'/>
                <span>MANAGE EMPLOYEE</span>
                {!showManageEmployee ? 
                    <ExpandMoreIcon fontSize='medium' className='caret'/> :
                    <ExpandLessIcon fontSize='medium' className='caret'/>
                }
            </div>

            {showManageEmployee &&
            <div>
                 <NavLink style={{transition: "all .3s"}} to = "/employee" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> EMPLOYEE</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/employee_deductions" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p>EMPLOYEE DEDUCTIONS</p>
                    </div>
                </NavLink>
               <NavLink style={{transition: "all .3s"}} to = "/employmentstatus" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> EMPLOYMENT STATUS</p>
                    </div>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/employee_status" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> EMPLOYEE STATUS</p>
                    </div>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/employee_leave_credits" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> LEAVE CREDITS</p>
                    </div>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/employee_wfa_credits" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> WFA CREDITS</p>
                    </div>
                </NavLink>
            </div>    
            }
            
            <div className='content' onClick={(e) => setShowManageSystem(!showManageSystem)}>
                <WidgetsIcon fontSize='medium' className='content-icon'/>
                <span>MANAGE SYSTEM</span>
                {!showManageSystem ? 
                    <ExpandMoreIcon fontSize='medium' className='caret'/> :
                    <ExpandLessIcon fontSize='medium' className='caret'/>
                }
            </div>

            {showManageSystem &&
            <div>
                 <NavLink style={{transition: "all .3s"}} to = "/branch" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> Branch</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/company_files" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> Company Files</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/leave_type" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> Leave Type</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/letter_type" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> Letter Type</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/project" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> PROJECT</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/department" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> DEPARTMENT</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/position" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> POSITION</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/holidays" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> HOLIDAY</p>
                    </div>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/shifts" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> SHIFT</p>
                    </div>
                </NavLink>
                {/* <NavLink style={{transition: "all .3s"}} to = "/deductions" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> DEDUCTION</p>
                    </div>
                </NavLink> */}
                <NavLink style={{transition: "all .3s"}} to = "/businessunit" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> BUSINESS UNIT</p>
                    </div>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/requirements" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> REQUIREMENTS</p>
                    </div>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/night_differential" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> NIGHT DIFFERENTIAL</p>
                    </div>
                </NavLink>
                </div>    
            }
            
            <NavLink style={{transition: "all .3s"}} to = "/">
                <div className='content' onClick={() => {removeUserSession(); refreshPage();}}>
                    <ExitToAppIcon fontSize='medium' className='logout-icon'/>
                    <span className="">logout</span>
                </div>
            </NavLink>
        </div>

    <ul class={`menu__box ${inactive ? "inactive" : ""}`}>
    <div className = "logo pt-0 pb-3 p-0 m-0" style={{width:"100%"}}>
                <img src = {Logo} className = 'logoSize' alt = 'Logo' />
            </div>
            <NavLink style={{transition: "all .3s"}} to = "/dashboard" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <HomeIcon fontSize='medium' className='content-icon'/>
                    <span>DASHBOARD</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/payroll" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <PointOfSaleIcon fontSize='medium' className='content-icon'/>
                    <span>PAYROLL</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/thirteenth_month_report" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <PointOfSaleIcon fontSize='medium' className='content-icon'/>
                    <span>13TH MONTH REPORT</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/aub" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <AccountBalanceIcon fontSize='medium' className='content-icon'/>
                    <span>AUB</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/attendance" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <CalendarMonthIcon fontSize='medium' className='content-icon'/>
                    <span>TIMESHEET</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/schedule" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <WatchLaterIcon fontSize="medium" className='content-icon'/>
                    <span>SCHEDULE</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/memo" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <EventNoteIcon fontSize='medium' className='content-icon'/>
                    <span>Memo</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/announcement" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <CampaignIcon fontSize='medium' className='content-icon'/>
                    <span>Announcement</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/communication_letters" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <EmailIcon fontSize='medium' className='content-icon'/>
                    <span>Communication</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/leave" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <MeetingRoomIcon fontSize='medium' className='content-icon'/>
                    <span>LEAVE</span>
                </div>
            </NavLink>
            <NavLink style={{transition: "all .3s"}} to = "/forms" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <QuestionAnswerIcon fontSize='medium' className='content-icon'/>
                    <span>FORMS</span>
                </div>
            </NavLink>

            <NavLink style={{transition: "all .3s"}} to = "/loan" className={({isActive}) => (isActive ? "content content-selected": "content")}>
                <div>
                    <CreditScoreIcon fontSize='medium' className='content-icon'/>
                    <span>LOAN</span>
                </div>
            </NavLink>

            <div className='content' onClick={(e) => setShowContribution(!showContribution)}>
                <AddCardIcon fontSize='medium' className='content-icon'/>
                <span>CONTRIBUTION</span>
                {!showContribution ? 
                    <ExpandMoreIcon fontSize='medium' className='caret'/> :
                    <ExpandLessIcon fontSize='medium' className='caret'/>
                }
            </div>
            
            {showContribution &&
            <div>
                 <NavLink style={{transition: "all .3s"}} to = "/philhealth" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> PHILHEALTH</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/sss" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> SSS</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/pagibig" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> PAG-IBIG</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/coop" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> COOP</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/taxwithheld" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> TAX WITHHELD</span>
                    </p>
                </NavLink>
            </div>    
            }

            <div className='content' onClick={(e) => setShowManageEmployee(!showManageEmployee)}>
                <GroupsIcon fontSize='medium' className='content-icon'/>
                <span>MANAGE EMPLOYEE</span>
                {!showManageEmployee ? 
                    <ExpandMoreIcon fontSize='medium' className='caret'/> :
                    <ExpandLessIcon fontSize='medium' className='caret'/>
                }
            </div>

            {showManageEmployee &&
            <div>
                 <NavLink style={{transition: "all .3s"}} to = "/employee" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                        <span> EMPLOYEE</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/employee_deductions" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p>EMPLOYEE DEDUCTIONS</p>
                    </div>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/employmentstatus" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> EMPLOYMENT STATUS</p>
                    </div>
                </NavLink>
            </div>    
            }
            
            <div className='content' onClick={(e) => setShowManageSystem(!showManageSystem)}>
                <WidgetsIcon fontSize='medium' className='content-icon'/>
                <span>MANAGE SYSTEM</span>
                {!showManageSystem ? 
                    <ExpandMoreIcon fontSize='medium' className='caret'/> :
                    <ExpandLessIcon fontSize='medium' className='caret'/>
                }
            </div>

            {showManageSystem &&
            <div>
                 <NavLink style={{transition: "all .3s"}} to = "/branch" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                         {/* <AccountBalanceIcon fontSize='medium' className='content-icon'/> */}
                        <span> Branch</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/project" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                         {/* <AccountBalanceIcon fontSize='medium' className='content-icon'/> */}
                        <span> PROJECT</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/department" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                         {/* <AccountBalanceIcon fontSize='medium' className='content-icon'/> */}
                        <span> DEPARTMENT</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/position" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <p>
                         {/* <AccountBalanceIcon fontSize='medium' className='content-icon'/> */}
                        <span> POSITION</span>
                    </p>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/holidays" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> HOLIDAYS</p>
                    </div>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/shifts" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> SHIFTS</p>
                    </div>
                </NavLink>
                {/* <NavLink style={{transition: "all .3s"}} to = "/deductions" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> DEDUCTIONS</p>
                    </div>
                </NavLink> */}
                <NavLink style={{transition: "all .3s"}} to = "/businessunit" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> BUSINESS UNIT</p>
                    </div>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/requirements" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> REQUIREMENTS</p>
                    </div>
                </NavLink>
                <NavLink style={{transition: "all .3s"}} to = "/night_differential" className={({isActive}) => (isActive ? "dropdown dropdown-selected": "dropdown")}>
                    <div>
                        <p> NIGHT DIFFERENTIAL</p>
                    </div>
                </NavLink>
                </div>    
            }
            
            <NavLink style={{transition: "all .3s"}} to = "/">
                <div className='content' onClick={() => {removeUserSession(); refreshPage();}}>
                    <ExitToAppIcon fontSize='medium' className='logout-icon'/>
                    <span className="">logout</span>
                </div>
            </NavLink>
            </ul>
        </div>
    );
};

export default function mainNavbar () {
    const userRoleId = getRoleId();

    if (userRoleId === '1' || userRoleId === '2') {
        return AdminNavbar()
    } else {
        return EmployeeNavbar()
    }
}