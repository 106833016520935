import {handleValidationChange} from '../CommonValidation';

export const validateHoliday = (data, setIsError) => {
    var isValid = true;

    if(data.name === "") {
        handleValidationChange("name", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("name", false, setIsError);
    }

    if(data.date === "") {
        handleValidationChange("date", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("date", false, setIsError);
    }

    if(data.type === "") {
        handleValidationChange("type", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("type", false, setIsError);
    }

    return isValid
  
}