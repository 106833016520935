import React from "react";
import { useRef, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";
import { refreshPage } from "../../utilities/common";
import pako from "pako";

// Components
import Navbar from "../Navbar/Navbar";
import { getToken, getUser, tokenExpired } from "../../utilities/common";
import SendEmailPrompt from "../Modals/SendEmailPrompt";

// Image
import NewLogo from "../../images/NewLogo.png";
import PrintIcon from "@mui/icons-material/Print";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

function EmployeePayslip() {
  var converter = require("number-to-words");
  const date_today = new Date();
  const componentRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const userId = getUser();
  const userToken = getToken();
  const [payrollDetails, setPayrollDetails] = useState({});
  const searchParams = new URLSearchParams(window.location.search);
  const payrollId = searchParams.get("id");

  const [allEarningsValues, setAllEarningsValues] = useState([]);
  const [allDeductionValues, setAllDeductionValues] = useState([]);
  const allEarningsLabels = [
    "Basic Pay",
    "Holiday Pay",
    "Night Differential",
    "Overtime",
    "Regular OT",
    "Regular Pay",
    "Restday OT",
    "Monthly",
    "Semi-monthly",
    "Salary Adjustment",
    "Special Holiday Pay",
    "Communication Allowance",
    "Daily Allowance",
    "Food Allowance",
    "HMO Allowance",
    "OPS Allowance",
    "Special Allowance",
    "Tech Allowance",
    "Transpo Allowance",
    "Training Allowance (F2F)",
    "Training Allowance (Virtual)",
    "Grocery Allowance",
    "Entertainment Allowance",
    "Medicine Allowance",
    "Uniform Allowance",
    "Thirteenth Month",
  ];
  const allDeductionLabels = [
    "Absent",
    "PhilHealth Contribution",
    "PhilHealth Loans",
    "PAGIBIG Contribution",
    "PAGIBIG Loans",
    "SSS Contribution",
    "SSS Loans",
    "SSS Calamity Loans",
    "SSS Salary Loans",
    "Calamity Loans",
    "Company Loans",
    "Cooperative Loans",
    "Cooperative Savings",
    "HMO Maxicare",
    "Late",
    "AXA",
    "Phone Loan",
    "Laptop Loan",
    "Cash Advance",
    "Undertime",
    "Withholding Tax",
    "Coop",
    "Others",
    "Intellicare Amount",
  ];
  const [totalEarnings, setTotalEarnings] = useState(null);
  const [totalDeductions, setTotalDeductions] = useState(null);
  const [netPay, setNetPay] = useState(null);
  const [wordedNetPay, setWordedNetPay] = useState(null);
  const [showEmailPromt, setShowEmailPrompt] = useState(false);
  const [sendClicked, setSendClicked] = useState(false);

  function SentenceCaseName(name) {
    const sentenceCasedName = name
      .toLowerCase()
      .replace(/(^|\s)[a-z]/g, (char) => char.toUpperCase());
    return sentenceCasedName;
  }

  function handleClickSend() {
    setShowEmailPrompt(true);
  }

  // FUNCTION TO SEND LETTER TO EMAIL
  function handleSendEmail() {
    const pdf = new jsPDF();
    html2canvas(componentRef.current, {
      ignoreElements: function (element) {
        return element.classList.contains("exclude");
      },
    }).then(function (canvas) {
      const imgData = canvas.toDataURL("image/jpeg", 0.4);
      pdf.addImage(imgData, "PNG", 10, 10, 180, 170, "", "FAST");
      const pdfData = pdf.output("arraybuffer");
      const pdfArray = new Uint8Array(pdfData);
      const pdfBlob = new Blob([pdfArray], { type: "application/pdf" });
      sendEmail(pdfBlob);
    });
  }

  function sendEmail(pdfBlob) {
    if (sendClicked === false) {
      var axios = require("axios");
      var FormData = require("form-data");
      var data = new FormData();

      data.append("token", userToken.replace(/['"]+/g, ""));
      data.append("requester", userId);
      data.append("date_from", payrollDetails.date_from);
      data.append("date_to", payrollDetails.date_to);
      data.append("payslip_file", pdfBlob, "employee_payslip.pdf");
      data.append("email", payrollDetails.email);

      axios({
        url: window.$link + "payrolls/send",
        method: "post",
        headers: {
          "api-key": window.$api_key,
          "Content-Type": "multipart/form-data",
        },
        data: data,
      })
        .then(function (response) {
          setSendClicked(true);
          toast.success("Payroll Sent Successfully!");
          setTimeout(() => refreshPage(), 500);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  // GET PAYROLL BY ID
  React.useEffect(() => {
    const userToken = getToken();
    const userId = getUser();

    var axios = require("axios");
    var qs = require("qs");

    axios({
      method: "post",
      url: window.$link + "payrolls/get",
      headers: {
        "api-key": window.$api_key,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        requester: userId,
        token: userToken.replace(/['"]+/g, ""),
        payroll_id: payrollId,
      }),
    })
      .then((response) => {
        const payroll = response.data.data[0];
        var earningValues = [];
        var deductionValues = [];
        var info = payroll;
        info.id = payroll.id;
        var salary = Number(payroll.basic_pay);
        info.salary = salary;
        info.date_from = payroll.date_from;
        info.date_to = payroll.date_to;
        earningValues.push(
          payroll.basic_pay,
          payroll.holiday_pay,
          payroll.night_differential,
          payroll.overtime,
          payroll.regular_ot,
          payroll.regular_pay,
          payroll.restday_ot,
          payroll.monthly,
          payroll.semi_monthly,
          payroll.salary_adj,
          payroll.special_holiday_pay,
          payroll.comm_allowance,
          payroll.daily_allowance,
          payroll.food_allowance,
          payroll.hmo_allowance,
          payroll.ops_allowance,
          payroll.special_allowance,
          payroll.tech_allowance,
          payroll.transpo_allowance,
          payroll.training_allowance_f2f,
          payroll.training_allowance_virtual,
          payroll.grocery_allowance,
          payroll.entertainment_allowance,
          payroll.medicine_allowance,
          payroll.uniform_allowance
        );
        deductionValues.push(
          payroll.absent,
          payroll.philhealth_contribution,
          payroll.philhealth_loans,
          payroll.pagibig_contribution,
          payroll.pagibig_loan,
          payroll.sss_contribution,
          payroll.sss_loans,
          payroll.sss_calamity_loan,
          payroll.sss_salary_loan,
          payroll.calamity_loan,
          payroll.company_loan,
          payroll.cooperative_loans,
          payroll.cooperative_savings,
          payroll.hmo_maxicare,
          payroll.late,
          payroll.axa_amount,
          payroll.phone_loan,
          payroll.laptop_loan,
          payroll.cash_advance,
          payroll.undertime,
          payroll.wth_tax,
          payroll.coop,
          payroll.others,
          payroll.intellicare_amount
        );

        if (payroll.is_thirteenth_included === "1") {
          earningValues.push(payroll.thirteenth_month);
        }

        setPayrollDetails(info);
        setAllEarningsValues(earningValues);
        setAllDeductionValues(deductionValues);
        var total_earning = 0;
        earningValues.forEach((item) => {
          total_earning += Number(item);
        });
        setTotalEarnings(total_earning);
        var total_deduction = 0;
        deductionValues.forEach((item) => {
          if (item) {
            total_deduction += Number(item);
          }
        });
        setTotalDeductions(total_deduction);
        var net_pay = Number(total_earning) - Number(total_deduction);
        setNetPay(net_pay);
        const converted = converter.toWords(net_pay);
        setWordedNetPay(converted);
      })
      .catch(function (error) {
        console.log(error);
        tokenExpired(error);
      });
  }, []);

  return (
    <div className="comm-page">
      <Navbar />
      <div className="comm-page-container no-margin-top">
        <div
          className="payslip-container me-3 mt-0 mb-2 payslip"
          ref={componentRef}
          id="html-content"
        >
          <div className="bordered-container p-1">
            <div className="bordered-container px-3 pb-4 pt-0">
              <table className="table table-bordered p-3 square-border mt-4">       
                <Row>
                  <Col xs={9} className="center">
                    <Row className="employeePayslip-header d-flex justify-content-center">
                      SALARY SLIP
                    </Row>
                    <Row className="bold justify-content-center top-border">
                      {moment(payrollDetails.date_from).format("MMM D, YYYY")} -{" "}
                      {moment(payrollDetails.date_to).format("MMM D, YYYY")}
                    </Row>
                  </Col>
                  <Col className="confidential-header d-flex justify-content-center confidential-container">
                    CONFIDENTIAL
                  </Col>
                </Row>

                <table className="table table-bordered" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="w-50">
                        <tr>
                          <td>Employee Name</td>
                          <td className="px-5"> : </td>
                          <td>{payrollDetails.employee_name? SentenceCaseName(payrollDetails.employee_name): ""}</td>
                        </tr>
                        <tr>
                          <td>Employee ID</td>
                          <td className="px-5"> : </td>
                          <td>{payrollDetails.employee_id? payrollDetails.employee_id: ""}</td>
                        </tr>
                      </td>
                      <td>
                        <tr>
                          <td>Position</td>
                          <td className="px-5"> : </td>
                          <td>{payrollDetails.position_name? SentenceCaseName(payrollDetails.position_name): ""}</td>
                        </tr>
                        <tr>
                          <td>Status of Employment</td>
                          <td className="px-5"> : </td>
                          <td>{payrollDetails.employment_status? SentenceCaseName(payrollDetails.employment_status): ""}</td>
                        </tr>
                        <tr>
                          <td>Department</td>
                          <td className="px-5"> : </td>
                          <td>{payrollDetails.department_name? SentenceCaseName(payrollDetails.department_name): ""}</td>
                        </tr>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Row className="">
                  <Col>
                    <table className="table table-bordered">
                      <thead>
                        <tr className="payslip-primary-bg text-center">
                          <th className="w-50">Description</th>
                          <th>Earnings</th>
                          <th>Deductions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="w-50">
                            {allEarningsValues.map((val, index) => {
                              if (parseFloat(val) > 0) {
                                return (
                                  <tr>
                                    <td>{allEarningsLabels[index]}</td>
                                  </tr>
                                );
                              }
                            })}
                            {allDeductionValues.map((val, index) => {
                              if (parseFloat(val) > 0) {
                                return (
                                  <tr>
                                    <td className="text-right">
                                      {allDeductionLabels[index]}
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </td>
                          <td>
                            {allEarningsValues.map((val, index) => {
                              if (parseFloat(val) > 0) {
                                return (
                                  <tr className="d-flex justify-content-end">
                                    <td>
                                      {Number(val).toLocaleString(
                                        navigator.language,
                                        { minimumFractionDigits: 2 }
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </td>
                          <td>
                            {allEarningsValues.map((val, index) => {
                              if (parseFloat(val) > 0) {
                                return (
                                  <tr className="d-flex justify-content-end">
                                    <td className="white">
                                      {Number(val).toLocaleString(
                                        navigator.language,
                                        { minimumFractionDigits: 2 }
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                            {allDeductionValues.map((val, index) => {
                              if (parseFloat(val) > 0) {
                                return (
                                  <tr className="d-flex justify-content-end">
                                    <td>
                                      {Number(val).toLocaleString(
                                        navigator.language,
                                        { minimumFractionDigits: 2 }
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </td>
                        </tr>

                        <tr className="bold">
                          <td>Total</td>
                          <td style={{ textAlign: "right" }}>
                            Php{" "}
                            {totalEarnings
                              ? totalEarnings.toLocaleString(
                                  navigator.language,
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )
                              : "0.00"}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            Php{" "}
                            {totalDeductions
                              ? totalDeductions.toLocaleString(
                                  navigator.language,
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )
                              : "0.00"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>

                <table
                  className="table table-bordered"
                  style={{ width: "100%" }}
                >
                  <tbody>
                    <tr>
                      <td className="w-50">
                        <tr>
                          <td>Payment Date</td>
                          <td className="px-4"> : </td>
                          <td>{moment(payrollDetails.payment_date).format("MMM D, YYYY")}</td>
                        </tr>
                        <tr>
                          <td>Bank Name</td>
                          <td className="px-4"> : </td>
                          <td>{payrollDetails.bank?SentenceCaseName(payrollDetails.bank):''}</td>
                        </tr>
                        <tr>
                          <td>Bank Account Name</td>
                          <td className="px-4"> : </td>
                          <td>{payrollDetails.bank_account_name?SentenceCaseName(payrollDetails.bank_account_name):''}</td>
                        </tr>
                        <tr>
                          <td>Bank Account #</td>
                          <td className="px-4"> : </td>
                          <td>{payrollDetails.bank_account_number}</td>
                        </tr>
                      </td>
                      <td className="p-0">
                        <table className="table table-bordered">
                          <thead>
                            <tr className="payslip-primary-bg ">
                              <th className="d-flex justify-content-center">
                                NET PAY
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="d-flex justify-content-center bold netPay">
                                Php{" "}
                                {netPay
                                  ? netPay.toLocaleString(
                                      navigator.language,
                                      {
                                        minimumFractionDigits: 2,
                                      }
                                    )
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td className="d-flex justify-content-center pink-bg">
                                {wordedNetPay
                                  ? SentenceCaseName(
                                      wordedNetPay.toLocaleString(
                                        navigator.language
                                      ) + ' Pesos Only'
                                    )
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </table>

              <Row className="d-flex justify-content-end me-3 mt-4 mt-5">
                <Col className="print d-flex justify-content-end">
                  <ReactToPrint
                    trigger={() => (
                      <button className="print-button me-0 noprint me-1 exclude">
                        <span className="export-text">
                          <PrintIcon /> Print
                        </span>
                      </button>
                    )}
                    content={() => componentRef.current}
                    documentTitle={"Payslip"}
                  />
                  <button
                    className="print-button me-0 noprint me-1 exclude"
                    onClick={handleClickSend}
                  >
                    <span className="export-text">
                      <ForwardToInboxIcon /> Send Email
                    </span>
                  </button>
                </Col>
              </Row>

              <SendEmailPrompt
                show={showEmailPromt}
                hide={() => setShowEmailPrompt(false)}
                send={handleSendEmail}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeePayslip;
