import React, { useState } from 'react';
import {Modal, Row, Col, Container, Form, InputGroup} from 'react-bootstrap'
import { getToken, getUser, refreshPage, tokenExpired } from '../../utilities/common';
import { toast } from 'react-toastify';
import '../Modals/modal.css'

// reused this since they both require 'name' lang
import { validateBusinessUnit } from '../../helpers/Validation/Manage/BusinessUnitValidation';
import InputError from '../../helpers/InputError/InpuError';
import { GetAllRequirements } from '../../helpers/CommonApi';

export default function AddEmploymentStatus(props) {    
    const userToken = getToken();
    const userId = getUser();
    const [click, setClick] = useState(false);
    const allRequirements = GetAllRequirements();
    const [data, setData] = useState({
        name: '',
        requirements: [{
            requirement_id: ''
        }]
    });

    const [isError, setIsError] = useState({
        name: false
    })

    function handle(e, element = null) {
        let newEmploymentStatusData = { ...data };
        if (e.target.id === "requirement_id") {
            let index = data["requirements"].map(t => t.id).indexOf(element.id);
            newEmploymentStatusData["requirements"][index][e.target.id] = e.target.value;
        } else {
            newEmploymentStatusData[e.target.id] = e.target.value;
        }
        setData(newEmploymentStatusData);
    }

    function isOnlyWhiteSpace (str) {
        return !str.trim();
    }

    function renderInputError(var_name) {
        let value = data[var_name];
        if (!value || isOnlyWhiteSpace(value)===true) {
            return (
                <InputError
                    isValid={isError[var_name]}
                    message={"This field is required."}
                />
            )
        }
    }

    
    function makeRandomID(){
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

        for(var i = 0; i < 32; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    function addRecipient(e) {
        var recipients = data.requirements;

        recipients.push({
            key: makeRandomID(),  
            id: makeRandomID(), 
            requirement_id: ''
        });

        setData((prev) => {
            return {
                ...prev,
                requirements: recipients
            }
        });
    }

    function removeRecipient(e, req_index) {
        var recipients = data.requirements;
        recipients.splice(req_index, 1);
    
        setData((prev) => {
            return {
                ...prev,
                requirements: recipients
            }
        })
    }

    // Add employment status API
    function submitAddEmploymentStatus(e) {
        var axios = require('axios');
        var qs = require('qs');
        if(validateBusinessUnit(data, setIsError)==true && click===false) {
            setClick(true);
            axios({
            url: window.$link + 'employment_statuses/add',
            method: 'post',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                name: data.name,
                no_of_employment_requirements: data.requirements.length,
                employment_requirements: data.requirements
            })
            })
            .then(function (response) {
                toast.success("Employment Status Added Successfully!");
                setTimeout(() => refreshPage(), 500);
            })
            .catch(function (error) {
                setClick(false);
                toast.error("Failed to Add Employment Status");
                console.log(error.response.data.messages);
                tokenExpired(error);
            });
        }
    }

    return (
        <div className='account-details'>
            <Modal show={props.showmodal1} onHide={props.hidemodal1} centered>
                <Modal.Body className=''>
                    <div className="modal-header p-0">
                        Add Employment Status
                    </div>
                    <button type="button" class="btn-close"  onClick={props.hidemodal1}>
                        <span class="icon-cross"></span>
                        <span class="visually-hidden">Close</span>
                    </button>
                    <div className='body-head no-scroll p-0 mt-2'>
                        <Container>
                            <Row>
                                <Col>
                                    <Form.Label className="h6" htmlFor="name">Employment Status Name<span className='red'> *</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        {/* <InputGroup.Text><Icon.FilePerson /></InputGroup.Text> */}
                                        <Form.Control
                                            className='input-field'
                                            type="text"
                                            aria-label="name" 
                                            aria-describedby="name" 
                                            placeholder="" 
                                            name="name" 
                                            id='name' 
                                            onChange={(e) => handle(e)}
                                        />
                                        {renderInputError("name")}
                                    </InputGroup>
                                </Col>
                            </Row>
                            {
                                data["requirements"].map((data, index) => {
                                    return (
                                        <Row>
                                            <Col xs={10}>
                                            <Form.Label className="h6" htmlFor="name">Requirement</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Select 
                                                    value={data.requirement_id}
                                                    aria-label="requirement_id"
                                                    aria_describedby="requirement_id"
                                                    name="requirement_id"
                                                    id="requirement_id"
                                                    placeholder=''
                                                    onChange={(e) => handle(e, data)}
                                                >
                                                    <option value=''>Select Requirement</option>
                                                    {
                                                        allRequirements.map((data) => (
                                                            <option value={data.id}>{data.name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </InputGroup>
                                            </Col>
                                            <Col xs={2}>
                                                <Form.Label className="h6"></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <button id="removeRow" className="delete-button" onClick={(e) => removeRecipient(e, index)}>Remove</button>
                                                </InputGroup>
                                            </Col>
                                        </Row>  
                                    )
                                })
                            }
                            <Row>
                                <Col xs={2}>
                                    <div className="h6">Add Requirement</div>
                                    <button id="addRow" className="add-button" onClick={(e) => addRecipient(e)}>+ Add</button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className='d-flex justify-content-end me-2 mt-4'>
                        <button type="" className='cancel-button me-2' onClick={props.hidemodal1}>Cancel</button>
                        <button type="submit" className='save-button' onClick={submitAddEmploymentStatus}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}