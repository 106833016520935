import React from 'react'
import { useRef, useState } from 'react';
import {Row, Col, Container} from 'react-bootstrap'
import ReactToPrint from 'react-to-print';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

// Components
import Navbar from '../Navbar/Navbar'
import { getToken, getUser, tokenExpired } from '../../utilities/common';

// Image
import PrintIcon from '@mui/icons-material/Print';
import NewLogo from "../../images/NewLogo.png";

import 'react-datepicker/dist/react-datepicker.css';

export default function ViewLoan () {
    const date_today = new Date();
    const componentRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const loanId = location.state;
    const [loanDetails, setLoanDetails] = useState({});
    const [loanHistory, setLoanHistory] = useState([]);
    const [loanReport, setLoanReport] = useState({});
    
    function SentenceCaseName (name) {
        const sentenceCasedName = name.toLowerCase().replace(/(^|\s)[a-z]/g, (char) => char.toUpperCase());
        return sentenceCasedName
    }

    function splitAndCapitalize (name) {
        return name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    // GET Loan BY ID
    React.useEffect(() => {
        const userToken = getToken();
        const userId = getUser();

        var axios = require('axios');
        var qs = require('qs');

        axios({
            method: 'post',
            url: window.$link + 'employee_loans/get_loan_history',
            headers: {
                "api-key": window.$api_key,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: qs.stringify({
                requester: userId,
                token: userToken.replace(/['"]+/g, ''),
                loan_id: loanId
            })
        }).then(response => {
            const loanDeets = response.data.employee_history.overview[0];
            const history = response.data.employee_history.loan_history;
            setLoanDetails(loanDeets);
            setLoanHistory(history);
           
        }).catch(function (error) {
            console.log(error);
            tokenExpired(error);
        });
    },[]);

    return (
        <div className='comm-page'>
            <Navbar/>
            <div className='comm-page-container no-margin-top'>
                <div className='print-page-container me-3 mt-0 payslip' ref={componentRef}>
                <Row className='payslip-header d-flex justify-content-end'>LOAN</Row>
                <Row className='d-flex justify-content-end'>Date Printed: {date_today.toLocaleDateString('en-GB', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                })}
                </Row>
                <Row className='p-1 ms-2'><img src = {NewLogo} alt={"logo"} className="payslip-logo ms-0"></img></Row>
                <Row className='bold p-1'>{window.$company_name}</Row>
                <Row className='p-1'>{window.$company_address}</Row>
                <Row className='p-1 mt-3'>
                    <table className='table table-bordered'>
                        <thead>
                            <tr className='payslip-blue-bg'>
                                <th colSpan={2}>Loan Information</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='bold w-50'>Employee Name</td>
                                <td>{loanDetails.employee_name ? SentenceCaseName(loanDetails.employee_name) : ''}</td>
                            </tr>
                            <tr>
                                <td className='bold w-50'>Issue Date</td>
                                <td>{loanDetails.issue_date?moment(loanDetails.issue_date).format('MMM D, yyyy'):''}</td>
                            </tr>
                            <tr>
                                <td className='bold w-50'>Loan Type</td>
                                <td>{loanDetails.loan_type?splitAndCapitalize(loanDetails.loan_type): ''}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>

                <Row className='p-2 mt-3'>
                    <Row className='title-philhealth py-3 bold'>PAYMENT SUMMARY</Row>
                    <table className='table table-bordered'>
                        <thead>
                            <tr className='payslip-blue-bg'>
                                <th>Loan Amount</th>
                                <th>Paid Amount</th>
                                <th>Remaining Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='left'>Php {loanDetails.loan_amount ? parseFloat(loanDetails.loan_amount).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</td>
                                <td className='left'>Php {loanDetails.paid_amount ? parseFloat(loanDetails.paid_amount).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</td>
                                <td className='left'>Php {loanDetails.remaining_balance ? parseFloat(loanDetails.remaining_balance).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>

                <Row className='mt-3'>
                <Row className='title-philhealth py-3 bold'>PAYMENT HISTORY</Row>
                    <table className='table table-bordered'>
                        <thead>
                            <tr className='payslip-blue-bg'>
                                <th>Year</th>
                                <th>Month</th>
                                <th className='d-flex justify-content-end'>Paid Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (loanHistory && loanHistory.length) ? (
                                    loanHistory.map((data) => {
                                    return (
                                        <tr>
                                            <td className=''>{data.year}</td>
                                            <td className=''>{data.month}</td>
                                            <td className='d-flex justify-content-end'>Php {data.loan_payment ? parseFloat(data.loan_payment).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) : ''}</td>
                                        </tr>
                                    )
                                    })
                                ) : ('')
                            }
                        </tbody>
                    </table>
                </Row>

                <Row className="d-flex justify-content-end me-3 mt-4 mt-5">
                    <Col className="print d-flex justify-content-end">
                        <ReactToPrint
                            trigger={() => (
                                <button className="save-button me-2 noprint">
                                    <span><PrintIcon/> Print</span>
                                </button>
                            )}
                            content={() => componentRef.current}
                            documentTitle={"Loan"}
                        />  
                    
                        <button
                            className="save-button blue noprint exclude"
                            onClick={() => navigate(-1)}
                        >
                            <span className="export-text mx-auto">Back</span>
                        </button>
                    </Col>
                </Row>
                
            </div>
            </div>
        </div>
    )
}
